/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReviewInstance } from './ReviewInstance';
import {
    ReviewInstanceFromJSON,
    ReviewInstanceFromJSONTyped,
    ReviewInstanceToJSON,
} from './ReviewInstance';

/**
 * List of instances
 * @export
 * @interface ReviewInstanceListResult
 */
export interface ReviewInstanceListResult {
    /**
     * 
     * @type {string}
     * @memberof ReviewInstanceListResult
     */
    sql?: string;
    /**
     * 
     * @type {Array<ReviewInstance>}
     * @memberof ReviewInstanceListResult
     */
    instances?: Array<ReviewInstance>;
    /**
     * 
     * @type {string}
     * @memberof ReviewInstanceListResult
     */
    pageMarker?: string;
}

/**
 * Check if a given object implements the ReviewInstanceListResult interface.
 */
export function instanceOfReviewInstanceListResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReviewInstanceListResultFromJSON(json: any): ReviewInstanceListResult {
    return ReviewInstanceListResultFromJSONTyped(json, false);
}

export function ReviewInstanceListResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewInstanceListResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sql': !exists(json, 'sql') ? undefined : json['sql'],
        'instances': !exists(json, 'instances') ? undefined : ((json['instances'] as Array<any>).map(ReviewInstanceFromJSON)),
        'pageMarker': !exists(json, 'pageMarker') ? undefined : json['pageMarker'],
    };
}

export function ReviewInstanceListResultToJSON(value?: ReviewInstanceListResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sql': value.sql,
        'instances': value.instances === undefined ? undefined : ((value.instances as Array<any>).map(ReviewInstanceToJSON)),
        'pageMarker': value.pageMarker,
    };
}

