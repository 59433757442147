/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CohortReview } from './CohortReview';
import {
    CohortReviewFromJSON,
    CohortReviewFromJSONTyped,
    CohortReviewToJSON,
} from './CohortReview';

/**
 * 
 * @export
 * @interface CohortReviewWithCountResponse
 */
export interface CohortReviewWithCountResponse {
    /**
     * The size of the query results
     * @type {number}
     * @memberof CohortReviewWithCountResponse
     */
    queryResultSize: number;
    /**
     * 
     * @type {CohortReview}
     * @memberof CohortReviewWithCountResponse
     */
    cohortReview: CohortReview;
}

/**
 * Check if a given object implements the CohortReviewWithCountResponse interface.
 */
export function instanceOfCohortReviewWithCountResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "queryResultSize" in value;
    isInstance = isInstance && "cohortReview" in value;

    return isInstance;
}

export function CohortReviewWithCountResponseFromJSON(json: any): CohortReviewWithCountResponse {
    return CohortReviewWithCountResponseFromJSONTyped(json, false);
}

export function CohortReviewWithCountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CohortReviewWithCountResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'queryResultSize': json['queryResultSize'],
        'cohortReview': CohortReviewFromJSON(json['cohortReview']),
    };
}

export function CohortReviewWithCountResponseToJSON(value?: CohortReviewWithCountResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'queryResultSize': value.queryResultSize,
        'cohortReview': CohortReviewToJSON(value.cohortReview),
    };
}

