/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AnnotationType } from './AnnotationType';
import {
    AnnotationTypeFromJSON,
    AnnotationTypeFromJSONTyped,
    AnnotationTypeToJSON,
} from './AnnotationType';

/**
 * 
 * @export
 * @interface CohortAnnotationDefinition
 */
export interface CohortAnnotationDefinition {
    /**
     * the annotation definition id.
     * @type {number}
     * @memberof CohortAnnotationDefinition
     */
    cohortAnnotationDefinitionId?: number;
    /**
     * Entity tag for optimistic concurrency control. To be set during a read-modify-write to ensure that the client has not attempted to modify a changed resource.
     * @type {string}
     * @memberof CohortAnnotationDefinition
     */
    etag?: string;
    /**
     * the cohort id.
     * @type {number}
     * @memberof CohortAnnotationDefinition
     */
    cohortId: number;
    /**
     * the name of this annotation.
     * @type {string}
     * @memberof CohortAnnotationDefinition
     */
    columnName: string;
    /**
     * 
     * @type {AnnotationType}
     * @memberof CohortAnnotationDefinition
     */
    annotationType: AnnotationType;
    /**
     * 
     * @type {Array<string>}
     * @memberof CohortAnnotationDefinition
     */
    enumValues?: Array<string>;
}

/**
 * Check if a given object implements the CohortAnnotationDefinition interface.
 */
export function instanceOfCohortAnnotationDefinition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cohortId" in value;
    isInstance = isInstance && "columnName" in value;
    isInstance = isInstance && "annotationType" in value;

    return isInstance;
}

export function CohortAnnotationDefinitionFromJSON(json: any): CohortAnnotationDefinition {
    return CohortAnnotationDefinitionFromJSONTyped(json, false);
}

export function CohortAnnotationDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CohortAnnotationDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cohortAnnotationDefinitionId': !exists(json, 'cohortAnnotationDefinitionId') ? undefined : json['cohortAnnotationDefinitionId'],
        'etag': !exists(json, 'etag') ? undefined : json['etag'],
        'cohortId': json['cohortId'],
        'columnName': json['columnName'],
        'annotationType': AnnotationTypeFromJSON(json['annotationType']),
        'enumValues': !exists(json, 'enumValues') ? undefined : json['enumValues'],
    };
}

export function CohortAnnotationDefinitionToJSON(value?: CohortAnnotationDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cohortAnnotationDefinitionId': value.cohortAnnotationDefinitionId,
        'etag': value.etag,
        'cohortId': value.cohortId,
        'columnName': value.columnName,
        'annotationType': AnnotationTypeToJSON(value.annotationType),
        'enumValues': value.enumValues,
    };
}

