/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ParticipantCohortStatus } from './ParticipantCohortStatus';
import {
    ParticipantCohortStatusFromJSON,
    ParticipantCohortStatusFromJSONTyped,
    ParticipantCohortStatusToJSON,
} from './ParticipantCohortStatus';
import type { ReviewStatus } from './ReviewStatus';
import {
    ReviewStatusFromJSON,
    ReviewStatusFromJSONTyped,
    ReviewStatusToJSON,
} from './ReviewStatus';

/**
 * 
 * @export
 * @interface CohortReview
 */
export interface CohortReview {
    /**
     * 
     * @type {number}
     * @memberof CohortReview
     */
    cohortReviewId: number;
    /**
     * 
     * @type {number}
     * @memberof CohortReview
     */
    cohortId: number;
    /**
     * 
     * @type {number}
     * @memberof CohortReview
     */
    cdrVersionId: number;
    /**
     * Entity tag for optimistic concurrency control. To be set during a read-modify-write to ensure that the client has not attempted to modify a changed resource.
     * @type {string}
     * @memberof CohortReview
     */
    etag?: string;
    /**
     * Milliseconds since the UNIX epoch.
     * @type {number}
     * @memberof CohortReview
     */
    creationTime: number;
    /**
     * The user who last modified this resource.
     * @type {string}
     * @memberof CohortReview
     */
    lastModifiedBy?: string;
    /**
     * Milliseconds since the UNIX epoch.
     * @type {number}
     * @memberof CohortReview
     */
    lastModifiedTime?: number;
    /**
     * 
     * @type {string}
     * @memberof CohortReview
     */
    cohortDefinition: string;
    /**
     * 
     * @type {string}
     * @memberof CohortReview
     */
    cohortName: string;
    /**
     * 
     * @type {string}
     * @memberof CohortReview
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof CohortReview
     */
    matchedParticipantCount: number;
    /**
     * 
     * @type {number}
     * @memberof CohortReview
     */
    reviewSize?: number;
    /**
     * 
     * @type {number}
     * @memberof CohortReview
     */
    reviewedCount?: number;
    /**
     * 
     * @type {ReviewStatus}
     * @memberof CohortReview
     */
    reviewStatus: ReviewStatus;
    /**
     * 
     * @type {Array<ParticipantCohortStatus>}
     * @memberof CohortReview
     */
    participantCohortStatuses?: Array<ParticipantCohortStatus>;
}

/**
 * Check if a given object implements the CohortReview interface.
 */
export function instanceOfCohortReview(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cohortReviewId" in value;
    isInstance = isInstance && "cohortId" in value;
    isInstance = isInstance && "cdrVersionId" in value;
    isInstance = isInstance && "creationTime" in value;
    isInstance = isInstance && "cohortDefinition" in value;
    isInstance = isInstance && "cohortName" in value;
    isInstance = isInstance && "matchedParticipantCount" in value;
    isInstance = isInstance && "reviewStatus" in value;

    return isInstance;
}

export function CohortReviewFromJSON(json: any): CohortReview {
    return CohortReviewFromJSONTyped(json, false);
}

export function CohortReviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): CohortReview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cohortReviewId': json['cohortReviewId'],
        'cohortId': json['cohortId'],
        'cdrVersionId': json['cdrVersionId'],
        'etag': !exists(json, 'etag') ? undefined : json['etag'],
        'creationTime': json['creationTime'],
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedTime': !exists(json, 'lastModifiedTime') ? undefined : json['lastModifiedTime'],
        'cohortDefinition': json['cohortDefinition'],
        'cohortName': json['cohortName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'matchedParticipantCount': json['matchedParticipantCount'],
        'reviewSize': !exists(json, 'reviewSize') ? undefined : json['reviewSize'],
        'reviewedCount': !exists(json, 'reviewedCount') ? undefined : json['reviewedCount'],
        'reviewStatus': ReviewStatusFromJSON(json['reviewStatus']),
        'participantCohortStatuses': !exists(json, 'participantCohortStatuses') ? undefined : ((json['participantCohortStatuses'] as Array<any>).map(ParticipantCohortStatusFromJSON)),
    };
}

export function CohortReviewToJSON(value?: CohortReview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cohortReviewId': value.cohortReviewId,
        'cohortId': value.cohortId,
        'cdrVersionId': value.cdrVersionId,
        'etag': value.etag,
        'creationTime': value.creationTime,
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedTime': value.lastModifiedTime,
        'cohortDefinition': value.cohortDefinition,
        'cohortName': value.cohortName,
        'description': value.description,
        'matchedParticipantCount': value.matchedParticipantCount,
        'reviewSize': value.reviewSize,
        'reviewedCount': value.reviewedCount,
        'reviewStatus': ReviewStatusToJSON(value.reviewStatus),
        'participantCohortStatuses': value.participantCohortStatuses === undefined ? undefined : ((value.participantCohortStatuses as Array<any>).map(ParticipantCohortStatusToJSON)),
    };
}

