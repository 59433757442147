/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CohortStatus } from './CohortStatus';
import {
    CohortStatusFromJSON,
    CohortStatusFromJSONTyped,
    CohortStatusToJSON,
} from './CohortStatus';

/**
 * 
 * @export
 * @interface ParticipantCohortStatus
 */
export interface ParticipantCohortStatus {
    /**
     * 
     * @type {number}
     * @memberof ParticipantCohortStatus
     */
    participantId: number;
    /**
     * 
     * @type {CohortStatus}
     * @memberof ParticipantCohortStatus
     */
    status: CohortStatus;
    /**
     * 
     * @type {number}
     * @memberof ParticipantCohortStatus
     */
    genderConceptId?: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipantCohortStatus
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantCohortStatus
     */
    birthDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ParticipantCohortStatus
     */
    raceConceptId?: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipantCohortStatus
     */
    race?: string;
    /**
     * 
     * @type {number}
     * @memberof ParticipantCohortStatus
     */
    ethnicityConceptId?: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipantCohortStatus
     */
    ethnicity?: string;
    /**
     * 
     * @type {number}
     * @memberof ParticipantCohortStatus
     */
    sexAtBirthConceptId?: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipantCohortStatus
     */
    sexAtBirth?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantCohortStatus
     */
    deceased?: boolean;
}

/**
 * Check if a given object implements the ParticipantCohortStatus interface.
 */
export function instanceOfParticipantCohortStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "participantId" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function ParticipantCohortStatusFromJSON(json: any): ParticipantCohortStatus {
    return ParticipantCohortStatusFromJSONTyped(json, false);
}

export function ParticipantCohortStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantCohortStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'participantId': json['participantId'],
        'status': CohortStatusFromJSON(json['status']),
        'genderConceptId': !exists(json, 'genderConceptId') ? undefined : json['genderConceptId'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'birthDate': !exists(json, 'birthDate') ? undefined : json['birthDate'],
        'raceConceptId': !exists(json, 'raceConceptId') ? undefined : json['raceConceptId'],
        'race': !exists(json, 'race') ? undefined : json['race'],
        'ethnicityConceptId': !exists(json, 'ethnicityConceptId') ? undefined : json['ethnicityConceptId'],
        'ethnicity': !exists(json, 'ethnicity') ? undefined : json['ethnicity'],
        'sexAtBirthConceptId': !exists(json, 'sexAtBirthConceptId') ? undefined : json['sexAtBirthConceptId'],
        'sexAtBirth': !exists(json, 'sexAtBirth') ? undefined : json['sexAtBirth'],
        'deceased': !exists(json, 'deceased') ? undefined : json['deceased'],
    };
}

export function ParticipantCohortStatusToJSON(value?: ParticipantCohortStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'participantId': value.participantId,
        'status': CohortStatusToJSON(value.status),
        'genderConceptId': value.genderConceptId,
        'gender': value.gender,
        'birthDate': value.birthDate,
        'raceConceptId': value.raceConceptId,
        'race': value.race,
        'ethnicityConceptId': value.ethnicityConceptId,
        'ethnicity': value.ethnicity,
        'sexAtBirthConceptId': value.sexAtBirthConceptId,
        'sexAtBirth': value.sexAtBirth,
        'deceased': value.deceased,
    };
}

