/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * levels of access to workspace, NO ACCESS is akin to removing a user from a workspace ACL.
 * @export
 */
export const WorkspaceAccessLevel = {
    NO_ACCESS: 'NO ACCESS',
    READER: 'READER',
    WRITER: 'WRITER',
    OWNER: 'OWNER'
} as const;
export type WorkspaceAccessLevel = typeof WorkspaceAccessLevel[keyof typeof WorkspaceAccessLevel];


export function WorkspaceAccessLevelFromJSON(json: any): WorkspaceAccessLevel {
    return WorkspaceAccessLevelFromJSONTyped(json, false);
}

export function WorkspaceAccessLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceAccessLevel {
    return json as WorkspaceAccessLevel;
}

export function WorkspaceAccessLevelToJSON(value?: WorkspaceAccessLevel | null): any {
    return value as any;
}

