/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Degree = {
    PHD: 'PHD',
    MD: 'MD',
    JD: 'JD',
    EDD: 'EDD',
    MSN: 'MSN',
    MS: 'MS',
    MA: 'MA',
    MBA: 'MBA',
    ME: 'ME',
    MSW: 'MSW',
    MPH: 'MPH',
    BA: 'BA',
    BS: 'BS',
    BSN: 'BSN',
    NONE: 'NONE'
} as const;
export type Degree = typeof Degree[keyof typeof Degree];


export function DegreeFromJSON(json: any): Degree {
    return DegreeFromJSONTyped(json, false);
}

export function DegreeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Degree {
    return json as Degree;
}

export function DegreeToJSON(value?: Degree | null): any {
    return value as any;
}

