/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SearchGroup } from './SearchGroup';
import {
    SearchGroupFromJSON,
    SearchGroupFromJSONTyped,
    SearchGroupToJSON,
} from './SearchGroup';

/**
 * The CohortDefinition describes the state of the Cohort Builder at any given moment. It contains two keys, `include` and `exclude`, each of which specifies an array of SearchGroups which are `AND`ed together, and which collectively specify which subjects to include or exclude from the cohort.
 * @export
 * @interface CohortDefinition
 */
export interface CohortDefinition {
    /**
     * 
     * @type {Array<SearchGroup>}
     * @memberof CohortDefinition
     */
    includes: Array<SearchGroup>;
    /**
     * 
     * @type {Array<SearchGroup>}
     * @memberof CohortDefinition
     */
    excludes: Array<SearchGroup>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CohortDefinition
     */
    dataFilters: Array<string>;
}

/**
 * Check if a given object implements the CohortDefinition interface.
 */
export function instanceOfCohortDefinition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "includes" in value;
    isInstance = isInstance && "excludes" in value;
    isInstance = isInstance && "dataFilters" in value;

    return isInstance;
}

export function CohortDefinitionFromJSON(json: any): CohortDefinition {
    return CohortDefinitionFromJSONTyped(json, false);
}

export function CohortDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CohortDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'includes': ((json['includes'] as Array<any>).map(SearchGroupFromJSON)),
        'excludes': ((json['excludes'] as Array<any>).map(SearchGroupFromJSON)),
        'dataFilters': json['dataFilters'],
    };
}

export function CohortDefinitionToJSON(value?: CohortDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'includes': ((value.includes as Array<any>).map(SearchGroupToJSON)),
        'excludes': ((value.excludes as Array<any>).map(SearchGroupToJSON)),
        'dataFilters': value.dataFilters,
    };
}

