/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SearchGroupItem } from './SearchGroupItem';
import {
    SearchGroupItemFromJSON,
    SearchGroupItemFromJSONTyped,
    SearchGroupItemToJSON,
} from './SearchGroupItem';
import type { TemporalMention } from './TemporalMention';
import {
    TemporalMentionFromJSON,
    TemporalMentionFromJSONTyped,
    TemporalMentionToJSON,
} from './TemporalMention';
import type { TemporalTime } from './TemporalTime';
import {
    TemporalTimeFromJSON,
    TemporalTimeFromJSONTyped,
    TemporalTimeToJSON,
} from './TemporalTime';

/**
 * A SearchGroup is a container for groups of criteria which are `OR`ed together.
 * @export
 * @interface SearchGroup
 */
export interface SearchGroup {
    /**
     * Unique within the cohort definition
     * @type {string}
     * @memberof SearchGroup
     */
    id?: string;
    /**
     * optional custom name set by user
     * @type {string}
     * @memberof SearchGroup
     */
    name?: string;
    /**
     * indicates if this search group relates to time
     * @type {boolean}
     * @memberof SearchGroup
     */
    temporal: boolean;
    /**
     * 
     * @type {TemporalMention}
     * @memberof SearchGroup
     */
    mention?: TemporalMention;
    /**
     * 
     * @type {TemporalTime}
     * @memberof SearchGroup
     */
    time?: TemporalTime;
    /**
     * time value between temporal items
     * @type {number}
     * @memberof SearchGroup
     */
    timeValue?: number;
    /**
     * time increments(Day, Month or Year)
     * @type {string}
     * @memberof SearchGroup
     */
    timeFrame?: string;
    /**
     * 
     * @type {Array<SearchGroupItem>}
     * @memberof SearchGroup
     */
    items: Array<SearchGroupItem>;
}

/**
 * Check if a given object implements the SearchGroup interface.
 */
export function instanceOfSearchGroup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "temporal" in value;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function SearchGroupFromJSON(json: any): SearchGroup {
    return SearchGroupFromJSONTyped(json, false);
}

export function SearchGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'temporal': json['temporal'],
        'mention': !exists(json, 'mention') ? undefined : TemporalMentionFromJSON(json['mention']),
        'time': !exists(json, 'time') ? undefined : TemporalTimeFromJSON(json['time']),
        'timeValue': !exists(json, 'timeValue') ? undefined : json['timeValue'],
        'timeFrame': !exists(json, 'timeFrame') ? undefined : json['timeFrame'],
        'items': ((json['items'] as Array<any>).map(SearchGroupItemFromJSON)),
    };
}

export function SearchGroupToJSON(value?: SearchGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'temporal': value.temporal,
        'mention': TemporalMentionToJSON(value.mention),
        'time': TemporalTimeToJSON(value.time),
        'timeValue': value.timeValue,
        'timeFrame': value.timeFrame,
        'items': ((value.items as Array<any>).map(SearchGroupItemToJSON)),
    };
}

