/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResendWelcomeEmailRequest
 */
export interface ResendWelcomeEmailRequest {
    /**
     * Username of account to resend welcome email to
     * @type {string}
     * @memberof ResendWelcomeEmailRequest
     */
    username: string;
    /**
     * The nonce returned from the account creation API.
     * @type {string}
     * @memberof ResendWelcomeEmailRequest
     */
    creationNonce: string;
}

/**
 * Check if a given object implements the ResendWelcomeEmailRequest interface.
 */
export function instanceOfResendWelcomeEmailRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "creationNonce" in value;

    return isInstance;
}

export function ResendWelcomeEmailRequestFromJSON(json: any): ResendWelcomeEmailRequest {
    return ResendWelcomeEmailRequestFromJSONTyped(json, false);
}

export function ResendWelcomeEmailRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResendWelcomeEmailRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'creationNonce': json['creationNonce'],
    };
}

export function ResendWelcomeEmailRequestToJSON(value?: ResendWelcomeEmailRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'creationNonce': value.creationNonce,
    };
}

