/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Literal } from './Literal';
import {
    LiteralFromJSON,
    LiteralFromJSONTyped,
    LiteralToJSON,
} from './Literal';

/**
 * Filter on an attribute value (e.g. color=red)
 * @export
 * @interface AttributeFilter
 */
export interface AttributeFilter {
    /**
     * 
     * @type {string}
     * @memberof AttributeFilter
     */
    attribute?: string;
    /**
     * 
     * @type {string}
     * @memberof AttributeFilter
     */
    operator?: AttributeFilterOperatorEnum;
    /**
     * 
     * @type {Array<Literal>}
     * @memberof AttributeFilter
     */
    values?: Array<Literal>;
}


/**
 * @export
 */
export const AttributeFilterOperatorEnum = {
    IsNull: 'IS_NULL',
    IsNotNull: 'IS_NOT_NULL',
    IsEmptyString: 'IS_EMPTY_STRING',
    Equals: 'EQUALS',
    NotEquals: 'NOT_EQUALS',
    LessThan: 'LESS_THAN',
    GreaterThan: 'GREATER_THAN',
    LessThanOrEqual: 'LESS_THAN_OR_EQUAL',
    GreaterThanOrEqual: 'GREATER_THAN_OR_EQUAL',
    In: 'IN',
    NotIn: 'NOT_IN',
    Between: 'BETWEEN'
} as const;
export type AttributeFilterOperatorEnum = typeof AttributeFilterOperatorEnum[keyof typeof AttributeFilterOperatorEnum];


/**
 * Check if a given object implements the AttributeFilter interface.
 */
export function instanceOfAttributeFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AttributeFilterFromJSON(json: any): AttributeFilter {
    return AttributeFilterFromJSONTyped(json, false);
}

export function AttributeFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attribute': !exists(json, 'attribute') ? undefined : json['attribute'],
        'operator': !exists(json, 'operator') ? undefined : json['operator'],
        'values': !exists(json, 'values') ? undefined : ((json['values'] as Array<any>).map(LiteralFromJSON)),
    };
}

export function AttributeFilterToJSON(value?: AttributeFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attribute': value.attribute,
        'operator': value.operator,
        'values': value.values === undefined ? undefined : ((value.values as Array<any>).map(LiteralToJSON)),
    };
}

