/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GeneralDiscoverySource = {
    RESEARCH_ALL_OF_US_WEBSITE: 'RESEARCH_ALL_OF_US_WEBSITE',
    SOCIAL_MEDIA: 'SOCIAL_MEDIA',
    JOURNAL_OR_NEWS_ARTICLE: 'JOURNAL_OR_NEWS_ARTICLE',
    ACTIVITY_PRESENTATION_OR_EVENT: 'ACTIVITY_PRESENTATION_OR_EVENT',
    FRIENDS_OR_COLLEAGUES: 'FRIENDS_OR_COLLEAGUES',
    OTHER_WEBSITE: 'OTHER_WEBSITE',
    OTHER: 'OTHER'
} as const;
export type GeneralDiscoverySource = typeof GeneralDiscoverySource[keyof typeof GeneralDiscoverySource];


export function GeneralDiscoverySourceFromJSON(json: any): GeneralDiscoverySource {
    return GeneralDiscoverySourceFromJSONTyped(json, false);
}

export function GeneralDiscoverySourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneralDiscoverySource {
    return json as GeneralDiscoverySource;
}

export function GeneralDiscoverySourceToJSON(value?: GeneralDiscoverySource | null): any {
    return value as any;
}

