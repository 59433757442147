/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccessModuleStatus } from './AccessModuleStatus';
import {
    AccessModuleStatusFromJSON,
    AccessModuleStatusFromJSONTyped,
    AccessModuleStatusToJSON,
} from './AccessModuleStatus';

/**
 * 
 * @export
 * @interface ProfileAccessModules
 */
export interface ProfileAccessModules {
    /**
     * 
     * @type {Array<AccessModuleStatus>}
     * @memberof ProfileAccessModules
     */
    modules?: Array<AccessModuleStatus>;
}

/**
 * Check if a given object implements the ProfileAccessModules interface.
 */
export function instanceOfProfileAccessModules(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProfileAccessModulesFromJSON(json: any): ProfileAccessModules {
    return ProfileAccessModulesFromJSONTyped(json, false);
}

export function ProfileAccessModulesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileAccessModules {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modules': !exists(json, 'modules') ? undefined : ((json['modules'] as Array<any>).map(AccessModuleStatusFromJSON)),
    };
}

export function ProfileAccessModulesToJSON(value?: ProfileAccessModules | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modules': value.modules === undefined ? undefined : ((value.modules as Array<any>).map(AccessModuleStatusToJSON)),
    };
}

