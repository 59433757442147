/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Vocabulary } from './Vocabulary';
import {
    VocabularyFromJSON,
    VocabularyFromJSONTyped,
    VocabularyToJSON,
} from './Vocabulary';

/**
 * 
 * @export
 * @interface VocabularyListResponse
 */
export interface VocabularyListResponse {
    /**
     * 
     * @type {Array<Vocabulary>}
     * @memberof VocabularyListResponse
     */
    items: Array<Vocabulary>;
}

/**
 * Check if a given object implements the VocabularyListResponse interface.
 */
export function instanceOfVocabularyListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function VocabularyListResponseFromJSON(json: any): VocabularyListResponse {
    return VocabularyListResponseFromJSONTyped(json, false);
}

export function VocabularyListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VocabularyListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(VocabularyFromJSON)),
    };
}

export function VocabularyListResponseToJSON(value?: VocabularyListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(VocabularyToJSON)),
    };
}

