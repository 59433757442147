/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Cohort
 */
export interface Cohort {
    /**
     * 
     * @type {number}
     * @memberof Cohort
     */
    id?: number;
    /**
     * Entity tag for optimistic concurrency control. To be set during a read-modify-write to ensure that the client has not attempted to modify a changed resource.
     * @type {string}
     * @memberof Cohort
     */
    etag?: string;
    /**
     * 
     * @type {string}
     * @memberof Cohort
     */
    name: string;
    /**
     * Internal representation of the cohort definition. Clients should not depend directly on this, but instead call client functions to issue a SQL query for the cohort.
     * @type {string}
     * @memberof Cohort
     */
    criteria: string;
    /**
     * 
     * @type {string}
     * @memberof Cohort
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof Cohort
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Cohort
     */
    creator?: string;
    /**
     * Milliseconds since the UNIX epoch.
     * @type {number}
     * @memberof Cohort
     */
    creationTime?: number;
    /**
     * The user who last modified this resource.
     * @type {string}
     * @memberof Cohort
     */
    lastModifiedBy?: string;
    /**
     * Milliseconds since the UNIX epoch.
     * @type {number}
     * @memberof Cohort
     */
    lastModifiedTime?: number;
}

/**
 * Check if a given object implements the Cohort interface.
 */
export function instanceOfCohort(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "criteria" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function CohortFromJSON(json: any): Cohort {
    return CohortFromJSONTyped(json, false);
}

export function CohortFromJSONTyped(json: any, ignoreDiscriminator: boolean): Cohort {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'etag': !exists(json, 'etag') ? undefined : json['etag'],
        'name': json['name'],
        'criteria': json['criteria'],
        'type': json['type'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'creator': !exists(json, 'creator') ? undefined : json['creator'],
        'creationTime': !exists(json, 'creationTime') ? undefined : json['creationTime'],
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedTime': !exists(json, 'lastModifiedTime') ? undefined : json['lastModifiedTime'],
    };
}

export function CohortToJSON(value?: Cohort | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'etag': value.etag,
        'name': value.name,
        'criteria': value.criteria,
        'type': value.type,
        'description': value.description,
        'creator': value.creator,
        'creationTime': value.creationTime,
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedTime': value.lastModifiedTime,
    };
}

