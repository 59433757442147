/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ParticipantCohortAnnotation } from './ParticipantCohortAnnotation';
import {
    ParticipantCohortAnnotationFromJSON,
    ParticipantCohortAnnotationFromJSONTyped,
    ParticipantCohortAnnotationToJSON,
} from './ParticipantCohortAnnotation';

/**
 * 
 * @export
 * @interface ParticipantCohortAnnotationListResponse
 */
export interface ParticipantCohortAnnotationListResponse {
    /**
     * 
     * @type {Array<ParticipantCohortAnnotation>}
     * @memberof ParticipantCohortAnnotationListResponse
     */
    items: Array<ParticipantCohortAnnotation>;
}

/**
 * Check if a given object implements the ParticipantCohortAnnotationListResponse interface.
 */
export function instanceOfParticipantCohortAnnotationListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function ParticipantCohortAnnotationListResponseFromJSON(json: any): ParticipantCohortAnnotationListResponse {
    return ParticipantCohortAnnotationListResponseFromJSONTyped(json, false);
}

export function ParticipantCohortAnnotationListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantCohortAnnotationListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(ParticipantCohortAnnotationFromJSON)),
    };
}

export function ParticipantCohortAnnotationListResponseToJSON(value?: ParticipantCohortAnnotationListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(ParticipantCohortAnnotationToJSON)),
    };
}

