/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillingAccount } from './BillingAccount';
import {
    BillingAccountFromJSON,
    BillingAccountFromJSONTyped,
    BillingAccountToJSON,
} from './BillingAccount';

/**
 * 
 * @export
 * @interface WorkbenchListBillingAccountsResponse
 */
export interface WorkbenchListBillingAccountsResponse {
    /**
     * 
     * @type {Array<BillingAccount>}
     * @memberof WorkbenchListBillingAccountsResponse
     */
    billingAccounts: Array<BillingAccount>;
}

/**
 * Check if a given object implements the WorkbenchListBillingAccountsResponse interface.
 */
export function instanceOfWorkbenchListBillingAccountsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "billingAccounts" in value;

    return isInstance;
}

export function WorkbenchListBillingAccountsResponseFromJSON(json: any): WorkbenchListBillingAccountsResponse {
    return WorkbenchListBillingAccountsResponseFromJSONTyped(json, false);
}

export function WorkbenchListBillingAccountsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkbenchListBillingAccountsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billingAccounts': ((json['billingAccounts'] as Array<any>).map(BillingAccountFromJSON)),
    };
}

export function WorkbenchListBillingAccountsResponseToJSON(value?: WorkbenchListBillingAccountsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billingAccounts': ((value.billingAccounts as Array<any>).map(BillingAccountToJSON)),
    };
}

