/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NewUserSatisfactionSurveySatisfaction } from './NewUserSatisfactionSurveySatisfaction';
import {
    NewUserSatisfactionSurveySatisfactionFromJSON,
    NewUserSatisfactionSurveySatisfactionFromJSONTyped,
    NewUserSatisfactionSurveySatisfactionToJSON,
} from './NewUserSatisfactionSurveySatisfaction';

/**
 * 
 * @export
 * @interface CreateNewUserSatisfactionSurvey
 */
export interface CreateNewUserSatisfactionSurvey {
    /**
     * 
     * @type {NewUserSatisfactionSurveySatisfaction}
     * @memberof CreateNewUserSatisfactionSurvey
     */
    satisfaction?: NewUserSatisfactionSurveySatisfaction;
    /**
     * Additional information regarding their satisfaction score
     * @type {string}
     * @memberof CreateNewUserSatisfactionSurvey
     */
    additionalInfo?: string;
}

/**
 * Check if a given object implements the CreateNewUserSatisfactionSurvey interface.
 */
export function instanceOfCreateNewUserSatisfactionSurvey(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateNewUserSatisfactionSurveyFromJSON(json: any): CreateNewUserSatisfactionSurvey {
    return CreateNewUserSatisfactionSurveyFromJSONTyped(json, false);
}

export function CreateNewUserSatisfactionSurveyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateNewUserSatisfactionSurvey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'satisfaction': !exists(json, 'satisfaction') ? undefined : NewUserSatisfactionSurveySatisfactionFromJSON(json['satisfaction']),
        'additionalInfo': !exists(json, 'additionalInfo') ? undefined : json['additionalInfo'],
    };
}

export function CreateNewUserSatisfactionSurveyToJSON(value?: CreateNewUserSatisfactionSurvey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'satisfaction': NewUserSatisfactionSurveySatisfactionToJSON(value.satisfaction),
        'additionalInfo': value.additionalInfo,
    };
}

