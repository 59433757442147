/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CriteriaMenu
 */
export interface CriteriaMenu {
    /**
     * The unique identifier for the menu item
     * @type {number}
     * @memberof CriteriaMenu
     */
    id: number;
    /**
     * The unique identifier for the menu parent
     * @type {number}
     * @memberof CriteriaMenu
     */
    parentId: number;
    /**
     * The category for this menu item(Program Data or Domains)
     * @type {string}
     * @memberof CriteriaMenu
     */
    category: string;
    /**
     * The domain for this menu item
     * @type {string}
     * @memberof CriteriaMenu
     */
    domainId: string;
    /**
     * The type for this menu item
     * @type {string}
     * @memberof CriteriaMenu
     */
    type?: string;
    /**
     * The name
     * @type {string}
     * @memberof CriteriaMenu
     */
    name: string;
    /**
     * Is this a parent
     * @type {boolean}
     * @memberof CriteriaMenu
     */
    group: boolean;
    /**
     * The order
     * @type {number}
     * @memberof CriteriaMenu
     */
    sortOrder: number;
}

/**
 * Check if a given object implements the CriteriaMenu interface.
 */
export function instanceOfCriteriaMenu(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "parentId" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "domainId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "group" in value;
    isInstance = isInstance && "sortOrder" in value;

    return isInstance;
}

export function CriteriaMenuFromJSON(json: any): CriteriaMenu {
    return CriteriaMenuFromJSONTyped(json, false);
}

export function CriteriaMenuFromJSONTyped(json: any, ignoreDiscriminator: boolean): CriteriaMenu {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'parentId': json['parentId'],
        'category': json['category'],
        'domainId': json['domainId'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'name': json['name'],
        'group': json['group'],
        'sortOrder': json['sortOrder'],
    };
}

export function CriteriaMenuToJSON(value?: CriteriaMenu | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'parentId': value.parentId,
        'category': value.category,
        'domainId': value.domainId,
        'type': value.type,
        'name': value.name,
        'group': value.group,
        'sortOrder': value.sortOrder,
    };
}

