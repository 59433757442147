/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ResourceType = {
    NOTEBOOK: 'NOTEBOOK',
    COHORT: 'COHORT',
    COHORT_SEARCH_GROUP: 'COHORT_SEARCH_GROUP',
    COHORT_SEARCH_ITEM: 'COHORT_SEARCH_ITEM',
    COHORT_REVIEW: 'COHORT_REVIEW',
    CONCEPT_SET: 'CONCEPT_SET',
    DATASET: 'DATASET',
    WORKSPACE: 'WORKSPACE'
} as const;
export type ResourceType = typeof ResourceType[keyof typeof ResourceType];


export function ResourceTypeFromJSON(json: any): ResourceType {
    return ResourceTypeFromJSONTyped(json, false);
}

export function ResourceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceType {
    return json as ResourceType;
}

export function ResourceTypeToJSON(value?: ResourceType | null): any {
    return value as any;
}

