/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Disability } from './Disability';
import {
    DisabilityFromJSON,
    DisabilityFromJSONTyped,
    DisabilityToJSON,
} from './Disability';
import type { Education } from './Education';
import {
    EducationFromJSON,
    EducationFromJSONTyped,
    EducationToJSON,
} from './Education';
import type { Ethnicity } from './Ethnicity';
import {
    EthnicityFromJSON,
    EthnicityFromJSONTyped,
    EthnicityToJSON,
} from './Ethnicity';
import type { GenderIdentity } from './GenderIdentity';
import {
    GenderIdentityFromJSON,
    GenderIdentityFromJSONTyped,
    GenderIdentityToJSON,
} from './GenderIdentity';
import type { Race } from './Race';
import {
    RaceFromJSON,
    RaceFromJSONTyped,
    RaceToJSON,
} from './Race';
import type { SexAtBirth } from './SexAtBirth';
import {
    SexAtBirthFromJSON,
    SexAtBirthFromJSONTyped,
    SexAtBirthToJSON,
} from './SexAtBirth';

/**
 * 
 * @export
 * @interface DemographicSurvey
 */
export interface DemographicSurvey {
    /**
     * 
     * @type {Array<Race>}
     * @memberof DemographicSurvey
     */
    race?: Array<Race>;
    /**
     * 
     * @type {Ethnicity}
     * @memberof DemographicSurvey
     */
    ethnicity?: Ethnicity;
    /**
     * 
     * @type {boolean}
     * @memberof DemographicSurvey
     */
    identifiesAsLgbtq?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DemographicSurvey
     */
    lgbtqIdentity?: string;
    /**
     * 
     * @type {Array<GenderIdentity>}
     * @memberof DemographicSurvey
     */
    genderIdentityList?: Array<GenderIdentity>;
    /**
     * 
     * @type {Array<SexAtBirth>}
     * @memberof DemographicSurvey
     */
    sexAtBirth?: Array<SexAtBirth>;
    /**
     * 
     * @type {number}
     * @memberof DemographicSurvey
     */
    yearOfBirth?: number;
    /**
     * 
     * @type {Education}
     * @memberof DemographicSurvey
     */
    education?: Education;
    /**
     * 
     * @type {Disability}
     * @memberof DemographicSurvey
     */
    disability?: Disability;
}

/**
 * Check if a given object implements the DemographicSurvey interface.
 */
export function instanceOfDemographicSurvey(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DemographicSurveyFromJSON(json: any): DemographicSurvey {
    return DemographicSurveyFromJSONTyped(json, false);
}

export function DemographicSurveyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DemographicSurvey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'race': !exists(json, 'race') ? undefined : ((json['race'] as Array<any>).map(RaceFromJSON)),
        'ethnicity': !exists(json, 'ethnicity') ? undefined : EthnicityFromJSON(json['ethnicity']),
        'identifiesAsLgbtq': !exists(json, 'identifiesAsLgbtq') ? undefined : json['identifiesAsLgbtq'],
        'lgbtqIdentity': !exists(json, 'lgbtqIdentity') ? undefined : json['lgbtqIdentity'],
        'genderIdentityList': !exists(json, 'genderIdentityList') ? undefined : ((json['genderIdentityList'] as Array<any>).map(GenderIdentityFromJSON)),
        'sexAtBirth': !exists(json, 'sexAtBirth') ? undefined : ((json['sexAtBirth'] as Array<any>).map(SexAtBirthFromJSON)),
        'yearOfBirth': !exists(json, 'yearOfBirth') ? undefined : json['yearOfBirth'],
        'education': !exists(json, 'education') ? undefined : EducationFromJSON(json['education']),
        'disability': !exists(json, 'disability') ? undefined : DisabilityFromJSON(json['disability']),
    };
}

export function DemographicSurveyToJSON(value?: DemographicSurvey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'race': value.race === undefined ? undefined : ((value.race as Array<any>).map(RaceToJSON)),
        'ethnicity': EthnicityToJSON(value.ethnicity),
        'identifiesAsLgbtq': value.identifiesAsLgbtq,
        'lgbtqIdentity': value.lgbtqIdentity,
        'genderIdentityList': value.genderIdentityList === undefined ? undefined : ((value.genderIdentityList as Array<any>).map(GenderIdentityToJSON)),
        'sexAtBirth': value.sexAtBirth === undefined ? undefined : ((value.sexAtBirth as Array<any>).map(SexAtBirthToJSON)),
        'yearOfBirth': value.yearOfBirth,
        'education': EducationToJSON(value.education),
        'disability': DisabilityToJSON(value.disability),
    };
}

