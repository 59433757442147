/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The union of the roles researchers can have at Institutions of all OrganizationTypes, derived from CAPS requirements.
 * @export
 */
export const InstitutionalRole = {
    UNDERGRADUATE: 'UNDERGRADUATE',
    TRAINEE: 'TRAINEE',
    FELLOW: 'FELLOW',
    EARLY_CAREER: 'EARLY_CAREER',
    MID_CAREER: 'MID_CAREER',
    LATE_CAREER: 'LATE_CAREER',
    PRE_DOCTORAL: 'PRE_DOCTORAL',
    POST_DOCTORAL: 'POST_DOCTORAL',
    SENIOR_RESEARCHER: 'SENIOR_RESEARCHER',
    TEACHER: 'TEACHER',
    STUDENT: 'STUDENT',
    ADMIN: 'ADMIN',
    PROJECT_PERSONNEL: 'PROJECT_PERSONNEL',
    OTHER: 'OTHER',
    ASSOCIATE_SCIENTIST: 'ASSOCIATE_SCIENTIST',
    MID_CAREER_SCIENTIST: 'MID_CAREER_SCIENTIST',
    SENIOR_SCIENTIST: 'SENIOR_SCIENTIST',
    DATA_ANALYST: 'DATA_ANALYST',
    HIGH_SCHOOL_STUDENT: 'HIGH_SCHOOL_STUDENT'
} as const;
export type InstitutionalRole = typeof InstitutionalRole[keyof typeof InstitutionalRole];


export function InstitutionalRoleFromJSON(json: any): InstitutionalRole {
    return InstitutionalRoleFromJSONTyped(json, false);
}

export function InstitutionalRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstitutionalRole {
    return json as InstitutionalRole;
}

export function InstitutionalRoleToJSON(value?: InstitutionalRole | null): any {
    return value as any;
}

