/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Composite object containing audited target information. See AuditLogEntry for individual fields.
 * @export
 * @interface AuditTarget
 */
export interface AuditTarget {
    /**
     * 
     * @type {number}
     * @memberof AuditTarget
     */
    targetId?: number;
    /**
     * 
     * @type {string}
     * @memberof AuditTarget
     */
    targetType?: string;
}

/**
 * Check if a given object implements the AuditTarget interface.
 */
export function instanceOfAuditTarget(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuditTargetFromJSON(json: any): AuditTarget {
    return AuditTargetFromJSONTyped(json, false);
}

export function AuditTargetFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditTarget {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'targetId': !exists(json, 'targetId') ? undefined : json['targetId'],
        'targetType': !exists(json, 'targetType') ? undefined : json['targetType'],
    };
}

export function AuditTargetToJSON(value?: AuditTarget | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'targetId': value.targetId,
        'targetType': value.targetType,
    };
}

