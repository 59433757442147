/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConceptSetConceptId
 */
export interface ConceptSetConceptId {
    /**
     * 
     * @type {number}
     * @memberof ConceptSetConceptId
     */
    conceptId: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConceptSetConceptId
     */
    standard: boolean;
}

/**
 * Check if a given object implements the ConceptSetConceptId interface.
 */
export function instanceOfConceptSetConceptId(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "conceptId" in value;
    isInstance = isInstance && "standard" in value;

    return isInstance;
}

export function ConceptSetConceptIdFromJSON(json: any): ConceptSetConceptId {
    return ConceptSetConceptIdFromJSONTyped(json, false);
}

export function ConceptSetConceptIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConceptSetConceptId {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conceptId': json['conceptId'],
        'standard': json['standard'],
    };
}

export function ConceptSetConceptIdToJSON(value?: ConceptSetConceptId | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conceptId': value.conceptId,
        'standard': value.standard,
    };
}

