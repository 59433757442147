/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AppLocalizeRequest,
  AppLocalizeResponse,
  CreateAppRequest,
  EmptyResponse,
  ErrorResponse,
  ListAppsResponse,
  UserAppEnvironment,
} from '../models';
import {
    AppLocalizeRequestFromJSON,
    AppLocalizeRequestToJSON,
    AppLocalizeResponseFromJSON,
    AppLocalizeResponseToJSON,
    CreateAppRequestFromJSON,
    CreateAppRequestToJSON,
    EmptyResponseFromJSON,
    EmptyResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ListAppsResponseFromJSON,
    ListAppsResponseToJSON,
    UserAppEnvironmentFromJSON,
    UserAppEnvironmentToJSON,
} from '../models';

export interface AppsApiCreateAppOperationRequest {
    workspaceNamespace: string;
    app: CreateAppRequest;
}

export interface AppsApiDeleteAppRequest {
    workspaceNamespace: string;
    appName: string;
    deleteDisk?: boolean;
}

export interface AppsApiListAppsInWorkspaceRequest {
    workspaceNamespace: string;
}

export interface AppsApiLocalizeAppRequest {
    workspaceNamespace: string;
    appName: string;
    localizeAllFiles?: boolean;
    body?: AppLocalizeRequest;
}

export interface AppsApiUpdateAppRequest {
    workspaceNamespace: string;
    appName: string;
    app?: UserAppEnvironment;
}

/**
 * 
 */
export class AppsApi extends runtime.BaseAPI {

    /**
     * Creates a new user app for the current user in the given workspace. If a user app already  exists for the user in this billing project, a 409 conflict error is returned (even if the  app is still initializing or is not in a ready state). 
     * Create a user app in this workspace.
     */
    async createAppRaw(requestParameters: AppsApiCreateAppOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling createApp.');
        }

        if (requestParameters.app === null || requestParameters.app === undefined) {
            throw new runtime.RequiredError('app','Required parameter requestParameters.app was null or undefined when calling createApp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/apps`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAppRequestToJSON(requestParameters.app),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Creates a new user app for the current user in the given workspace. If a user app already  exists for the user in this billing project, a 409 conflict error is returned (even if the  app is still initializing or is not in a ready state). 
     * Create a user app in this workspace.
     */
    async createApp(workspaceNamespace: string, app: CreateAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.createAppRaw({ workspaceNamespace: workspaceNamespace, app: app }, initOverrides);
        return await response.value();
    }

    /**
     * Delete a workspace user app.
     */
    async deleteAppRaw(requestParameters: AppsApiDeleteAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling deleteApp.');
        }

        if (requestParameters.appName === null || requestParameters.appName === undefined) {
            throw new runtime.RequiredError('appName','Required parameter requestParameters.appName was null or undefined when calling deleteApp.');
        }

        const queryParameters: any = {};

        if (requestParameters.deleteDisk !== undefined) {
            queryParameters['deleteDisk'] = requestParameters.deleteDisk;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/apps/{appName}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"appName"}}`, encodeURIComponent(String(requestParameters.appName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Delete a workspace user app.
     */
    async deleteApp(workspaceNamespace: string, appName: string, deleteDisk?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.deleteAppRaw({ workspaceNamespace: workspaceNamespace, appName: appName, deleteDisk: deleteDisk }, initOverrides);
        return await response.value();
    }

    /**
     * List user apps the caller has access to, in given workspaces
     * List user apps in workspace
     */
    async listAppsInWorkspaceRaw(requestParameters: AppsApiListAppsInWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAppsResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling listAppsInWorkspace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/apps`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAppsResponseFromJSON(jsonValue));
    }

    /**
     * List user apps the caller has access to, in given workspaces
     * List user apps in workspace
     */
    async listAppsInWorkspace(workspaceNamespace: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAppsResponse> {
        const response = await this.listAppsInWorkspaceRaw({ workspaceNamespace: workspaceNamespace }, initOverrides);
        return await response.value();
    }

    /**
     * Localize files to the user\'s GKE App.
     * Localize files to the user\'s GKE App.
     */
    async localizeAppRaw(requestParameters: AppsApiLocalizeAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppLocalizeResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling localizeApp.');
        }

        if (requestParameters.appName === null || requestParameters.appName === undefined) {
            throw new runtime.RequiredError('appName','Required parameter requestParameters.appName was null or undefined when calling localizeApp.');
        }

        const queryParameters: any = {};

        if (requestParameters.localizeAllFiles !== undefined) {
            queryParameters['localizeAllFiles'] = requestParameters.localizeAllFiles;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/apps/{appName}/localize`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"appName"}}`, encodeURIComponent(String(requestParameters.appName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppLocalizeRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppLocalizeResponseFromJSON(jsonValue));
    }

    /**
     * Localize files to the user\'s GKE App.
     * Localize files to the user\'s GKE App.
     */
    async localizeApp(workspaceNamespace: string, appName: string, localizeAllFiles?: boolean, body?: AppLocalizeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppLocalizeResponse> {
        const response = await this.localizeAppRaw({ workspaceNamespace: workspaceNamespace, appName: appName, localizeAllFiles: localizeAllFiles, body: body }, initOverrides);
        return await response.value();
    }

    /**
     * In order to update the configuration of a user app, it must first be running or paused
     * Updates the configuration of a user app
     */
    async updateAppRaw(requestParameters: AppsApiUpdateAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling updateApp.');
        }

        if (requestParameters.appName === null || requestParameters.appName === undefined) {
            throw new runtime.RequiredError('appName','Required parameter requestParameters.appName was null or undefined when calling updateApp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/apps/{appName}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"appName"}}`, encodeURIComponent(String(requestParameters.appName))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserAppEnvironmentToJSON(requestParameters.app),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * In order to update the configuration of a user app, it must first be running or paused
     * Updates the configuration of a user app
     */
    async updateApp(workspaceNamespace: string, appName: string, app?: UserAppEnvironment, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.updateAppRaw({ workspaceNamespace: workspaceNamespace, appName: appName, app: app }, initOverrides);
        return await response.value();
    }

}
