/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UserResponse,
  WorkbenchListBillingAccountsResponse,
} from '../models';
import {
    UserResponseFromJSON,
    UserResponseToJSON,
    WorkbenchListBillingAccountsResponseFromJSON,
    WorkbenchListBillingAccountsResponseToJSON,
} from '../models';

export interface UserApiUserSearchRequest {
    accessTierShortName: string;
    term: string;
    pageToken?: string;
    pageSize?: number;
    sortOrder?: string;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Returns a list of billing accounts the caller can set a workspace\'s billing account to
     */
    async listBillingAccountsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkbenchListBillingAccountsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/user/billingAccounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkbenchListBillingAccountsResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of billing accounts the caller can set a workspace\'s billing account to
     */
    async listBillingAccounts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkbenchListBillingAccountsResponse> {
        const response = await this.listBillingAccountsRaw(initOverrides);
        return await response.value();
    }

    /**
     * SignOut user\'s gsuite account
     */
    async signOutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/signOut`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * SignOut user\'s gsuite account
     */
    async signOut(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.signOutRaw(initOverrides);
    }

    /**
     * Return a page of users matching a search term and an access tier. Used by autocomplete for workspace sharing. 
     */
    async userSearchRaw(requestParameters: UserApiUserSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserResponse>> {
        if (requestParameters.accessTierShortName === null || requestParameters.accessTierShortName === undefined) {
            throw new runtime.RequiredError('accessTierShortName','Required parameter requestParameters.accessTierShortName was null or undefined when calling userSearch.');
        }

        if (requestParameters.term === null || requestParameters.term === undefined) {
            throw new runtime.RequiredError('term','Required parameter requestParameters.term was null or undefined when calling userSearch.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageToken !== undefined) {
            queryParameters['pageToken'] = requestParameters.pageToken;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/userSearch/{accessTierShortName}/{term}`.replace(`{${"accessTierShortName"}}`, encodeURIComponent(String(requestParameters.accessTierShortName))).replace(`{${"term"}}`, encodeURIComponent(String(requestParameters.term))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseFromJSON(jsonValue));
    }

    /**
     * Return a page of users matching a search term and an access tier. Used by autocomplete for workspace sharing. 
     */
    async userSearch(accessTierShortName: string, term: string, pageToken?: string, pageSize?: number, sortOrder?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserResponse> {
        const response = await this.userSearchRaw({ accessTierShortName: accessTierShortName, term: term, pageToken: pageToken, pageSize: pageSize, sortOrder: sortOrder }, initOverrides);
        return await response.value();
    }

}
