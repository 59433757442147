/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateEgressBypassWindowRequest
 */
export interface CreateEgressBypassWindowRequest {
    /**
     * Timestamp when user egress bypass window begins in milliseconds since the UNIX epoch
     * @type {number}
     * @memberof CreateEgressBypassWindowRequest
     */
    startTime?: number;
    /**
     * Brief description of this bypass request.
     * @type {string}
     * @memberof CreateEgressBypassWindowRequest
     */
    byPassDescription?: string;
}

/**
 * Check if a given object implements the CreateEgressBypassWindowRequest interface.
 */
export function instanceOfCreateEgressBypassWindowRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateEgressBypassWindowRequestFromJSON(json: any): CreateEgressBypassWindowRequest {
    return CreateEgressBypassWindowRequestFromJSONTyped(json, false);
}

export function CreateEgressBypassWindowRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEgressBypassWindowRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startTime': !exists(json, 'startTime') ? undefined : json['startTime'],
        'byPassDescription': !exists(json, 'byPassDescription') ? undefined : json['byPassDescription'],
    };
}

export function CreateEgressBypassWindowRequestToJSON(value?: CreateEgressBypassWindowRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startTime': value.startTime,
        'byPassDescription': value.byPassDescription,
    };
}

