/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Which terms best express how you describe your current gender identity? (Select all that apply)
 * @export
 */
export const GenderIdentityV2 = {
    GENDERQUEER: 'GENDERQUEER',
    MAN: 'MAN',
    NON_BINARY: 'NON_BINARY',
    QUESTIONING: 'QUESTIONING',
    TRANS_MAN: 'TRANS_MAN',
    TRANS_WOMAN: 'TRANS_WOMAN',
    TWO_SPIRIT: 'TWO_SPIRIT',
    WOMAN: 'WOMAN',
    OTHER: 'OTHER',
    PREFER_NOT_TO_ANSWER: 'PREFER_NOT_TO_ANSWER'
} as const;
export type GenderIdentityV2 = typeof GenderIdentityV2[keyof typeof GenderIdentityV2];


export function GenderIdentityV2FromJSON(json: any): GenderIdentityV2 {
    return GenderIdentityV2FromJSONTyped(json, false);
}

export function GenderIdentityV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): GenderIdentityV2 {
    return json as GenderIdentityV2;
}

export function GenderIdentityV2ToJSON(value?: GenderIdentityV2 | null): any {
    return value as any;
}

