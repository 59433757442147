/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiskType } from './DiskType';
import {
    DiskTypeFromJSON,
    DiskTypeFromJSONTyped,
    DiskTypeToJSON,
} from './DiskType';

/**
 * configuration to create a disk
 * @export
 * @interface PersistentDiskRequest
 */
export interface PersistentDiskRequest {
    /**
     * The name of the disk. This can reference an existing disk.
     * @type {string}
     * @memberof PersistentDiskRequest
     */
    name?: string;
    /**
     * the size of the disk, if this is a creation request. Has an intelligent default otherwise
     * @type {number}
     * @memberof PersistentDiskRequest
     */
    size?: number;
    /**
     * 
     * @type {DiskType}
     * @memberof PersistentDiskRequest
     */
    diskType?: DiskType;
    /**
     * The labels to be placed on the cluster. Of type Map[String,String]
     * @type {object}
     * @memberof PersistentDiskRequest
     */
    labels?: object;
}

/**
 * Check if a given object implements the PersistentDiskRequest interface.
 */
export function instanceOfPersistentDiskRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PersistentDiskRequestFromJSON(json: any): PersistentDiskRequest {
    return PersistentDiskRequestFromJSONTyped(json, false);
}

export function PersistentDiskRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersistentDiskRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'diskType': !exists(json, 'diskType') ? undefined : DiskTypeFromJSON(json['diskType']),
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
    };
}

export function PersistentDiskRequestToJSON(value?: PersistentDiskRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'size': value.size,
        'diskType': DiskTypeToJSON(value.diskType),
        'labels': value.labels,
    };
}

