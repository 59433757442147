/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Criteria
 */
export interface Criteria {
    /**
     * Primary identifier which is unique within a CDR version. Value may not be stable across different CDR versions.
     * @type {number}
     * @memberof Criteria
     */
    id: number;
    /**
     * The parent id of the criteria. 0 if this is the root node of a criteria tree.
     * @type {number}
     * @memberof Criteria
     */
    parentId: number;
    /**
     * The tree type of this criteria, see TreeType. This will need to change to CriteriaType with the new implementation(change type below to ref CriteriaType)
     * @type {string}
     * @memberof Criteria
     */
    type: string;
    /**
     * The subtype of this criteria, see TreeSubType. This will need to change to CriteriaSubType with the new implementation(change type below to ref CriteriaSubType)
     * @type {string}
     * @memberof Criteria
     */
    subtype?: string;
    /**
     * Code that identifies this criteria. In some vocabularies such as ICD9 and ICD10, this code captures the tree hierarchy, e.g. '001.002.003'. Multiple criteria may exist for the same code within a CDR version if a given concept has multiple entries at different locations in the criteria tree (this is common in SNOMED).
     * Criteria codes should generally be stable across CDR versions.
     * @type {string}
     * @memberof Criteria
     */
    code?: string;
    /**
     * description of criteria
     * @type {string}
     * @memberof Criteria
     */
    name: string;
    /**
     * Estimated number of participants in a particular CDR version which have a least one event matching this criteria.
     * @type {number}
     * @memberof Criteria
     */
    count?: number;
    /**
     * Estimated number of Parents participants in a particular CDR version which have a least one event matching this criteria.
     * @type {number}
     * @memberof Criteria
     */
    parentCount?: number;
    /**
     * Estimated number of Children participants in a particular CDR version which have a least one event matching this criteria.
     * @type {number}
     * @memberof Criteria
     */
    childCount?: number;
    /**
     * specifies if child or parent
     * @type {boolean}
     * @memberof Criteria
     */
    group: boolean;
    /**
     * Whether or not a client can specify this criteria in a search request. Selecting a group implies selecting all criteria contained within that group.
     * @type {boolean}
     * @memberof Criteria
     */
    selectable: boolean;
    /**
     * The OMOP concept id associated with this criteria. May be null if this criteria does not match an OMOP concept, e.g. for intermediate nodes (groups) in the criteria tree. Concept ids are ids associated with a specific vocabulary item in the concept table and allow you to search for that code in its domain table. This may be a source or standard concept ID, depending on the tree for the criteria.
     * Similar to a code, multiple criteria may reference the same concept ID due to multiple occurrences of a concept in the criteria tree. In other cases a criteria may share the concept ID of its parent, e.g. in the PPI tree a question and child answer share a concept id.
     * Concept IDs should generally be stable across CDR versions.
     * @type {number}
     * @memberof Criteria
     */
    conceptId?: number;
    /**
     * # TODO: Remove this field. Deprecated. Clue to determine which OMOP tables to search, but these only exist for leaves in the tree. Parents don't have domain ids and concept id will be used in the case that a parent is selectable.
     * @type {string}
     * @memberof Criteria
     */
    domainId?: string;
    /**
     * Whether this criteria has associated attributes which can be filtered by value during a search, for example a blood pressure measurement criteria might have an associated measurement value attribute.
     * @type {boolean}
     * @memberof Criteria
     */
    hasAttributes: boolean;
    /**
     * A "." delimited path of all parent criteria IDs. Does not include the id for this criteria; root criteria nodes have a null path.
     * @type {string}
     * @memberof Criteria
     */
    path?: string;
    /**
     * A helper property to fully describe PPI/AGE data. Age holds the value of the age and for PPI it holds value as number or value as concept id.
     * @type {string}
     * @memberof Criteria
     */
    value?: string;
    /**
     * Whether criteria is linked to a tree.
     * @type {boolean}
     * @memberof Criteria
     */
    hasHierarchy?: boolean;
    /**
     * Whether criteria needs lookup in the criteria_ancestor table.
     * @type {boolean}
     * @memberof Criteria
     */
    hasAncestorData?: boolean;
    /**
     * Reveals if this criteria is standard or source.
     * @type {boolean}
     * @memberof Criteria
     */
    standard?: boolean;
}

/**
 * Check if a given object implements the Criteria interface.
 */
export function instanceOfCriteria(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "parentId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "group" in value;
    isInstance = isInstance && "selectable" in value;
    isInstance = isInstance && "hasAttributes" in value;

    return isInstance;
}

export function CriteriaFromJSON(json: any): Criteria {
    return CriteriaFromJSONTyped(json, false);
}

export function CriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): Criteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'parentId': json['parentId'],
        'type': json['type'],
        'subtype': !exists(json, 'subtype') ? undefined : json['subtype'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': json['name'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'parentCount': !exists(json, 'parentCount') ? undefined : json['parentCount'],
        'childCount': !exists(json, 'childCount') ? undefined : json['childCount'],
        'group': json['group'],
        'selectable': json['selectable'],
        'conceptId': !exists(json, 'conceptId') ? undefined : json['conceptId'],
        'domainId': !exists(json, 'domainId') ? undefined : json['domainId'],
        'hasAttributes': json['hasAttributes'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'hasHierarchy': !exists(json, 'hasHierarchy') ? undefined : json['hasHierarchy'],
        'hasAncestorData': !exists(json, 'hasAncestorData') ? undefined : json['hasAncestorData'],
        'standard': !exists(json, 'standard') ? undefined : json['standard'],
    };
}

export function CriteriaToJSON(value?: Criteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'parentId': value.parentId,
        'type': value.type,
        'subtype': value.subtype,
        'code': value.code,
        'name': value.name,
        'count': value.count,
        'parentCount': value.parentCount,
        'childCount': value.childCount,
        'group': value.group,
        'selectable': value.selectable,
        'conceptId': value.conceptId,
        'domainId': value.domainId,
        'hasAttributes': value.hasAttributes,
        'path': value.path,
        'value': value.value,
        'hasHierarchy': value.hasHierarchy,
        'hasAncestorData': value.hasAncestorData,
        'standard': value.standard,
    };
}

