/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccessModule } from './AccessModule';
import {
    AccessModuleFromJSON,
    AccessModuleFromJSONTyped,
    AccessModuleToJSON,
} from './AccessModule';

/**
 * 
 * @export
 * @interface AccessModuleStatus
 */
export interface AccessModuleStatus {
    /**
     * 
     * @type {AccessModule}
     * @memberof AccessModuleStatus
     */
    moduleName: AccessModule;
    /**
     * When this module completed if applicable
     * @type {number}
     * @memberof AccessModuleStatus
     */
    completionEpochMillis?: number;
    /**
     * When this module expired or will expire if applicable.
     * @type {number}
     * @memberof AccessModuleStatus
     */
    expirationEpochMillis?: number;
    /**
     * When this module is bypassed if applicable
     * @type {number}
     * @memberof AccessModuleStatus
     */
    bypassEpochMillis?: number;
}

/**
 * Check if a given object implements the AccessModuleStatus interface.
 */
export function instanceOfAccessModuleStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "moduleName" in value;

    return isInstance;
}

export function AccessModuleStatusFromJSON(json: any): AccessModuleStatus {
    return AccessModuleStatusFromJSONTyped(json, false);
}

export function AccessModuleStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessModuleStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'moduleName': AccessModuleFromJSON(json['moduleName']),
        'completionEpochMillis': !exists(json, 'completionEpochMillis') ? undefined : json['completionEpochMillis'],
        'expirationEpochMillis': !exists(json, 'expirationEpochMillis') ? undefined : json['expirationEpochMillis'],
        'bypassEpochMillis': !exists(json, 'bypassEpochMillis') ? undefined : json['bypassEpochMillis'],
    };
}

export function AccessModuleStatusToJSON(value?: AccessModuleStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'moduleName': AccessModuleToJSON(value.moduleName),
        'completionEpochMillis': value.completionEpochMillis,
        'expirationEpochMillis': value.expirationEpochMillis,
        'bypassEpochMillis': value.bypassEpochMillis,
    };
}

