/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Highest Level of Education Completed
 * @export
 */
export const EducationV2 = {
    NO_EDUCATION: 'NO_EDUCATION',
    GRADES_1_12: 'GRADES_1_12',
    UNDERGRADUATE: 'UNDERGRADUATE',
    COLLEGE_GRADUATE: 'COLLEGE_GRADUATE',
    MASTER: 'MASTER',
    DOCTORATE: 'DOCTORATE',
    PREFER_NOT_TO_ANSWER: 'PREFER_NOT_TO_ANSWER'
} as const;
export type EducationV2 = typeof EducationV2[keyof typeof EducationV2];


export function EducationV2FromJSON(json: any): EducationV2 {
    return EducationV2FromJSONTyped(json, false);
}

export function EducationV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): EducationV2 {
    return json as EducationV2;
}

export function EducationV2ToJSON(value?: EducationV2 | null): any {
    return value as any;
}

