/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CohortChartDataListResponse,
  CohortReview,
  CohortReviewListResponse,
  CohortReviewWithCountResponse,
  CreateReviewRequest,
  DemoChartInfoListResponse,
  EmptyResponse,
  ModifyCohortStatusRequest,
  ModifyParticipantCohortAnnotationRequest,
  PageFilterRequest,
  ParticipantChartDataListResponse,
  ParticipantCohortAnnotation,
  ParticipantCohortAnnotationListResponse,
  ParticipantCohortStatus,
  ParticipantDataCountResponse,
  ParticipantDataListResponse,
  VocabularyListResponse,
} from '../models';
import {
    CohortChartDataListResponseFromJSON,
    CohortChartDataListResponseToJSON,
    CohortReviewFromJSON,
    CohortReviewToJSON,
    CohortReviewListResponseFromJSON,
    CohortReviewListResponseToJSON,
    CohortReviewWithCountResponseFromJSON,
    CohortReviewWithCountResponseToJSON,
    CreateReviewRequestFromJSON,
    CreateReviewRequestToJSON,
    DemoChartInfoListResponseFromJSON,
    DemoChartInfoListResponseToJSON,
    EmptyResponseFromJSON,
    EmptyResponseToJSON,
    ModifyCohortStatusRequestFromJSON,
    ModifyCohortStatusRequestToJSON,
    ModifyParticipantCohortAnnotationRequestFromJSON,
    ModifyParticipantCohortAnnotationRequestToJSON,
    PageFilterRequestFromJSON,
    PageFilterRequestToJSON,
    ParticipantChartDataListResponseFromJSON,
    ParticipantChartDataListResponseToJSON,
    ParticipantCohortAnnotationFromJSON,
    ParticipantCohortAnnotationToJSON,
    ParticipantCohortAnnotationListResponseFromJSON,
    ParticipantCohortAnnotationListResponseToJSON,
    ParticipantCohortStatusFromJSON,
    ParticipantCohortStatusToJSON,
    ParticipantDataCountResponseFromJSON,
    ParticipantDataCountResponseToJSON,
    ParticipantDataListResponseFromJSON,
    ParticipantDataListResponseToJSON,
    VocabularyListResponseFromJSON,
    VocabularyListResponseToJSON,
} from '../models';

export interface CohortReviewApiCohortParticipantCountRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortId: number;
}

export interface CohortReviewApiCreateCohortReviewRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortId: number;
    request: CreateReviewRequest;
}

export interface CohortReviewApiCreateParticipantCohortAnnotationRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortReviewId: number;
    participantId: number;
    request: ParticipantCohortAnnotation;
}

export interface CohortReviewApiDeleteCohortReviewRequest {
    workspaceNamespace: string;
    terraName: string;
    id: number;
}

export interface CohortReviewApiDeleteParticipantCohortAnnotationRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortReviewId: number;
    participantId: number;
    annotationId: number;
}

export interface CohortReviewApiFindCohortReviewDemoChartInfoRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortReviewId: number;
}

export interface CohortReviewApiGetCohortReviewChartDataRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortReviewId: number;
    domain: string;
}

export interface CohortReviewApiGetCohortReviewsByCohortIdRequest {
    workspaceNamespace: string;
    terraName: string;
    id: number;
}

export interface CohortReviewApiGetCohortReviewsInWorkspaceRequest {
    workspaceNamespace: string;
    terraName: string;
}

export interface CohortReviewApiGetParticipantChartDataRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortReviewId: number;
    participantId: number;
    domain: string;
}

export interface CohortReviewApiGetParticipantCohortAnnotationsRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortReviewId: number;
    participantId: number;
}

export interface CohortReviewApiGetParticipantCohortStatusRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortReviewId: number;
    participantId: number;
}

export interface CohortReviewApiGetParticipantCohortStatusesRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortReviewId: number;
    request: PageFilterRequest;
}

export interface CohortReviewApiGetParticipantCountRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortReviewId: number;
    participantId: number;
    request: PageFilterRequest;
}

export interface CohortReviewApiGetParticipantDataRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortReviewId: number;
    participantId: number;
    request: PageFilterRequest;
}

export interface CohortReviewApiGetVocabulariesRequest {
    workspaceNamespace: string;
    terraName: string;
}

export interface CohortReviewApiUpdateCohortReviewRequest {
    workspaceNamespace: string;
    terraName: string;
    id: number;
    cohortReview?: CohortReview;
}

export interface CohortReviewApiUpdateParticipantCohortAnnotationRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortReviewId: number;
    participantId: number;
    annotationId: number;
    request: ModifyParticipantCohortAnnotationRequest;
}

export interface CohortReviewApiUpdateParticipantCohortStatusRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortReviewId: number;
    participantId: number;
    cohortStatusRequest?: ModifyCohortStatusRequest;
}

/**
 * 
 */
export class CohortReviewApi extends runtime.BaseAPI {

    /**
     * This endpoint will return the cohort total participant count.
     */
    async cohortParticipantCountRaw(requestParameters: CohortReviewApiCohortParticipantCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling cohortParticipantCount.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling cohortParticipantCount.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling cohortParticipantCount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/review/{cohortId}/cohort-count`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * This endpoint will return the cohort total participant count.
     */
    async cohortParticipantCount(workspaceNamespace: string, terraName: string, cohortId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.cohortParticipantCountRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortId: cohortId }, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint will create an cohort review which is a participant cohort sample specified by the review size parameter.
     */
    async createCohortReviewRaw(requestParameters: CohortReviewApiCreateCohortReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CohortReview>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling createCohortReview.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling createCohortReview.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling createCohortReview.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling createCohortReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/review/{cohortId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateReviewRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CohortReviewFromJSON(jsonValue));
    }

    /**
     * This endpoint will create an cohort review which is a participant cohort sample specified by the review size parameter.
     */
    async createCohortReview(workspaceNamespace: string, terraName: string, cohortId: number, request: CreateReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CohortReview> {
        const response = await this.createCohortReviewRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortId: cohortId, request: request }, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint will create a ParticipantCohortAnnotation.
     */
    async createParticipantCohortAnnotationRaw(requestParameters: CohortReviewApiCreateParticipantCohortAnnotationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParticipantCohortAnnotation>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling createParticipantCohortAnnotation.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling createParticipantCohortAnnotation.');
        }

        if (requestParameters.cohortReviewId === null || requestParameters.cohortReviewId === undefined) {
            throw new runtime.RequiredError('cohortReviewId','Required parameter requestParameters.cohortReviewId was null or undefined when calling createParticipantCohortAnnotation.');
        }

        if (requestParameters.participantId === null || requestParameters.participantId === undefined) {
            throw new runtime.RequiredError('participantId','Required parameter requestParameters.participantId was null or undefined when calling createParticipantCohortAnnotation.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling createParticipantCohortAnnotation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/review/{cohortReviewId}/participants/{participantId}/annotations`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortReviewId"}}`, encodeURIComponent(String(requestParameters.cohortReviewId))).replace(`{${"participantId"}}`, encodeURIComponent(String(requestParameters.participantId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ParticipantCohortAnnotationToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParticipantCohortAnnotationFromJSON(jsonValue));
    }

    /**
     * This endpoint will create a ParticipantCohortAnnotation.
     */
    async createParticipantCohortAnnotation(workspaceNamespace: string, terraName: string, cohortReviewId: number, participantId: number, request: ParticipantCohortAnnotation, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParticipantCohortAnnotation> {
        const response = await this.createParticipantCohortAnnotationRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortReviewId: cohortReviewId, participantId: participantId, request: request }, initOverrides);
        return await response.value();
    }

    /**
     * Deletes the cohort review with the specified ID
     */
    async deleteCohortReviewRaw(requestParameters: CohortReviewApiDeleteCohortReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling deleteCohortReview.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling deleteCohortReview.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCohortReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/cohort-reviews/{id}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Deletes the cohort review with the specified ID
     */
    async deleteCohortReview(workspaceNamespace: string, terraName: string, id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.deleteCohortReviewRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Deletes the ParticipantCohortAnnotation with the specified ID
     */
    async deleteParticipantCohortAnnotationRaw(requestParameters: CohortReviewApiDeleteParticipantCohortAnnotationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling deleteParticipantCohortAnnotation.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling deleteParticipantCohortAnnotation.');
        }

        if (requestParameters.cohortReviewId === null || requestParameters.cohortReviewId === undefined) {
            throw new runtime.RequiredError('cohortReviewId','Required parameter requestParameters.cohortReviewId was null or undefined when calling deleteParticipantCohortAnnotation.');
        }

        if (requestParameters.participantId === null || requestParameters.participantId === undefined) {
            throw new runtime.RequiredError('participantId','Required parameter requestParameters.participantId was null or undefined when calling deleteParticipantCohortAnnotation.');
        }

        if (requestParameters.annotationId === null || requestParameters.annotationId === undefined) {
            throw new runtime.RequiredError('annotationId','Required parameter requestParameters.annotationId was null or undefined when calling deleteParticipantCohortAnnotation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/review/{cohortReviewId}/participants/{participantId}/annotations/{annotationId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortReviewId"}}`, encodeURIComponent(String(requestParameters.cohortReviewId))).replace(`{${"participantId"}}`, encodeURIComponent(String(requestParameters.participantId))).replace(`{${"annotationId"}}`, encodeURIComponent(String(requestParameters.annotationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Deletes the ParticipantCohortAnnotation with the specified ID
     */
    async deleteParticipantCohortAnnotation(workspaceNamespace: string, terraName: string, cohortReviewId: number, participantId: number, annotationId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.deleteParticipantCohortAnnotationRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortReviewId: cohortReviewId, participantId: participantId, annotationId: annotationId }, initOverrides);
        return await response.value();
    }

    /**
     * Searches for demographic info about subjects.
     */
    async findCohortReviewDemoChartInfoRaw(requestParameters: CohortReviewApiFindCohortReviewDemoChartInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DemoChartInfoListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findCohortReviewDemoChartInfo.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findCohortReviewDemoChartInfo.');
        }

        if (requestParameters.cohortReviewId === null || requestParameters.cohortReviewId === undefined) {
            throw new runtime.RequiredError('cohortReviewId','Required parameter requestParameters.cohortReviewId was null or undefined when calling findCohortReviewDemoChartInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/chartinfo/{cohortReviewId}/demo`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortReviewId"}}`, encodeURIComponent(String(requestParameters.cohortReviewId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DemoChartInfoListResponseFromJSON(jsonValue));
    }

    /**
     * Searches for demographic info about subjects.
     */
    async findCohortReviewDemoChartInfo(workspaceNamespace: string, terraName: string, cohortReviewId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DemoChartInfoListResponse> {
        const response = await this.findCohortReviewDemoChartInfoRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortReviewId: cohortReviewId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a collection of CohortChartData for UI charting in cohort.
     */
    async getCohortReviewChartDataRaw(requestParameters: CohortReviewApiGetCohortReviewChartDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CohortChartDataListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getCohortReviewChartData.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getCohortReviewChartData.');
        }

        if (requestParameters.cohortReviewId === null || requestParameters.cohortReviewId === undefined) {
            throw new runtime.RequiredError('cohortReviewId','Required parameter requestParameters.cohortReviewId was null or undefined when calling getCohortReviewChartData.');
        }

        if (requestParameters.domain === null || requestParameters.domain === undefined) {
            throw new runtime.RequiredError('domain','Required parameter requestParameters.domain was null or undefined when calling getCohortReviewChartData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/top/chartinfo/{cohortReviewId}/{domain}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortReviewId"}}`, encodeURIComponent(String(requestParameters.cohortReviewId))).replace(`{${"domain"}}`, encodeURIComponent(String(requestParameters.domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CohortChartDataListResponseFromJSON(jsonValue));
    }

    /**
     * Returns a collection of CohortChartData for UI charting in cohort.
     */
    async getCohortReviewChartData(workspaceNamespace: string, terraName: string, cohortReviewId: number, domain: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CohortChartDataListResponse> {
        const response = await this.getCohortReviewChartDataRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortReviewId: cohortReviewId, domain: domain }, initOverrides);
        return await response.value();
    }

    /**
     * Returns all cohort reviews per cohortId
     */
    async getCohortReviewsByCohortIdRaw(requestParameters: CohortReviewApiGetCohortReviewsByCohortIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CohortReviewListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getCohortReviewsByCohortId.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getCohortReviewsByCohortId.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCohortReviewsByCohortId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/cohort-reviews/{id}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CohortReviewListResponseFromJSON(jsonValue));
    }

    /**
     * Returns all cohort reviews per cohortId
     */
    async getCohortReviewsByCohortId(workspaceNamespace: string, terraName: string, id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CohortReviewListResponse> {
        const response = await this.getCohortReviewsByCohortIdRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Returns all cohort reviews in a workspace
     */
    async getCohortReviewsInWorkspaceRaw(requestParameters: CohortReviewApiGetCohortReviewsInWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CohortReviewListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getCohortReviewsInWorkspace.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getCohortReviewsInWorkspace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/cohort-reviews`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CohortReviewListResponseFromJSON(jsonValue));
    }

    /**
     * Returns all cohort reviews in a workspace
     */
    async getCohortReviewsInWorkspace(workspaceNamespace: string, terraName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CohortReviewListResponse> {
        const response = await this.getCohortReviewsInWorkspaceRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName }, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint returns ParticipantChartDataListResponse containing list of ParticipantChartData objects
     */
    async getParticipantChartDataRaw(requestParameters: CohortReviewApiGetParticipantChartDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParticipantChartDataListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getParticipantChartData.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getParticipantChartData.');
        }

        if (requestParameters.cohortReviewId === null || requestParameters.cohortReviewId === undefined) {
            throw new runtime.RequiredError('cohortReviewId','Required parameter requestParameters.cohortReviewId was null or undefined when calling getParticipantChartData.');
        }

        if (requestParameters.participantId === null || requestParameters.participantId === undefined) {
            throw new runtime.RequiredError('participantId','Required parameter requestParameters.participantId was null or undefined when calling getParticipantChartData.');
        }

        if (requestParameters.domain === null || requestParameters.domain === undefined) {
            throw new runtime.RequiredError('domain','Required parameter requestParameters.domain was null or undefined when calling getParticipantChartData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/review/{cohortReviewId}/participants/{participantId}/charts/{domain}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortReviewId"}}`, encodeURIComponent(String(requestParameters.cohortReviewId))).replace(`{${"participantId"}}`, encodeURIComponent(String(requestParameters.participantId))).replace(`{${"domain"}}`, encodeURIComponent(String(requestParameters.domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParticipantChartDataListResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint returns ParticipantChartDataListResponse containing list of ParticipantChartData objects
     */
    async getParticipantChartData(workspaceNamespace: string, terraName: string, cohortReviewId: number, participantId: number, domain: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParticipantChartDataListResponse> {
        const response = await this.getParticipantChartDataRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortReviewId: cohortReviewId, participantId: participantId, domain: domain }, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint will get a collection of ParticipantCohortAnnotations.
     */
    async getParticipantCohortAnnotationsRaw(requestParameters: CohortReviewApiGetParticipantCohortAnnotationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParticipantCohortAnnotationListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getParticipantCohortAnnotations.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getParticipantCohortAnnotations.');
        }

        if (requestParameters.cohortReviewId === null || requestParameters.cohortReviewId === undefined) {
            throw new runtime.RequiredError('cohortReviewId','Required parameter requestParameters.cohortReviewId was null or undefined when calling getParticipantCohortAnnotations.');
        }

        if (requestParameters.participantId === null || requestParameters.participantId === undefined) {
            throw new runtime.RequiredError('participantId','Required parameter requestParameters.participantId was null or undefined when calling getParticipantCohortAnnotations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/review/{cohortReviewId}/participants/{participantId}/annotations`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortReviewId"}}`, encodeURIComponent(String(requestParameters.cohortReviewId))).replace(`{${"participantId"}}`, encodeURIComponent(String(requestParameters.participantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParticipantCohortAnnotationListResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint will get a collection of ParticipantCohortAnnotations.
     */
    async getParticipantCohortAnnotations(workspaceNamespace: string, terraName: string, cohortReviewId: number, participantId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParticipantCohortAnnotationListResponse> {
        const response = await this.getParticipantCohortAnnotationsRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortReviewId: cohortReviewId, participantId: participantId }, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint will return a ParticipantCohortStatus
     */
    async getParticipantCohortStatusRaw(requestParameters: CohortReviewApiGetParticipantCohortStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParticipantCohortStatus>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getParticipantCohortStatus.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getParticipantCohortStatus.');
        }

        if (requestParameters.cohortReviewId === null || requestParameters.cohortReviewId === undefined) {
            throw new runtime.RequiredError('cohortReviewId','Required parameter requestParameters.cohortReviewId was null or undefined when calling getParticipantCohortStatus.');
        }

        if (requestParameters.participantId === null || requestParameters.participantId === undefined) {
            throw new runtime.RequiredError('participantId','Required parameter requestParameters.participantId was null or undefined when calling getParticipantCohortStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/review/{cohortReviewId}/participants/{participantId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortReviewId"}}`, encodeURIComponent(String(requestParameters.cohortReviewId))).replace(`{${"participantId"}}`, encodeURIComponent(String(requestParameters.participantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParticipantCohortStatusFromJSON(jsonValue));
    }

    /**
     * This endpoint will return a ParticipantCohortStatus
     */
    async getParticipantCohortStatus(workspaceNamespace: string, terraName: string, cohortReviewId: number, participantId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParticipantCohortStatus> {
        const response = await this.getParticipantCohortStatusRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortReviewId: cohortReviewId, participantId: participantId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a collection of participants for the specified cohortId. This endpoint does pagination based on page, limit, order and column.
     */
    async getParticipantCohortStatusesRaw(requestParameters: CohortReviewApiGetParticipantCohortStatusesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CohortReviewWithCountResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getParticipantCohortStatuses.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getParticipantCohortStatuses.');
        }

        if (requestParameters.cohortReviewId === null || requestParameters.cohortReviewId === undefined) {
            throw new runtime.RequiredError('cohortReviewId','Required parameter requestParameters.cohortReviewId was null or undefined when calling getParticipantCohortStatuses.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling getParticipantCohortStatuses.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/review/{cohortReviewId}/participants`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortReviewId"}}`, encodeURIComponent(String(requestParameters.cohortReviewId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PageFilterRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CohortReviewWithCountResponseFromJSON(jsonValue));
    }

    /**
     * Returns a collection of participants for the specified cohortId. This endpoint does pagination based on page, limit, order and column.
     */
    async getParticipantCohortStatuses(workspaceNamespace: string, terraName: string, cohortReviewId: number, request: PageFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CohortReviewWithCountResponse> {
        const response = await this.getParticipantCohortStatusesRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortReviewId: cohortReviewId, request: request }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a count based on page, limit, order, sort column and/or filter.
     */
    async getParticipantCountRaw(requestParameters: CohortReviewApiGetParticipantCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParticipantDataCountResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getParticipantCount.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getParticipantCount.');
        }

        if (requestParameters.cohortReviewId === null || requestParameters.cohortReviewId === undefined) {
            throw new runtime.RequiredError('cohortReviewId','Required parameter requestParameters.cohortReviewId was null or undefined when calling getParticipantCount.');
        }

        if (requestParameters.participantId === null || requestParameters.participantId === undefined) {
            throw new runtime.RequiredError('participantId','Required parameter requestParameters.participantId was null or undefined when calling getParticipantCount.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling getParticipantCount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/review/{cohortReviewId}/participants/{participantId}/count`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortReviewId"}}`, encodeURIComponent(String(requestParameters.cohortReviewId))).replace(`{${"participantId"}}`, encodeURIComponent(String(requestParameters.participantId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PageFilterRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParticipantDataCountResponseFromJSON(jsonValue));
    }

    /**
     * Returns a count based on page, limit, order, sort column and/or filter.
     */
    async getParticipantCount(workspaceNamespace: string, terraName: string, cohortReviewId: number, participantId: number, request: PageFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParticipantDataCountResponse> {
        const response = await this.getParticipantCountRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortReviewId: cohortReviewId, participantId: participantId, request: request }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a collection of participant data for the specified params based off the PageFilterRequest. This endpoint does pagination based on page, limit, order and column.
     */
    async getParticipantDataRaw(requestParameters: CohortReviewApiGetParticipantDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParticipantDataListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getParticipantData.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getParticipantData.');
        }

        if (requestParameters.cohortReviewId === null || requestParameters.cohortReviewId === undefined) {
            throw new runtime.RequiredError('cohortReviewId','Required parameter requestParameters.cohortReviewId was null or undefined when calling getParticipantData.');
        }

        if (requestParameters.participantId === null || requestParameters.participantId === undefined) {
            throw new runtime.RequiredError('participantId','Required parameter requestParameters.participantId was null or undefined when calling getParticipantData.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling getParticipantData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/review/{cohortReviewId}/participants/{participantId}/data`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortReviewId"}}`, encodeURIComponent(String(requestParameters.cohortReviewId))).replace(`{${"participantId"}}`, encodeURIComponent(String(requestParameters.participantId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PageFilterRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParticipantDataListResponseFromJSON(jsonValue));
    }

    /**
     * Returns a collection of participant data for the specified params based off the PageFilterRequest. This endpoint does pagination based on page, limit, order and column.
     */
    async getParticipantData(workspaceNamespace: string, terraName: string, cohortReviewId: number, participantId: number, request: PageFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParticipantDataListResponse> {
        const response = await this.getParticipantDataRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortReviewId: cohortReviewId, participantId: participantId, request: request }, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint will get a collection of OMOP vocabularies per cdrVersionId.
     */
    async getVocabulariesRaw(requestParameters: CohortReviewApiGetVocabulariesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VocabularyListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getVocabularies.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getVocabularies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/review/vocabularies`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VocabularyListResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint will get a collection of OMOP vocabularies per cdrVersionId.
     */
    async getVocabularies(workspaceNamespace: string, terraName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VocabularyListResponse> {
        const response = await this.getVocabulariesRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName }, initOverrides);
        return await response.value();
    }

    /**
     * Modifies the cohort review with the specified ID; fields that are omitted will not be modified
     */
    async updateCohortReviewRaw(requestParameters: CohortReviewApiUpdateCohortReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CohortReview>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling updateCohortReview.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling updateCohortReview.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCohortReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/cohort-reviews/{id}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CohortReviewToJSON(requestParameters.cohortReview),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CohortReviewFromJSON(jsonValue));
    }

    /**
     * Modifies the cohort review with the specified ID; fields that are omitted will not be modified
     */
    async updateCohortReview(workspaceNamespace: string, terraName: string, id: number, cohortReview?: CohortReview, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CohortReview> {
        const response = await this.updateCohortReviewRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, id: id, cohortReview: cohortReview }, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint will modify a ParticipantCohortAnnotation.
     */
    async updateParticipantCohortAnnotationRaw(requestParameters: CohortReviewApiUpdateParticipantCohortAnnotationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParticipantCohortAnnotation>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling updateParticipantCohortAnnotation.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling updateParticipantCohortAnnotation.');
        }

        if (requestParameters.cohortReviewId === null || requestParameters.cohortReviewId === undefined) {
            throw new runtime.RequiredError('cohortReviewId','Required parameter requestParameters.cohortReviewId was null or undefined when calling updateParticipantCohortAnnotation.');
        }

        if (requestParameters.participantId === null || requestParameters.participantId === undefined) {
            throw new runtime.RequiredError('participantId','Required parameter requestParameters.participantId was null or undefined when calling updateParticipantCohortAnnotation.');
        }

        if (requestParameters.annotationId === null || requestParameters.annotationId === undefined) {
            throw new runtime.RequiredError('annotationId','Required parameter requestParameters.annotationId was null or undefined when calling updateParticipantCohortAnnotation.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling updateParticipantCohortAnnotation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/review/{cohortReviewId}/participants/{participantId}/annotations/{annotationId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortReviewId"}}`, encodeURIComponent(String(requestParameters.cohortReviewId))).replace(`{${"participantId"}}`, encodeURIComponent(String(requestParameters.participantId))).replace(`{${"annotationId"}}`, encodeURIComponent(String(requestParameters.annotationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ModifyParticipantCohortAnnotationRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParticipantCohortAnnotationFromJSON(jsonValue));
    }

    /**
     * This endpoint will modify a ParticipantCohortAnnotation.
     */
    async updateParticipantCohortAnnotation(workspaceNamespace: string, terraName: string, cohortReviewId: number, participantId: number, annotationId: number, request: ModifyParticipantCohortAnnotationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParticipantCohortAnnotation> {
        const response = await this.updateParticipantCohortAnnotationRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortReviewId: cohortReviewId, participantId: participantId, annotationId: annotationId, request: request }, initOverrides);
        return await response.value();
    }

    /**
     * Modifies the ParticipantCohortStatus status
     */
    async updateParticipantCohortStatusRaw(requestParameters: CohortReviewApiUpdateParticipantCohortStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParticipantCohortStatus>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling updateParticipantCohortStatus.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling updateParticipantCohortStatus.');
        }

        if (requestParameters.cohortReviewId === null || requestParameters.cohortReviewId === undefined) {
            throw new runtime.RequiredError('cohortReviewId','Required parameter requestParameters.cohortReviewId was null or undefined when calling updateParticipantCohortStatus.');
        }

        if (requestParameters.participantId === null || requestParameters.participantId === undefined) {
            throw new runtime.RequiredError('participantId','Required parameter requestParameters.participantId was null or undefined when calling updateParticipantCohortStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/review/{cohortReviewId}/participants/{participantId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortReviewId"}}`, encodeURIComponent(String(requestParameters.cohortReviewId))).replace(`{${"participantId"}}`, encodeURIComponent(String(requestParameters.participantId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ModifyCohortStatusRequestToJSON(requestParameters.cohortStatusRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParticipantCohortStatusFromJSON(jsonValue));
    }

    /**
     * Modifies the ParticipantCohortStatus status
     */
    async updateParticipantCohortStatus(workspaceNamespace: string, terraName: string, cohortReviewId: number, participantId: number, cohortStatusRequest?: ModifyCohortStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParticipantCohortStatus> {
        const response = await this.updateParticipantCohortStatusRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortReviewId: cohortReviewId, participantId: participantId, cohortStatusRequest: cohortStatusRequest }, initOverrides);
        return await response.value();
    }

}
