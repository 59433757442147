/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AuditAgent } from './AuditAgent';
import {
    AuditAgentFromJSON,
    AuditAgentFromJSONTyped,
    AuditAgentToJSON,
} from './AuditAgent';
import type { AuditTarget } from './AuditTarget';
import {
    AuditTargetFromJSON,
    AuditTargetFromJSONTyped,
    AuditTargetToJSON,
} from './AuditTarget';

/**
 * Unique header for this event bundle within the parent action. Consists of agent, target, and action type information. This tuple is used internally to filter the rows of each action.
 * @export
 * @interface AuditEventBundleHeader
 */
export interface AuditEventBundleHeader {
    /**
     * 
     * @type {string}
     * @memberof AuditEventBundleHeader
     */
    actionType?: string;
    /**
     * 
     * @type {AuditAgent}
     * @memberof AuditEventBundleHeader
     */
    agent?: AuditAgent;
    /**
     * 
     * @type {AuditTarget}
     * @memberof AuditEventBundleHeader
     */
    target?: AuditTarget;
}

/**
 * Check if a given object implements the AuditEventBundleHeader interface.
 */
export function instanceOfAuditEventBundleHeader(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuditEventBundleHeaderFromJSON(json: any): AuditEventBundleHeader {
    return AuditEventBundleHeaderFromJSONTyped(json, false);
}

export function AuditEventBundleHeaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditEventBundleHeader {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actionType': !exists(json, 'actionType') ? undefined : json['actionType'],
        'agent': !exists(json, 'agent') ? undefined : AuditAgentFromJSON(json['agent']),
        'target': !exists(json, 'target') ? undefined : AuditTargetFromJSON(json['target']),
    };
}

export function AuditEventBundleHeaderToJSON(value?: AuditEventBundleHeader | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actionType': value.actionType,
        'agent': AuditAgentToJSON(value.agent),
        'target': AuditTargetToJSON(value.target),
    };
}

