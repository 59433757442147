/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgeTypeCount } from './AgeTypeCount';
import {
    AgeTypeCountFromJSON,
    AgeTypeCountFromJSONTyped,
    AgeTypeCountToJSON,
} from './AgeTypeCount';

/**
 * 
 * @export
 * @interface AgeTypeCountListResponse
 */
export interface AgeTypeCountListResponse {
    /**
     * 
     * @type {Array<AgeTypeCount>}
     * @memberof AgeTypeCountListResponse
     */
    items: Array<AgeTypeCount>;
}

/**
 * Check if a given object implements the AgeTypeCountListResponse interface.
 */
export function instanceOfAgeTypeCountListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function AgeTypeCountListResponseFromJSON(json: any): AgeTypeCountListResponse {
    return AgeTypeCountListResponseFromJSONTyped(json, false);
}

export function AgeTypeCountListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgeTypeCountListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(AgeTypeCountFromJSON)),
    };
}

export function AgeTypeCountListResponseToJSON(value?: AgeTypeCountListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(AgeTypeCountToJSON)),
    };
}

