/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminWorkspaceResources } from './AdminWorkspaceResources';
import {
    AdminWorkspaceResourcesFromJSON,
    AdminWorkspaceResourcesFromJSONTyped,
    AdminWorkspaceResourcesToJSON,
} from './AdminWorkspaceResources';
import type { Workspace } from './Workspace';
import {
    WorkspaceFromJSON,
    WorkspaceFromJSONTyped,
    WorkspaceToJSON,
} from './Workspace';
import type { WorkspaceActiveStatus } from './WorkspaceActiveStatus';
import {
    WorkspaceActiveStatusFromJSON,
    WorkspaceActiveStatusFromJSONTyped,
    WorkspaceActiveStatusToJSON,
} from './WorkspaceActiveStatus';
import type { WorkspaceUserAdminView } from './WorkspaceUserAdminView';
import {
    WorkspaceUserAdminViewFromJSON,
    WorkspaceUserAdminViewFromJSONTyped,
    WorkspaceUserAdminViewToJSON,
} from './WorkspaceUserAdminView';

/**
 * Admin-visible metadata about a workspace.
 * @export
 * @interface WorkspaceAdminView
 */
export interface WorkspaceAdminView {
    /**
     * 
     * @type {Workspace}
     * @memberof WorkspaceAdminView
     */
    workspace?: Workspace;
    /**
     * ID used in the AoU RWB workspace table. Useful for DB queries and Action Audit searches in Stackdriver Logging and BigQuery.
     * @type {number}
     * @memberof WorkspaceAdminView
     */
    workspaceDatabaseId?: number;
    /**
     * List of users who own or collaborate on this workspace.
     * @type {Array<WorkspaceUserAdminView>}
     * @memberof WorkspaceAdminView
     */
    collaborators?: Array<WorkspaceUserAdminView>;
    /**
     * 
     * @type {AdminWorkspaceResources}
     * @memberof WorkspaceAdminView
     */
    resources?: AdminWorkspaceResources;
    /**
     * 
     * @type {WorkspaceActiveStatus}
     * @memberof WorkspaceAdminView
     */
    activeStatus?: WorkspaceActiveStatus;
}

/**
 * Check if a given object implements the WorkspaceAdminView interface.
 */
export function instanceOfWorkspaceAdminView(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WorkspaceAdminViewFromJSON(json: any): WorkspaceAdminView {
    return WorkspaceAdminViewFromJSONTyped(json, false);
}

export function WorkspaceAdminViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceAdminView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workspace': !exists(json, 'workspace') ? undefined : WorkspaceFromJSON(json['workspace']),
        'workspaceDatabaseId': !exists(json, 'workspaceDatabaseId') ? undefined : json['workspaceDatabaseId'],
        'collaborators': !exists(json, 'collaborators') ? undefined : ((json['collaborators'] as Array<any>).map(WorkspaceUserAdminViewFromJSON)),
        'resources': !exists(json, 'resources') ? undefined : AdminWorkspaceResourcesFromJSON(json['resources']),
        'activeStatus': !exists(json, 'activeStatus') ? undefined : WorkspaceActiveStatusFromJSON(json['activeStatus']),
    };
}

export function WorkspaceAdminViewToJSON(value?: WorkspaceAdminView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workspace': WorkspaceToJSON(value.workspace),
        'workspaceDatabaseId': value.workspaceDatabaseId,
        'collaborators': value.collaborators === undefined ? undefined : ((value.collaborators as Array<any>).map(WorkspaceUserAdminViewToJSON)),
        'resources': AdminWorkspaceResourcesToJSON(value.resources),
        'activeStatus': WorkspaceActiveStatusToJSON(value.activeStatus),
    };
}

