/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminWorkspaceCloudStorageCounts
 */
export interface AdminWorkspaceCloudStorageCounts {
    /**
     * Full gs:// path to the workspace-associated GCS bucket.
     * @type {string}
     * @memberof AdminWorkspaceCloudStorageCounts
     */
    storageBucketPath?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminWorkspaceCloudStorageCounts
     */
    notebookFileCount?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminWorkspaceCloudStorageCounts
     */
    nonNotebookFileCount?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminWorkspaceCloudStorageCounts
     */
    storageBytesUsed?: number;
}

/**
 * Check if a given object implements the AdminWorkspaceCloudStorageCounts interface.
 */
export function instanceOfAdminWorkspaceCloudStorageCounts(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdminWorkspaceCloudStorageCountsFromJSON(json: any): AdminWorkspaceCloudStorageCounts {
    return AdminWorkspaceCloudStorageCountsFromJSONTyped(json, false);
}

export function AdminWorkspaceCloudStorageCountsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminWorkspaceCloudStorageCounts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'storageBucketPath': !exists(json, 'storageBucketPath') ? undefined : json['storageBucketPath'],
        'notebookFileCount': !exists(json, 'notebookFileCount') ? undefined : json['notebookFileCount'],
        'nonNotebookFileCount': !exists(json, 'nonNotebookFileCount') ? undefined : json['nonNotebookFileCount'],
        'storageBytesUsed': !exists(json, 'storageBytesUsed') ? undefined : json['storageBytesUsed'],
    };
}

export function AdminWorkspaceCloudStorageCountsToJSON(value?: AdminWorkspaceCloudStorageCounts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'storageBucketPath': value.storageBucketPath,
        'notebookFileCount': value.notebookFileCount,
        'nonNotebookFileCount': value.nonNotebookFileCount,
        'storageBytesUsed': value.storageBytesUsed,
    };
}

