/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Workspace } from './Workspace';
import {
    WorkspaceFromJSON,
    WorkspaceFromJSONTyped,
    WorkspaceToJSON,
} from './Workspace';

/**
 * 
 * @export
 * @interface CloneWorkspaceRequest
 */
export interface CloneWorkspaceRequest {
    /**
     * Whether to duplicate the user roles (sharing settings) on the workspace. By default, duplicate follows the behavior of CreateWorkspace - it is shared solely with the creator as an OWNER. If true, all user roles are also copied onto the new workspace in addition to the caller becoming an OWNER.
     * @type {boolean}
     * @memberof CloneWorkspaceRequest
     */
    includeUserRoles?: boolean;
    /**
     * 
     * @type {Workspace}
     * @memberof CloneWorkspaceRequest
     */
    workspace: Workspace;
}

/**
 * Check if a given object implements the CloneWorkspaceRequest interface.
 */
export function instanceOfCloneWorkspaceRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "workspace" in value;

    return isInstance;
}

export function CloneWorkspaceRequestFromJSON(json: any): CloneWorkspaceRequest {
    return CloneWorkspaceRequestFromJSONTyped(json, false);
}

export function CloneWorkspaceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloneWorkspaceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'includeUserRoles': !exists(json, 'includeUserRoles') ? undefined : json['includeUserRoles'],
        'workspace': WorkspaceFromJSON(json['workspace']),
    };
}

export function CloneWorkspaceRequestToJSON(value?: CloneWorkspaceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'includeUserRoles': value.includeUserRoles,
        'workspace': WorkspaceToJSON(value.workspace),
    };
}

