/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Composite object containing audited agent information. See AuditLogEntry for individual fields.
 * @export
 * @interface AuditAgent
 */
export interface AuditAgent {
    /**
     * 
     * @type {number}
     * @memberof AuditAgent
     */
    agentId?: number;
    /**
     * 
     * @type {string}
     * @memberof AuditAgent
     */
    agentType?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditAgent
     */
    agentUsername?: string;
}

/**
 * Check if a given object implements the AuditAgent interface.
 */
export function instanceOfAuditAgent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuditAgentFromJSON(json: any): AuditAgent {
    return AuditAgentFromJSONTyped(json, false);
}

export function AuditAgentFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditAgent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agentId': !exists(json, 'agentId') ? undefined : json['agentId'],
        'agentType': !exists(json, 'agentType') ? undefined : json['agentType'],
        'agentUsername': !exists(json, 'agentUsername') ? undefined : json['agentUsername'],
    };
}

export function AuditAgentToJSON(value?: AuditAgent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agentId': value.agentId,
        'agentType': value.agentType,
        'agentUsername': value.agentUsername,
    };
}

