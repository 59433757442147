/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PrePackagedConceptSetEnum = {
    NONE: 'NONE',
    PERSON: 'PERSON',
    SURVEY: 'SURVEY',
    SURVEY_BASICS: 'SURVEY_BASICS',
    SURVEY_LIFESTYLE: 'SURVEY_LIFESTYLE',
    SURVEY_OVERALL_HEALTH: 'SURVEY_OVERALL_HEALTH',
    SURVEY_HEALTHCARE_ACCESS_UTILIZATION: 'SURVEY_HEALTHCARE_ACCESS_UTILIZATION',
    SURVEY_COPE: 'SURVEY_COPE',
    SURVEY_SDOH: 'SURVEY_SDOH',
    SURVEY_COVID_VACCINE: 'SURVEY_COVID_VACCINE',
    SURVEY_PFHH: 'SURVEY_PFHH',
    FITBIT: 'FITBIT',
    FITBIT_HEART_RATE_SUMMARY: 'FITBIT_HEART_RATE_SUMMARY',
    FITBIT_HEART_RATE_LEVEL: 'FITBIT_HEART_RATE_LEVEL',
    FITBIT_ACTIVITY: 'FITBIT_ACTIVITY',
    FITBIT_INTRADAY_STEPS: 'FITBIT_INTRADAY_STEPS',
    FITBIT_SLEEP_DAILY_SUMMARY: 'FITBIT_SLEEP_DAILY_SUMMARY',
    FITBIT_SLEEP_LEVEL: 'FITBIT_SLEEP_LEVEL',
    FITBIT_DEVICE: 'FITBIT_DEVICE',
    WHOLE_GENOME: 'WHOLE_GENOME',
    BOTH: 'BOTH',
    ZIP_CODE_SOCIOECONOMIC: 'ZIP_CODE_SOCIOECONOMIC'
} as const;
export type PrePackagedConceptSetEnum = typeof PrePackagedConceptSetEnum[keyof typeof PrePackagedConceptSetEnum];


export function PrePackagedConceptSetEnumFromJSON(json: any): PrePackagedConceptSetEnum {
    return PrePackagedConceptSetEnumFromJSONTyped(json, false);
}

export function PrePackagedConceptSetEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrePackagedConceptSetEnum {
    return json as PrePackagedConceptSetEnum;
}

export function PrePackagedConceptSetEnumToJSON(value?: PrePackagedConceptSetEnum | null): any {
    return value as any;
}

