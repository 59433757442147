/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Representation of the BigQuery Audit Log entry, post-translation from Stackdriver logging. All fields are treated as optional here, as there are no non-null constraints in the BigQuery dataset.
 * It's possible that due to some error now or in the past that some columns might not match their expected values, nothing in this type definition is stricter than the BigQuery schema.
 * @export
 * @interface AuditLogEntry
 */
export interface AuditLogEntry {
    /**
     * Unique ID for this action. Used for grouping similar events under a single action, though in this representation we're returning a straight list of rows. This is technically a UUID, but this may change in the future (to a shorter random string), so we only type it as a String.
     * @type {string}
     * @memberof AuditLogEntry
     */
    actionId?: string;
    /**
     * Defines what action is taking place in this event. (There can be several ACTION_TYPEs in a single Action assigned to different events. String representation of the ActionType enum (not generated).
     * @type {string}
     * @memberof AuditLogEntry
     */
    actionType?: string;
    /**
     * ID for the Agent (typically a User). If there's a SQL table for this agent type, this this is the PK for that table (e.g. user_id).
     * @type {number}
     * @memberof AuditLogEntry
     */
    agentId?: number;
    /**
     * A member of the org.pmiops.workbench.actionaudit.AgentType enum (not generated). Since this is typed as a string in BigQuery (and not constrained to that type), we don't try to narrow the type in the admin API.
     * @type {string}
     * @memberof AuditLogEntry
     */
    agentType?: string;
    /**
     * Username of the user or administrator taking the action. This is the agent_email column in BigQuery.
     * @type {string}
     * @memberof AuditLogEntry
     */
    agentUsername?: string;
    /**
     * Timestamp recorded at time of the event. Called timestamp in the BigQuery table. Epoch millis.
     * @type {number}
     * @memberof AuditLogEntry
     */
    eventTime?: number;
    /**
     * Value of the target property (if any) after the event.
     * @type {string}
     * @memberof AuditLogEntry
     */
    newValue?: string;
    /**
     * Value of the target property at the instant of this event beginning. prev_value in BigQuery.
     * @type {string}
     * @memberof AuditLogEntry
     */
    previousValue?: string;
    /**
     * Database ID of the corresponding table for the target entity.
     * @type {number}
     * @memberof AuditLogEntry
     */
    targetId?: number;
    /**
     * Property on the target entity affected by this event. One of several internal enums such as WorkspaceTargetProperty.
     * @type {string}
     * @memberof AuditLogEntry
     */
    targetProperty?: string;
    /**
     * Type of target. Described by the enum TargetType (which is not generated). Left as string since BigQuery doesn't enforce the enum membership.
     * @type {string}
     * @memberof AuditLogEntry
     */
    targetType?: string;
}

/**
 * Check if a given object implements the AuditLogEntry interface.
 */
export function instanceOfAuditLogEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuditLogEntryFromJSON(json: any): AuditLogEntry {
    return AuditLogEntryFromJSONTyped(json, false);
}

export function AuditLogEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditLogEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actionId': !exists(json, 'actionId') ? undefined : json['actionId'],
        'actionType': !exists(json, 'actionType') ? undefined : json['actionType'],
        'agentId': !exists(json, 'agentId') ? undefined : json['agentId'],
        'agentType': !exists(json, 'agentType') ? undefined : json['agentType'],
        'agentUsername': !exists(json, 'agentUsername') ? undefined : json['agentUsername'],
        'eventTime': !exists(json, 'eventTime') ? undefined : json['eventTime'],
        'newValue': !exists(json, 'newValue') ? undefined : json['newValue'],
        'previousValue': !exists(json, 'previousValue') ? undefined : json['previousValue'],
        'targetId': !exists(json, 'targetId') ? undefined : json['targetId'],
        'targetProperty': !exists(json, 'targetProperty') ? undefined : json['targetProperty'],
        'targetType': !exists(json, 'targetType') ? undefined : json['targetType'],
    };
}

export function AuditLogEntryToJSON(value?: AuditLogEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actionId': value.actionId,
        'actionType': value.actionType,
        'agentId': value.agentId,
        'agentType': value.agentType,
        'agentUsername': value.agentUsername,
        'eventTime': value.eventTime,
        'newValue': value.newValue,
        'previousValue': value.previousValue,
        'targetId': value.targetId,
        'targetProperty': value.targetProperty,
        'targetType': value.targetType,
    };
}

