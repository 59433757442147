/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AgeTypeCountListResponse,
  CardCountResponse,
  CohortChartDataListResponse,
  CohortDefinition,
  ConceptsRequest,
  CriteriaAttributeListResponse,
  CriteriaListResponse,
  CriteriaListWithCountResponse,
  CriteriaMenuListResponse,
  CriteriaRequest,
  CriteriaSearchRequest,
  DataFiltersResponse,
  DemoChartInfoListResponse,
  DomainCardResponse,
  EthnicityInfoListResponse,
  ParticipantDemographics,
  SurveyVersionListResponse,
  SurveysResponse,
  VariantFilter,
  VariantFilterInfoResponse,
  VariantFilterRequest,
  VariantFilterResponse,
  VariantListResponse,
} from '../models';
import {
    AgeTypeCountListResponseFromJSON,
    AgeTypeCountListResponseToJSON,
    CardCountResponseFromJSON,
    CardCountResponseToJSON,
    CohortChartDataListResponseFromJSON,
    CohortChartDataListResponseToJSON,
    CohortDefinitionFromJSON,
    CohortDefinitionToJSON,
    ConceptsRequestFromJSON,
    ConceptsRequestToJSON,
    CriteriaAttributeListResponseFromJSON,
    CriteriaAttributeListResponseToJSON,
    CriteriaListResponseFromJSON,
    CriteriaListResponseToJSON,
    CriteriaListWithCountResponseFromJSON,
    CriteriaListWithCountResponseToJSON,
    CriteriaMenuListResponseFromJSON,
    CriteriaMenuListResponseToJSON,
    CriteriaRequestFromJSON,
    CriteriaRequestToJSON,
    CriteriaSearchRequestFromJSON,
    CriteriaSearchRequestToJSON,
    DataFiltersResponseFromJSON,
    DataFiltersResponseToJSON,
    DemoChartInfoListResponseFromJSON,
    DemoChartInfoListResponseToJSON,
    DomainCardResponseFromJSON,
    DomainCardResponseToJSON,
    EthnicityInfoListResponseFromJSON,
    EthnicityInfoListResponseToJSON,
    ParticipantDemographicsFromJSON,
    ParticipantDemographicsToJSON,
    SurveyVersionListResponseFromJSON,
    SurveyVersionListResponseToJSON,
    SurveysResponseFromJSON,
    SurveysResponseToJSON,
    VariantFilterFromJSON,
    VariantFilterToJSON,
    VariantFilterInfoResponseFromJSON,
    VariantFilterInfoResponseToJSON,
    VariantFilterRequestFromJSON,
    VariantFilterRequestToJSON,
    VariantFilterResponseFromJSON,
    VariantFilterResponseToJSON,
    VariantListResponseFromJSON,
    VariantListResponseToJSON,
} from '../models';

export interface CohortBuilderApiCountParticipantsRequest {
    workspaceNamespace: string;
    terraName: string;
    request: CohortDefinition;
}

export interface CohortBuilderApiFindAgeTypeCountsRequest {
    workspaceNamespace: string;
    terraName: string;
}

export interface CohortBuilderApiFindConceptCountsRequest {
    workspaceNamespace: string;
    terraName: string;
    term: string;
}

export interface CohortBuilderApiFindCriteriaAttributeByConceptIdRequest {
    workspaceNamespace: string;
    terraName: string;
    conceptId: number;
}

export interface CohortBuilderApiFindCriteriaAutoCompleteRequest {
    workspaceNamespace: string;
    terraName: string;
    request: CriteriaSearchRequest;
}

export interface CohortBuilderApiFindCriteriaByRequest {
    workspaceNamespace: string;
    terraName: string;
    domain: string;
    type?: string;
    standard?: boolean;
    parentId?: number;
}

export interface CohortBuilderApiFindCriteriaByConceptIdsOrConceptCodesRequest {
    workspaceNamespace: string;
    terraName: string;
    request: ConceptsRequest;
}

export interface CohortBuilderApiFindCriteriaByDomainRequest {
    workspaceNamespace: string;
    terraName: string;
    request: CriteriaSearchRequest;
}

export interface CohortBuilderApiFindCriteriaForCohortEditRequest {
    workspaceNamespace: string;
    terraName: string;
    domain: string;
    request: CriteriaRequest;
}

export interface CohortBuilderApiFindCriteriaMenuRequest {
    workspaceNamespace: string;
    terraName: string;
    parentId: number;
}

export interface CohortBuilderApiFindDataFiltersRequest {
    workspaceNamespace: string;
    terraName: string;
}

export interface CohortBuilderApiFindDemoChartInfoRequest {
    workspaceNamespace: string;
    terraName: string;
    genderSexRaceOrEth: string;
    age: string;
    request: CohortDefinition;
}

export interface CohortBuilderApiFindDomainCardsRequest {
    workspaceNamespace: string;
    terraName: string;
}

export interface CohortBuilderApiFindDrugBrandOrIngredientByValueRequest {
    workspaceNamespace: string;
    terraName: string;
    value: string;
}

export interface CohortBuilderApiFindDrugIngredientByConceptIdRequest {
    workspaceNamespace: string;
    terraName: string;
    conceptId: number;
}

export interface CohortBuilderApiFindEthnicityInfoRequest {
    workspaceNamespace: string;
    terraName: string;
    request: CohortDefinition;
}

export interface CohortBuilderApiFindParticipantDemographicsRequest {
    workspaceNamespace: string;
    terraName: string;
}

export interface CohortBuilderApiFindStandardCriteriaByDomainAndConceptIdRequest {
    workspaceNamespace: string;
    terraName: string;
    domain: string;
    conceptId: number;
}

export interface CohortBuilderApiFindSurveyModulesRequest {
    workspaceNamespace: string;
    terraName: string;
}

export interface CohortBuilderApiFindSurveyVersionByQuestionConceptIdRequest {
    workspaceNamespace: string;
    terraName: string;
    questionConceptId: number;
}

export interface CohortBuilderApiFindSurveyVersionByQuestionConceptIdAndAnswerConceptIdRequest {
    workspaceNamespace: string;
    terraName: string;
    questionConceptId: number;
    answerConceptId: number;
}

export interface CohortBuilderApiFindUniversalDomainCountsRequest {
    workspaceNamespace: string;
    terraName: string;
    term: string;
}

export interface CohortBuilderApiFindVariantFilterInfoRequest {
    workspaceNamespace: string;
    terraName: string;
    variantFilter: VariantFilter;
}

export interface CohortBuilderApiFindVariantFiltersRequest {
    workspaceNamespace: string;
    terraName: string;
    variantFilterRequest: VariantFilterRequest;
}

export interface CohortBuilderApiFindVariantsRequest {
    workspaceNamespace: string;
    terraName: string;
    variantFilterRequest: VariantFilterRequest;
}

export interface CohortBuilderApiFindVersionedSurveysRequest {
    workspaceNamespace: string;
    terraName: string;
}

export interface CohortBuilderApiGetCohortChartDataRequest {
    workspaceNamespace: string;
    terraName: string;
    domain: string;
    request: CohortDefinition;
}

/**
 * 
 */
export class CohortBuilderApi extends runtime.BaseAPI {

    /**
     * Searches for participants based on criteria, criteria specific parameters, and modifiers.
     */
    async countParticipantsRaw(requestParameters: CohortBuilderApiCountParticipantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling countParticipants.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling countParticipants.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling countParticipants.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/search`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CohortDefinitionToJSON(requestParameters.request),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Searches for participants based on criteria, criteria specific parameters, and modifiers.
     */
    async countParticipants(workspaceNamespace: string, terraName: string, request: CohortDefinition, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countParticipantsRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, request: request }, initOverrides);
        return await response.value();
    }

    /**
     * Return counts for age type and age
     */
    async findAgeTypeCountsRaw(requestParameters: CohortBuilderApiFindAgeTypeCountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AgeTypeCountListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findAgeTypeCounts.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findAgeTypeCounts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/age/counts`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgeTypeCountListResponseFromJSON(jsonValue));
    }

    /**
     * Return counts for age type and age
     */
    async findAgeTypeCounts(workspaceNamespace: string, terraName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AgeTypeCountListResponse> {
        const response = await this.findAgeTypeCountsRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a count of term matches per domain for EHR domains, Physical Measurement css, and per survey name for all survey names
     */
    async findConceptCountsRaw(requestParameters: CohortBuilderApiFindConceptCountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CardCountResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findConceptCounts.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findConceptCounts.');
        }

        if (requestParameters.term === null || requestParameters.term === undefined) {
            throw new runtime.RequiredError('term','Required parameter requestParameters.term was null or undefined when calling findConceptCounts.');
        }

        const queryParameters: any = {};

        if (requestParameters.term !== undefined) {
            queryParameters['term'] = requestParameters.term;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/criteria/conceptCounts`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CardCountResponseFromJSON(jsonValue));
    }

    /**
     * Returns a count of term matches per domain for EHR domains, Physical Measurement css, and per survey name for all survey names
     */
    async findConceptCounts(workspaceNamespace: string, terraName: string, term: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CardCountResponse> {
        const response = await this.findConceptCountsRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, term: term }, initOverrides);
        return await response.value();
    }

    /**
     * Returns criteria tree with the specified name
     */
    async findCriteriaAttributeByConceptIdRaw(requestParameters: CohortBuilderApiFindCriteriaAttributeByConceptIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CriteriaAttributeListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findCriteriaAttributeByConceptId.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findCriteriaAttributeByConceptId.');
        }

        if (requestParameters.conceptId === null || requestParameters.conceptId === undefined) {
            throw new runtime.RequiredError('conceptId','Required parameter requestParameters.conceptId was null or undefined when calling findCriteriaAttributeByConceptId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/criteria/attribute/{conceptId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"conceptId"}}`, encodeURIComponent(String(requestParameters.conceptId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CriteriaAttributeListResponseFromJSON(jsonValue));
    }

    /**
     * Returns criteria tree with the specified name
     */
    async findCriteriaAttributeByConceptId(workspaceNamespace: string, terraName: string, conceptId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CriteriaAttributeListResponse> {
        const response = await this.findCriteriaAttributeByConceptIdRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, conceptId: conceptId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns matches on survey / criteria table by code or name
     */
    async findCriteriaAutoCompleteRaw(requestParameters: CohortBuilderApiFindCriteriaAutoCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CriteriaListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findCriteriaAutoComplete.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findCriteriaAutoComplete.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling findCriteriaAutoComplete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/criteria/autocomplete`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CriteriaSearchRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CriteriaListResponseFromJSON(jsonValue));
    }

    /**
     * Returns matches on survey / criteria table by code or name
     */
    async findCriteriaAutoComplete(workspaceNamespace: string, terraName: string, request: CriteriaSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CriteriaListResponse> {
        const response = await this.findCriteriaAutoCompleteRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, request: request }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a collection of criteria by optional query parameters of the following: type, subtype, parentId and/or allChildren
     */
    async findCriteriaByRaw(requestParameters: CohortBuilderApiFindCriteriaByRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CriteriaListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findCriteriaBy.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findCriteriaBy.');
        }

        if (requestParameters.domain === null || requestParameters.domain === undefined) {
            throw new runtime.RequiredError('domain','Required parameter requestParameters.domain was null or undefined when calling findCriteriaBy.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.standard !== undefined) {
            queryParameters['standard'] = requestParameters.standard;
        }

        if (requestParameters.parentId !== undefined) {
            queryParameters['parentId'] = requestParameters.parentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/criteria/{domain}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"domain"}}`, encodeURIComponent(String(requestParameters.domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CriteriaListResponseFromJSON(jsonValue));
    }

    /**
     * Returns a collection of criteria by optional query parameters of the following: type, subtype, parentId and/or allChildren
     */
    async findCriteriaBy(workspaceNamespace: string, terraName: string, domain: string, type?: string, standard?: boolean, parentId?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CriteriaListResponse> {
        const response = await this.findCriteriaByRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, domain: domain, type: type, standard: standard, parentId: parentId }, initOverrides);
        return await response.value();
    }

    /**
     * Return criteria for matching concept_ids, if no match then return criteria for matching concept_codes
     */
    async findCriteriaByConceptIdsOrConceptCodesRaw(requestParameters: CohortBuilderApiFindCriteriaByConceptIdsOrConceptCodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CriteriaListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findCriteriaByConceptIdsOrConceptCodes.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findCriteriaByConceptIdsOrConceptCodes.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling findCriteriaByConceptIdsOrConceptCodes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/criteria`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConceptsRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CriteriaListResponseFromJSON(jsonValue));
    }

    /**
     * Return criteria for matching concept_ids, if no match then return criteria for matching concept_codes
     */
    async findCriteriaByConceptIdsOrConceptCodes(workspaceNamespace: string, terraName: string, request: ConceptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CriteriaListResponse> {
        const response = await this.findCriteriaByConceptIdsOrConceptCodesRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, request: request }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a collection of criteria per search term.
     */
    async findCriteriaByDomainRaw(requestParameters: CohortBuilderApiFindCriteriaByDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CriteriaListWithCountResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findCriteriaByDomain.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findCriteriaByDomain.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling findCriteriaByDomain.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/criteria/search/term`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CriteriaSearchRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CriteriaListWithCountResponseFromJSON(jsonValue));
    }

    /**
     * Returns a collection of criteria per search term.
     */
    async findCriteriaByDomain(workspaceNamespace: string, terraName: string, request: CriteriaSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CriteriaListWithCountResponse> {
        const response = await this.findCriteriaByDomainRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, request: request }, initOverrides);
        return await response.value();
    }

    /**
     * Lookup criteria based on domain, standard and conceptId.
     */
    async findCriteriaForCohortEditRaw(requestParameters: CohortBuilderApiFindCriteriaForCohortEditRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CriteriaListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findCriteriaForCohortEdit.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findCriteriaForCohortEdit.');
        }

        if (requestParameters.domain === null || requestParameters.domain === undefined) {
            throw new runtime.RequiredError('domain','Required parameter requestParameters.domain was null or undefined when calling findCriteriaForCohortEdit.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling findCriteriaForCohortEdit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/criteria/{domain}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"domain"}}`, encodeURIComponent(String(requestParameters.domain))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CriteriaRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CriteriaListResponseFromJSON(jsonValue));
    }

    /**
     * Lookup criteria based on domain, standard and conceptId.
     */
    async findCriteriaForCohortEdit(workspaceNamespace: string, terraName: string, domain: string, request: CriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CriteriaListResponse> {
        const response = await this.findCriteriaForCohortEditRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, domain: domain, request: request }, initOverrides);
        return await response.value();
    }

    /**
     * Returns criteria menu
     */
    async findCriteriaMenuRaw(requestParameters: CohortBuilderApiFindCriteriaMenuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CriteriaMenuListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findCriteriaMenu.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findCriteriaMenu.');
        }

        if (requestParameters.parentId === null || requestParameters.parentId === undefined) {
            throw new runtime.RequiredError('parentId','Required parameter requestParameters.parentId was null or undefined when calling findCriteriaMenu.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/criteria/menu/{parentId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"parentId"}}`, encodeURIComponent(String(requestParameters.parentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CriteriaMenuListResponseFromJSON(jsonValue));
    }

    /**
     * Returns criteria menu
     */
    async findCriteriaMenu(workspaceNamespace: string, terraName: string, parentId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CriteriaMenuListResponse> {
        const response = await this.findCriteriaMenuRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, parentId: parentId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns data filter options per cdr version
     */
    async findDataFiltersRaw(requestParameters: CohortBuilderApiFindDataFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataFiltersResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findDataFilters.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findDataFilters.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/dataFilters`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataFiltersResponseFromJSON(jsonValue));
    }

    /**
     * Returns data filter options per cdr version
     */
    async findDataFilters(workspaceNamespace: string, terraName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataFiltersResponse> {
        const response = await this.findDataFiltersRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName }, initOverrides);
        return await response.value();
    }

    /**
     * Searches for demographic info about subjects.
     */
    async findDemoChartInfoRaw(requestParameters: CohortBuilderApiFindDemoChartInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DemoChartInfoListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findDemoChartInfo.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findDemoChartInfo.');
        }

        if (requestParameters.genderSexRaceOrEth === null || requestParameters.genderSexRaceOrEth === undefined) {
            throw new runtime.RequiredError('genderSexRaceOrEth','Required parameter requestParameters.genderSexRaceOrEth was null or undefined when calling findDemoChartInfo.');
        }

        if (requestParameters.age === null || requestParameters.age === undefined) {
            throw new runtime.RequiredError('age','Required parameter requestParameters.age was null or undefined when calling findDemoChartInfo.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling findDemoChartInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/chartinfo/{genderSexRaceOrEth}/{age}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"genderSexRaceOrEth"}}`, encodeURIComponent(String(requestParameters.genderSexRaceOrEth))).replace(`{${"age"}}`, encodeURIComponent(String(requestParameters.age))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CohortDefinitionToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DemoChartInfoListResponseFromJSON(jsonValue));
    }

    /**
     * Searches for demographic info about subjects.
     */
    async findDemoChartInfo(workspaceNamespace: string, terraName: string, genderSexRaceOrEth: string, age: string, request: CohortDefinition, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DemoChartInfoListResponse> {
        const response = await this.findDemoChartInfoRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, genderSexRaceOrEth: genderSexRaceOrEth, age: age, request: request }, initOverrides);
        return await response.value();
    }

    /**
     * Returns information on the domains of data in the workspace\'s CDR version along with participant and concept counts
     */
    async findDomainCardsRaw(requestParameters: CohortBuilderApiFindDomainCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DomainCardResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findDomainCards.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findDomainCards.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/criteria/domainCard`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DomainCardResponseFromJSON(jsonValue));
    }

    /**
     * Returns information on the domains of data in the workspace\'s CDR version along with participant and concept counts
     */
    async findDomainCards(workspaceNamespace: string, terraName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DomainCardResponse> {
        const response = await this.findDomainCardsRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName }, initOverrides);
        return await response.value();
    }

    /**
     * Returns criteria tree matching value
     */
    async findDrugBrandOrIngredientByValueRaw(requestParameters: CohortBuilderApiFindDrugBrandOrIngredientByValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CriteriaListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findDrugBrandOrIngredientByValue.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findDrugBrandOrIngredientByValue.');
        }

        if (requestParameters.value === null || requestParameters.value === undefined) {
            throw new runtime.RequiredError('value','Required parameter requestParameters.value was null or undefined when calling findDrugBrandOrIngredientByValue.');
        }

        const queryParameters: any = {};

        if (requestParameters.value !== undefined) {
            queryParameters['value'] = requestParameters.value;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/criteria/drug`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CriteriaListResponseFromJSON(jsonValue));
    }

    /**
     * Returns criteria tree matching value
     */
    async findDrugBrandOrIngredientByValue(workspaceNamespace: string, terraName: string, value: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CriteriaListResponse> {
        const response = await this.findDrugBrandOrIngredientByValueRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, value: value }, initOverrides);
        return await response.value();
    }

    /**
     * Returns criteria tree with the specified name
     */
    async findDrugIngredientByConceptIdRaw(requestParameters: CohortBuilderApiFindDrugIngredientByConceptIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CriteriaListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findDrugIngredientByConceptId.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findDrugIngredientByConceptId.');
        }

        if (requestParameters.conceptId === null || requestParameters.conceptId === undefined) {
            throw new runtime.RequiredError('conceptId','Required parameter requestParameters.conceptId was null or undefined when calling findDrugIngredientByConceptId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/criteria/drug/ingredient/{conceptId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"conceptId"}}`, encodeURIComponent(String(requestParameters.conceptId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CriteriaListResponseFromJSON(jsonValue));
    }

    /**
     * Returns criteria tree with the specified name
     */
    async findDrugIngredientByConceptId(workspaceNamespace: string, terraName: string, conceptId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CriteriaListResponse> {
        const response = await this.findDrugIngredientByConceptIdRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, conceptId: conceptId }, initOverrides);
        return await response.value();
    }

    /**
     * Searches for ethnicity info about subjects.
     */
    async findEthnicityInfoRaw(requestParameters: CohortBuilderApiFindEthnicityInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EthnicityInfoListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findEthnicityInfo.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findEthnicityInfo.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling findEthnicityInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/chartinfo/ethnicity`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CohortDefinitionToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EthnicityInfoListResponseFromJSON(jsonValue));
    }

    /**
     * Searches for ethnicity info about subjects.
     */
    async findEthnicityInfo(workspaceNamespace: string, terraName: string, request: CohortDefinition, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EthnicityInfoListResponse> {
        const response = await this.findEthnicityInfoRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, request: request }, initOverrides);
        return await response.value();
    }

    /**
     * Will return a list all values for gender, race and ethnicity.
     */
    async findParticipantDemographicsRaw(requestParameters: CohortBuilderApiFindParticipantDemographicsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParticipantDemographics>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findParticipantDemographics.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findParticipantDemographics.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/demographics`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParticipantDemographicsFromJSON(jsonValue));
    }

    /**
     * Will return a list all values for gender, race and ethnicity.
     */
    async findParticipantDemographics(workspaceNamespace: string, terraName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParticipantDemographics> {
        const response = await this.findParticipantDemographicsRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a collection of criteria per concept id
     */
    async findStandardCriteriaByDomainAndConceptIdRaw(requestParameters: CohortBuilderApiFindStandardCriteriaByDomainAndConceptIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CriteriaListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findStandardCriteriaByDomainAndConceptId.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findStandardCriteriaByDomainAndConceptId.');
        }

        if (requestParameters.domain === null || requestParameters.domain === undefined) {
            throw new runtime.RequiredError('domain','Required parameter requestParameters.domain was null or undefined when calling findStandardCriteriaByDomainAndConceptId.');
        }

        if (requestParameters.conceptId === null || requestParameters.conceptId === undefined) {
            throw new runtime.RequiredError('conceptId','Required parameter requestParameters.conceptId was null or undefined when calling findStandardCriteriaByDomainAndConceptId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/criteria/{domain}/{conceptId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"domain"}}`, encodeURIComponent(String(requestParameters.domain))).replace(`{${"conceptId"}}`, encodeURIComponent(String(requestParameters.conceptId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CriteriaListResponseFromJSON(jsonValue));
    }

    /**
     * Returns a collection of criteria per concept id
     */
    async findStandardCriteriaByDomainAndConceptId(workspaceNamespace: string, terraName: string, domain: string, conceptId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CriteriaListResponse> {
        const response = await this.findStandardCriteriaByDomainAndConceptIdRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, domain: domain, conceptId: conceptId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns survey information in the workspace\'s CDR version along with participant and question count
     */
    async findSurveyModulesRaw(requestParameters: CohortBuilderApiFindSurveyModulesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SurveysResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findSurveyModules.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findSurveyModules.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/criteria/surveymodule`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveysResponseFromJSON(jsonValue));
    }

    /**
     * Returns survey information in the workspace\'s CDR version along with participant and question count
     */
    async findSurveyModules(workspaceNamespace: string, terraName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SurveysResponse> {
        const response = await this.findSurveyModulesRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName }, initOverrides);
        return await response.value();
    }

    /**
     * Returns SurveyVersion with counts
     */
    async findSurveyVersionByQuestionConceptIdRaw(requestParameters: CohortBuilderApiFindSurveyVersionByQuestionConceptIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SurveyVersionListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findSurveyVersionByQuestionConceptId.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findSurveyVersionByQuestionConceptId.');
        }

        if (requestParameters.questionConceptId === null || requestParameters.questionConceptId === undefined) {
            throw new runtime.RequiredError('questionConceptId','Required parameter requestParameters.questionConceptId was null or undefined when calling findSurveyVersionByQuestionConceptId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/criteria/survey/attribute/{questionConceptId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"questionConceptId"}}`, encodeURIComponent(String(requestParameters.questionConceptId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyVersionListResponseFromJSON(jsonValue));
    }

    /**
     * Returns SurveyVersion with counts
     */
    async findSurveyVersionByQuestionConceptId(workspaceNamespace: string, terraName: string, questionConceptId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SurveyVersionListResponse> {
        const response = await this.findSurveyVersionByQuestionConceptIdRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, questionConceptId: questionConceptId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns SurveyVersion with counts
     */
    async findSurveyVersionByQuestionConceptIdAndAnswerConceptIdRaw(requestParameters: CohortBuilderApiFindSurveyVersionByQuestionConceptIdAndAnswerConceptIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SurveyVersionListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findSurveyVersionByQuestionConceptIdAndAnswerConceptId.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findSurveyVersionByQuestionConceptIdAndAnswerConceptId.');
        }

        if (requestParameters.questionConceptId === null || requestParameters.questionConceptId === undefined) {
            throw new runtime.RequiredError('questionConceptId','Required parameter requestParameters.questionConceptId was null or undefined when calling findSurveyVersionByQuestionConceptIdAndAnswerConceptId.');
        }

        if (requestParameters.answerConceptId === null || requestParameters.answerConceptId === undefined) {
            throw new runtime.RequiredError('answerConceptId','Required parameter requestParameters.answerConceptId was null or undefined when calling findSurveyVersionByQuestionConceptIdAndAnswerConceptId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/criteria/survey/attribute/{questionConceptId}/{answerConceptId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"questionConceptId"}}`, encodeURIComponent(String(requestParameters.questionConceptId))).replace(`{${"answerConceptId"}}`, encodeURIComponent(String(requestParameters.answerConceptId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyVersionListResponseFromJSON(jsonValue));
    }

    /**
     * Returns SurveyVersion with counts
     */
    async findSurveyVersionByQuestionConceptIdAndAnswerConceptId(workspaceNamespace: string, terraName: string, questionConceptId: number, answerConceptId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SurveyVersionListResponse> {
        const response = await this.findSurveyVersionByQuestionConceptIdAndAnswerConceptIdRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, questionConceptId: questionConceptId, answerConceptId: answerConceptId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a count of term matches per domain for EHR domains
     */
    async findUniversalDomainCountsRaw(requestParameters: CohortBuilderApiFindUniversalDomainCountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CardCountResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findUniversalDomainCounts.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findUniversalDomainCounts.');
        }

        if (requestParameters.term === null || requestParameters.term === undefined) {
            throw new runtime.RequiredError('term','Required parameter requestParameters.term was null or undefined when calling findUniversalDomainCounts.');
        }

        const queryParameters: any = {};

        if (requestParameters.term !== undefined) {
            queryParameters['term'] = requestParameters.term;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/criteria/universalDomainCounts`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CardCountResponseFromJSON(jsonValue));
    }

    /**
     * Returns a count of term matches per domain for EHR domains
     */
    async findUniversalDomainCounts(workspaceNamespace: string, terraName: string, term: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CardCountResponse> {
        const response = await this.findUniversalDomainCountsRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, term: term }, initOverrides);
        return await response.value();
    }

    /**
     * Returns info about this VariantFilter
     */
    async findVariantFilterInfoRaw(requestParameters: CohortBuilderApiFindVariantFilterInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VariantFilterInfoResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findVariantFilterInfo.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findVariantFilterInfo.');
        }

        if (requestParameters.variantFilter === null || requestParameters.variantFilter === undefined) {
            throw new runtime.RequiredError('variantFilter','Required parameter requestParameters.variantFilter was null or undefined when calling findVariantFilterInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/variant/filter/info`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VariantFilterToJSON(requestParameters.variantFilter),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VariantFilterInfoResponseFromJSON(jsonValue));
    }

    /**
     * Returns info about this VariantFilter
     */
    async findVariantFilterInfo(workspaceNamespace: string, terraName: string, variantFilter: VariantFilter, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VariantFilterInfoResponse> {
        const response = await this.findVariantFilterInfoRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, variantFilter: variantFilter }, initOverrides);
        return await response.value();
    }

    /**
     * Returns filters that can narrow variant results
     */
    async findVariantFiltersRaw(requestParameters: CohortBuilderApiFindVariantFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VariantFilterResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findVariantFilters.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findVariantFilters.');
        }

        if (requestParameters.variantFilterRequest === null || requestParameters.variantFilterRequest === undefined) {
            throw new runtime.RequiredError('variantFilterRequest','Required parameter requestParameters.variantFilterRequest was null or undefined when calling findVariantFilters.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/variant/filters`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VariantFilterRequestToJSON(requestParameters.variantFilterRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VariantFilterResponseFromJSON(jsonValue));
    }

    /**
     * Returns filters that can narrow variant results
     */
    async findVariantFilters(workspaceNamespace: string, terraName: string, variantFilterRequest: VariantFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VariantFilterResponse> {
        const response = await this.findVariantFiltersRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, variantFilterRequest: variantFilterRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Returns genomic variants from VAT per specified search term
     */
    async findVariantsRaw(requestParameters: CohortBuilderApiFindVariantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VariantListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findVariants.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findVariants.');
        }

        if (requestParameters.variantFilterRequest === null || requestParameters.variantFilterRequest === undefined) {
            throw new runtime.RequiredError('variantFilterRequest','Required parameter requestParameters.variantFilterRequest was null or undefined when calling findVariants.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/variants`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VariantFilterRequestToJSON(requestParameters.variantFilterRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VariantListResponseFromJSON(jsonValue));
    }

    /**
     * Returns genomic variants from VAT per specified search term
     */
    async findVariants(workspaceNamespace: string, terraName: string, variantFilterRequest: VariantFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VariantListResponse> {
        const response = await this.findVariantsRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, variantFilterRequest: variantFilterRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Returns any surveys that are versioned
     */
    async findVersionedSurveysRaw(requestParameters: CohortBuilderApiFindVersionedSurveysRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CriteriaListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling findVersionedSurveys.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling findVersionedSurveys.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/criteria/survey/versions`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CriteriaListResponseFromJSON(jsonValue));
    }

    /**
     * Returns any surveys that are versioned
     */
    async findVersionedSurveys(workspaceNamespace: string, terraName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CriteriaListResponse> {
        const response = await this.findVersionedSurveysRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a collection of CohortChartData for UI charting in cohort.
     */
    async getCohortChartDataRaw(requestParameters: CohortBuilderApiGetCohortChartDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CohortChartDataListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getCohortChartData.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getCohortChartData.');
        }

        if (requestParameters.domain === null || requestParameters.domain === undefined) {
            throw new runtime.RequiredError('domain','Required parameter requestParameters.domain was null or undefined when calling getCohortChartData.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling getCohortChartData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cohortbuilder/{workspaceNamespace}/{terraName}/top/chartinfo/{domain}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"domain"}}`, encodeURIComponent(String(requestParameters.domain))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CohortDefinitionToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CohortChartDataListResponseFromJSON(jsonValue));
    }

    /**
     * Returns a collection of CohortChartData for UI charting in cohort.
     */
    async getCohortChartData(workspaceNamespace: string, terraName: string, domain: string, request: CohortDefinition, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CohortChartDataListResponse> {
        const response = await this.getCohortChartDataRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, domain: domain, request: request }, initOverrides);
        return await response.value();
    }

}
