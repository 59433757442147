/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The CriteriaRequest represents any domain specific conceptIds grouped by Source/standard flags. This request has been created specifically for use within edit function of Cohort Definitions, since Cohort Definitions are not able to save criteria id Because this varies from CDR to CDR.
 * @export
 * @interface CriteriaRequest
 */
export interface CriteriaRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof CriteriaRequest
     */
    sourceConceptIds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CriteriaRequest
     */
    standardConceptIds?: Array<number>;
}

/**
 * Check if a given object implements the CriteriaRequest interface.
 */
export function instanceOfCriteriaRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CriteriaRequestFromJSON(json: any): CriteriaRequest {
    return CriteriaRequestFromJSONTyped(json, false);
}

export function CriteriaRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CriteriaRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourceConceptIds': !exists(json, 'sourceConceptIds') ? undefined : json['sourceConceptIds'],
        'standardConceptIds': !exists(json, 'standardConceptIds') ? undefined : json['standardConceptIds'],
    };
}

export function CriteriaRequestToJSON(value?: CriteriaRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sourceConceptIds': value.sourceConceptIds,
        'standardConceptIds': value.standardConceptIds,
    };
}

