/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VariantFilterInfoResponse
 */
export interface VariantFilterInfoResponse {
    /**
     * Total vids count for the VariantFilter.
     * @type {number}
     * @memberof VariantFilterInfoResponse
     */
    vidsCount: number;
    /**
     * Total participant count for the VariantFilter.
     * @type {number}
     * @memberof VariantFilterInfoResponse
     */
    participantCount: number;
    /**
     * Total vids count of variants found in less than or equal to 5k of participants.
     * @type {number}
     * @memberof VariantFilterInfoResponse
     */
    lessThanOrEqualToFiveThousand: number;
    /**
     * Total vids count of variants found in over 5k of participants.
     * @type {number}
     * @memberof VariantFilterInfoResponse
     */
    overFiveThousand: number;
    /**
     * Total vids count of variants found in over 10k of participants.
     * @type {number}
     * @memberof VariantFilterInfoResponse
     */
    overTenThousand: number;
    /**
     * Total vids count of variants found in over 100k of participants.
     * @type {number}
     * @memberof VariantFilterInfoResponse
     */
    overHundredThousand: number;
    /**
     * Total vids count of variants found in over 200k of participants.
     * @type {number}
     * @memberof VariantFilterInfoResponse
     */
    overTwoHundredThousand: number;
}

/**
 * Check if a given object implements the VariantFilterInfoResponse interface.
 */
export function instanceOfVariantFilterInfoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vidsCount" in value;
    isInstance = isInstance && "participantCount" in value;
    isInstance = isInstance && "lessThanOrEqualToFiveThousand" in value;
    isInstance = isInstance && "overFiveThousand" in value;
    isInstance = isInstance && "overTenThousand" in value;
    isInstance = isInstance && "overHundredThousand" in value;
    isInstance = isInstance && "overTwoHundredThousand" in value;

    return isInstance;
}

export function VariantFilterInfoResponseFromJSON(json: any): VariantFilterInfoResponse {
    return VariantFilterInfoResponseFromJSONTyped(json, false);
}

export function VariantFilterInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariantFilterInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vidsCount': json['vidsCount'],
        'participantCount': json['participantCount'],
        'lessThanOrEqualToFiveThousand': json['lessThanOrEqualToFiveThousand'],
        'overFiveThousand': json['overFiveThousand'],
        'overTenThousand': json['overTenThousand'],
        'overHundredThousand': json['overHundredThousand'],
        'overTwoHundredThousand': json['overTwoHundredThousand'],
    };
}

export function VariantFilterInfoResponseToJSON(value?: VariantFilterInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vidsCount': value.vidsCount,
        'participantCount': value.participantCount,
        'lessThanOrEqualToFiveThousand': value.lessThanOrEqualToFiveThousand,
        'overFiveThousand': value.overFiveThousand,
        'overTenThousand': value.overTenThousand,
        'overHundredThousand': value.overHundredThousand,
        'overTwoHundredThousand': value.overTwoHundredThousand,
    };
}

