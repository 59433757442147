/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Variant } from './Variant';
import {
    VariantFromJSON,
    VariantFromJSONTyped,
    VariantToJSON,
} from './Variant';

/**
 * 
 * @export
 * @interface VariantListResponse
 */
export interface VariantListResponse {
    /**
     * 
     * @type {Array<Variant>}
     * @memberof VariantListResponse
     */
    items: Array<Variant>;
    /**
     * If present, this token can be provided to retrieve the next page of results. If null or empty, there are no further pages in the result stream.
     * @type {string}
     * @memberof VariantListResponse
     */
    nextPageToken?: string;
    /**
     * Total size of the result set.
     * @type {number}
     * @memberof VariantListResponse
     */
    totalSize?: number;
}

/**
 * Check if a given object implements the VariantListResponse interface.
 */
export function instanceOfVariantListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function VariantListResponseFromJSON(json: any): VariantListResponse {
    return VariantListResponseFromJSONTyped(json, false);
}

export function VariantListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariantListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(VariantFromJSON)),
        'nextPageToken': !exists(json, 'nextPageToken') ? undefined : json['nextPageToken'],
        'totalSize': !exists(json, 'totalSize') ? undefined : json['totalSize'],
    };
}

export function VariantListResponseToJSON(value?: VariantListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(VariantToJSON)),
        'nextPageToken': value.nextPageToken,
        'totalSize': value.totalSize,
    };
}

