/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Leonardo
 * Workbench notebooks service. 
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://notebooks.firecloud.org".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface ErrorReport
 */
export interface ErrorReport {
    /**
     * service causing error
     * @type {string}
     * @memberof ErrorReport
     */
    source: string;
    /**
     * what went wrong
     * @type {string}
     * @memberof ErrorReport
     */
    message: string;
    /**
     * class of exception thrown
     * @type {string}
     * @memberof ErrorReport
     */
    exceptionClass?: string;
    /**
     * HTTP status code
     * @type {number}
     * @memberof ErrorReport
     */
    statusCode?: number;
    /**
     * errors triggering this one
     * @type {Array<ErrorReport>}
     * @memberof ErrorReport
     */
    causes: Array<ErrorReport>;
    /**
     * stack trace
     * @type {Array<StackTraceElement>}
     * @memberof ErrorReport
     */
    stackTrace: Array<StackTraceElement>;
}

/**
 * 
 * @export
 * @interface InlineResponse400
 */
export interface InlineResponse400 {
    /**
     * Error condition
     * @type {string}
     * @memberof InlineResponse400
     */
    error?: string;
    /**
     * Explanation of error reason
     * @type {string}
     * @memberof InlineResponse400
     */
    reason?: string;
}

/**
 * A contents object.  The content and format keys may be null if content is not contained.  If type is 'file', then the mimetype will be null.
 * @export
 * @interface JupyterContents
 */
export interface JupyterContents {
    /**
     * Name of file or directory, equivalent to the last part of the path
     * @type {string}
     * @memberof JupyterContents
     */
    name: string;
    /**
     * Full path for file or directory
     * @type {string}
     * @memberof JupyterContents
     */
    path: string;
    /**
     * Type of content
     * @type {string}
     * @memberof JupyterContents
     */
    type: JupyterContents.TypeEnum;
    /**
     * indicates whether the requester has permission to edit the file
     * @type {boolean}
     * @memberof JupyterContents
     */
    writable: boolean;
    /**
     * Creation timestamp
     * @type {string}
     * @memberof JupyterContents
     */
    created: string;
    /**
     * Last modified timestamp
     * @type {string}
     * @memberof JupyterContents
     */
    lastModified: string;
    /**
     * The mimetype of a file.  If content is not null, and type is 'file', this will contain the mimetype of the file, otherwise this will be null.
     * @type {string}
     * @memberof JupyterContents
     */
    mimetype: string;
    /**
     * The content, if requested (otherwise null).  Will be an array if type is 'directory'
     * @type {string}
     * @memberof JupyterContents
     */
    content: string;
    /**
     * Format of content (one of null, 'text', 'base64', 'json')
     * @type {string}
     * @memberof JupyterContents
     */
    format: string;
}

/**
 * @export
 * @namespace JupyterContents
 */
export namespace JupyterContents {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Directory = <any> 'directory',
        File = <any> 'file',
        Notebook = <any> 'notebook'
    }
}

/**
 * 
 * @export
 * @interface JupyterModel
 */
export interface JupyterModel {
    /**
     * The new filename if changed
     * @type {string}
     * @memberof JupyterModel
     */
    name?: string;
    /**
     * New path for file or directory
     * @type {string}
     * @memberof JupyterModel
     */
    path?: string;
    /**
     * Path dtype ('notebook', 'file', 'directory')
     * @type {string}
     * @memberof JupyterModel
     */
    type?: string;
    /**
     * File format ('json', 'text', 'base64')
     * @type {string}
     * @memberof JupyterModel
     */
    format?: string;
    /**
     * The actual body of the document excluding directory type
     * @type {string}
     * @memberof JupyterModel
     */
    content?: string;
}

/**
 * 
 * @export
 * @interface LocalizationEntry
 */
export interface LocalizationEntry {
    /**
     * 
     * @type {string}
     * @memberof LocalizationEntry
     */
    sourceUri?: string;
    /**
     * 
     * @type {string}
     * @memberof LocalizationEntry
     */
    localDestinationPath?: string;
}

/**
 * 
 * @export
 * @interface Localize
 */
export interface Localize {
    /**
     * 
     * @type {string}
     * @memberof Localize
     */
    action?: string;
    /**
     * 
     * @type {Array<LocalizationEntry>}
     * @memberof Localize
     */
    entries?: Array<LocalizationEntry>;
}

/**
 * 
 * @export
 * @interface Model
 */
export interface Model {
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    ext?: string;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    type?: string;
}

/**
 * 
 * @export
 * @interface StackTraceElement
 */
export interface StackTraceElement {
    /**
     * class name
     * @type {string}
     * @memberof StackTraceElement
     */
    className: string;
    /**
     * method name
     * @type {string}
     * @memberof StackTraceElement
     */
    methodName: string;
    /**
     * source file name
     * @type {string}
     * @memberof StackTraceElement
     */
    fileName: string;
    /**
     * line number
     * @type {number}
     * @memberof StackTraceElement
     */
    lineNumber: number;
}

/**
 * 
 * @export
 * @interface StorageLink
 */
export interface StorageLink {
    /**
     * 
     * @type {string}
     * @memberof StorageLink
     */
    localBaseDirectory?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageLink
     */
    localSafeModeBaseDirectory?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageLink
     */
    cloudStorageDirectory?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageLink
     */
    pattern?: string;
}

/**
 * 
 * @export
 * @interface WelderErrorReport
 */
export interface WelderErrorReport {
    /**
     * 
     * @type {string}
     * @memberof WelderErrorReport
     */
    errorMessage?: string;
    /**
     * 
     * @type {number}
     * @memberof WelderErrorReport
     */
    errorCode?: number;
}


/**
 * AppsApi - fetch parameter creator
 * @export
 */
export const AppsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Starts the stopped app
         * @summary Starts an app with the given project and name
         * @param {string} googleProject googleProject
         * @param {string} appName appName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startApp(googleProject: string, appName: string, options: any = {}): FetchArgs {
            // verify required parameter 'googleProject' is not null or undefined
            if (googleProject === null || googleProject === undefined) {
                throw new RequiredError('googleProject','Required parameter googleProject was null or undefined when calling startApp.');
            }
            // verify required parameter 'appName' is not null or undefined
            if (appName === null || appName === undefined) {
                throw new RequiredError('appName','Required parameter appName was null or undefined when calling startApp.');
            }
            const localVarPath = `/api/google/v1/apps/{googleProject}/{appName}/start`
                .replace(`{${"googleProject"}}`, encodeURIComponent(String(googleProject)))
                .replace(`{${"appName"}}`, encodeURIComponent(String(appName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication googleoauth required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("googleoauth", ["openid", "profile", "email"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stops the running compute, but retains any data persisted on disk. The app may be restarted with the /start endpoint. 
         * @summary Stops an app with the given project and name
         * @param {string} googleProject googleProject
         * @param {string} appName appName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopApp(googleProject: string, appName: string, options: any = {}): FetchArgs {
            // verify required parameter 'googleProject' is not null or undefined
            if (googleProject === null || googleProject === undefined) {
                throw new RequiredError('googleProject','Required parameter googleProject was null or undefined when calling stopApp.');
            }
            // verify required parameter 'appName' is not null or undefined
            if (appName === null || appName === undefined) {
                throw new RequiredError('appName','Required parameter appName was null or undefined when calling stopApp.');
            }
            const localVarPath = `/api/google/v1/apps/{googleProject}/{appName}/stop`
                .replace(`{${"googleProject"}}`, encodeURIComponent(String(googleProject)))
                .replace(`{${"appName"}}`, encodeURIComponent(String(appName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication googleoauth required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("googleoauth", ["openid", "profile", "email"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppsApi - functional programming interface
 * @export
 */
export const AppsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Starts the stopped app
         * @summary Starts an app with the given project and name
         * @param {string} googleProject googleProject
         * @param {string} appName appName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startApp(googleProject: string, appName: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AppsApiFetchParamCreator(configuration).startApp(googleProject, appName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Stops the running compute, but retains any data persisted on disk. The app may be restarted with the /start endpoint. 
         * @summary Stops an app with the given project and name
         * @param {string} googleProject googleProject
         * @param {string} appName appName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopApp(googleProject: string, appName: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AppsApiFetchParamCreator(configuration).stopApp(googleProject, appName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AppsApi - factory interface
 * @export
 */
export const AppsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Starts the stopped app
         * @summary Starts an app with the given project and name
         * @param {string} googleProject googleProject
         * @param {string} appName appName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startApp(googleProject: string, appName: string, options?: any) {
            return AppsApiFp(configuration).startApp(googleProject, appName, options)(fetch, basePath);
        },
        /**
         * Stops the running compute, but retains any data persisted on disk. The app may be restarted with the /start endpoint. 
         * @summary Stops an app with the given project and name
         * @param {string} googleProject googleProject
         * @param {string} appName appName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopApp(googleProject: string, appName: string, options?: any) {
            return AppsApiFp(configuration).stopApp(googleProject, appName, options)(fetch, basePath);
        },
    };
};

/**
 * AppsApi - object-oriented interface
 * @export
 * @class AppsApi
 * @extends {BaseAPI}
 */
export class AppsApi extends BaseAPI {
    /**
     * Starts the stopped app
     * @summary Starts an app with the given project and name
     * @param {string} googleProject googleProject
     * @param {string} appName appName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public startApp(googleProject: string, appName: string, options?: any) {
        return AppsApiFp(this.configuration).startApp(googleProject, appName, options)(this.fetch, this.basePath);
    }

    /**
     * Stops the running compute, but retains any data persisted on disk. The app may be restarted with the /start endpoint. 
     * @summary Stops an app with the given project and name
     * @param {string} googleProject googleProject
     * @param {string} appName appName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public stopApp(googleProject: string, appName: string, options?: any) {
        return AppsApiFp(this.configuration).stopApp(googleProject, appName, options)(this.fetch, this.basePath);
    }

}

/**
 * JupyterApi - fetch parameter creator
 * @export
 */
export const JupyterApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * A client can optionally specify a type and/or format argument via URL parameter. When given, the Contents service shall return a model in the requested type and/or format. If the request cannot be satisfied, e.g. type=text is requested, but the file is binary, then the request shall fail with 400 and have a JSON response containing a 'reason' field, with the value 'bad format' or 'bad type', depending on what was requested.
         * @summary Get contents of file or directory
         * @param {string} googleProject googleProject
         * @param {string} runtimeName runtimeName
         * @param {string} fileName fileName
         * @param {'file' | 'directory'} [type] File type (&#39;file&#39;, &#39;directory&#39;)
         * @param {'text' | 'base64'} [format] How file content should be returned (&#39;text&#39;, &#39;base64&#39;)
         * @param {number} [content] Return content (0 for no content, 1 for return content)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootContents(googleProject: string, runtimeName: string, fileName: string, type?: 'file' | 'directory', format?: 'text' | 'base64', content?: number, options: any = {}): FetchArgs {
            // verify required parameter 'googleProject' is not null or undefined
            if (googleProject === null || googleProject === undefined) {
                throw new RequiredError('googleProject','Required parameter googleProject was null or undefined when calling getRootContents.');
            }
            // verify required parameter 'runtimeName' is not null or undefined
            if (runtimeName === null || runtimeName === undefined) {
                throw new RequiredError('runtimeName','Required parameter runtimeName was null or undefined when calling getRootContents.');
            }
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling getRootContents.');
            }
            const localVarPath = `/proxy/{googleProject}/{runtimeName}/jupyter/api/contents/{fileName}`
                .replace(`{${"googleProject"}}`, encodeURIComponent(String(googleProject)))
                .replace(`{${"runtimeName"}}`, encodeURIComponent(String(runtimeName)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication googleoauth required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("googleoauth", ["openid", "profile", "email"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A POST to /api/contents/path creates a New untitled, empty file or directory. A POST to /api/contents/path with body {'copy_from': '/path/to/OtherNotebook.ipynb'} creates a new copy of OtherNotebook in path.
         * @summary Create a new file in the specified path
         * @param {string} googleProject googleProject
         * @param {string} runtimeName runtimeName
         * @param {string} workspaceDir workspaceDir
         * @param {Model} [model] Path of file to copy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContents(googleProject: string, runtimeName: string, workspaceDir: string, model?: Model, options: any = {}): FetchArgs {
            // verify required parameter 'googleProject' is not null or undefined
            if (googleProject === null || googleProject === undefined) {
                throw new RequiredError('googleProject','Required parameter googleProject was null or undefined when calling postContents.');
            }
            // verify required parameter 'runtimeName' is not null or undefined
            if (runtimeName === null || runtimeName === undefined) {
                throw new RequiredError('runtimeName','Required parameter runtimeName was null or undefined when calling postContents.');
            }
            // verify required parameter 'workspaceDir' is not null or undefined
            if (workspaceDir === null || workspaceDir === undefined) {
                throw new RequiredError('workspaceDir','Required parameter workspaceDir was null or undefined when calling postContents.');
            }
            const localVarPath = `/proxy/{googleProject}/{runtimeName}/jupyter/api/contents/workspaces/{workspaceDir}`
                .replace(`{${"googleProject"}}`, encodeURIComponent(String(googleProject)))
                .replace(`{${"runtimeName"}}`, encodeURIComponent(String(runtimeName)))
                .replace(`{${"workspaceDir"}}`, encodeURIComponent(String(workspaceDir)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication googleoauth required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("googleoauth", ["openid", "profile", "email"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Model" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(model || {}) : (model || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Saves the file in the location specified by name and path.  PUT is very similar to POST, but the requester specifies the name, whereas with POST, the server picks the name.
         * @summary Save or upload file.
         * @param {string} googleProject googleProject
         * @param {string} runtimeName runtimeName
         * @param {string} workspaceDir workspaceDir
         * @param {string} fileName fileName
         * @param {JupyterModel} [model] New path for file or directory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContents(googleProject: string, runtimeName: string, workspaceDir: string, fileName: string, model?: JupyterModel, options: any = {}): FetchArgs {
            // verify required parameter 'googleProject' is not null or undefined
            if (googleProject === null || googleProject === undefined) {
                throw new RequiredError('googleProject','Required parameter googleProject was null or undefined when calling putContents.');
            }
            // verify required parameter 'runtimeName' is not null or undefined
            if (runtimeName === null || runtimeName === undefined) {
                throw new RequiredError('runtimeName','Required parameter runtimeName was null or undefined when calling putContents.');
            }
            // verify required parameter 'workspaceDir' is not null or undefined
            if (workspaceDir === null || workspaceDir === undefined) {
                throw new RequiredError('workspaceDir','Required parameter workspaceDir was null or undefined when calling putContents.');
            }
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling putContents.');
            }
            const localVarPath = `/proxy/{googleProject}/{runtimeName}/jupyter/api/contents/workspaces/{workspaceDir}/{fileName}`
                .replace(`{${"googleProject"}}`, encodeURIComponent(String(googleProject)))
                .replace(`{${"runtimeName"}}`, encodeURIComponent(String(runtimeName)))
                .replace(`{${"workspaceDir"}}`, encodeURIComponent(String(workspaceDir)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication googleoauth required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("googleoauth", ["openid", "profile", "email"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JupyterModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(model || {}) : (model || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JupyterApi - functional programming interface
 * @export
 */
export const JupyterApiFp = function(configuration?: Configuration) {
    return {
        /**
         * A client can optionally specify a type and/or format argument via URL parameter. When given, the Contents service shall return a model in the requested type and/or format. If the request cannot be satisfied, e.g. type=text is requested, but the file is binary, then the request shall fail with 400 and have a JSON response containing a 'reason' field, with the value 'bad format' or 'bad type', depending on what was requested.
         * @summary Get contents of file or directory
         * @param {string} googleProject googleProject
         * @param {string} runtimeName runtimeName
         * @param {string} fileName fileName
         * @param {'file' | 'directory'} [type] File type (&#39;file&#39;, &#39;directory&#39;)
         * @param {'text' | 'base64'} [format] How file content should be returned (&#39;text&#39;, &#39;base64&#39;)
         * @param {number} [content] Return content (0 for no content, 1 for return content)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootContents(googleProject: string, runtimeName: string, fileName: string, type?: 'file' | 'directory', format?: 'text' | 'base64', content?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JupyterContents> {
            const localVarFetchArgs = JupyterApiFetchParamCreator(configuration).getRootContents(googleProject, runtimeName, fileName, type, format, content, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * A POST to /api/contents/path creates a New untitled, empty file or directory. A POST to /api/contents/path with body {'copy_from': '/path/to/OtherNotebook.ipynb'} creates a new copy of OtherNotebook in path.
         * @summary Create a new file in the specified path
         * @param {string} googleProject googleProject
         * @param {string} runtimeName runtimeName
         * @param {string} workspaceDir workspaceDir
         * @param {Model} [model] Path of file to copy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContents(googleProject: string, runtimeName: string, workspaceDir: string, model?: Model, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JupyterContents> {
            const localVarFetchArgs = JupyterApiFetchParamCreator(configuration).postContents(googleProject, runtimeName, workspaceDir, model, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Saves the file in the location specified by name and path.  PUT is very similar to POST, but the requester specifies the name, whereas with POST, the server picks the name.
         * @summary Save or upload file.
         * @param {string} googleProject googleProject
         * @param {string} runtimeName runtimeName
         * @param {string} workspaceDir workspaceDir
         * @param {string} fileName fileName
         * @param {JupyterModel} [model] New path for file or directory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContents(googleProject: string, runtimeName: string, workspaceDir: string, fileName: string, model?: JupyterModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JupyterContents> {
            const localVarFetchArgs = JupyterApiFetchParamCreator(configuration).putContents(googleProject, runtimeName, workspaceDir, fileName, model, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * JupyterApi - factory interface
 * @export
 */
export const JupyterApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * A client can optionally specify a type and/or format argument via URL parameter. When given, the Contents service shall return a model in the requested type and/or format. If the request cannot be satisfied, e.g. type=text is requested, but the file is binary, then the request shall fail with 400 and have a JSON response containing a 'reason' field, with the value 'bad format' or 'bad type', depending on what was requested.
         * @summary Get contents of file or directory
         * @param {string} googleProject googleProject
         * @param {string} runtimeName runtimeName
         * @param {string} fileName fileName
         * @param {'file' | 'directory'} [type] File type (&#39;file&#39;, &#39;directory&#39;)
         * @param {'text' | 'base64'} [format] How file content should be returned (&#39;text&#39;, &#39;base64&#39;)
         * @param {number} [content] Return content (0 for no content, 1 for return content)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootContents(googleProject: string, runtimeName: string, fileName: string, type?: 'file' | 'directory', format?: 'text' | 'base64', content?: number, options?: any) {
            return JupyterApiFp(configuration).getRootContents(googleProject, runtimeName, fileName, type, format, content, options)(fetch, basePath);
        },
        /**
         * A POST to /api/contents/path creates a New untitled, empty file or directory. A POST to /api/contents/path with body {'copy_from': '/path/to/OtherNotebook.ipynb'} creates a new copy of OtherNotebook in path.
         * @summary Create a new file in the specified path
         * @param {string} googleProject googleProject
         * @param {string} runtimeName runtimeName
         * @param {string} workspaceDir workspaceDir
         * @param {Model} [model] Path of file to copy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContents(googleProject: string, runtimeName: string, workspaceDir: string, model?: Model, options?: any) {
            return JupyterApiFp(configuration).postContents(googleProject, runtimeName, workspaceDir, model, options)(fetch, basePath);
        },
        /**
         * Saves the file in the location specified by name and path.  PUT is very similar to POST, but the requester specifies the name, whereas with POST, the server picks the name.
         * @summary Save or upload file.
         * @param {string} googleProject googleProject
         * @param {string} runtimeName runtimeName
         * @param {string} workspaceDir workspaceDir
         * @param {string} fileName fileName
         * @param {JupyterModel} [model] New path for file or directory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContents(googleProject: string, runtimeName: string, workspaceDir: string, fileName: string, model?: JupyterModel, options?: any) {
            return JupyterApiFp(configuration).putContents(googleProject, runtimeName, workspaceDir, fileName, model, options)(fetch, basePath);
        },
    };
};

/**
 * JupyterApi - object-oriented interface
 * @export
 * @class JupyterApi
 * @extends {BaseAPI}
 */
export class JupyterApi extends BaseAPI {
    /**
     * A client can optionally specify a type and/or format argument via URL parameter. When given, the Contents service shall return a model in the requested type and/or format. If the request cannot be satisfied, e.g. type=text is requested, but the file is binary, then the request shall fail with 400 and have a JSON response containing a 'reason' field, with the value 'bad format' or 'bad type', depending on what was requested.
     * @summary Get contents of file or directory
     * @param {string} googleProject googleProject
     * @param {string} runtimeName runtimeName
     * @param {string} fileName fileName
     * @param {'file' | 'directory'} [type] File type (&#39;file&#39;, &#39;directory&#39;)
     * @param {'text' | 'base64'} [format] How file content should be returned (&#39;text&#39;, &#39;base64&#39;)
     * @param {number} [content] Return content (0 for no content, 1 for return content)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JupyterApi
     */
    public getRootContents(googleProject: string, runtimeName: string, fileName: string, type?: 'file' | 'directory', format?: 'text' | 'base64', content?: number, options?: any) {
        return JupyterApiFp(this.configuration).getRootContents(googleProject, runtimeName, fileName, type, format, content, options)(this.fetch, this.basePath);
    }

    /**
     * A POST to /api/contents/path creates a New untitled, empty file or directory. A POST to /api/contents/path with body {'copy_from': '/path/to/OtherNotebook.ipynb'} creates a new copy of OtherNotebook in path.
     * @summary Create a new file in the specified path
     * @param {string} googleProject googleProject
     * @param {string} runtimeName runtimeName
     * @param {string} workspaceDir workspaceDir
     * @param {Model} [model] Path of file to copy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JupyterApi
     */
    public postContents(googleProject: string, runtimeName: string, workspaceDir: string, model?: Model, options?: any) {
        return JupyterApiFp(this.configuration).postContents(googleProject, runtimeName, workspaceDir, model, options)(this.fetch, this.basePath);
    }

    /**
     * Saves the file in the location specified by name and path.  PUT is very similar to POST, but the requester specifies the name, whereas with POST, the server picks the name.
     * @summary Save or upload file.
     * @param {string} googleProject googleProject
     * @param {string} runtimeName runtimeName
     * @param {string} workspaceDir workspaceDir
     * @param {string} fileName fileName
     * @param {JupyterModel} [model] New path for file or directory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JupyterApi
     */
    public putContents(googleProject: string, runtimeName: string, workspaceDir: string, fileName: string, model?: JupyterModel, options?: any) {
        return JupyterApiFp(this.configuration).putContents(googleProject, runtimeName, workspaceDir, fileName, model, options)(this.fetch, this.basePath);
    }

}

/**
 * ProxyApi - fetch parameter creator
 * @export
 */
export const ProxyApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Connect to a jupyter terminal
         * @param {string} googleProject 
         * @param {string} runtimeName runtimeName
         * @param {string} terminalName terminalName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectToTerminal(googleProject: string, runtimeName: string, terminalName: string, options: any = {}): FetchArgs {
            // verify required parameter 'googleProject' is not null or undefined
            if (googleProject === null || googleProject === undefined) {
                throw new RequiredError('googleProject','Required parameter googleProject was null or undefined when calling connectToTerminal.');
            }
            // verify required parameter 'runtimeName' is not null or undefined
            if (runtimeName === null || runtimeName === undefined) {
                throw new RequiredError('runtimeName','Required parameter runtimeName was null or undefined when calling connectToTerminal.');
            }
            // verify required parameter 'terminalName' is not null or undefined
            if (terminalName === null || terminalName === undefined) {
                throw new RequiredError('terminalName','Required parameter terminalName was null or undefined when calling connectToTerminal.');
            }
            const localVarPath = `/proxy/{googleProject}/{runtimeName}/jupyter/terminals/{terminalName}`
                .replace(`{${"googleProject"}}`, encodeURIComponent(String(googleProject)))
                .replace(`{${"runtimeName"}}`, encodeURIComponent(String(runtimeName)))
                .replace(`{${"terminalName"}}`, encodeURIComponent(String(terminalName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication googleoauth required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("googleoauth", ["openid", "profile", "email"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * If using Google token-based auth, call this endpoint when a user's Google token is invalidated (e.g. when logging out of the application). This ensures that the token is also invalidated in Leo and that the user's proxied connections stop working. 
         * @summary Invalidates a token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidateToken(options: any = {}): FetchArgs {
            const localVarPath = `/proxy/invalidateToken`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication googleoauth required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("googleoauth", ["openid", "profile", "email"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * If using Google token-based authorization to a runtime, the Leo proxy accepts a Google token passed as a cookie value. This endpoint facilitates setting that cookie. It accepts a bearer token in an Authorization header and responds with a Set-Cookie header. If no bearer token is present, it unsets the cookie by returning a Set-Cookie header with a null value and expiration date in the past. 
         * @summary Sets a browser cookie needed to authorize connections to a Leonardo runtime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCookie(options: any = {}): FetchArgs {
            const localVarPath = `/proxy/setCookie`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication googleoauth required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("googleoauth", ["openid", "profile", "email"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creates the specified storage link configuration for the runtime
         * @param {string} googleProject googleProject
         * @param {string} runtimeName runtimeName
         * @param {StorageLink} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welderCreateStorageLink(googleProject: string, runtimeName: string, body: StorageLink, options: any = {}): FetchArgs {
            // verify required parameter 'googleProject' is not null or undefined
            if (googleProject === null || googleProject === undefined) {
                throw new RequiredError('googleProject','Required parameter googleProject was null or undefined when calling welderCreateStorageLink.');
            }
            // verify required parameter 'runtimeName' is not null or undefined
            if (runtimeName === null || runtimeName === undefined) {
                throw new RequiredError('runtimeName','Required parameter runtimeName was null or undefined when calling welderCreateStorageLink.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling welderCreateStorageLink.');
            }
            const localVarPath = `/proxy/{googleProject}/{runtimeName}/welder/storageLinks`
                .replace(`{${"googleProject"}}`, encodeURIComponent(String(googleProject)))
                .replace(`{${"runtimeName"}}`, encodeURIComponent(String(runtimeName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication googleoauth required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("googleoauth", ["openid", "profile", "email"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StorageLink" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creates the specified storage link configuration for the app
         * @param {string} googleProject googleProject
         * @param {string} appName appName
         * @param {StorageLink} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welderCreateStorageLinkForApp(googleProject: string, appName: string, body: StorageLink, options: any = {}): FetchArgs {
            // verify required parameter 'googleProject' is not null or undefined
            if (googleProject === null || googleProject === undefined) {
                throw new RequiredError('googleProject','Required parameter googleProject was null or undefined when calling welderCreateStorageLinkForApp.');
            }
            // verify required parameter 'appName' is not null or undefined
            if (appName === null || appName === undefined) {
                throw new RequiredError('appName','Required parameter appName was null or undefined when calling welderCreateStorageLinkForApp.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling welderCreateStorageLinkForApp.');
            }
            const localVarPath = `/proxy/google/v1/apps/{googleProject}/{appName}/welder-service/storageLinks`
                .replace(`{${"googleProject"}}`, encodeURIComponent(String(googleProject)))
                .replace(`{${"appName"}}`, encodeURIComponent(String(appName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication googleoauth required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("googleoauth", ["openid", "profile", "email"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StorageLink" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} googleProject googleProject
         * @param {string} runtimeName runtimeName
         * @param {Localize} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welderLocalize(googleProject: string, runtimeName: string, body: Localize, options: any = {}): FetchArgs {
            // verify required parameter 'googleProject' is not null or undefined
            if (googleProject === null || googleProject === undefined) {
                throw new RequiredError('googleProject','Required parameter googleProject was null or undefined when calling welderLocalize.');
            }
            // verify required parameter 'runtimeName' is not null or undefined
            if (runtimeName === null || runtimeName === undefined) {
                throw new RequiredError('runtimeName','Required parameter runtimeName was null or undefined when calling welderLocalize.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling welderLocalize.');
            }
            const localVarPath = `/proxy/{googleProject}/{runtimeName}/welder/objects`
                .replace(`{${"googleProject"}}`, encodeURIComponent(String(googleProject)))
                .replace(`{${"runtimeName"}}`, encodeURIComponent(String(runtimeName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication googleoauth required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("googleoauth", ["openid", "profile", "email"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Localize" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} googleProject googleProject
         * @param {string} appName appName
         * @param {Localize} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welderLocalizeForApp(googleProject: string, appName: string, body: Localize, options: any = {}): FetchArgs {
            // verify required parameter 'googleProject' is not null or undefined
            if (googleProject === null || googleProject === undefined) {
                throw new RequiredError('googleProject','Required parameter googleProject was null or undefined when calling welderLocalizeForApp.');
            }
            // verify required parameter 'appName' is not null or undefined
            if (appName === null || appName === undefined) {
                throw new RequiredError('appName','Required parameter appName was null or undefined when calling welderLocalizeForApp.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling welderLocalizeForApp.');
            }
            const localVarPath = `/proxy/google/v1/apps/{googleProject}/{appName}/welder-service/objects`
                .replace(`{${"googleProject"}}`, encodeURIComponent(String(googleProject)))
                .replace(`{${"appName"}}`, encodeURIComponent(String(appName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication googleoauth required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("googleoauth", ["openid", "profile", "email"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Localize" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProxyApi - functional programming interface
 * @export
 */
export const ProxyApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Connect to a jupyter terminal
         * @param {string} googleProject 
         * @param {string} runtimeName runtimeName
         * @param {string} terminalName terminalName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectToTerminal(googleProject: string, runtimeName: string, terminalName: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProxyApiFetchParamCreator(configuration).connectToTerminal(googleProject, runtimeName, terminalName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * If using Google token-based auth, call this endpoint when a user's Google token is invalidated (e.g. when logging out of the application). This ensures that the token is also invalidated in Leo and that the user's proxied connections stop working. 
         * @summary Invalidates a token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidateToken(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProxyApiFetchParamCreator(configuration).invalidateToken(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * If using Google token-based authorization to a runtime, the Leo proxy accepts a Google token passed as a cookie value. This endpoint facilitates setting that cookie. It accepts a bearer token in an Authorization header and responds with a Set-Cookie header. If no bearer token is present, it unsets the cookie by returning a Set-Cookie header with a null value and expiration date in the past. 
         * @summary Sets a browser cookie needed to authorize connections to a Leonardo runtime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCookie(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProxyApiFetchParamCreator(configuration).setCookie(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary creates the specified storage link configuration for the runtime
         * @param {string} googleProject googleProject
         * @param {string} runtimeName runtimeName
         * @param {StorageLink} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welderCreateStorageLink(googleProject: string, runtimeName: string, body: StorageLink, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StorageLink> {
            const localVarFetchArgs = ProxyApiFetchParamCreator(configuration).welderCreateStorageLink(googleProject, runtimeName, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary creates the specified storage link configuration for the app
         * @param {string} googleProject googleProject
         * @param {string} appName appName
         * @param {StorageLink} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welderCreateStorageLinkForApp(googleProject: string, appName: string, body: StorageLink, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StorageLink> {
            const localVarFetchArgs = ProxyApiFetchParamCreator(configuration).welderCreateStorageLinkForApp(googleProject, appName, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary 
         * @param {string} googleProject googleProject
         * @param {string} runtimeName runtimeName
         * @param {Localize} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welderLocalize(googleProject: string, runtimeName: string, body: Localize, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProxyApiFetchParamCreator(configuration).welderLocalize(googleProject, runtimeName, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary 
         * @param {string} googleProject googleProject
         * @param {string} appName appName
         * @param {Localize} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welderLocalizeForApp(googleProject: string, appName: string, body: Localize, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProxyApiFetchParamCreator(configuration).welderLocalizeForApp(googleProject, appName, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProxyApi - factory interface
 * @export
 */
export const ProxyApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Connect to a jupyter terminal
         * @param {string} googleProject 
         * @param {string} runtimeName runtimeName
         * @param {string} terminalName terminalName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectToTerminal(googleProject: string, runtimeName: string, terminalName: string, options?: any) {
            return ProxyApiFp(configuration).connectToTerminal(googleProject, runtimeName, terminalName, options)(fetch, basePath);
        },
        /**
         * If using Google token-based auth, call this endpoint when a user's Google token is invalidated (e.g. when logging out of the application). This ensures that the token is also invalidated in Leo and that the user's proxied connections stop working. 
         * @summary Invalidates a token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidateToken(options?: any) {
            return ProxyApiFp(configuration).invalidateToken(options)(fetch, basePath);
        },
        /**
         * If using Google token-based authorization to a runtime, the Leo proxy accepts a Google token passed as a cookie value. This endpoint facilitates setting that cookie. It accepts a bearer token in an Authorization header and responds with a Set-Cookie header. If no bearer token is present, it unsets the cookie by returning a Set-Cookie header with a null value and expiration date in the past. 
         * @summary Sets a browser cookie needed to authorize connections to a Leonardo runtime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCookie(options?: any) {
            return ProxyApiFp(configuration).setCookie(options)(fetch, basePath);
        },
        /**
         * 
         * @summary creates the specified storage link configuration for the runtime
         * @param {string} googleProject googleProject
         * @param {string} runtimeName runtimeName
         * @param {StorageLink} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welderCreateStorageLink(googleProject: string, runtimeName: string, body: StorageLink, options?: any) {
            return ProxyApiFp(configuration).welderCreateStorageLink(googleProject, runtimeName, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary creates the specified storage link configuration for the app
         * @param {string} googleProject googleProject
         * @param {string} appName appName
         * @param {StorageLink} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welderCreateStorageLinkForApp(googleProject: string, appName: string, body: StorageLink, options?: any) {
            return ProxyApiFp(configuration).welderCreateStorageLinkForApp(googleProject, appName, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary 
         * @param {string} googleProject googleProject
         * @param {string} runtimeName runtimeName
         * @param {Localize} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welderLocalize(googleProject: string, runtimeName: string, body: Localize, options?: any) {
            return ProxyApiFp(configuration).welderLocalize(googleProject, runtimeName, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary 
         * @param {string} googleProject googleProject
         * @param {string} appName appName
         * @param {Localize} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welderLocalizeForApp(googleProject: string, appName: string, body: Localize, options?: any) {
            return ProxyApiFp(configuration).welderLocalizeForApp(googleProject, appName, body, options)(fetch, basePath);
        },
    };
};

/**
 * ProxyApi - object-oriented interface
 * @export
 * @class ProxyApi
 * @extends {BaseAPI}
 */
export class ProxyApi extends BaseAPI {
    /**
     * 
     * @summary Connect to a jupyter terminal
     * @param {string} googleProject 
     * @param {string} runtimeName runtimeName
     * @param {string} terminalName terminalName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProxyApi
     */
    public connectToTerminal(googleProject: string, runtimeName: string, terminalName: string, options?: any) {
        return ProxyApiFp(this.configuration).connectToTerminal(googleProject, runtimeName, terminalName, options)(this.fetch, this.basePath);
    }

    /**
     * If using Google token-based auth, call this endpoint when a user's Google token is invalidated (e.g. when logging out of the application). This ensures that the token is also invalidated in Leo and that the user's proxied connections stop working. 
     * @summary Invalidates a token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProxyApi
     */
    public invalidateToken(options?: any) {
        return ProxyApiFp(this.configuration).invalidateToken(options)(this.fetch, this.basePath);
    }

    /**
     * If using Google token-based authorization to a runtime, the Leo proxy accepts a Google token passed as a cookie value. This endpoint facilitates setting that cookie. It accepts a bearer token in an Authorization header and responds with a Set-Cookie header. If no bearer token is present, it unsets the cookie by returning a Set-Cookie header with a null value and expiration date in the past. 
     * @summary Sets a browser cookie needed to authorize connections to a Leonardo runtime
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProxyApi
     */
    public setCookie(options?: any) {
        return ProxyApiFp(this.configuration).setCookie(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary creates the specified storage link configuration for the runtime
     * @param {string} googleProject googleProject
     * @param {string} runtimeName runtimeName
     * @param {StorageLink} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProxyApi
     */
    public welderCreateStorageLink(googleProject: string, runtimeName: string, body: StorageLink, options?: any) {
        return ProxyApiFp(this.configuration).welderCreateStorageLink(googleProject, runtimeName, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary creates the specified storage link configuration for the app
     * @param {string} googleProject googleProject
     * @param {string} appName appName
     * @param {StorageLink} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProxyApi
     */
    public welderCreateStorageLinkForApp(googleProject: string, appName: string, body: StorageLink, options?: any) {
        return ProxyApiFp(this.configuration).welderCreateStorageLinkForApp(googleProject, appName, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary 
     * @param {string} googleProject googleProject
     * @param {string} runtimeName runtimeName
     * @param {Localize} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProxyApi
     */
    public welderLocalize(googleProject: string, runtimeName: string, body: Localize, options?: any) {
        return ProxyApiFp(this.configuration).welderLocalize(googleProject, runtimeName, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary 
     * @param {string} googleProject googleProject
     * @param {string} appName appName
     * @param {Localize} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProxyApi
     */
    public welderLocalizeForApp(googleProject: string, appName: string, body: Localize, options?: any) {
        return ProxyApiFp(this.configuration).welderLocalizeForApp(googleProject, appName, body, options)(this.fetch, this.basePath);
    }

}

/**
 * RuntimesApi - fetch parameter creator
 * @export
 */
export const RuntimesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Starts the a stopped runtime
         * @summary Starts a Dataproc cluster or Google Compute Engine instance
         * @param {string} googleProject googleProject
         * @param {string} name runtimeName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startRuntime(googleProject: string, name: string, options: any = {}): FetchArgs {
            // verify required parameter 'googleProject' is not null or undefined
            if (googleProject === null || googleProject === undefined) {
                throw new RequiredError('googleProject','Required parameter googleProject was null or undefined when calling startRuntime.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling startRuntime.');
            }
            const localVarPath = `/api/google/v1/runtimes/{googleProject}/{name}/start`
                .replace(`{${"googleProject"}}`, encodeURIComponent(String(googleProject)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication googleoauth required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("googleoauth", ["openid", "profile", "email"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stops the running compute, but retains any data persisted on disk. The runtime may be restarted with the /start endpoint. 
         * @summary Stops a Dataproc cluster or Google Compute Engine instance
         * @param {string} googleProject googleProject
         * @param {string} name runtime name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopRuntime(googleProject: string, name: string, options: any = {}): FetchArgs {
            // verify required parameter 'googleProject' is not null or undefined
            if (googleProject === null || googleProject === undefined) {
                throw new RequiredError('googleProject','Required parameter googleProject was null or undefined when calling stopRuntime.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling stopRuntime.');
            }
            const localVarPath = `/api/google/v1/runtimes/{googleProject}/{name}/stop`
                .replace(`{${"googleProject"}}`, encodeURIComponent(String(googleProject)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication googleoauth required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("googleoauth", ["openid", "profile", "email"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RuntimesApi - functional programming interface
 * @export
 */
export const RuntimesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Starts the a stopped runtime
         * @summary Starts a Dataproc cluster or Google Compute Engine instance
         * @param {string} googleProject googleProject
         * @param {string} name runtimeName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startRuntime(googleProject: string, name: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = RuntimesApiFetchParamCreator(configuration).startRuntime(googleProject, name, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Stops the running compute, but retains any data persisted on disk. The runtime may be restarted with the /start endpoint. 
         * @summary Stops a Dataproc cluster or Google Compute Engine instance
         * @param {string} googleProject googleProject
         * @param {string} name runtime name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopRuntime(googleProject: string, name: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = RuntimesApiFetchParamCreator(configuration).stopRuntime(googleProject, name, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RuntimesApi - factory interface
 * @export
 */
export const RuntimesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Starts the a stopped runtime
         * @summary Starts a Dataproc cluster or Google Compute Engine instance
         * @param {string} googleProject googleProject
         * @param {string} name runtimeName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startRuntime(googleProject: string, name: string, options?: any) {
            return RuntimesApiFp(configuration).startRuntime(googleProject, name, options)(fetch, basePath);
        },
        /**
         * Stops the running compute, but retains any data persisted on disk. The runtime may be restarted with the /start endpoint. 
         * @summary Stops a Dataproc cluster or Google Compute Engine instance
         * @param {string} googleProject googleProject
         * @param {string} name runtime name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopRuntime(googleProject: string, name: string, options?: any) {
            return RuntimesApiFp(configuration).stopRuntime(googleProject, name, options)(fetch, basePath);
        },
    };
};

/**
 * RuntimesApi - object-oriented interface
 * @export
 * @class RuntimesApi
 * @extends {BaseAPI}
 */
export class RuntimesApi extends BaseAPI {
    /**
     * Starts the a stopped runtime
     * @summary Starts a Dataproc cluster or Google Compute Engine instance
     * @param {string} googleProject googleProject
     * @param {string} name runtimeName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimesApi
     */
    public startRuntime(googleProject: string, name: string, options?: any) {
        return RuntimesApiFp(this.configuration).startRuntime(googleProject, name, options)(this.fetch, this.basePath);
    }

    /**
     * Stops the running compute, but retains any data persisted on disk. The runtime may be restarted with the /start endpoint. 
     * @summary Stops a Dataproc cluster or Google Compute Engine instance
     * @param {string} googleProject googleProject
     * @param {string} name runtime name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimesApi
     */
    public stopRuntime(googleProject: string, name: string, options?: any) {
        return RuntimesApiFp(this.configuration).stopRuntime(googleProject, name, options)(this.fetch, this.basePath);
    }

}

