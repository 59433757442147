/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminWorkspaceCloudStorageCounts } from './AdminWorkspaceCloudStorageCounts';
import {
    AdminWorkspaceCloudStorageCountsFromJSON,
    AdminWorkspaceCloudStorageCountsFromJSONTyped,
    AdminWorkspaceCloudStorageCountsToJSON,
} from './AdminWorkspaceCloudStorageCounts';
import type { AdminWorkspaceObjectsCounts } from './AdminWorkspaceObjectsCounts';
import {
    AdminWorkspaceObjectsCountsFromJSON,
    AdminWorkspaceObjectsCountsFromJSONTyped,
    AdminWorkspaceObjectsCountsToJSON,
} from './AdminWorkspaceObjectsCounts';

/**
 * A summary of application-level resources associated with a workspace. Includes data about Cohort Builder objects, analysis runtimes, and cloud-storage contents.
 * @export
 * @interface AdminWorkspaceResources
 */
export interface AdminWorkspaceResources {
    /**
     * 
     * @type {AdminWorkspaceObjectsCounts}
     * @memberof AdminWorkspaceResources
     */
    workspaceObjects?: AdminWorkspaceObjectsCounts;
    /**
     * 
     * @type {AdminWorkspaceCloudStorageCounts}
     * @memberof AdminWorkspaceResources
     */
    cloudStorage?: AdminWorkspaceCloudStorageCounts;
}

/**
 * Check if a given object implements the AdminWorkspaceResources interface.
 */
export function instanceOfAdminWorkspaceResources(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdminWorkspaceResourcesFromJSON(json: any): AdminWorkspaceResources {
    return AdminWorkspaceResourcesFromJSONTyped(json, false);
}

export function AdminWorkspaceResourcesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminWorkspaceResources {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workspaceObjects': !exists(json, 'workspaceObjects') ? undefined : AdminWorkspaceObjectsCountsFromJSON(json['workspaceObjects']),
        'cloudStorage': !exists(json, 'cloudStorage') ? undefined : AdminWorkspaceCloudStorageCountsFromJSON(json['cloudStorage']),
    };
}

export function AdminWorkspaceResourcesToJSON(value?: AdminWorkspaceResources | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workspaceObjects': AdminWorkspaceObjectsCountsToJSON(value.workspaceObjects),
        'cloudStorage': AdminWorkspaceCloudStorageCountsToJSON(value.cloudStorage),
    };
}

