/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppType } from './AppType';
import {
    AppTypeFromJSON,
    AppTypeFromJSONTyped,
    AppTypeToJSON,
} from './AppType';
import type { KubernetesRuntimeConfig } from './KubernetesRuntimeConfig';
import {
    KubernetesRuntimeConfigFromJSON,
    KubernetesRuntimeConfigFromJSONTyped,
    KubernetesRuntimeConfigToJSON,
} from './KubernetesRuntimeConfig';
import type { PersistentDiskRequest } from './PersistentDiskRequest';
import {
    PersistentDiskRequestFromJSON,
    PersistentDiskRequestFromJSONTyped,
    PersistentDiskRequestToJSON,
} from './PersistentDiskRequest';

/**
 * Create User App request body
 * @export
 * @interface CreateAppRequest
 */
export interface CreateAppRequest {
    /**
     * 
     * @type {AppType}
     * @memberof CreateAppRequest
     */
    appType: AppType;
    /**
     * 
     * @type {KubernetesRuntimeConfig}
     * @memberof CreateAppRequest
     */
    kubernetesRuntimeConfig: KubernetesRuntimeConfig;
    /**
     * 
     * @type {PersistentDiskRequest}
     * @memberof CreateAppRequest
     */
    persistentDiskRequest: PersistentDiskRequest;
    /**
     * Whether to turn on autodelete
     * @type {boolean}
     * @memberof CreateAppRequest
     */
    autodeleteEnabled?: boolean;
    /**
     * The number of minutes of idle time to elapse before the app is deleted. When autodeleteEnabled is true, a positive integer is required
     * @type {number}
     * @memberof CreateAppRequest
     */
    autodeleteThreshold?: number;
}

/**
 * Check if a given object implements the CreateAppRequest interface.
 */
export function instanceOfCreateAppRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "appType" in value;
    isInstance = isInstance && "kubernetesRuntimeConfig" in value;
    isInstance = isInstance && "persistentDiskRequest" in value;

    return isInstance;
}

export function CreateAppRequestFromJSON(json: any): CreateAppRequest {
    return CreateAppRequestFromJSONTyped(json, false);
}

export function CreateAppRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAppRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appType': AppTypeFromJSON(json['appType']),
        'kubernetesRuntimeConfig': KubernetesRuntimeConfigFromJSON(json['kubernetesRuntimeConfig']),
        'persistentDiskRequest': PersistentDiskRequestFromJSON(json['persistentDiskRequest']),
        'autodeleteEnabled': !exists(json, 'autodeleteEnabled') ? undefined : json['autodeleteEnabled'],
        'autodeleteThreshold': !exists(json, 'autodeleteThreshold') ? undefined : json['autodeleteThreshold'],
    };
}

export function CreateAppRequestToJSON(value?: CreateAppRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appType': AppTypeToJSON(value.appType),
        'kubernetesRuntimeConfig': KubernetesRuntimeConfigToJSON(value.kubernetesRuntimeConfig),
        'persistentDiskRequest': PersistentDiskRequestToJSON(value.persistentDiskRequest),
        'autodeleteEnabled': value.autodeleteEnabled,
        'autodeleteThreshold': value.autodeleteThreshold,
    };
}

