/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParticipantCohortAnnotation
 */
export interface ParticipantCohortAnnotation {
    /**
     * participant annotation id.
     * @type {number}
     * @memberof ParticipantCohortAnnotation
     */
    annotationId?: number;
    /**
     * annotation definition id.
     * @type {number}
     * @memberof ParticipantCohortAnnotation
     */
    cohortAnnotationDefinitionId: number;
    /**
     * the cohort review id.
     * @type {number}
     * @memberof ParticipantCohortAnnotation
     */
    cohortReviewId: number;
    /**
     * the participant id.
     * @type {number}
     * @memberof ParticipantCohortAnnotation
     */
    participantId: number;
    /**
     * The Value of the annotation if the AnnotationType is STRING
     * @type {string}
     * @memberof ParticipantCohortAnnotation
     */
    annotationValueString?: string;
    /**
     * The option chosen for the annotation if the AnnotationType is ENUM
     * @type {string}
     * @memberof ParticipantCohortAnnotation
     */
    annotationValueEnum?: string;
    /**
     * The Value of the annotation if the AnnotationType is DATE
     * @type {string}
     * @memberof ParticipantCohortAnnotation
     */
    annotationValueDate?: string;
    /**
     * The Value of the annotation if the AnnotationType is BOOLEAN
     * @type {boolean}
     * @memberof ParticipantCohortAnnotation
     */
    annotationValueBoolean?: boolean;
    /**
     * The Value of the annotation if the AnnotationType is INTEGER
     * @type {number}
     * @memberof ParticipantCohortAnnotation
     */
    annotationValueInteger?: number;
}

/**
 * Check if a given object implements the ParticipantCohortAnnotation interface.
 */
export function instanceOfParticipantCohortAnnotation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cohortAnnotationDefinitionId" in value;
    isInstance = isInstance && "cohortReviewId" in value;
    isInstance = isInstance && "participantId" in value;

    return isInstance;
}

export function ParticipantCohortAnnotationFromJSON(json: any): ParticipantCohortAnnotation {
    return ParticipantCohortAnnotationFromJSONTyped(json, false);
}

export function ParticipantCohortAnnotationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantCohortAnnotation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annotationId': !exists(json, 'annotationId') ? undefined : json['annotationId'],
        'cohortAnnotationDefinitionId': json['cohortAnnotationDefinitionId'],
        'cohortReviewId': json['cohortReviewId'],
        'participantId': json['participantId'],
        'annotationValueString': !exists(json, 'annotationValueString') ? undefined : json['annotationValueString'],
        'annotationValueEnum': !exists(json, 'annotationValueEnum') ? undefined : json['annotationValueEnum'],
        'annotationValueDate': !exists(json, 'annotationValueDate') ? undefined : json['annotationValueDate'],
        'annotationValueBoolean': !exists(json, 'annotationValueBoolean') ? undefined : json['annotationValueBoolean'],
        'annotationValueInteger': !exists(json, 'annotationValueInteger') ? undefined : json['annotationValueInteger'],
    };
}

export function ParticipantCohortAnnotationToJSON(value?: ParticipantCohortAnnotation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annotationId': value.annotationId,
        'cohortAnnotationDefinitionId': value.cohortAnnotationDefinitionId,
        'cohortReviewId': value.cohortReviewId,
        'participantId': value.participantId,
        'annotationValueString': value.annotationValueString,
        'annotationValueEnum': value.annotationValueEnum,
        'annotationValueDate': value.annotationValueDate,
        'annotationValueBoolean': value.annotationValueBoolean,
        'annotationValueInteger': value.annotationValueInteger,
    };
}

