/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgeTypeCount
 */
export interface AgeTypeCount {
    /**
     * type of age
     * @type {string}
     * @memberof AgeTypeCount
     */
    ageType: string;
    /**
     * age
     * @type {number}
     * @memberof AgeTypeCount
     */
    age: number;
    /**
     * count
     * @type {number}
     * @memberof AgeTypeCount
     */
    count: number;
}

/**
 * Check if a given object implements the AgeTypeCount interface.
 */
export function instanceOfAgeTypeCount(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ageType" in value;
    isInstance = isInstance && "age" in value;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function AgeTypeCountFromJSON(json: any): AgeTypeCount {
    return AgeTypeCountFromJSONTyped(json, false);
}

export function AgeTypeCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgeTypeCount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ageType': json['ageType'],
        'age': json['age'],
        'count': json['count'],
    };
}

export function AgeTypeCountToJSON(value?: AgeTypeCount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ageType': value.ageType,
        'age': value.age,
        'count': value.count,
    };
}

