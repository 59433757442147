/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CopyRequest,
  EmptyResponse,
  ErrorResponse,
  FileDetail,
  KernelTypeResponse,
  NotebookLockingMetadataResponse,
  NotebookRename,
  ReadOnlyNotebookResponse,
} from '../models';
import {
    CopyRequestFromJSON,
    CopyRequestToJSON,
    EmptyResponseFromJSON,
    EmptyResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    FileDetailFromJSON,
    FileDetailToJSON,
    KernelTypeResponseFromJSON,
    KernelTypeResponseToJSON,
    NotebookLockingMetadataResponseFromJSON,
    NotebookLockingMetadataResponseToJSON,
    NotebookRenameFromJSON,
    NotebookRenameToJSON,
    ReadOnlyNotebookResponseFromJSON,
    ReadOnlyNotebookResponseToJSON,
} from '../models';

export interface NotebooksApiCloneNotebookRequest {
    workspaceNamespace: string;
    terraName: string;
    notebookName: string;
}

export interface NotebooksApiCopyNotebookRequest {
    workspaceNamespace: string;
    terraName: string;
    notebookName: string;
    copyNotebookRequest: CopyRequest;
}

export interface NotebooksApiDeleteNotebookRequest {
    workspaceNamespace: string;
    terraName: string;
    notebookName: string;
}

export interface NotebooksApiGetNoteBookListRequest {
    workspaceNamespace: string;
    terraName: string;
}

export interface NotebooksApiGetNotebookKernelRequest {
    workspaceNamespace: string;
    terraName: string;
    notebookName: string;
}

export interface NotebooksApiGetNotebookLockingMetadataRequest {
    workspaceNamespace: string;
    terraName: string;
    notebookName: string;
}

export interface NotebooksApiReadOnlyNotebookRequest {
    workspaceNamespace: string;
    terraName: string;
    notebookName: string;
}

export interface NotebooksApiRenameNotebookRequest {
    workspaceNamespace: string;
    terraName: string;
    notebookRename: NotebookRename;
}

/**
 * 
 */
export class NotebooksApi extends runtime.BaseAPI {

    /**
     * Duplicates specified notebook
     */
    async cloneNotebookRaw(requestParameters: NotebooksApiCloneNotebookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDetail>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling cloneNotebook.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling cloneNotebook.');
        }

        if (requestParameters.notebookName === null || requestParameters.notebookName === undefined) {
            throw new runtime.RequiredError('notebookName','Required parameter requestParameters.notebookName was null or undefined when calling cloneNotebook.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/notebooks/{notebookName}/duplicate`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"notebookName"}}`, encodeURIComponent(String(requestParameters.notebookName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDetailFromJSON(jsonValue));
    }

    /**
     * Duplicates specified notebook
     */
    async cloneNotebook(workspaceNamespace: string, terraName: string, notebookName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDetail> {
        const response = await this.cloneNotebookRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, notebookName: notebookName }, initOverrides);
        return await response.value();
    }

    /**
     * Copy specified notebook in path to specified workspace in body
     */
    async copyNotebookRaw(requestParameters: NotebooksApiCopyNotebookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDetail>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling copyNotebook.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling copyNotebook.');
        }

        if (requestParameters.notebookName === null || requestParameters.notebookName === undefined) {
            throw new runtime.RequiredError('notebookName','Required parameter requestParameters.notebookName was null or undefined when calling copyNotebook.');
        }

        if (requestParameters.copyNotebookRequest === null || requestParameters.copyNotebookRequest === undefined) {
            throw new runtime.RequiredError('copyNotebookRequest','Required parameter requestParameters.copyNotebookRequest was null or undefined when calling copyNotebook.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/notebooks/{notebookName}/copy`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"notebookName"}}`, encodeURIComponent(String(requestParameters.notebookName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CopyRequestToJSON(requestParameters.copyNotebookRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDetailFromJSON(jsonValue));
    }

    /**
     * Copy specified notebook in path to specified workspace in body
     */
    async copyNotebook(workspaceNamespace: string, terraName: string, notebookName: string, copyNotebookRequest: CopyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDetail> {
        const response = await this.copyNotebookRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, notebookName: notebookName, copyNotebookRequest: copyNotebookRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Deletes specified notebook
     */
    async deleteNotebookRaw(requestParameters: NotebooksApiDeleteNotebookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling deleteNotebook.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling deleteNotebook.');
        }

        if (requestParameters.notebookName === null || requestParameters.notebookName === undefined) {
            throw new runtime.RequiredError('notebookName','Required parameter requestParameters.notebookName was null or undefined when calling deleteNotebook.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/notebooks/{notebookName}/delete`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"notebookName"}}`, encodeURIComponent(String(requestParameters.notebookName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Deletes specified notebook
     */
    async deleteNotebook(workspaceNamespace: string, terraName: string, notebookName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.deleteNotebookRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, notebookName: notebookName }, initOverrides);
        return await response.value();
    }

    /**
     * Returns list of name and path of python files from google bucket, directory notebook.
     * Get details of Python files from google Bucket directory notebook
     */
    async getNoteBookListRaw(requestParameters: NotebooksApiGetNoteBookListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FileDetail>>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getNoteBookList.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getNoteBookList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/notebook-list`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FileDetailFromJSON));
    }

    /**
     * Returns list of name and path of python files from google bucket, directory notebook.
     * Get details of Python files from google Bucket directory notebook
     */
    async getNoteBookList(workspaceNamespace: string, terraName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FileDetail>> {
        const response = await this.getNoteBookListRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName }, initOverrides);
        return await response.value();
    }

    /**
     * Get Kernel Type of notebook
     */
    async getNotebookKernelRaw(requestParameters: NotebooksApiGetNotebookKernelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KernelTypeResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getNotebookKernel.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getNotebookKernel.');
        }

        if (requestParameters.notebookName === null || requestParameters.notebookName === undefined) {
            throw new runtime.RequiredError('notebookName','Required parameter requestParameters.notebookName was null or undefined when calling getNotebookKernel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/notebooks/{notebookName}/kernel`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"notebookName"}}`, encodeURIComponent(String(requestParameters.notebookName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KernelTypeResponseFromJSON(jsonValue));
    }

    /**
     * Get Kernel Type of notebook
     */
    async getNotebookKernel(workspaceNamespace: string, terraName: string, notebookName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KernelTypeResponse> {
        const response = await this.getNotebookKernelRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, notebookName: notebookName }, initOverrides);
        return await response.value();
    }

    /**
     * Get the locking metadata for a notebook, if available.  If the notebook is not locked (e.g. at first creation) this may be empty.  Additionally, the caller of this endpoint may not have the appropriate permissions to determine who is locking this notebook. When this is the case, the lastLockedBy field is set to UNKNOWN. 
     * Get the locking metadata for a notebook
     */
    async getNotebookLockingMetadataRaw(requestParameters: NotebooksApiGetNotebookLockingMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotebookLockingMetadataResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getNotebookLockingMetadata.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getNotebookLockingMetadata.');
        }

        if (requestParameters.notebookName === null || requestParameters.notebookName === undefined) {
            throw new runtime.RequiredError('notebookName','Required parameter requestParameters.notebookName was null or undefined when calling getNotebookLockingMetadata.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/notebooks/{notebookName}/lockingMetadata`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"notebookName"}}`, encodeURIComponent(String(requestParameters.notebookName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotebookLockingMetadataResponseFromJSON(jsonValue));
    }

    /**
     * Get the locking metadata for a notebook, if available.  If the notebook is not locked (e.g. at first creation) this may be empty.  Additionally, the caller of this endpoint may not have the appropriate permissions to determine who is locking this notebook. When this is the case, the lastLockedBy field is set to UNKNOWN. 
     * Get the locking metadata for a notebook
     */
    async getNotebookLockingMetadata(workspaceNamespace: string, terraName: string, notebookName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotebookLockingMetadataResponse> {
        const response = await this.getNotebookLockingMetadataRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, notebookName: notebookName }, initOverrides);
        return await response.value();
    }

    /**
     * Get the HTML for a read only view of the notebook
     */
    async readOnlyNotebookRaw(requestParameters: NotebooksApiReadOnlyNotebookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReadOnlyNotebookResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling readOnlyNotebook.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling readOnlyNotebook.');
        }

        if (requestParameters.notebookName === null || requestParameters.notebookName === undefined) {
            throw new runtime.RequiredError('notebookName','Required parameter requestParameters.notebookName was null or undefined when calling readOnlyNotebook.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/notebooks/{notebookName}/readonly`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"notebookName"}}`, encodeURIComponent(String(requestParameters.notebookName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyNotebookResponseFromJSON(jsonValue));
    }

    /**
     * Get the HTML for a read only view of the notebook
     */
    async readOnlyNotebook(workspaceNamespace: string, terraName: string, notebookName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReadOnlyNotebookResponse> {
        const response = await this.readOnlyNotebookRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, notebookName: notebookName }, initOverrides);
        return await response.value();
    }

    /**
     * Renames specified notebook
     */
    async renameNotebookRaw(requestParameters: NotebooksApiRenameNotebookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDetail>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling renameNotebook.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling renameNotebook.');
        }

        if (requestParameters.notebookRename === null || requestParameters.notebookRename === undefined) {
            throw new runtime.RequiredError('notebookRename','Required parameter requestParameters.notebookRename was null or undefined when calling renameNotebook.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/notebooks/rename`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotebookRenameToJSON(requestParameters.notebookRename),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDetailFromJSON(jsonValue));
    }

    /**
     * Renames specified notebook
     */
    async renameNotebook(workspaceNamespace: string, terraName: string, notebookRename: NotebookRename, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDetail> {
        const response = await this.renameNotebookRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, notebookRename: notebookRename }, initOverrides);
        return await response.value();
    }

}
