/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuditEgressRuntimeLogEntry
 */
export interface AuditEgressRuntimeLogEntry {
    /**
     * Time at which the log message was written, in ISO 8601 format.
     * @type {string}
     * @memberof AuditEgressRuntimeLogEntry
     */
    timestamp?: string;
    /**
     * The log message text payload.
     * @type {string}
     * @memberof AuditEgressRuntimeLogEntry
     */
    message?: string;
}

/**
 * Check if a given object implements the AuditEgressRuntimeLogEntry interface.
 */
export function instanceOfAuditEgressRuntimeLogEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuditEgressRuntimeLogEntryFromJSON(json: any): AuditEgressRuntimeLogEntry {
    return AuditEgressRuntimeLogEntryFromJSONTyped(json, false);
}

export function AuditEgressRuntimeLogEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditEgressRuntimeLogEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function AuditEgressRuntimeLogEntryToJSON(value?: AuditEgressRuntimeLogEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': value.timestamp,
        'message': value.message,
    };
}

