/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FilterColumns = {
    PARTICIPANTID: 'PARTICIPANTID',
    STATUS: 'STATUS',
    GENDER: 'GENDER',
    BIRTHDATE: 'BIRTHDATE',
    RACE: 'RACE',
    ETHNICITY: 'ETHNICITY',
    SEXATBIRTH: 'SEXATBIRTH',
    DECEASED: 'DECEASED',
    START_DATETIME: 'START_DATETIME',
    STANDARD_CODE: 'STANDARD_CODE',
    STANDARD_VOCABULARY: 'STANDARD_VOCABULARY',
    STANDARD_NAME: 'STANDARD_NAME',
    STANDARD_CONCEPT_ID: 'STANDARD_CONCEPT_ID',
    SOURCE_CODE: 'SOURCE_CODE',
    SOURCE_VOCABULARY: 'SOURCE_VOCABULARY',
    SOURCE_NAME: 'SOURCE_NAME',
    SOURCE_CONCEPT_ID: 'SOURCE_CONCEPT_ID',
    DOMAIN: 'DOMAIN',
    AGE_AT_EVENT: 'AGE_AT_EVENT',
    NUM_MENTIONS: 'NUM_MENTIONS',
    FIRST_MENTION: 'FIRST_MENTION',
    LAST_MENTION: 'LAST_MENTION',
    VISIT_TYPE: 'VISIT_TYPE',
    ROUTE: 'ROUTE',
    DOSE: 'DOSE',
    STRENGTH: 'STRENGTH',
    VALUE_AS_NUMBER: 'VALUE_AS_NUMBER',
    UNIT: 'UNIT',
    REF_RANGE: 'REF_RANGE',
    SURVEY_NAME: 'SURVEY_NAME',
    QUESTION: 'QUESTION',
    ANSWER: 'ANSWER'
} as const;
export type FilterColumns = typeof FilterColumns[keyof typeof FilterColumns];


export function FilterColumnsFromJSON(json: any): FilterColumns {
    return FilterColumnsFromJSONTyped(json, false);
}

export function FilterColumnsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterColumns {
    return json as FilterColumns;
}

export function FilterColumnsToJSON(value?: FilterColumns | null): any {
    return value as any;
}

