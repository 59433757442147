/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RecentWorkspace } from './RecentWorkspace';
import {
    RecentWorkspaceFromJSON,
    RecentWorkspaceFromJSONTyped,
    RecentWorkspaceToJSON,
} from './RecentWorkspace';

/**
 * 
 * @export
 * @interface RecentWorkspaceResponse
 */
export interface RecentWorkspaceResponse extends Array<RecentWorkspace> {
}

/**
 * Check if a given object implements the RecentWorkspaceResponse interface.
 */
export function instanceOfRecentWorkspaceResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RecentWorkspaceResponseFromJSON(json: any): RecentWorkspaceResponse {
    return RecentWorkspaceResponseFromJSONTyped(json, false);
}

export function RecentWorkspaceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecentWorkspaceResponse {
    return json;
}

export function RecentWorkspaceResponseToJSON(value?: RecentWorkspaceResponse | null): any {
    return value;
}

