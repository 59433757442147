/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Vocabulary
 */
export interface Vocabulary {
    /**
     * Source or Standard
     * @type {string}
     * @memberof Vocabulary
     */
    type: string;
    /**
     * OMOP domain
     * @type {string}
     * @memberof Vocabulary
     */
    domain: string;
    /**
     * Vocabulary
     * @type {string}
     * @memberof Vocabulary
     */
    vocabulary: string;
}

/**
 * Check if a given object implements the Vocabulary interface.
 */
export function instanceOfVocabulary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "vocabulary" in value;

    return isInstance;
}

export function VocabularyFromJSON(json: any): Vocabulary {
    return VocabularyFromJSONTyped(json, false);
}

export function VocabularyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Vocabulary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'domain': json['domain'],
        'vocabulary': json['vocabulary'],
    };
}

export function VocabularyToJSON(value?: Vocabulary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'domain': value.domain,
        'vocabulary': value.vocabulary,
    };
}

