/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminTableUser
 */
export interface AdminTableUser {
    /**
     * 
     * @type {number}
     * @memberof AdminTableUser
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminTableUser
     */
    username?: string;
    /**
     * Short names of access tiers available to the user.
     * @type {Array<string>}
     * @memberof AdminTableUser
     */
    accessTierShortNames?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminTableUser
     */
    givenName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTableUser
     */
    familyName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminTableUser
     */
    disabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminTableUser
     */
    contactEmail?: string;
    /**
     * Display name of the user's affiliated institution, if it exists
     * @type {string}
     * @memberof AdminTableUser
     */
    institutionName?: string;
    /**
     * Short name of the user's affiliated institution, if it exists
     * @type {string}
     * @memberof AdminTableUser
     */
    institutionShortName?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminTableUser
     */
    firstSignInTime?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminTableUser
     */
    creationTime?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminTableUser
     */
    twoFactorAuthBypassTime?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminTableUser
     */
    profileConfirmationBypassTime?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminTableUser
     */
    publicationConfirmationBypassTime?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminTableUser
     */
    eraCommonsBypassTime?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminTableUser
     */
    identityBypassTime?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminTableUser
     */
    complianceTrainingBypassTime?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminTableUser
     */
    ctComplianceTrainingBypassTime?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminTableUser
     */
    duccBypassTime?: number;
}

/**
 * Check if a given object implements the AdminTableUser interface.
 */
export function instanceOfAdminTableUser(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdminTableUserFromJSON(json: any): AdminTableUser {
    return AdminTableUserFromJSONTyped(json, false);
}

export function AdminTableUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminTableUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'accessTierShortNames': !exists(json, 'accessTierShortNames') ? undefined : json['accessTierShortNames'],
        'givenName': !exists(json, 'givenName') ? undefined : json['givenName'],
        'familyName': !exists(json, 'familyName') ? undefined : json['familyName'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
        'contactEmail': !exists(json, 'contactEmail') ? undefined : json['contactEmail'],
        'institutionName': !exists(json, 'institutionName') ? undefined : json['institutionName'],
        'institutionShortName': !exists(json, 'institutionShortName') ? undefined : json['institutionShortName'],
        'firstSignInTime': !exists(json, 'firstSignInTime') ? undefined : json['firstSignInTime'],
        'creationTime': !exists(json, 'creationTime') ? undefined : json['creationTime'],
        'twoFactorAuthBypassTime': !exists(json, 'twoFactorAuthBypassTime') ? undefined : json['twoFactorAuthBypassTime'],
        'profileConfirmationBypassTime': !exists(json, 'profileConfirmationBypassTime') ? undefined : json['profileConfirmationBypassTime'],
        'publicationConfirmationBypassTime': !exists(json, 'publicationConfirmationBypassTime') ? undefined : json['publicationConfirmationBypassTime'],
        'eraCommonsBypassTime': !exists(json, 'eraCommonsBypassTime') ? undefined : json['eraCommonsBypassTime'],
        'identityBypassTime': !exists(json, 'identityBypassTime') ? undefined : json['identityBypassTime'],
        'complianceTrainingBypassTime': !exists(json, 'complianceTrainingBypassTime') ? undefined : json['complianceTrainingBypassTime'],
        'ctComplianceTrainingBypassTime': !exists(json, 'ctComplianceTrainingBypassTime') ? undefined : json['ctComplianceTrainingBypassTime'],
        'duccBypassTime': !exists(json, 'duccBypassTime') ? undefined : json['duccBypassTime'],
    };
}

export function AdminTableUserToJSON(value?: AdminTableUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'username': value.username,
        'accessTierShortNames': value.accessTierShortNames,
        'givenName': value.givenName,
        'familyName': value.familyName,
        'disabled': value.disabled,
        'contactEmail': value.contactEmail,
        'institutionName': value.institutionName,
        'institutionShortName': value.institutionShortName,
        'firstSignInTime': value.firstSignInTime,
        'creationTime': value.creationTime,
        'twoFactorAuthBypassTime': value.twoFactorAuthBypassTime,
        'profileConfirmationBypassTime': value.profileConfirmationBypassTime,
        'publicationConfirmationBypassTime': value.publicationConfirmationBypassTime,
        'eraCommonsBypassTime': value.eraCommonsBypassTime,
        'identityBypassTime': value.identityBypassTime,
        'complianceTrainingBypassTime': value.complianceTrainingBypassTime,
        'ctComplianceTrainingBypassTime': value.ctComplianceTrainingBypassTime,
        'duccBypassTime': value.duccBypassTime,
    };
}

