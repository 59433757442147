/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CriteriaSearchRequest
 */
export interface CriteriaSearchRequest {
    /**
     * The omop domain that this criteria belongs to
     * @type {string}
     * @memberof CriteriaSearchRequest
     */
    domain: string;
    /**
     * flag that determines standard or source
     * @type {boolean}
     * @memberof CriteriaSearchRequest
     */
    standard: boolean;
    /**
     * the term to search for
     * @type {string}
     * @memberof CriteriaSearchRequest
     */
    term: string;
    /**
     * name for the survey to search for
     * @type {string}
     * @memberof CriteriaSearchRequest
     */
    surveyName?: string;
    /**
     * True - remove brand names from results
     * @type {boolean}
     * @memberof CriteriaSearchRequest
     */
    removeDrugBrand?: boolean;
    /**
     * the type of the criteria to search for
     * @type {string}
     * @memberof CriteriaSearchRequest
     */
    type?: string;
}

/**
 * Check if a given object implements the CriteriaSearchRequest interface.
 */
export function instanceOfCriteriaSearchRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "standard" in value;
    isInstance = isInstance && "term" in value;

    return isInstance;
}

export function CriteriaSearchRequestFromJSON(json: any): CriteriaSearchRequest {
    return CriteriaSearchRequestFromJSONTyped(json, false);
}

export function CriteriaSearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CriteriaSearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'domain': json['domain'],
        'standard': json['standard'],
        'term': json['term'],
        'surveyName': !exists(json, 'surveyName') ? undefined : json['surveyName'],
        'removeDrugBrand': !exists(json, 'removeDrugBrand') ? undefined : json['removeDrugBrand'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function CriteriaSearchRequestToJSON(value?: CriteriaSearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'domain': value.domain,
        'standard': value.standard,
        'term': value.term,
        'surveyName': value.surveyName,
        'removeDrugBrand': value.removeDrugBrand,
        'type': value.type,
    };
}

