/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserRole } from './UserRole';
import {
    UserRoleFromJSON,
    UserRoleFromJSONTyped,
    UserRoleToJSON,
} from './UserRole';

/**
 * 
 * @export
 * @interface WorkspaceUserRolesResponse
 */
export interface WorkspaceUserRolesResponse {
    /**
     * Updated workspace etag after the share request has been applied.
     * @type {string}
     * @memberof WorkspaceUserRolesResponse
     */
    workspaceEtag?: string;
    /**
     * 
     * @type {Array<UserRole>}
     * @memberof WorkspaceUserRolesResponse
     */
    items?: Array<UserRole>;
    /**
     * A list of user emails for whom we were unable to revoke workflow runner roles, if any
     * @type {Array<string>}
     * @memberof WorkspaceUserRolesResponse
     */
    failedWorkflowRevocations?: Array<string>;
}

/**
 * Check if a given object implements the WorkspaceUserRolesResponse interface.
 */
export function instanceOfWorkspaceUserRolesResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WorkspaceUserRolesResponseFromJSON(json: any): WorkspaceUserRolesResponse {
    return WorkspaceUserRolesResponseFromJSONTyped(json, false);
}

export function WorkspaceUserRolesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceUserRolesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workspaceEtag': !exists(json, 'workspaceEtag') ? undefined : json['workspaceEtag'],
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(UserRoleFromJSON)),
        'failedWorkflowRevocations': !exists(json, 'failedWorkflowRevocations') ? undefined : json['failedWorkflowRevocations'],
    };
}

export function WorkspaceUserRolesResponseToJSON(value?: WorkspaceUserRolesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workspaceEtag': value.workspaceEtag,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(UserRoleToJSON)),
        'failedWorkflowRevocations': value.failedWorkflowRevocations,
    };
}

