/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AccessReason,
  AdminLockingRequest,
  AdminRuntimeFields,
  CloudStorageTraffic,
  EmptyResponse,
  ErrorResponse,
  FileDetail,
  PublishWorkspaceRequest,
  ReadOnlyNotebookResponse,
  UserAppEnvironment,
  WorkspaceAdminView,
  WorkspaceAuditLogQueryResponse,
} from '../models';
import {
    AccessReasonFromJSON,
    AccessReasonToJSON,
    AdminLockingRequestFromJSON,
    AdminLockingRequestToJSON,
    AdminRuntimeFieldsFromJSON,
    AdminRuntimeFieldsToJSON,
    CloudStorageTrafficFromJSON,
    CloudStorageTrafficToJSON,
    EmptyResponseFromJSON,
    EmptyResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    FileDetailFromJSON,
    FileDetailToJSON,
    PublishWorkspaceRequestFromJSON,
    PublishWorkspaceRequestToJSON,
    ReadOnlyNotebookResponseFromJSON,
    ReadOnlyNotebookResponseToJSON,
    UserAppEnvironmentFromJSON,
    UserAppEnvironmentToJSON,
    WorkspaceAdminViewFromJSON,
    WorkspaceAdminViewToJSON,
    WorkspaceAuditLogQueryResponseFromJSON,
    WorkspaceAuditLogQueryResponseToJSON,
} from '../models';

export interface WorkspaceAdminApiAdminDeleteRuntimeRequest {
    workspaceNamespace: string;
    runtimeName: string;
}

export interface WorkspaceAdminApiAdminListRuntimesRequest {
    workspaceNamespace: string;
}

export interface WorkspaceAdminApiAdminListUserAppsInWorkspaceRequest {
    workspaceNamespace: string;
}

export interface WorkspaceAdminApiAdminReadOnlyNotebookRequest {
    workspaceNamespace: string;
    notebookName: string;
    accessReason: AccessReason;
}

export interface WorkspaceAdminApiGetCloudStorageTrafficRequest {
    workspaceNamespace: string;
}

export interface WorkspaceAdminApiGetWorkspaceAdminViewRequest {
    workspaceNamespace: string;
}

export interface WorkspaceAdminApiGetWorkspaceAuditLogEntriesRequest {
    workspaceNamespace: string;
    limit?: number;
    after?: number;
    before?: number;
}

export interface WorkspaceAdminApiListFilesRequest {
    workspaceNamespace: string;
    onlyAppFiles?: boolean;
}

export interface WorkspaceAdminApiPublishWorkspaceViaDBRequest {
    workspaceNamespace: string;
    publishWorkspaceRequest?: PublishWorkspaceRequest;
}

export interface WorkspaceAdminApiSetAdminLockedStateRequest {
    workspaceNamespace: string;
    adminLockingRequest: AdminLockingRequest;
}

export interface WorkspaceAdminApiSetAdminUnlockedStateRequest {
    workspaceNamespace: string;
}

export interface WorkspaceAdminApiUnpublishWorkspaceViaDBRequest {
    workspaceNamespace: string;
}

/**
 * 
 */
export class WorkspaceAdminApi extends runtime.BaseAPI {

    /**
     * An admin gated endpoint that deletes the given runtime in a given workspace.
     * Delete the specified runtime in the given workspace namespace.
     */
    async adminDeleteRuntimeRaw(requestParameters: WorkspaceAdminApiAdminDeleteRuntimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminRuntimeFields>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling adminDeleteRuntime.');
        }

        if (requestParameters.runtimeName === null || requestParameters.runtimeName === undefined) {
            throw new runtime.RequiredError('runtimeName','Required parameter requestParameters.runtimeName was null or undefined when calling adminDeleteRuntime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/security/workspaces/{workspaceNamespace}/runtimes/{runtimeName}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"runtimeName"}}`, encodeURIComponent(String(requestParameters.runtimeName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminRuntimeFieldsFromJSON(jsonValue));
    }

    /**
     * An admin gated endpoint that deletes the given runtime in a given workspace.
     * Delete the specified runtime in the given workspace namespace.
     */
    async adminDeleteRuntime(workspaceNamespace: string, runtimeName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminRuntimeFields> {
        const response = await this.adminDeleteRuntimeRaw({ workspaceNamespace: workspaceNamespace, runtimeName: runtimeName }, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of runtimes in this Workspace. RESEARCHER_DATA_VIEW authority required.
     */
    async adminListRuntimesRaw(requestParameters: WorkspaceAdminApiAdminListRuntimesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AdminRuntimeFields>>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling adminListRuntimes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/workspaces/{workspaceNamespace}/listRuntimes`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminRuntimeFieldsFromJSON));
    }

    /**
     * Get a list of runtimes in this Workspace. RESEARCHER_DATA_VIEW authority required.
     */
    async adminListRuntimes(workspaceNamespace: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AdminRuntimeFields>> {
        const response = await this.adminListRuntimesRaw({ workspaceNamespace: workspaceNamespace }, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of user apps in this Workspace. RESEARCHER_DATA_VIEW authority required.
     */
    async adminListUserAppsInWorkspaceRaw(requestParameters: WorkspaceAdminApiAdminListUserAppsInWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserAppEnvironment>>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling adminListUserAppsInWorkspace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/workspaces/{workspaceNamespace}/userApps`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserAppEnvironmentFromJSON));
    }

    /**
     * Get a list of user apps in this Workspace. RESEARCHER_DATA_VIEW authority required.
     */
    async adminListUserAppsInWorkspace(workspaceNamespace: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserAppEnvironment>> {
        const response = await this.adminListUserAppsInWorkspaceRaw({ workspaceNamespace: workspaceNamespace }, initOverrides);
        return await response.value();
    }

    /**
     * Get the HTML for a read only view of the notebook. RESEARCHER_DATA_VIEW authority required.
     */
    async adminReadOnlyNotebookRaw(requestParameters: WorkspaceAdminApiAdminReadOnlyNotebookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReadOnlyNotebookResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling adminReadOnlyNotebook.');
        }

        if (requestParameters.notebookName === null || requestParameters.notebookName === undefined) {
            throw new runtime.RequiredError('notebookName','Required parameter requestParameters.notebookName was null or undefined when calling adminReadOnlyNotebook.');
        }

        if (requestParameters.accessReason === null || requestParameters.accessReason === undefined) {
            throw new runtime.RequiredError('accessReason','Required parameter requestParameters.accessReason was null or undefined when calling adminReadOnlyNotebook.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/workspaces/{workspaceNamespace}/notebooks/{notebookName}/readonly`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"notebookName"}}`, encodeURIComponent(String(requestParameters.notebookName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccessReasonToJSON(requestParameters.accessReason),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyNotebookResponseFromJSON(jsonValue));
    }

    /**
     * Get the HTML for a read only view of the notebook. RESEARCHER_DATA_VIEW authority required.
     */
    async adminReadOnlyNotebook(workspaceNamespace: string, notebookName: string, accessReason: AccessReason, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReadOnlyNotebookResponse> {
        const response = await this.adminReadOnlyNotebookRaw({ workspaceNamespace: workspaceNamespace, notebookName: notebookName, accessReason: accessReason }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a time series of cloud storage traffic for the given project. Requires the RESEARCHER_DATA_VIEW authority.
     */
    async getCloudStorageTrafficRaw(requestParameters: WorkspaceAdminApiGetCloudStorageTrafficRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CloudStorageTraffic>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getCloudStorageTraffic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/workspaces/{workspaceNamespace}/cloudStorageTraffic`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CloudStorageTrafficFromJSON(jsonValue));
    }

    /**
     * Returns a time series of cloud storage traffic for the given project. Requires the RESEARCHER_DATA_VIEW authority.
     */
    async getCloudStorageTraffic(workspaceNamespace: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CloudStorageTraffic> {
        const response = await this.getCloudStorageTrafficRaw({ workspaceNamespace: workspaceNamespace }, initOverrides);
        return await response.value();
    }

    /**
     * Given a workspace namespace (Google Cloud Platform  Project ID), returns workspace details. Requires the RESEARCHER_DATA_VIEW authority.
     */
    async getWorkspaceAdminViewRaw(requestParameters: WorkspaceAdminApiGetWorkspaceAdminViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceAdminView>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getWorkspaceAdminView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/workspaces/{workspaceNamespace}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceAdminViewFromJSON(jsonValue));
    }

    /**
     * Given a workspace namespace (Google Cloud Platform  Project ID), returns workspace details. Requires the RESEARCHER_DATA_VIEW authority.
     */
    async getWorkspaceAdminView(workspaceNamespace: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceAdminView> {
        const response = await this.getWorkspaceAdminViewRaw({ workspaceNamespace: workspaceNamespace }, initOverrides);
        return await response.value();
    }

    /**
     * Fetch audit logs involving this workspace as either a subject or object. Requires the RESEARCHER_DATA_VIEW authority. 
     */
    async getWorkspaceAuditLogEntriesRaw(requestParameters: WorkspaceAdminApiGetWorkspaceAuditLogEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceAuditLogQueryResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getWorkspaceAuditLogEntries.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        if (requestParameters.before !== undefined) {
            queryParameters['before'] = requestParameters.before;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/workspaces/{workspaceNamespace}/audit`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceAuditLogQueryResponseFromJSON(jsonValue));
    }

    /**
     * Fetch audit logs involving this workspace as either a subject or object. Requires the RESEARCHER_DATA_VIEW authority. 
     */
    async getWorkspaceAuditLogEntries(workspaceNamespace: string, limit?: number, after?: number, before?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceAuditLogQueryResponse> {
        const response = await this.getWorkspaceAuditLogEntriesRaw({ workspaceNamespace: workspaceNamespace, limit: limit, after: after, before: before }, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of files corresponding to the storage objects associated with the Workspace. RESEARCHER_DATA_VIEW authority required. 
     */
    async listFilesRaw(requestParameters: WorkspaceAdminApiListFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FileDetail>>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling listFiles.');
        }

        const queryParameters: any = {};

        if (requestParameters.onlyAppFiles !== undefined) {
            queryParameters['onlyAppFiles'] = requestParameters.onlyAppFiles;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/workspaces/{workspaceNamespace}/files`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FileDetailFromJSON));
    }

    /**
     * Get a list of files corresponding to the storage objects associated with the Workspace. RESEARCHER_DATA_VIEW authority required. 
     */
    async listFiles(workspaceNamespace: string, onlyAppFiles?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FileDetail>> {
        const response = await this.listFilesRaw({ workspaceNamespace: workspaceNamespace, onlyAppFiles: onlyAppFiles }, initOverrides);
        return await response.value();
    }

    /**
     * Publish the workspace so it features in the Featured Workspaces tab under requested category.  Requires RESEARCHER_DATA_VIEW authority.
     */
    async publishWorkspaceViaDBRaw(requestParameters: WorkspaceAdminApiPublishWorkspaceViaDBRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling publishWorkspaceViaDB.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/workspaces/{workspaceNamespace}/publish`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PublishWorkspaceRequestToJSON(requestParameters.publishWorkspaceRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Publish the workspace so it features in the Featured Workspaces tab under requested category.  Requires RESEARCHER_DATA_VIEW authority.
     */
    async publishWorkspaceViaDB(workspaceNamespace: string, publishWorkspaceRequest?: PublishWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.publishWorkspaceViaDBRaw({ workspaceNamespace: workspaceNamespace, publishWorkspaceRequest: publishWorkspaceRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Set admin locked state for the workspace
     */
    async setAdminLockedStateRaw(requestParameters: WorkspaceAdminApiSetAdminLockedStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling setAdminLockedState.');
        }

        if (requestParameters.adminLockingRequest === null || requestParameters.adminLockingRequest === undefined) {
            throw new runtime.RequiredError('adminLockingRequest','Required parameter requestParameters.adminLockingRequest was null or undefined when calling setAdminLockedState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/workspaces/{workspaceNamespace}/locked`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminLockingRequestToJSON(requestParameters.adminLockingRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Set admin locked state for the workspace
     */
    async setAdminLockedState(workspaceNamespace: string, adminLockingRequest: AdminLockingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.setAdminLockedStateRaw({ workspaceNamespace: workspaceNamespace, adminLockingRequest: adminLockingRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Set admin unlocked state for the workspace
     */
    async setAdminUnlockedStateRaw(requestParameters: WorkspaceAdminApiSetAdminUnlockedStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling setAdminUnlockedState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/workspaces/{workspaceNamespace}/unlocked`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Set admin unlocked state for the workspace
     */
    async setAdminUnlockedState(workspaceNamespace: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.setAdminUnlockedStateRaw({ workspaceNamespace: workspaceNamespace }, initOverrides);
        return await response.value();
    }

    /**
     * Unpublish a workspace removing it from Featured Workspaces tab. Requires RESEARCHER_DATA_VIEW authority.
     */
    async unpublishWorkspaceViaDBRaw(requestParameters: WorkspaceAdminApiUnpublishWorkspaceViaDBRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling unpublishWorkspaceViaDB.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/workspaces/{workspaceNamespace}/publish`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Unpublish a workspace removing it from Featured Workspaces tab. Requires RESEARCHER_DATA_VIEW authority.
     */
    async unpublishWorkspaceViaDB(workspaceNamespace: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.unpublishWorkspaceViaDBRaw({ workspaceNamespace: workspaceNamespace }, initOverrides);
        return await response.value();
    }

}
