/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EgressBypassWindow } from './EgressBypassWindow';
import {
    EgressBypassWindowFromJSON,
    EgressBypassWindowFromJSONTyped,
    EgressBypassWindowToJSON,
} from './EgressBypassWindow';

/**
 * 
 * @export
 * @interface ListEgressBypassWindowResponse
 */
export interface ListEgressBypassWindowResponse {
    /**
     * 
     * @type {Array<EgressBypassWindow>}
     * @memberof ListEgressBypassWindowResponse
     */
    bypassWindows?: Array<EgressBypassWindow>;
}

/**
 * Check if a given object implements the ListEgressBypassWindowResponse interface.
 */
export function instanceOfListEgressBypassWindowResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListEgressBypassWindowResponseFromJSON(json: any): ListEgressBypassWindowResponse {
    return ListEgressBypassWindowResponseFromJSONTyped(json, false);
}

export function ListEgressBypassWindowResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListEgressBypassWindowResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bypassWindows': !exists(json, 'bypassWindows') ? undefined : ((json['bypassWindows'] as Array<any>).map(EgressBypassWindowFromJSON)),
    };
}

export function ListEgressBypassWindowResponseToJSON(value?: ListEgressBypassWindowResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bypassWindows': value.bypassWindows === undefined ? undefined : ((value.bypassWindows as Array<any>).map(EgressBypassWindowToJSON)),
    };
}

