/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RecentResourceRequest,
  WorkspaceResource,
  WorkspaceResourceResponse,
} from '../models';
import {
    RecentResourceRequestFromJSON,
    RecentResourceRequestToJSON,
    WorkspaceResourceFromJSON,
    WorkspaceResourceToJSON,
    WorkspaceResourceResponseFromJSON,
    WorkspaceResourceResponseToJSON,
} from '../models';

export interface UserMetricsApiUpdateRecentResourceRequest {
    workspaceNamespace: string;
    terraName: string;
    recentResourceRequest: RecentResourceRequest;
}

/**
 * 
 */
export class UserMetricsApi extends runtime.BaseAPI {

    /**
     * Returns the resources accessed by user order by access time desc
     */
    async getUserRecentResourcesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceResourceResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/user-recent-resources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceResourceResponseFromJSON(jsonValue));
    }

    /**
     * Returns the resources accessed by user order by access time desc
     */
    async getUserRecentResources(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceResourceResponse> {
        const response = await this.getUserRecentResourcesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Add/update user recent resource
     */
    async updateRecentResourceRaw(requestParameters: UserMetricsApiUpdateRecentResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceResource>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling updateRecentResource.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling updateRecentResource.');
        }

        if (requestParameters.recentResourceRequest === null || requestParameters.recentResourceRequest === undefined) {
            throw new runtime.RequiredError('recentResourceRequest','Required parameter requestParameters.recentResourceRequest was null or undefined when calling updateRecentResource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/user-recent-resources/update`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecentResourceRequestToJSON(requestParameters.recentResourceRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceResourceFromJSON(jsonValue));
    }

    /**
     * Add/update user recent resource
     */
    async updateRecentResource(workspaceNamespace: string, terraName: string, recentResourceRequest: RecentResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceResource> {
        const response = await this.updateRecentResourceRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, recentResourceRequest: recentResourceRequest }, initOverrides);
        return await response.value();
    }

}
