/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Modifier } from './Modifier';
import {
    ModifierFromJSON,
    ModifierFromJSONTyped,
    ModifierToJSON,
} from './Modifier';
import type { SearchParameter } from './SearchParameter';
import {
    SearchParameterFromJSON,
    SearchParameterFromJSONTyped,
    SearchParameterToJSON,
} from './SearchParameter';

/**
 * A SearchGroupItem is the "line item" of the Cohort Builder.  It specifies a set of criteria of a given kind, possibly alongside a set of modifiers, the results of which are `OR`ed together with the other criteria in the group.
 * @export
 * @interface SearchGroupItem
 */
export interface SearchGroupItem {
    /**
     * Unique within the cohort definition
     * @type {string}
     * @memberof SearchGroupItem
     */
    id?: string;
    /**
     * optional custom name set by user
     * @type {string}
     * @memberof SearchGroupItem
     */
    name?: string;
    /**
     * type of criteria
     * @type {string}
     * @memberof SearchGroupItem
     */
    type: string;
    /**
     * temporal group that this item belongs to
     * @type {number}
     * @memberof SearchGroupItem
     */
    temporalGroup?: number;
    /**
     * values that help search for subjects
     * @type {Array<SearchParameter>}
     * @memberof SearchGroupItem
     */
    searchParameters: Array<SearchParameter>;
    /**
     * Predicates to apply to the search parameters. Aggregate modifiers (i.e. NUM_OF_OCCURRENCES) are applied independently to each SearchParameter and furthermore, are applied independently to any group elements within those SearchParameters.
     * Consider the following example query scenario.
     * Example criteria tree: - parent
     *   - child1
     *   - child2
     * 
     * Curated dataset contains 1 participant with 1 event each of concepts child1 and child2. Search request is made on "parent", with a modifier of {type: NUM_OF_OCCURRENCES, operands: [2]}.
     * This does not match - the participant would need to have at least 2 events of type child1 and/or of child2 and/or of parent (in the event that "parent" corresponds to a real concept in the data).
     * Additionally, aggregate modifiers are applied secondarily to other modifiers. For example, combining the AGE_AT_EVENT with NUM_OF_OCCURRENCES - first we filter down all events by the age predicate, then we count occurrences.
     * @type {Array<Modifier>}
     * @memberof SearchGroupItem
     */
    modifiers: Array<Modifier>;
}

/**
 * Check if a given object implements the SearchGroupItem interface.
 */
export function instanceOfSearchGroupItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "searchParameters" in value;
    isInstance = isInstance && "modifiers" in value;

    return isInstance;
}

export function SearchGroupItemFromJSON(json: any): SearchGroupItem {
    return SearchGroupItemFromJSONTyped(json, false);
}

export function SearchGroupItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchGroupItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': json['type'],
        'temporalGroup': !exists(json, 'temporalGroup') ? undefined : json['temporalGroup'],
        'searchParameters': ((json['searchParameters'] as Array<any>).map(SearchParameterFromJSON)),
        'modifiers': ((json['modifiers'] as Array<any>).map(ModifierFromJSON)),
    };
}

export function SearchGroupItemToJSON(value?: SearchGroupItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'temporalGroup': value.temporalGroup,
        'searchParameters': ((value.searchParameters as Array<any>).map(SearchParameterToJSON)),
        'modifiers': ((value.modifiers as Array<any>).map(ModifierToJSON)),
    };
}

