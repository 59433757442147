/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EgressEvent } from './EgressEvent';
import {
    EgressEventFromJSON,
    EgressEventFromJSONTyped,
    EgressEventToJSON,
} from './EgressEvent';

/**
 * 
 * @export
 * @interface ListEgressEventsResponse
 */
export interface ListEgressEventsResponse {
    /**
     * 
     * @type {Array<EgressEvent>}
     * @memberof ListEgressEventsResponse
     */
    events?: Array<EgressEvent>;
    /**
     * If present, this token can be provided to retrieve the next page of results. If null or empty, there are no further pages in the result stream.
     * @type {string}
     * @memberof ListEgressEventsResponse
     */
    nextPageToken?: string;
    /**
     * Total size of the result set.
     * @type {number}
     * @memberof ListEgressEventsResponse
     */
    totalSize?: number;
}

/**
 * Check if a given object implements the ListEgressEventsResponse interface.
 */
export function instanceOfListEgressEventsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListEgressEventsResponseFromJSON(json: any): ListEgressEventsResponse {
    return ListEgressEventsResponseFromJSONTyped(json, false);
}

export function ListEgressEventsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListEgressEventsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'events': !exists(json, 'events') ? undefined : ((json['events'] as Array<any>).map(EgressEventFromJSON)),
        'nextPageToken': !exists(json, 'nextPageToken') ? undefined : json['nextPageToken'],
        'totalSize': !exists(json, 'totalSize') ? undefined : json['totalSize'],
    };
}

export function ListEgressEventsResponseToJSON(value?: ListEgressEventsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'events': value.events === undefined ? undefined : ((value.events as Array<any>).map(EgressEventToJSON)),
        'nextPageToken': value.nextPageToken,
        'totalSize': value.totalSize,
    };
}

