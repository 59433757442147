/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Variant
 */
export interface Variant {
    /**
     * Primary identifier of a variant.
     * @type {string}
     * @memberof Variant
     */
    vid: string;
    /**
     * Gene name.
     * @type {string}
     * @memberof Variant
     */
    gene?: string;
    /**
     * 
     * @type {string}
     * @memberof Variant
     */
    consequence?: string;
    /**
     * 
     * @type {string}
     * @memberof Variant
     */
    proteinChange?: string;
    /**
     * 
     * @type {string}
     * @memberof Variant
     */
    clinVarSignificance?: string;
    /**
     * 
     * @type {number}
     * @memberof Variant
     */
    alleleCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Variant
     */
    alleleNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof Variant
     */
    alleleFrequency?: number;
    /**
     * 
     * @type {number}
     * @memberof Variant
     */
    participantCount: number;
}

/**
 * Check if a given object implements the Variant interface.
 */
export function instanceOfVariant(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vid" in value;
    isInstance = isInstance && "participantCount" in value;

    return isInstance;
}

export function VariantFromJSON(json: any): Variant {
    return VariantFromJSONTyped(json, false);
}

export function VariantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Variant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vid': json['vid'],
        'gene': !exists(json, 'gene') ? undefined : json['gene'],
        'consequence': !exists(json, 'consequence') ? undefined : json['consequence'],
        'proteinChange': !exists(json, 'proteinChange') ? undefined : json['proteinChange'],
        'clinVarSignificance': !exists(json, 'clinVarSignificance') ? undefined : json['clinVarSignificance'],
        'alleleCount': !exists(json, 'alleleCount') ? undefined : json['alleleCount'],
        'alleleNumber': !exists(json, 'alleleNumber') ? undefined : json['alleleNumber'],
        'alleleFrequency': !exists(json, 'alleleFrequency') ? undefined : json['alleleFrequency'],
        'participantCount': json['participantCount'],
    };
}

export function VariantToJSON(value?: Variant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vid': value.vid,
        'gene': value.gene,
        'consequence': value.consequence,
        'proteinChange': value.proteinChange,
        'clinVarSignificance': value.clinVarSignificance,
        'alleleCount': value.alleleCount,
        'alleleNumber': value.alleleNumber,
        'alleleFrequency': value.alleleFrequency,
        'participantCount': value.participantCount,
    };
}

