/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { WorkspaceAccessLevel } from './WorkspaceAccessLevel';
import {
    WorkspaceAccessLevelFromJSON,
    WorkspaceAccessLevelFromJSONTyped,
    WorkspaceAccessLevelToJSON,
} from './WorkspaceAccessLevel';

/**
 * 
 * @export
 * @interface WorkspaceUserAdminView
 */
export interface WorkspaceUserAdminView {
    /**
     * 
     * @type {User}
     * @memberof WorkspaceUserAdminView
     */
    userModel: User;
    /**
     * 
     * @type {WorkspaceAccessLevel}
     * @memberof WorkspaceUserAdminView
     */
    role: WorkspaceAccessLevel;
    /**
     * user_id value in the user table in the MySQL database. Useful for DB queries and Action Audit searches in Stackdriver Logging and BigQuery.
     * @type {number}
     * @memberof WorkspaceUserAdminView
     */
    userDatabaseId: number;
    /**
     * Datetime at creation of this user's accont
     * @type {Date}
     * @memberof WorkspaceUserAdminView
     */
    userAccountCreatedTime?: Date;
}

/**
 * Check if a given object implements the WorkspaceUserAdminView interface.
 */
export function instanceOfWorkspaceUserAdminView(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userModel" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "userDatabaseId" in value;

    return isInstance;
}

export function WorkspaceUserAdminViewFromJSON(json: any): WorkspaceUserAdminView {
    return WorkspaceUserAdminViewFromJSONTyped(json, false);
}

export function WorkspaceUserAdminViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceUserAdminView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userModel': UserFromJSON(json['userModel']),
        'role': WorkspaceAccessLevelFromJSON(json['role']),
        'userDatabaseId': json['userDatabaseId'],
        'userAccountCreatedTime': !exists(json, 'userAccountCreatedTime') ? undefined : (new Date(json['userAccountCreatedTime'])),
    };
}

export function WorkspaceUserAdminViewToJSON(value?: WorkspaceUserAdminView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userModel': UserToJSON(value.userModel),
        'role': WorkspaceAccessLevelToJSON(value.role),
        'userDatabaseId': value.userDatabaseId,
        'userAccountCreatedTime': value.userAccountCreatedTime === undefined ? undefined : (value.userAccountCreatedTime.toISOString()),
    };
}

