/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AuditEgressRuntimeLogEntry } from './AuditEgressRuntimeLogEntry';
import {
    AuditEgressRuntimeLogEntryFromJSON,
    AuditEgressRuntimeLogEntryFromJSONTyped,
    AuditEgressRuntimeLogEntryToJSON,
} from './AuditEgressRuntimeLogEntry';

/**
 * 
 * @export
 * @interface AuditEgressRuntimeLogGroup
 */
export interface AuditEgressRuntimeLogGroup {
    /**
     * Display name for this log group
     * @type {string}
     * @memberof AuditEgressRuntimeLogGroup
     */
    name?: string;
    /**
     * Pattern used to match the log message text
     * @type {string}
     * @memberof AuditEgressRuntimeLogGroup
     */
    pattern?: string;
    /**
     * The log entries matching the specified pattern, in descending timestamp order. May be truncated.
     * @type {Array<AuditEgressRuntimeLogEntry>}
     * @memberof AuditEgressRuntimeLogGroup
     */
    entries?: Array<AuditEgressRuntimeLogEntry>;
    /**
     * The total number of log entries matching this pattern. To determine whether entries are truncated, compare against len(entries).
     * @type {number}
     * @memberof AuditEgressRuntimeLogGroup
     */
    totalEntries?: number;
}

/**
 * Check if a given object implements the AuditEgressRuntimeLogGroup interface.
 */
export function instanceOfAuditEgressRuntimeLogGroup(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuditEgressRuntimeLogGroupFromJSON(json: any): AuditEgressRuntimeLogGroup {
    return AuditEgressRuntimeLogGroupFromJSONTyped(json, false);
}

export function AuditEgressRuntimeLogGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditEgressRuntimeLogGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'pattern': !exists(json, 'pattern') ? undefined : json['pattern'],
        'entries': !exists(json, 'entries') ? undefined : ((json['entries'] as Array<any>).map(AuditEgressRuntimeLogEntryFromJSON)),
        'totalEntries': !exists(json, 'totalEntries') ? undefined : json['totalEntries'],
    };
}

export function AuditEgressRuntimeLogGroupToJSON(value?: AuditEgressRuntimeLogGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'pattern': value.pattern,
        'entries': value.entries === undefined ? undefined : ((value.entries as Array<any>).map(AuditEgressRuntimeLogEntryToJSON)),
        'totalEntries': value.totalEntries,
    };
}

