/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FilterColumns } from './FilterColumns';
import {
    FilterColumnsFromJSON,
    FilterColumnsFromJSONTyped,
    FilterColumnsToJSON,
} from './FilterColumns';
import type { Operator } from './Operator';
import {
    OperatorFromJSON,
    OperatorFromJSONTyped,
    OperatorToJSON,
} from './Operator';

/**
 * 
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * 
     * @type {FilterColumns}
     * @memberof Filter
     */
    property: FilterColumns;
    /**
     * 
     * @type {Operator}
     * @memberof Filter
     */
    operator: Operator;
    /**
     * 
     * @type {Array<string>}
     * @memberof Filter
     */
    values: Array<string>;
}

/**
 * Check if a given object implements the Filter interface.
 */
export function instanceOfFilter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "property" in value;
    isInstance = isInstance && "operator" in value;
    isInstance = isInstance && "values" in value;

    return isInstance;
}

export function FilterFromJSON(json: any): Filter {
    return FilterFromJSONTyped(json, false);
}

export function FilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): Filter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'property': FilterColumnsFromJSON(json['property']),
        'operator': OperatorFromJSON(json['operator']),
        'values': json['values'],
    };
}

export function FilterToJSON(value?: Filter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'property': FilterColumnsToJSON(value.property),
        'operator': OperatorToJSON(value.operator),
        'values': value.values,
    };
}

