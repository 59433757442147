/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListEgressEventsRequest
 */
export interface ListEgressEventsRequest {
    /**
     * If specified, only return events which were caused by this user. Should include the email domain of the user.
     * @type {string}
     * @memberof ListEgressEventsRequest
     */
    sourceUserEmail?: string;
    /**
     * If specified, only return event which were detected within the given workspace.
     * @type {string}
     * @memberof ListEgressEventsRequest
     */
    sourceWorkspaceNamespace?: string;
    /**
     * The number of egress events to return, per page. If unspecified, a system default is used.
     * @type {number}
     * @memberof ListEgressEventsRequest
     */
    pageSize?: number;
    /**
     * Pagination token used to receive the next page of results. Provide this value from the nextPageToken of a prior response.
     * @type {string}
     * @memberof ListEgressEventsRequest
     */
    pageToken?: string;
}

/**
 * Check if a given object implements the ListEgressEventsRequest interface.
 */
export function instanceOfListEgressEventsRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListEgressEventsRequestFromJSON(json: any): ListEgressEventsRequest {
    return ListEgressEventsRequestFromJSONTyped(json, false);
}

export function ListEgressEventsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListEgressEventsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourceUserEmail': !exists(json, 'sourceUserEmail') ? undefined : json['sourceUserEmail'],
        'sourceWorkspaceNamespace': !exists(json, 'sourceWorkspaceNamespace') ? undefined : json['sourceWorkspaceNamespace'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'pageToken': !exists(json, 'pageToken') ? undefined : json['pageToken'],
    };
}

export function ListEgressEventsRequestToJSON(value?: ListEgressEventsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sourceUserEmail': value.sourceUserEmail,
        'sourceWorkspaceNamespace': value.sourceWorkspaceNamespace,
        'pageSize': value.pageSize,
        'pageToken': value.pageToken,
    };
}

