/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Literal } from './Literal';
import {
    LiteralFromJSON,
    LiteralFromJSONTyped,
    LiteralToJSON,
} from './Literal';

/**
 * Filter on a hierarchy (e.g. descendant of id=12)
 * @export
 * @interface HierarchyFilter
 */
export interface HierarchyFilter {
    /**
     * Name of the hierarchy
     * @type {string}
     * @memberof HierarchyFilter
     */
    hierarchy?: string;
    /**
     * 
     * @type {string}
     * @memberof HierarchyFilter
     */
    operator?: HierarchyFilterOperatorEnum;
    /**
     * 
     * @type {Array<Literal>}
     * @memberof HierarchyFilter
     */
    values?: Array<Literal>;
}


/**
 * @export
 */
export const HierarchyFilterOperatorEnum = {
    ChildOf: 'CHILD_OF',
    DescendantOfInclusive: 'DESCENDANT_OF_INCLUSIVE',
    IsRoot: 'IS_ROOT',
    IsMember: 'IS_MEMBER',
    IsLeaf: 'IS_LEAF'
} as const;
export type HierarchyFilterOperatorEnum = typeof HierarchyFilterOperatorEnum[keyof typeof HierarchyFilterOperatorEnum];


/**
 * Check if a given object implements the HierarchyFilter interface.
 */
export function instanceOfHierarchyFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HierarchyFilterFromJSON(json: any): HierarchyFilter {
    return HierarchyFilterFromJSONTyped(json, false);
}

export function HierarchyFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): HierarchyFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hierarchy': !exists(json, 'hierarchy') ? undefined : json['hierarchy'],
        'operator': !exists(json, 'operator') ? undefined : json['operator'],
        'values': !exists(json, 'values') ? undefined : ((json['values'] as Array<any>).map(LiteralFromJSON)),
    };
}

export function HierarchyFilterToJSON(value?: HierarchyFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hierarchy': value.hierarchy,
        'operator': value.operator,
        'values': value.values === undefined ? undefined : ((value.values as Array<any>).map(LiteralToJSON)),
    };
}

