/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InstitutionalRole } from './InstitutionalRole';
import {
    InstitutionalRoleFromJSON,
    InstitutionalRoleFromJSONTyped,
    InstitutionalRoleToJSON,
} from './InstitutionalRole';

/**
 * 
 * @export
 * @interface VerifiedInstitutionalAffiliation
 */
export interface VerifiedInstitutionalAffiliation {
    /**
     * The unique Short Name of the Institution where the user has a Verified Affiliation, such as 'VUMC'
     * @type {string}
     * @memberof VerifiedInstitutionalAffiliation
     */
    institutionShortName: string;
    /**
     * The longer, more descriptive name of the Institution where the user has a Verified Affiliation, such as 'Vanderbilt University Medical Center'
     * @type {string}
     * @memberof VerifiedInstitutionalAffiliation
     */
    institutionDisplayName?: string;
    /**
     * Optional url for institution's handling their own access requests
     * @type {string}
     * @memberof VerifiedInstitutionalAffiliation
     */
    institutionRequestAccessUrl?: string;
    /**
     * 
     * @type {InstitutionalRole}
     * @memberof VerifiedInstitutionalAffiliation
     */
    institutionalRoleEnum: InstitutionalRole;
    /**
     * The user's detailed Institutional Role at this Institution, as text, if its enumerated type is OTHER
     * @type {string}
     * @memberof VerifiedInstitutionalAffiliation
     */
    institutionalRoleOtherText?: string;
}

/**
 * Check if a given object implements the VerifiedInstitutionalAffiliation interface.
 */
export function instanceOfVerifiedInstitutionalAffiliation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "institutionShortName" in value;
    isInstance = isInstance && "institutionalRoleEnum" in value;

    return isInstance;
}

export function VerifiedInstitutionalAffiliationFromJSON(json: any): VerifiedInstitutionalAffiliation {
    return VerifiedInstitutionalAffiliationFromJSONTyped(json, false);
}

export function VerifiedInstitutionalAffiliationFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifiedInstitutionalAffiliation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'institutionShortName': json['institutionShortName'],
        'institutionDisplayName': !exists(json, 'institutionDisplayName') ? undefined : json['institutionDisplayName'],
        'institutionRequestAccessUrl': !exists(json, 'institutionRequestAccessUrl') ? undefined : json['institutionRequestAccessUrl'],
        'institutionalRoleEnum': InstitutionalRoleFromJSON(json['institutionalRoleEnum']),
        'institutionalRoleOtherText': !exists(json, 'institutionalRoleOtherText') ? undefined : json['institutionalRoleOtherText'],
    };
}

export function VerifiedInstitutionalAffiliationToJSON(value?: VerifiedInstitutionalAffiliation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'institutionShortName': value.institutionShortName,
        'institutionDisplayName': value.institutionDisplayName,
        'institutionRequestAccessUrl': value.institutionRequestAccessUrl,
        'institutionalRoleEnum': InstitutionalRoleToJSON(value.institutionalRoleEnum),
        'institutionalRoleOtherText': value.institutionalRoleOtherText,
    };
}

