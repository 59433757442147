/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Which categories describe you? (Select all that apply). Note, you may select more than one group.
 * @export
 */
export const EthnicCategory = {
    AI_AN: 'AI_AN',
    AI_AN_AMERICAN_INDIAN: 'AI_AN_AMERICAN_INDIAN',
    AI_AN_ALASKA_NATIVE: 'AI_AN_ALASKA_NATIVE',
    AI_AN_CENTRAL_SOUTH: 'AI_AN_CENTRAL_SOUTH',
    AI_AN_OTHER: 'AI_AN_OTHER',
    ASIAN: 'ASIAN',
    ASIAN_INDIAN: 'ASIAN_INDIAN',
    ASIAN_CAMBODIAN: 'ASIAN_CAMBODIAN',
    ASIAN_CHINESE: 'ASIAN_CHINESE',
    ASIAN_FILIPINO: 'ASIAN_FILIPINO',
    ASIAN_HMONG: 'ASIAN_HMONG',
    ASIAN_JAPANESE: 'ASIAN_JAPANESE',
    ASIAN_KOREAN: 'ASIAN_KOREAN',
    ASIAN_LAO: 'ASIAN_LAO',
    ASIAN_PAKISTANI: 'ASIAN_PAKISTANI',
    ASIAN_VIETNAMESE: 'ASIAN_VIETNAMESE',
    ASIAN_OTHER: 'ASIAN_OTHER',
    BLACK: 'BLACK',
    BLACK_AA: 'BLACK_AA',
    BLACK_BARBADIAN: 'BLACK_BARBADIAN',
    BLACK_CARIBBEAN: 'BLACK_CARIBBEAN',
    BLACK_ETHIOPIAN: 'BLACK_ETHIOPIAN',
    BLACK_GHANAIAN: 'BLACK_GHANAIAN',
    BLACK_HAITIAN: 'BLACK_HAITIAN',
    BLACK_JAMAICAN: 'BLACK_JAMAICAN',
    BLACK_LIBERIAN: 'BLACK_LIBERIAN',
    BLACK_NIGERIAN: 'BLACK_NIGERIAN',
    BLACK_SOMALI: 'BLACK_SOMALI',
    BLACK_SOUTH_AFRICAN: 'BLACK_SOUTH_AFRICAN',
    BLACK_OTHER: 'BLACK_OTHER',
    HISPANIC: 'HISPANIC',
    HISPANIC_COLOMBIAN: 'HISPANIC_COLOMBIAN',
    HISPANIC_CUBAN: 'HISPANIC_CUBAN',
    HISPANIC_DOMINICAN: 'HISPANIC_DOMINICAN',
    HISPANIC_ECUADORIAN: 'HISPANIC_ECUADORIAN',
    HISPANIC_HONDURAN: 'HISPANIC_HONDURAN',
    HISPANIC_MEXICAN: 'HISPANIC_MEXICAN',
    HISPANIC_PUERTO_RICAN: 'HISPANIC_PUERTO_RICAN',
    HISPANIC_SALVADORAN: 'HISPANIC_SALVADORAN',
    HISPANIC_SPANISH: 'HISPANIC_SPANISH',
    HISPANIC_OTHER: 'HISPANIC_OTHER',
    MENA: 'MENA',
    MENA_AFGHAN: 'MENA_AFGHAN',
    MENA_ALGERIAN: 'MENA_ALGERIAN',
    MENA_EGYPTIAN: 'MENA_EGYPTIAN',
    MENA_IRANIAN: 'MENA_IRANIAN',
    MENA_IRAQI: 'MENA_IRAQI',
    MENA_ISRAELI: 'MENA_ISRAELI',
    MENA_LEBANESE: 'MENA_LEBANESE',
    MENA_MOROCCAN: 'MENA_MOROCCAN',
    MENA_SYRIAN: 'MENA_SYRIAN',
    MENA_TUNISIAN: 'MENA_TUNISIAN',
    MENA_OTHER: 'MENA_OTHER',
    NHPI: 'NHPI',
    NHPI_CHAMORRO: 'NHPI_CHAMORRO',
    NHPI_CHUUKESE: 'NHPI_CHUUKESE',
    NHPI_FIJIAN: 'NHPI_FIJIAN',
    NHPI_MARSHALLESE: 'NHPI_MARSHALLESE',
    NHPI_HAWAIIAN: 'NHPI_HAWAIIAN',
    NHPI_PALAUAN: 'NHPI_PALAUAN',
    NHPI_SAMOAN: 'NHPI_SAMOAN',
    NHPI_TAHITIAN: 'NHPI_TAHITIAN',
    NHPI_TONGAN: 'NHPI_TONGAN',
    NHPI_OTHER: 'NHPI_OTHER',
    WHITE: 'WHITE',
    WHITE_DUTCH: 'WHITE_DUTCH',
    WHITE_ENGLISH: 'WHITE_ENGLISH',
    WHITE_EUROPEAN: 'WHITE_EUROPEAN',
    WHITE_FRENCH: 'WHITE_FRENCH',
    WHITE_GERMAN: 'WHITE_GERMAN',
    WHITE_IRISH: 'WHITE_IRISH',
    WHITE_ITALIAN: 'WHITE_ITALIAN',
    WHITE_NORWEGIAN: 'WHITE_NORWEGIAN',
    WHITE_POLISH: 'WHITE_POLISH',
    WHITE_SCOTTISH: 'WHITE_SCOTTISH',
    WHITE_SPANISH: 'WHITE_SPANISH',
    WHITE_OTHER: 'WHITE_OTHER',
    OTHER: 'OTHER',
    PREFER_NOT_TO_ANSWER: 'PREFER_NOT_TO_ANSWER'
} as const;
export type EthnicCategory = typeof EthnicCategory[keyof typeof EthnicCategory];


export function EthnicCategoryFromJSON(json: any): EthnicCategory {
    return EthnicCategoryFromJSONTyped(json, false);
}

export function EthnicCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EthnicCategory {
    return json as EthnicCategory;
}

export function EthnicCategoryToJSON(value?: EthnicCategory | null): any {
    return value as any;
}

