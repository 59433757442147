/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Literal } from './Literal';
import {
    LiteralFromJSON,
    LiteralFromJSONTyped,
    LiteralToJSON,
} from './Literal';

/**
 * Attribute value and optional display string
 * @export
 * @interface ValueDisplay
 */
export interface ValueDisplay {
    /**
     * 
     * @type {Literal}
     * @memberof ValueDisplay
     */
    value?: Literal | null;
    /**
     * Optional display string
     * @type {string}
     * @memberof ValueDisplay
     */
    display?: string | null;
    /**
     * 
     * @type {Array<Literal>}
     * @memberof ValueDisplay
     */
    repeatedValue?: Array<Literal>;
    /**
     * 
     * @type {boolean}
     * @memberof ValueDisplay
     */
    isRepeatedValue?: boolean;
}

/**
 * Check if a given object implements the ValueDisplay interface.
 */
export function instanceOfValueDisplay(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ValueDisplayFromJSON(json: any): ValueDisplay {
    return ValueDisplayFromJSONTyped(json, false);
}

export function ValueDisplayFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValueDisplay {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : LiteralFromJSON(json['value']),
        'display': !exists(json, 'display') ? undefined : json['display'],
        'repeatedValue': !exists(json, 'repeatedValue') ? undefined : ((json['repeatedValue'] as Array<any>).map(LiteralFromJSON)),
        'isRepeatedValue': !exists(json, 'isRepeatedValue') ? undefined : json['isRepeatedValue'],
    };
}

export function ValueDisplayToJSON(value?: ValueDisplay | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': LiteralToJSON(value.value),
        'display': value.display,
        'repeatedValue': value.repeatedValue === undefined ? undefined : ((value.repeatedValue as Array<any>).map(LiteralToJSON)),
        'isRepeatedValue': value.isRepeatedValue,
    };
}

