/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AccessModule = {
    DATA_USER_CODE_OF_CONDUCT: 'DATA_USER_CODE_OF_CONDUCT',
    COMPLIANCE_TRAINING: 'COMPLIANCE_TRAINING',
    CT_COMPLIANCE_TRAINING: 'CT_COMPLIANCE_TRAINING',
    ERA_COMMONS: 'ERA_COMMONS',
    TWO_FACTOR_AUTH: 'TWO_FACTOR_AUTH',
    RAS_LINK_LOGIN_GOV: 'RAS_LINK_LOGIN_GOV',
    RAS_LINK_ID_ME: 'RAS_LINK_ID_ME',
    IDENTITY: 'IDENTITY',
    PROFILE_CONFIRMATION: 'PROFILE_CONFIRMATION',
    PUBLICATION_CONFIRMATION: 'PUBLICATION_CONFIRMATION'
} as const;
export type AccessModule = typeof AccessModule[keyof typeof AccessModule];


export function AccessModuleFromJSON(json: any): AccessModule {
    return AccessModuleFromJSONTyped(json, false);
}

export function AccessModuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessModule {
    return json as AccessModule;
}

export function AccessModuleToJSON(value?: AccessModule | null): any {
    return value as any;
}

