/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Criteria } from './Criteria';
import {
    CriteriaFromJSON,
    CriteriaFromJSONTyped,
    CriteriaToJSON,
} from './Criteria';
import type { EntityOutput } from './EntityOutput';
import {
    EntityOutputFromJSON,
    EntityOutputFromJSONTyped,
    EntityOutputToJSON,
} from './EntityOutput';

/**
 * 
 * @export
 * @interface FeatureSetUpdateInfo
 */
export interface FeatureSetUpdateInfo {
    /**
     * Human readable name of the feature set
     * @type {string}
     * @memberof FeatureSetUpdateInfo
     */
    displayName?: string;
    /**
     * Description of the feature set
     * @type {string}
     * @memberof FeatureSetUpdateInfo
     */
    description?: string;
    /**
     * Criteria that define the entity filter
     * @type {Array<Criteria>}
     * @memberof FeatureSetUpdateInfo
     */
    criteria?: Array<Criteria>;
    /**
     * Outputs per entity
     * @type {Array<EntityOutput>}
     * @memberof FeatureSetUpdateInfo
     */
    entityOutputs?: Array<EntityOutput>;
}

/**
 * Check if a given object implements the FeatureSetUpdateInfo interface.
 */
export function instanceOfFeatureSetUpdateInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FeatureSetUpdateInfoFromJSON(json: any): FeatureSetUpdateInfo {
    return FeatureSetUpdateInfoFromJSONTyped(json, false);
}

export function FeatureSetUpdateInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeatureSetUpdateInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'criteria': !exists(json, 'criteria') ? undefined : ((json['criteria'] as Array<any>).map(CriteriaFromJSON)),
        'entityOutputs': !exists(json, 'entityOutputs') ? undefined : ((json['entityOutputs'] as Array<any>).map(EntityOutputFromJSON)),
    };
}

export function FeatureSetUpdateInfoToJSON(value?: FeatureSetUpdateInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'displayName': value.displayName,
        'description': value.description,
        'criteria': value.criteria === undefined ? undefined : ((value.criteria as Array<any>).map(CriteriaToJSON)),
        'entityOutputs': value.entityOutputs === undefined ? undefined : ((value.entityOutputs as Array<any>).map(EntityOutputToJSON)),
    };
}

