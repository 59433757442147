/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents how researchers plan to to disseminate thier research findings.
 * @export
 */
export const DisseminateResearchEnum = {
    PUBLICATION_PEER_REVIEWED_JOURNALS: 'PUBLICATION_PEER_REVIEWED_JOURNALS',
    PRESENATATION_SCIENTIFIC_CONFERENCES: 'PRESENATATION_SCIENTIFIC_CONFERENCES',
    PRESS_RELEASE: 'PRESS_RELEASE',
    PUBLICATION_COMMUNITY_BASED_BLOG: 'PUBLICATION_COMMUNITY_BASED_BLOG',
    PUBLICATION_PERSONAL_BLOG: 'PUBLICATION_PERSONAL_BLOG',
    SOCIAL_MEDIA: 'SOCIAL_MEDIA',
    PRESENTATION_ADVISORY_GROUPS: 'PRESENTATION_ADVISORY_GROUPS',
    OTHER: 'OTHER'
} as const;
export type DisseminateResearchEnum = typeof DisseminateResearchEnum[keyof typeof DisseminateResearchEnum];


export function DisseminateResearchEnumFromJSON(json: any): DisseminateResearchEnum {
    return DisseminateResearchEnumFromJSONTyped(json, false);
}

export function DisseminateResearchEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisseminateResearchEnum {
    return json as DisseminateResearchEnum;
}

export function DisseminateResearchEnumToJSON(value?: DisseminateResearchEnum | null): any {
    return value as any;
}

