/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RasLinkRequestBody
 */
export interface RasLinkRequestBody {
    /**
     * the standard OAuth authorization code from RAS that we can use to exchange access token.
     * @type {string}
     * @memberof RasLinkRequestBody
     */
    authCode: string;
    /**
     * The redirect url that was used when generating the authorization code.
     * @type {string}
     * @memberof RasLinkRequestBody
     */
    redirectUrl: string;
}

/**
 * Check if a given object implements the RasLinkRequestBody interface.
 */
export function instanceOfRasLinkRequestBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "authCode" in value;
    isInstance = isInstance && "redirectUrl" in value;

    return isInstance;
}

export function RasLinkRequestBodyFromJSON(json: any): RasLinkRequestBody {
    return RasLinkRequestBodyFromJSONTyped(json, false);
}

export function RasLinkRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): RasLinkRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authCode': json['authCode'],
        'redirectUrl': json['redirectUrl'],
    };
}

export function RasLinkRequestBodyToJSON(value?: RasLinkRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authCode': value.authCode,
        'redirectUrl': value.redirectUrl,
    };
}

