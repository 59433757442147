/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Institution-specific instructions for users to perform after registration.
 * @export
 * @interface InstitutionUserInstructions
 */
export interface InstitutionUserInstructions {
    /**
     * The short unique name for the Institution used as an identifier, such as 'VUMC'.
     * @type {string}
     * @memberof InstitutionUserInstructions
     */
    institutionShortName: string;
    /**
     * Text to include in an email sent to this Institution's users after they are registered.
     * @type {string}
     * @memberof InstitutionUserInstructions
     */
    instructions: string;
}

/**
 * Check if a given object implements the InstitutionUserInstructions interface.
 */
export function instanceOfInstitutionUserInstructions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "institutionShortName" in value;
    isInstance = isInstance && "instructions" in value;

    return isInstance;
}

export function InstitutionUserInstructionsFromJSON(json: any): InstitutionUserInstructions {
    return InstitutionUserInstructionsFromJSONTyped(json, false);
}

export function InstitutionUserInstructionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstitutionUserInstructions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'institutionShortName': json['institutionShortName'],
        'instructions': json['instructions'],
    };
}

export function InstitutionUserInstructionsToJSON(value?: InstitutionUserInstructions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'institutionShortName': value.institutionShortName,
        'instructions': value.instructions,
    };
}

