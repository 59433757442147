/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccessModule } from './AccessModule';
import {
    AccessModuleFromJSON,
    AccessModuleFromJSONTyped,
    AccessModuleToJSON,
} from './AccessModule';

/**
 * 
 * @export
 * @interface AccessModuleConfig
 */
export interface AccessModuleConfig {
    /**
     * 
     * @type {AccessModule}
     * @memberof AccessModuleConfig
     */
    name: AccessModule;
    /**
     * 
     * @type {boolean}
     * @memberof AccessModuleConfig
     */
    expirable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccessModuleConfig
     */
    requiredForRTAccess: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccessModuleConfig
     */
    requiredForCTAccess: boolean;
}

/**
 * Check if a given object implements the AccessModuleConfig interface.
 */
export function instanceOfAccessModuleConfig(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "expirable" in value;
    isInstance = isInstance && "requiredForRTAccess" in value;
    isInstance = isInstance && "requiredForCTAccess" in value;

    return isInstance;
}

export function AccessModuleConfigFromJSON(json: any): AccessModuleConfig {
    return AccessModuleConfigFromJSONTyped(json, false);
}

export function AccessModuleConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessModuleConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': AccessModuleFromJSON(json['name']),
        'expirable': json['expirable'],
        'requiredForRTAccess': json['requiredForRTAccess'],
        'requiredForCTAccess': json['requiredForCTAccess'],
    };
}

export function AccessModuleConfigToJSON(value?: AccessModuleConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': AccessModuleToJSON(value.name),
        'expirable': value.expirable,
        'requiredForRTAccess': value.requiredForRTAccess,
        'requiredForCTAccess': value.requiredForCTAccess,
    };
}

