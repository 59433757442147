/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Criteria } from './Criteria';
import {
    CriteriaFromJSON,
    CriteriaFromJSONTyped,
    CriteriaToJSON,
} from './Criteria';
import type { EntityOutput } from './EntityOutput';
import {
    EntityOutputFromJSON,
    EntityOutputFromJSONTyped,
    EntityOutputToJSON,
} from './EntityOutput';

/**
 * 
 * @export
 * @interface FeatureSet
 */
export interface FeatureSet {
    /**
     * ID of the feature set, immutable
     * @type {string}
     * @memberof FeatureSet
     */
    id: string;
    /**
     * Name of the underlay, immutable
     * @type {string}
     * @memberof FeatureSet
     */
    underlayName: string;
    /**
     * Human readable name of the feature set
     * @type {string}
     * @memberof FeatureSet
     */
    displayName: string;
    /**
     * Description of the feature set
     * @type {string}
     * @memberof FeatureSet
     */
    description?: string;
    /**
     * Criteria that define the entity filter
     * @type {Array<Criteria>}
     * @memberof FeatureSet
     */
    criteria: Array<Criteria>;
    /**
     * Outputs per entity
     * @type {Array<EntityOutput>}
     * @memberof FeatureSet
     */
    entityOutputs: Array<EntityOutput>;
    /**
     * Timestamp of when the feature set was created
     * @type {Date}
     * @memberof FeatureSet
     */
    created: Date;
    /**
     * Email of user who created the feature set
     * @type {string}
     * @memberof FeatureSet
     */
    createdBy: string;
    /**
     * Timestamp of when the feature set was last modified
     * @type {Date}
     * @memberof FeatureSet
     */
    lastModified: Date;
}

/**
 * Check if a given object implements the FeatureSet interface.
 */
export function instanceOfFeatureSet(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "underlayName" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "criteria" in value;
    isInstance = isInstance && "entityOutputs" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "createdBy" in value;
    isInstance = isInstance && "lastModified" in value;

    return isInstance;
}

export function FeatureSetFromJSON(json: any): FeatureSet {
    return FeatureSetFromJSONTyped(json, false);
}

export function FeatureSetFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeatureSet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'underlayName': json['underlayName'],
        'displayName': json['displayName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'criteria': ((json['criteria'] as Array<any>).map(CriteriaFromJSON)),
        'entityOutputs': ((json['entityOutputs'] as Array<any>).map(EntityOutputFromJSON)),
        'created': (new Date(json['created'])),
        'createdBy': json['createdBy'],
        'lastModified': (new Date(json['lastModified'])),
    };
}

export function FeatureSetToJSON(value?: FeatureSet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'underlayName': value.underlayName,
        'displayName': value.displayName,
        'description': value.description,
        'criteria': ((value.criteria as Array<any>).map(CriteriaToJSON)),
        'entityOutputs': ((value.entityOutputs as Array<any>).map(EntityOutputToJSON)),
        'created': (value.created.toISOString()),
        'createdBy': value.createdBy,
        'lastModified': (value.lastModified.toISOString()),
    };
}

