/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EmptyResponse,
  ListDisksResponse,
} from '../models';
import {
    EmptyResponseFromJSON,
    EmptyResponseToJSON,
    ListDisksResponseFromJSON,
    ListDisksResponseToJSON,
} from '../models';

export interface DiskAdminApiAdminDeleteDiskRequest {
    workspaceNamespace: string;
    diskName: string;
}

export interface DiskAdminApiListDisksInWorkspaceRequest {
    workspaceNamespace: string;
}

/**
 * 
 */
export class DiskAdminApi extends runtime.BaseAPI {

    /**
     * Deletes a disk as an admin. Requires the RESEARCHER_DATA_VIEW authority.
     */
    async adminDeleteDiskRaw(requestParameters: DiskAdminApiAdminDeleteDiskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling adminDeleteDisk.');
        }

        if (requestParameters.diskName === null || requestParameters.diskName === undefined) {
            throw new runtime.RequiredError('diskName','Required parameter requestParameters.diskName was null or undefined when calling adminDeleteDisk.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/workspaces/{workspaceNamespace}/disks/{diskName}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"diskName"}}`, encodeURIComponent(String(requestParameters.diskName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Deletes a disk as an admin. Requires the RESEARCHER_DATA_VIEW authority.
     */
    async adminDeleteDisk(workspaceNamespace: string, diskName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.adminDeleteDiskRaw({ workspaceNamespace: workspaceNamespace, diskName: diskName }, initOverrides);
        return await response.value();
    }

    /**
     * Lists persistent disks in a workspace. Requires the RESEARCHER_DATA_VIEW authority.
     */
    async listDisksInWorkspaceRaw(requestParameters: DiskAdminApiListDisksInWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListDisksResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling listDisksInWorkspace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/workspaces/{workspaceNamespace}/disks`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListDisksResponseFromJSON(jsonValue));
    }

    /**
     * Lists persistent disks in a workspace. Requires the RESEARCHER_DATA_VIEW authority.
     */
    async listDisksInWorkspace(workspaceNamespace: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListDisksResponse> {
        const response = await this.listDisksInWorkspaceRaw({ workspaceNamespace: workspaceNamespace }, initOverrides);
        return await response.value();
    }

}
