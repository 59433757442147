/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Attribute } from './Attribute';
import {
    AttributeFromJSON,
    AttributeFromJSONTyped,
    AttributeToJSON,
} from './Attribute';
import type { VariantFilter } from './VariantFilter';
import {
    VariantFilterFromJSON,
    VariantFilterFromJSONTyped,
    VariantFilterToJSON,
} from './VariantFilter';

/**
 * 
 * @export
 * @interface SearchParameter
 */
export interface SearchParameter {
    /**
     * Unique within the cohort definition
     * @type {string}
     * @memberof SearchParameter
     */
    parameterId: string;
    /**
     * The name of the generating Criterion
     * @type {string}
     * @memberof SearchParameter
     */
    name: string;
    /**
     * The omop domain that this criteria belongs to
     * @type {string}
     * @memberof SearchParameter
     */
    domain: string;
    /**
     * The type of the generating Criterion
     * @type {string}
     * @memberof SearchParameter
     */
    type: string;
    /**
     * The subtype of the generating Criterion
     * @type {string}
     * @memberof SearchParameter
     */
    subtype?: string;
    /**
     * Specifies if the generating Criterion is a leaf or node in its Criteria tree
     * @type {boolean}
     * @memberof SearchParameter
     */
    group: boolean;
    /**
     * flag that determines if concept ids need to be looked up in the ancestor table
     * @type {boolean}
     * @memberof SearchParameter
     */
    ancestorData: boolean;
    /**
     * flag that determines standard or source
     * @type {boolean}
     * @memberof SearchParameter
     */
    standard: boolean;
    /**
     * The concept id that maps to concept table, if any.
     * @type {number}
     * @memberof SearchParameter
     */
    conceptId?: number;
    /**
     * The variant id of SNP Indel Variants.
     * @type {string}
     * @memberof SearchParameter
     */
    variantId?: string;
    /**
     * 
     * @type {VariantFilter}
     * @memberof SearchParameter
     */
    variantFilter?: VariantFilter;
    /**
     * Attributes are extra inputs provided by users through the UX. Currently used by Demographics(Age), Measurements(Body Height, Weight.. etc) and Physical Measurements(Blood Pressure, Body Weight.. etc). Measurements and Physical Measurements have overlap in many areas such as body weight, but the difference is standard(measurements) and source(physical measurements).
     * 
     *  Example demo age search parameter:
     *  {
     *    "type": "DEMO",
     *    "searchParameters": [
     *      {
     *        "type": "DEMO",
     *        "subtype": "AGE",
     *        "group": false,
     *        "attributes": [{"name": "Age", "operator": "BETWEEN", "operands": [23, 31]}]
     *      }
     *    ],
     *    "modifiers": []
     *  }
     * 
     *  Example physical measurement blood pressure(Hypotensive (Systolic <= 90 / Diastolic <= 60))
     *  {
     *    "type": "PM",
     *    "searchParameters": [
     *      {
     *        "type": "PM",
     *        "subtype": "BP",
     *        "group": false,
     *        "attributes": [
     *          {
     *            "conceptId": 903118,
     *            "name": "Systolic",
     *            "operands": ["90"],
     *            "operator":"LESS_THAN_OR_EQUAL_TO"
     *          },
     *          {
     *            "conceptId": 903115,
     *            "name": "Diastolic",
     *            "operands": ["60"],
     *             "operator": "LESS_THAN_OR_EQUAL_TO"
     *           }
     *         ]
     *       }
     *     ],
     *     "modifiers": []
     *   }
     * @type {Array<Attribute>}
     * @memberof SearchParameter
     */
    attributes: Array<Attribute>;
}

/**
 * Check if a given object implements the SearchParameter interface.
 */
export function instanceOfSearchParameter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "parameterId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "group" in value;
    isInstance = isInstance && "ancestorData" in value;
    isInstance = isInstance && "standard" in value;
    isInstance = isInstance && "attributes" in value;

    return isInstance;
}

export function SearchParameterFromJSON(json: any): SearchParameter {
    return SearchParameterFromJSONTyped(json, false);
}

export function SearchParameterFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchParameter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameterId': json['parameterId'],
        'name': json['name'],
        'domain': json['domain'],
        'type': json['type'],
        'subtype': !exists(json, 'subtype') ? undefined : json['subtype'],
        'group': json['group'],
        'ancestorData': json['ancestorData'],
        'standard': json['standard'],
        'conceptId': !exists(json, 'conceptId') ? undefined : json['conceptId'],
        'variantId': !exists(json, 'variantId') ? undefined : json['variantId'],
        'variantFilter': !exists(json, 'variantFilter') ? undefined : VariantFilterFromJSON(json['variantFilter']),
        'attributes': ((json['attributes'] as Array<any>).map(AttributeFromJSON)),
    };
}

export function SearchParameterToJSON(value?: SearchParameter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameterId': value.parameterId,
        'name': value.name,
        'domain': value.domain,
        'type': value.type,
        'subtype': value.subtype,
        'group': value.group,
        'ancestorData': value.ancestorData,
        'standard': value.standard,
        'conceptId': value.conceptId,
        'variantId': value.variantId,
        'variantFilter': VariantFilterToJSON(value.variantFilter),
        'attributes': ((value.attributes as Array<any>).map(AttributeToJSON)),
    };
}

