/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CdrVersionTier } from './CdrVersionTier';
import {
    CdrVersionTierFromJSON,
    CdrVersionTierFromJSONTyped,
    CdrVersionTierToJSON,
} from './CdrVersionTier';

/**
 * 
 * @export
 * @interface CdrVersionTiersResponse
 */
export interface CdrVersionTiersResponse {
    /**
     * 
     * @type {Array<CdrVersionTier>}
     * @memberof CdrVersionTiersResponse
     */
    tiers: Array<CdrVersionTier>;
}

/**
 * Check if a given object implements the CdrVersionTiersResponse interface.
 */
export function instanceOfCdrVersionTiersResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tiers" in value;

    return isInstance;
}

export function CdrVersionTiersResponseFromJSON(json: any): CdrVersionTiersResponse {
    return CdrVersionTiersResponseFromJSONTyped(json, false);
}

export function CdrVersionTiersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CdrVersionTiersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tiers': ((json['tiers'] as Array<any>).map(CdrVersionTierFromJSON)),
    };
}

export function CdrVersionTiersResponseToJSON(value?: CdrVersionTiersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tiers': ((value.tiers as Array<any>).map(CdrVersionTierToJSON)),
    };
}

