/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttributeFilter } from './AttributeFilter';
import {
    AttributeFilterFromJSON,
    AttributeFilterFromJSONTyped,
    AttributeFilterToJSON,
} from './AttributeFilter';
import type { BooleanLogicFilter } from './BooleanLogicFilter';
import {
    BooleanLogicFilterFromJSON,
    BooleanLogicFilterFromJSONTyped,
    BooleanLogicFilterToJSON,
} from './BooleanLogicFilter';
import type { GroupHasItemsFilter } from './GroupHasItemsFilter';
import {
    GroupHasItemsFilterFromJSON,
    GroupHasItemsFilterFromJSONTyped,
    GroupHasItemsFilterToJSON,
} from './GroupHasItemsFilter';
import type { HierarchyFilter } from './HierarchyFilter';
import {
    HierarchyFilterFromJSON,
    HierarchyFilterFromJSONTyped,
    HierarchyFilterToJSON,
} from './HierarchyFilter';
import type { ItemInGroupFilter } from './ItemInGroupFilter';
import {
    ItemInGroupFilterFromJSON,
    ItemInGroupFilterFromJSONTyped,
    ItemInGroupFilterToJSON,
} from './ItemInGroupFilter';
import type { OccurrenceForPrimaryFilter } from './OccurrenceForPrimaryFilter';
import {
    OccurrenceForPrimaryFilterFromJSON,
    OccurrenceForPrimaryFilterFromJSONTyped,
    OccurrenceForPrimaryFilterToJSON,
} from './OccurrenceForPrimaryFilter';
import type { PrimaryWithCriteriaFilter } from './PrimaryWithCriteriaFilter';
import {
    PrimaryWithCriteriaFilterFromJSON,
    PrimaryWithCriteriaFilterFromJSONTyped,
    PrimaryWithCriteriaFilterToJSON,
} from './PrimaryWithCriteriaFilter';
import type { RelationshipFilter } from './RelationshipFilter';
import {
    RelationshipFilterFromJSON,
    RelationshipFilterFromJSONTyped,
    RelationshipFilterToJSON,
} from './RelationshipFilter';
import type { TextFilter } from './TextFilter';
import {
    TextFilterFromJSON,
    TextFilterFromJSONTyped,
    TextFilterToJSON,
} from './TextFilter';

/**
 * 
 * @export
 * @interface FilterFilterUnion
 */
export interface FilterFilterUnion {
    /**
     * 
     * @type {AttributeFilter}
     * @memberof FilterFilterUnion
     */
    attributeFilter?: AttributeFilter;
    /**
     * 
     * @type {TextFilter}
     * @memberof FilterFilterUnion
     */
    textFilter?: TextFilter;
    /**
     * 
     * @type {HierarchyFilter}
     * @memberof FilterFilterUnion
     */
    hierarchyFilter?: HierarchyFilter;
    /**
     * 
     * @type {RelationshipFilter}
     * @memberof FilterFilterUnion
     */
    relationshipFilter?: RelationshipFilter;
    /**
     * 
     * @type {BooleanLogicFilter}
     * @memberof FilterFilterUnion
     */
    booleanLogicFilter?: BooleanLogicFilter;
    /**
     * 
     * @type {ItemInGroupFilter}
     * @memberof FilterFilterUnion
     */
    itemInGroupFilter?: ItemInGroupFilter;
    /**
     * 
     * @type {GroupHasItemsFilter}
     * @memberof FilterFilterUnion
     */
    groupHasItemsFilter?: GroupHasItemsFilter;
    /**
     * 
     * @type {OccurrenceForPrimaryFilter}
     * @memberof FilterFilterUnion
     */
    occurrenceForPrimaryFilter?: OccurrenceForPrimaryFilter;
    /**
     * 
     * @type {PrimaryWithCriteriaFilter}
     * @memberof FilterFilterUnion
     */
    primaryWithCriteriaFilter?: PrimaryWithCriteriaFilter;
}

/**
 * Check if a given object implements the FilterFilterUnion interface.
 */
export function instanceOfFilterFilterUnion(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FilterFilterUnionFromJSON(json: any): FilterFilterUnion {
    return FilterFilterUnionFromJSONTyped(json, false);
}

export function FilterFilterUnionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterFilterUnion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeFilter': !exists(json, 'attributeFilter') ? undefined : AttributeFilterFromJSON(json['attributeFilter']),
        'textFilter': !exists(json, 'textFilter') ? undefined : TextFilterFromJSON(json['textFilter']),
        'hierarchyFilter': !exists(json, 'hierarchyFilter') ? undefined : HierarchyFilterFromJSON(json['hierarchyFilter']),
        'relationshipFilter': !exists(json, 'relationshipFilter') ? undefined : RelationshipFilterFromJSON(json['relationshipFilter']),
        'booleanLogicFilter': !exists(json, 'booleanLogicFilter') ? undefined : BooleanLogicFilterFromJSON(json['booleanLogicFilter']),
        'itemInGroupFilter': !exists(json, 'itemInGroupFilter') ? undefined : ItemInGroupFilterFromJSON(json['itemInGroupFilter']),
        'groupHasItemsFilter': !exists(json, 'groupHasItemsFilter') ? undefined : GroupHasItemsFilterFromJSON(json['groupHasItemsFilter']),
        'occurrenceForPrimaryFilter': !exists(json, 'occurrenceForPrimaryFilter') ? undefined : OccurrenceForPrimaryFilterFromJSON(json['occurrenceForPrimaryFilter']),
        'primaryWithCriteriaFilter': !exists(json, 'primaryWithCriteriaFilter') ? undefined : PrimaryWithCriteriaFilterFromJSON(json['primaryWithCriteriaFilter']),
    };
}

export function FilterFilterUnionToJSON(value?: FilterFilterUnion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeFilter': AttributeFilterToJSON(value.attributeFilter),
        'textFilter': TextFilterToJSON(value.textFilter),
        'hierarchyFilter': HierarchyFilterToJSON(value.hierarchyFilter),
        'relationshipFilter': RelationshipFilterToJSON(value.relationshipFilter),
        'booleanLogicFilter': BooleanLogicFilterToJSON(value.booleanLogicFilter),
        'itemInGroupFilter': ItemInGroupFilterToJSON(value.itemInGroupFilter),
        'groupHasItemsFilter': GroupHasItemsFilterToJSON(value.groupHasItemsFilter),
        'occurrenceForPrimaryFilter': OccurrenceForPrimaryFilterToJSON(value.occurrenceForPrimaryFilter),
        'primaryWithCriteriaFilter': PrimaryWithCriteriaFilterToJSON(value.primaryWithCriteriaFilter),
    };
}

