/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DomainValue } from './DomainValue';
import {
    DomainValueFromJSON,
    DomainValueFromJSONTyped,
    DomainValueToJSON,
} from './DomainValue';

/**
 * Domain with list of domain values map to various column names in OMOP.
 * @export
 * @interface DomainWithDomainValues
 */
export interface DomainWithDomainValues {
    /**
     * The domain for this list of values.
     * @type {string}
     * @memberof DomainWithDomainValues
     */
    domain?: string;
    /**
     * 
     * @type {Array<DomainValue>}
     * @memberof DomainWithDomainValues
     */
    items?: Array<DomainValue>;
}

/**
 * Check if a given object implements the DomainWithDomainValues interface.
 */
export function instanceOfDomainWithDomainValues(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DomainWithDomainValuesFromJSON(json: any): DomainWithDomainValues {
    return DomainWithDomainValuesFromJSONTyped(json, false);
}

export function DomainWithDomainValuesFromJSONTyped(json: any, ignoreDiscriminator: boolean): DomainWithDomainValues {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(DomainValueFromJSON)),
    };
}

export function DomainWithDomainValuesToJSON(value?: DomainWithDomainValues | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'domain': value.domain,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(DomainValueToJSON)),
    };
}

