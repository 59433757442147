/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AuditEventBundleHeader } from './AuditEventBundleHeader';
import {
    AuditEventBundleHeaderFromJSON,
    AuditEventBundleHeaderFromJSONTyped,
    AuditEventBundleHeaderToJSON,
} from './AuditEventBundleHeader';
import type { AuditTargetPropertyChange } from './AuditTargetPropertyChange';
import {
    AuditTargetPropertyChangeFromJSON,
    AuditTargetPropertyChangeFromJSONTyped,
    AuditTargetPropertyChangeToJSON,
} from './AuditTargetPropertyChange';

/**
 * Collection of an AuditAction's sub-actions. Useful as a map key, plus it contains information about the agent, target, and action type (of which there can be multiple per action). Additionally, any property changes are coalesced into an array under the propertyChanges property.
 * @export
 * @interface AuditEventBundle
 */
export interface AuditEventBundle {
    /**
     * 
     * @type {AuditEventBundleHeader}
     * @memberof AuditEventBundle
     */
    header?: AuditEventBundleHeader;
    /**
     * List of property changes corresponding to this event bundle. For example, with a workspace create event, there may be many field changed from null to their initial values.
     * @type {Array<AuditTargetPropertyChange>}
     * @memberof AuditEventBundle
     */
    propertyChanges?: Array<AuditTargetPropertyChange>;
}

/**
 * Check if a given object implements the AuditEventBundle interface.
 */
export function instanceOfAuditEventBundle(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuditEventBundleFromJSON(json: any): AuditEventBundle {
    return AuditEventBundleFromJSONTyped(json, false);
}

export function AuditEventBundleFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditEventBundle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'header': !exists(json, 'header') ? undefined : AuditEventBundleHeaderFromJSON(json['header']),
        'propertyChanges': !exists(json, 'propertyChanges') ? undefined : ((json['propertyChanges'] as Array<any>).map(AuditTargetPropertyChangeFromJSON)),
    };
}

export function AuditEventBundleToJSON(value?: AuditEventBundle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'header': AuditEventBundleHeaderToJSON(value.header),
        'propertyChanges': value.propertyChanges === undefined ? undefined : ((value.propertyChanges as Array<any>).map(AuditTargetPropertyChangeToJSON)),
    };
}

