/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Criteria } from './Criteria';
import {
    CriteriaFromJSON,
    CriteriaFromJSONTyped,
    CriteriaToJSON,
} from './Criteria';

/**
 * 
 * @export
 * @interface CriteriaListWithCountResponse
 */
export interface CriteriaListWithCountResponse {
    /**
     * 
     * @type {Array<Criteria>}
     * @memberof CriteriaListWithCountResponse
     */
    items: Array<Criteria>;
    /**
     * 
     * @type {number}
     * @memberof CriteriaListWithCountResponse
     */
    totalCount: number;
}

/**
 * Check if a given object implements the CriteriaListWithCountResponse interface.
 */
export function instanceOfCriteriaListWithCountResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;
    isInstance = isInstance && "totalCount" in value;

    return isInstance;
}

export function CriteriaListWithCountResponseFromJSON(json: any): CriteriaListWithCountResponse {
    return CriteriaListWithCountResponseFromJSONTyped(json, false);
}

export function CriteriaListWithCountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CriteriaListWithCountResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(CriteriaFromJSON)),
        'totalCount': json['totalCount'],
    };
}

export function CriteriaListWithCountResponseToJSON(value?: CriteriaListWithCountResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(CriteriaToJSON)),
        'totalCount': value.totalCount,
    };
}

