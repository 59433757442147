/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AuditEventBundle } from './AuditEventBundle';
import {
    AuditEventBundleFromJSON,
    AuditEventBundleFromJSONTyped,
    AuditEventBundleToJSON,
} from './AuditEventBundle';

/**
 * A user- or administrator-visible system action in the audit log. Becasue a single action taken may have effects on more than on target, each action may contain multiple agents, targets, and action types.
 * @export
 * @interface AuditAction
 */
export interface AuditAction {
    /**
     * Unique ID for this action. Used for grouping similar events under a single action, though in this representation we're returning a straight list of rows. This is technically a UUID, but this may change in the future (to a shorter random string), so we only type it as a String.
     * @type {string}
     * @memberof AuditAction
     */
    actionId?: string;
    /**
     * Time of the earliest event (row) for this action. It's assumed that all rows in an action occur at the same time. Epoch millis.
     * @type {number}
     * @memberof AuditAction
     */
    actionTime?: number;
    /**
     * Within an action there might be sub-actions, or event bundles, that describe effects on different targets, for example when sharing a workspace with other users.
     * @type {Array<AuditEventBundle>}
     * @memberof AuditAction
     */
    eventBundles?: Array<AuditEventBundle>;
}

/**
 * Check if a given object implements the AuditAction interface.
 */
export function instanceOfAuditAction(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuditActionFromJSON(json: any): AuditAction {
    return AuditActionFromJSONTyped(json, false);
}

export function AuditActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actionId': !exists(json, 'actionId') ? undefined : json['actionId'],
        'actionTime': !exists(json, 'actionTime') ? undefined : json['actionTime'],
        'eventBundles': !exists(json, 'eventBundles') ? undefined : ((json['eventBundles'] as Array<any>).map(AuditEventBundleFromJSON)),
    };
}

export function AuditActionToJSON(value?: AuditAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actionId': value.actionId,
        'actionTime': value.actionTime,
        'eventBundles': value.eventBundles === undefined ? undefined : ((value.eventBundles as Array<any>).map(AuditEventBundleToJSON)),
    };
}

