/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccessBypassRequest } from './AccessBypassRequest';
import {
    AccessBypassRequestFromJSON,
    AccessBypassRequestFromJSONTyped,
    AccessBypassRequestToJSON,
} from './AccessBypassRequest';
import type { AccountDisabledStatus } from './AccountDisabledStatus';
import {
    AccountDisabledStatusFromJSON,
    AccountDisabledStatusFromJSONTyped,
    AccountDisabledStatusToJSON,
} from './AccountDisabledStatus';
import type { VerifiedInstitutionalAffiliation } from './VerifiedInstitutionalAffiliation';
import {
    VerifiedInstitutionalAffiliationFromJSON,
    VerifiedInstitutionalAffiliationFromJSONTyped,
    VerifiedInstitutionalAffiliationToJSON,
} from './VerifiedInstitutionalAffiliation';

/**
 * A group of changes an admin can make to a user in the Edit Information card of the individual-user admin page. Updates to the contact email or the institutional affiliation will be rejected if they do not validate.
 * @export
 * @interface AccountPropertyUpdate
 */
export interface AccountPropertyUpdate {
    /**
     * The full system-assigned ID of a user, including email domain
     * @type {string}
     * @memberof AccountPropertyUpdate
     */
    username: string;
    /**
     * When set, overrides the default free credits dollar limit for this user.
     * @type {number}
     * @memberof AccountPropertyUpdate
     */
    freeCreditsLimit?: number;
    /**
     * When set, replaces the user's contact email.  Must validate against the user's Institutional Affiliation, if one exists.
     * @type {string}
     * @memberof AccountPropertyUpdate
     */
    contactEmail?: string;
    /**
     * 
     * @type {VerifiedInstitutionalAffiliation}
     * @memberof AccountPropertyUpdate
     */
    affiliation?: VerifiedInstitutionalAffiliation;
    /**
     * Updates the user's bypass status for the given access modules.
     * @type {Array<AccessBypassRequest>}
     * @memberof AccountPropertyUpdate
     */
    accessBypassRequests: Array<AccessBypassRequest>;
    /**
     * 
     * @type {AccountDisabledStatus}
     * @memberof AccountPropertyUpdate
     */
    accountDisabledStatus?: AccountDisabledStatus;
    /**
     * When set to true, the user will not be required to spend their initial credits within the expiration period.
     * @type {boolean}
     * @memberof AccountPropertyUpdate
     */
    initialCreditsExpirationBypassed?: boolean;
}

/**
 * Check if a given object implements the AccountPropertyUpdate interface.
 */
export function instanceOfAccountPropertyUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "accessBypassRequests" in value;

    return isInstance;
}

export function AccountPropertyUpdateFromJSON(json: any): AccountPropertyUpdate {
    return AccountPropertyUpdateFromJSONTyped(json, false);
}

export function AccountPropertyUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountPropertyUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'freeCreditsLimit': !exists(json, 'freeCreditsLimit') ? undefined : json['freeCreditsLimit'],
        'contactEmail': !exists(json, 'contactEmail') ? undefined : json['contactEmail'],
        'affiliation': !exists(json, 'affiliation') ? undefined : VerifiedInstitutionalAffiliationFromJSON(json['affiliation']),
        'accessBypassRequests': ((json['accessBypassRequests'] as Array<any>).map(AccessBypassRequestFromJSON)),
        'accountDisabledStatus': !exists(json, 'accountDisabledStatus') ? undefined : AccountDisabledStatusFromJSON(json['accountDisabledStatus']),
        'initialCreditsExpirationBypassed': !exists(json, 'initialCreditsExpirationBypassed') ? undefined : json['initialCreditsExpirationBypassed'],
    };
}

export function AccountPropertyUpdateToJSON(value?: AccountPropertyUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'freeCreditsLimit': value.freeCreditsLimit,
        'contactEmail': value.contactEmail,
        'affiliation': VerifiedInstitutionalAffiliationToJSON(value.affiliation),
        'accessBypassRequests': ((value.accessBypassRequests as Array<any>).map(AccessBypassRequestToJSON)),
        'accountDisabledStatus': AccountDisabledStatusToJSON(value.accountDisabledStatus),
        'initialCreditsExpirationBypassed': value.initialCreditsExpirationBypassed,
    };
}

