/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A config that describes the gpus associated with a runtime
 * @export
 * @interface GpuConfig
 */
export interface GpuConfig {
    /**
     * The google identifier for the gpu specs associated with this runtime, ex `nvidia-tesla-t4`. See https://cloud.google.com/compute/docs/gpus
     * @type {string}
     * @memberof GpuConfig
     */
    gpuType: string;
    /**
     * The number of gpus associated with this runtime
     * @type {number}
     * @memberof GpuConfig
     */
    numOfGpus: number;
}

/**
 * Check if a given object implements the GpuConfig interface.
 */
export function instanceOfGpuConfig(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "gpuType" in value;
    isInstance = isInstance && "numOfGpus" in value;

    return isInstance;
}

export function GpuConfigFromJSON(json: any): GpuConfig {
    return GpuConfigFromJSONTyped(json, false);
}

export function GpuConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): GpuConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gpuType': json['gpuType'],
        'numOfGpus': json['numOfGpus'],
    };
}

export function GpuConfigToJSON(value?: GpuConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gpuType': value.gpuType,
        'numOfGpus': value.numOfGpus,
    };
}

