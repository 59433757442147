/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AuditEgressRuntimeLogGroup } from './AuditEgressRuntimeLogGroup';
import {
    AuditEgressRuntimeLogGroupFromJSON,
    AuditEgressRuntimeLogGroupFromJSONTyped,
    AuditEgressRuntimeLogGroupToJSON,
} from './AuditEgressRuntimeLogGroup';
import type { EgressEvent } from './EgressEvent';
import {
    EgressEventFromJSON,
    EgressEventFromJSONTyped,
    EgressEventToJSON,
} from './EgressEvent';

/**
 * 
 * @export
 * @interface AuditEgressEventResponse
 */
export interface AuditEgressEventResponse {
    /**
     * 
     * @type {EgressEvent}
     * @memberof AuditEgressEventResponse
     */
    egressEvent?: EgressEvent;
    /**
     * Original event received from Sumologic. Should be treated as an opaque JSON payload, as the event schema may change over time; but generally will correspond to SumologicEgressEvent.
     * @type {object}
     * @memberof AuditEgressEventResponse
     */
    sumologicEvent?: object;
    /**
     * A set of heuristically relevant log pattern/configuration groups for investigating the cause of an egress event.
     * @type {Array<AuditEgressRuntimeLogGroup>}
     * @memberof AuditEgressEventResponse
     */
    runtimeLogGroups?: Array<AuditEgressRuntimeLogGroup>;
}

/**
 * Check if a given object implements the AuditEgressEventResponse interface.
 */
export function instanceOfAuditEgressEventResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuditEgressEventResponseFromJSON(json: any): AuditEgressEventResponse {
    return AuditEgressEventResponseFromJSONTyped(json, false);
}

export function AuditEgressEventResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditEgressEventResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'egressEvent': !exists(json, 'egressEvent') ? undefined : EgressEventFromJSON(json['egressEvent']),
        'sumologicEvent': !exists(json, 'sumologicEvent') ? undefined : json['sumologicEvent'],
        'runtimeLogGroups': !exists(json, 'runtimeLogGroups') ? undefined : ((json['runtimeLogGroups'] as Array<any>).map(AuditEgressRuntimeLogGroupFromJSON)),
    };
}

export function AuditEgressEventResponseToJSON(value?: AuditEgressEventResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'egressEvent': EgressEventToJSON(value.egressEvent),
        'sumologicEvent': value.sumologicEvent,
        'runtimeLogGroups': value.runtimeLogGroups === undefined ? undefined : ((value.runtimeLogGroups as Array<any>).map(AuditEgressRuntimeLogGroupToJSON)),
    };
}

