import {
  faBell,
  faClipboard,
  faClock,
  faClone,
  faEye,
  faFileAlt,
  faFolder,
  faFolderOpen,
  faListAlt,
  faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import {
  faArrowLeft,
  faArrowRight,
  faBan,
  faCaretDown,
  faChalkboard,
  faCheck,
  faCheckCircle,
  faCircle,
  faClock as faClockSolid,
  faCloud,
  faCog,
  faCreditCard,
  faDownload,
  faEllipsisV,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandArrowsAlt,
  faFileInvoiceDollar,
  faFolder as faFolderSolid,
  faGripHorizontal,
  faInfoCircle,
  faLock,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faMinusCircle,
  faMoneyCheckAlt,
  faPause,
  faPen,
  faPlay,
  faPlus,
  faPlusCircle,
  faQuestion,
  faQuestionCircle,
  faRocket,
  faSearch,
  faShareAlt,
  faSquare as faSquareSolid,
  faStar,
  faSyncAlt,
  faTachometerAlt,
  faTasks,
  faTerminal,
  faTrashAlt,
  faUnlock,
  faVirus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash/fp';
import { h } from 'react-hyperscript-helpers';
import angleDoubleUp from 'src/icons/angle-double-up-regular.svg';
import angleUp from 'src/icons/angle-up-regular.svg';
import arrowLeftRegular from 'src/icons/arrow-left-regular.svg';
import bars from 'src/icons/bars-light.svg';
import books from 'src/icons/books-solid.svg';
import cardMenuIcon from 'src/icons/card-menu-icon.svg';
import faCloudBolt from 'src/icons/cloud-compute.svg';
import cloudUpload from 'src/icons/cloud-upload-solid.svg';
import columnGrabber from 'src/icons/column_grabber.svg';
import copySolid from 'src/icons/copy-solid.svg';
import downloadRegular from 'src/icons/download-regular.svg';
import externalLinkAlt from 'src/icons/external-link-alt-regular.svg';
import fileExport from 'src/icons/file-export-regular.svg';
import fileSearchSolid from 'src/icons/file-search-solid.svg';
import infoCircleRegular from 'src/icons/info-circle-regular.svg';
import list from 'src/icons/list-regular.svg';
import loadingSpinner from 'src/icons/loading-spinner.svg';
import renameIcon from 'src/icons/rename-icon.svg';
import squareLight from 'src/icons/square-light.svg';
import syncAlt from 'src/icons/sync-alt-regular.svg';
import talkBubble from 'src/icons/talk-bubble.svg';
import times from 'src/icons/times-light.svg';
import warningInfo from 'src/icons/warning-info.svg';

const fa = _.curry((shape, { size, ...props }) => h(FontAwesomeIcon, _.merge({ icon: shape, style: { height: size, width: size } }, props)));
const custom = _.curry((shape, { size, ...props }) =>
  h(shape, _.merge({ 'aria-hidden': true, focusable: false, style: { height: size, width: size } }, props))
);

const rotate = _.curry((rotation, shape, props) => shape(_.merge({ style: { transform: `rotate(${rotation}deg)` } }, props)));

const iconDict = {
  'angle-down': rotate(180, custom(angleUp)),
  'angle-left': rotate(-90, custom(angleUp)),
  'angle-right': rotate(90, custom(angleUp)),
  'angle-up': custom(angleUp),
  'angle-double-left': rotate(-90, custom(angleDoubleUp)),
  'angle-double-right': rotate(90, custom(angleDoubleUp)),
  arrowLeft: fa(faArrowLeft),
  arrowLeftRegular: custom(arrowLeftRegular),
  arrowRight: fa(faArrowRight),
  ban: fa(faBan),
  bars: custom(bars),
  bell: fa(faBell),
  cardMenuIcon: custom(cardMenuIcon),
  caretDown: fa(faCaretDown),
  chalkboard: fa(faChalkboard),
  check: fa(faCheck),
  circle: fa(faCircle),
  clock: fa(faClock),
  clockSolid: fa(faClockSolid),
  cloud: fa(faCloud),
  cloudBolt: custom(faCloudBolt),
  cog: fa(faCog),
  columnGrabber: custom(columnGrabber),
  copy: fa(faClone),
  copySolid: custom(copySolid),
  'copy-to-clipboard': fa(faClipboard),
  creditCard: fa(faCreditCard),
  download: fa(faDownload),
  downloadRegular: custom(downloadRegular),
  edit: fa(faPen),
  'ellipsis-v': fa(faEllipsisV),
  'ellipsis-v-circle': (props) => fa(faEllipsisV, { mask: faCircle, transform: 'shrink-8', ...props }),
  'error-standard': fa(faExclamationCircle),
  'expand-arrows-alt': fa(faExpandArrowsAlt),
  export: custom(fileExport),
  eye: fa(faEye),
  fileAlt: fa(faFileAlt),
  fileSearchSolid: custom(fileSearchSolid),
  folder: fa(faFolder),
  folderSolid: fa(faFolderSolid),
  'folder-open': fa(faFolderOpen),
  help: fa(faQuestionCircle),
  'info-circle': fa(faInfoCircle),
  'info-circle-regular': custom(infoCircleRegular),
  library: custom(books),
  listAlt: fa(faListAlt),
  loadingSpinner: custom(loadingSpinner),
  lock: fa(faLock),
  'long-arrow-alt-down': fa(faLongArrowAltDown),
  'long-arrow-alt-up': fa(faLongArrowAltUp),
  'minus-circle': fa(faMinusCircle),
  'money-check-alt': fa(faMoneyCheckAlt),
  pause: fa(faPause),
  play: fa(faPlay),
  plus: fa(faPlus),
  'plus-circle': fa(faPlusCircle),
  'lighter-plus-circle': (props) => fa(faPlus, { mask: faCircle, transform: 'shrink-6', ...props }),
  'pop-out': custom(externalLinkAlt),
  purchaseOrder: fa(faFileInvoiceDollar),
  question: fa(faQuestion),
  renameIcon: custom(renameIcon),
  rocket: fa(faRocket),
  search: fa(faSearch),
  share: fa(faShareAlt),
  squareLight: custom(squareLight),
  squareSolid: fa(faSquareSolid),
  star: fa(faStar),
  'success-standard': fa(faCheckCircle),
  sync: custom(syncAlt),
  syncAlt: fa(faSyncAlt),
  tachometer: fa(faTachometerAlt),
  tasks: fa(faTasks),
  terminal: (props) => fa(faTerminal, { mask: faSquareSolid, transform: 'shrink-8', ...props }),
  times: custom(times),
  'talk-bubble': custom(talkBubble),
  'times-circle': fa(faTimesCircle),
  trash: fa(faTrashAlt),
  unlock: fa(faUnlock),
  'upload-cloud': custom(cloudUpload),
  'view-cards': fa(faGripHorizontal),
  'view-list': custom(list),
  virus: fa(faVirus),
  'warning-info': custom(warningInfo),
  'warning-standard': fa(faExclamationTriangle),
};

export default iconDict;
