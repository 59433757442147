/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataSetPreviewValueList } from './DataSetPreviewValueList';
import {
    DataSetPreviewValueListFromJSON,
    DataSetPreviewValueListFromJSONTyped,
    DataSetPreviewValueListToJSON,
} from './DataSetPreviewValueList';
import type { Domain } from './Domain';
import {
    DomainFromJSON,
    DomainFromJSONTyped,
    DomainToJSON,
} from './Domain';

/**
 * 
 * @export
 * @interface DataSetPreviewResponse
 */
export interface DataSetPreviewResponse {
    /**
     * 
     * @type {Domain}
     * @memberof DataSetPreviewResponse
     */
    domain?: Domain;
    /**
     * 
     * @type {Array<DataSetPreviewValueList>}
     * @memberof DataSetPreviewResponse
     */
    values?: Array<DataSetPreviewValueList>;
}

/**
 * Check if a given object implements the DataSetPreviewResponse interface.
 */
export function instanceOfDataSetPreviewResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataSetPreviewResponseFromJSON(json: any): DataSetPreviewResponse {
    return DataSetPreviewResponseFromJSONTyped(json, false);
}

export function DataSetPreviewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSetPreviewResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'domain': !exists(json, 'domain') ? undefined : DomainFromJSON(json['domain']),
        'values': !exists(json, 'values') ? undefined : ((json['values'] as Array<any>).map(DataSetPreviewValueListFromJSON)),
    };
}

export function DataSetPreviewResponseToJSON(value?: DataSetPreviewResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'domain': DomainToJSON(value.domain),
        'values': value.values === undefined ? undefined : ((value.values as Array<any>).map(DataSetPreviewValueListToJSON)),
    };
}

