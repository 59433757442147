/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Domain } from './Domain';
import {
    DomainFromJSON,
    DomainFromJSONTyped,
    DomainToJSON,
} from './Domain';

/**
 * 
 * @export
 * @interface CardCount
 */
export interface CardCount {
    /**
     * 
     * @type {Domain}
     * @memberof CardCount
     */
    domain: Domain;
    /**
     * display name of the domain
     * @type {string}
     * @memberof CardCount
     */
    name: string;
    /**
     * number of matched term counts in this domain
     * @type {number}
     * @memberof CardCount
     */
    count: number;
}

/**
 * Check if a given object implements the CardCount interface.
 */
export function instanceOfCardCount(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function CardCountFromJSON(json: any): CardCount {
    return CardCountFromJSONTyped(json, false);
}

export function CardCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardCount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'domain': DomainFromJSON(json['domain']),
        'name': json['name'],
        'count': json['count'],
    };
}

export function CardCountToJSON(value?: CardCount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'domain': DomainToJSON(value.domain),
        'name': value.name,
        'count': value.count,
    };
}

