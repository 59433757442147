/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Workspace } from './Workspace';
import {
    WorkspaceFromJSON,
    WorkspaceFromJSONTyped,
    WorkspaceToJSON,
} from './Workspace';

/**
 * 
 * @export
 * @interface DuplicateWorkspaceTaskRequest
 */
export interface DuplicateWorkspaceTaskRequest {
    /**
     * Workspace operation ID
     * @type {number}
     * @memberof DuplicateWorkspaceTaskRequest
     */
    operationId: number;
    /**
     * Namespace of the From-Workspace
     * @type {string}
     * @memberof DuplicateWorkspaceTaskRequest
     */
    fromWorkspaceNamespace: string;
    /**
     * Firecloud Name of the From-Workspace
     * @type {string}
     * @memberof DuplicateWorkspaceTaskRequest
     */
    fromWorkspaceFirecloudName: string;
    /**
     * Should the duplicated workspace also duplicate the roles of the source
     * @type {boolean}
     * @memberof DuplicateWorkspaceTaskRequest
     */
    shouldDuplicateRoles?: boolean;
    /**
     * 
     * @type {Workspace}
     * @memberof DuplicateWorkspaceTaskRequest
     */
    workspace: Workspace;
}

/**
 * Check if a given object implements the DuplicateWorkspaceTaskRequest interface.
 */
export function instanceOfDuplicateWorkspaceTaskRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "operationId" in value;
    isInstance = isInstance && "fromWorkspaceNamespace" in value;
    isInstance = isInstance && "fromWorkspaceFirecloudName" in value;
    isInstance = isInstance && "workspace" in value;

    return isInstance;
}

export function DuplicateWorkspaceTaskRequestFromJSON(json: any): DuplicateWorkspaceTaskRequest {
    return DuplicateWorkspaceTaskRequestFromJSONTyped(json, false);
}

export function DuplicateWorkspaceTaskRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DuplicateWorkspaceTaskRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'operationId': json['operationId'],
        'fromWorkspaceNamespace': json['fromWorkspaceNamespace'],
        'fromWorkspaceFirecloudName': json['fromWorkspaceFirecloudName'],
        'shouldDuplicateRoles': !exists(json, 'shouldDuplicateRoles') ? undefined : json['shouldDuplicateRoles'],
        'workspace': WorkspaceFromJSON(json['workspace']),
    };
}

export function DuplicateWorkspaceTaskRequestToJSON(value?: DuplicateWorkspaceTaskRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'operationId': value.operationId,
        'fromWorkspaceNamespace': value.fromWorkspaceNamespace,
        'fromWorkspaceFirecloudName': value.fromWorkspaceFirecloudName,
        'shouldDuplicateRoles': value.shouldDuplicateRoles,
        'workspace': WorkspaceToJSON(value.workspace),
    };
}

