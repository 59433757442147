/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * What was the sex assigned to you at birth, such as on your original birth certificate?
 * @export
 */
export const SexAtBirthV2 = {
    FEMALE: 'FEMALE',
    INTERSEX: 'INTERSEX',
    MALE: 'MALE',
    OTHER: 'OTHER',
    PREFER_NOT_TO_ANSWER: 'PREFER_NOT_TO_ANSWER'
} as const;
export type SexAtBirthV2 = typeof SexAtBirthV2[keyof typeof SexAtBirthV2];


export function SexAtBirthV2FromJSON(json: any): SexAtBirthV2 {
    return SexAtBirthV2FromJSONTyped(json, false);
}

export function SexAtBirthV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): SexAtBirthV2 {
    return json as SexAtBirthV2;
}

export function SexAtBirthV2ToJSON(value?: SexAtBirthV2 | null): any {
    return value as any;
}

