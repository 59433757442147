/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EgressBypassWindow
 */
export interface EgressBypassWindow {
    /**
     * Timestamp when user egress bypass window begins in milliseconds since the UNIX epoch
     * @type {number}
     * @memberof EgressBypassWindow
     */
    startTime?: number;
    /**
     * Timestamp when user egress bypass window ends in milliseconds since the UNIX epoch
     * @type {number}
     * @memberof EgressBypassWindow
     */
    endTime?: number;
    /**
     * Brief description of this bypass request.
     * @type {string}
     * @memberof EgressBypassWindow
     */
    description?: string;
}

/**
 * Check if a given object implements the EgressBypassWindow interface.
 */
export function instanceOfEgressBypassWindow(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EgressBypassWindowFromJSON(json: any): EgressBypassWindow {
    return EgressBypassWindowFromJSONTyped(json, false);
}

export function EgressBypassWindowFromJSONTyped(json: any, ignoreDiscriminator: boolean): EgressBypassWindow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startTime': !exists(json, 'startTime') ? undefined : json['startTime'],
        'endTime': !exists(json, 'endTime') ? undefined : json['endTime'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function EgressBypassWindowToJSON(value?: EgressBypassWindow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startTime': value.startTime,
        'endTime': value.endTime,
        'description': value.description,
    };
}

