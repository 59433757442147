/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PageVisit
 */
export interface PageVisit {
    /**
     * 
     * @type {string}
     * @memberof PageVisit
     */
    page?: string;
    /**
     * 
     * @type {number}
     * @memberof PageVisit
     */
    firstVisit?: number;
}

/**
 * Check if a given object implements the PageVisit interface.
 */
export function instanceOfPageVisit(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PageVisitFromJSON(json: any): PageVisit {
    return PageVisitFromJSONTyped(json, false);
}

export function PageVisitFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageVisit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'firstVisit': !exists(json, 'firstVisit') ? undefined : json['firstVisit'],
    };
}

export function PageVisitToJSON(value?: PageVisit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'firstVisit': value.firstVisit,
    };
}

