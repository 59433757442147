/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Short parsable descriptions of specific population categories
 * @export
 */
export const SpecificPopulationEnum = {
    RACE_ETHNICITY: 'RACE_ETHNICITY',
    AGE_GROUPS: 'AGE_GROUPS',
    SEX: 'SEX',
    GENDER_IDENTITY: 'GENDER_IDENTITY',
    SEXUAL_ORIENTATION: 'SEXUAL_ORIENTATION',
    GEOGRAPHY: 'GEOGRAPHY',
    DISABILITY_STATUS: 'DISABILITY_STATUS',
    ACCESS_TO_CARE: 'ACCESS_TO_CARE',
    EDUCATION_LEVEL: 'EDUCATION_LEVEL',
    INCOME_LEVEL: 'INCOME_LEVEL',
    OTHER: 'OTHER',
    RACE_ASIAN: 'RACE_ASIAN',
    RACE_AA: 'RACE_AA',
    RACE_HISPANIC: 'RACE_HISPANIC',
    RACE_AIAN: 'RACE_AIAN',
    RACE_MENA: 'RACE_MENA',
    RACE_NHPI: 'RACE_NHPI',
    RACE_MORE_THAN_ONE: 'RACE_MORE_THAN_ONE',
    AGE_CHILDREN: 'AGE_CHILDREN',
    AGE_ADOLESCENTS: 'AGE_ADOLESCENTS',
    AGE_OLDER: 'AGE_OLDER',
    AGE_OLDER_MORE_THAN_75: 'AGE_OLDER_MORE_THAN_75'
} as const;
export type SpecificPopulationEnum = typeof SpecificPopulationEnum[keyof typeof SpecificPopulationEnum];


export function SpecificPopulationEnumFromJSON(json: any): SpecificPopulationEnum {
    return SpecificPopulationEnumFromJSONTyped(json, false);
}

export function SpecificPopulationEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecificPopulationEnum {
    return json as SpecificPopulationEnum;
}

export function SpecificPopulationEnumToJSON(value?: SpecificPopulationEnum | null): any {
    return value as any;
}

