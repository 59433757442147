/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Profile } from './Profile';
import {
    ProfileFromJSON,
    ProfileFromJSONTyped,
    ProfileToJSON,
} from './Profile';

/**
 * 
 * @export
 * @interface CreateAccountRequest
 */
export interface CreateAccountRequest {
    /**
     * 
     * @type {Profile}
     * @memberof CreateAccountRequest
     */
    profile?: Profile;
    /**
     * Token generated by captcha service once user successfully completes captcha
     * @type {string}
     * @memberof CreateAccountRequest
     */
    captchaVerificationToken?: string;
    /**
     * Optional version of the TOS that the user has acknowledged during the sign-up flow. If null, the system assumes that the user has not acknowledged any TOS during sign-up.
     * @type {number}
     * @memberof CreateAccountRequest
     */
    termsOfServiceVersion?: number;
}

/**
 * Check if a given object implements the CreateAccountRequest interface.
 */
export function instanceOfCreateAccountRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateAccountRequestFromJSON(json: any): CreateAccountRequest {
    return CreateAccountRequestFromJSONTyped(json, false);
}

export function CreateAccountRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAccountRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'profile': !exists(json, 'profile') ? undefined : ProfileFromJSON(json['profile']),
        'captchaVerificationToken': !exists(json, 'captchaVerificationToken') ? undefined : json['captchaVerificationToken'],
        'termsOfServiceVersion': !exists(json, 'termsOfServiceVersion') ? undefined : json['termsOfServiceVersion'],
    };
}

export function CreateAccountRequestToJSON(value?: CreateAccountRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'profile': ProfileToJSON(value.profile),
        'captchaVerificationToken': value.captchaVerificationToken,
        'termsOfServiceVersion': value.termsOfServiceVersion,
    };
}

