/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BinaryOperator } from './BinaryOperator';
import {
    BinaryOperatorFromJSON,
    BinaryOperatorFromJSONTyped,
    BinaryOperatorToJSON,
} from './BinaryOperator';
import type { Filter } from './Filter';
import {
    FilterFromJSON,
    FilterFromJSONTyped,
    FilterToJSON,
} from './Filter';

/**
 * Filter a group entity with any related items entities using a group-items entity group.
 * (e.g. brand with at least one ingredient, person with at least one blood pressure reading).
 * @export
 * @interface GroupHasItemsFilter
 */
export interface GroupHasItemsFilter {
    /**
     * 
     * @type {string}
     * @memberof GroupHasItemsFilter
     */
    entityGroup: string;
    /**
     * 
     * @type {Filter}
     * @memberof GroupHasItemsFilter
     */
    itemsSubfilter?: Filter | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GroupHasItemsFilter
     */
    groupByCountAttributes?: Array<string>;
    /**
     * 
     * @type {BinaryOperator}
     * @memberof GroupHasItemsFilter
     */
    groupByCountOperator?: BinaryOperator;
    /**
     * 
     * @type {number}
     * @memberof GroupHasItemsFilter
     */
    groupByCountValue?: number;
}

/**
 * Check if a given object implements the GroupHasItemsFilter interface.
 */
export function instanceOfGroupHasItemsFilter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entityGroup" in value;

    return isInstance;
}

export function GroupHasItemsFilterFromJSON(json: any): GroupHasItemsFilter {
    return GroupHasItemsFilterFromJSONTyped(json, false);
}

export function GroupHasItemsFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupHasItemsFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityGroup': json['entityGroup'],
        'itemsSubfilter': !exists(json, 'itemsSubfilter') ? undefined : FilterFromJSON(json['itemsSubfilter']),
        'groupByCountAttributes': !exists(json, 'groupByCountAttributes') ? undefined : json['groupByCountAttributes'],
        'groupByCountOperator': !exists(json, 'groupByCountOperator') ? undefined : BinaryOperatorFromJSON(json['groupByCountOperator']),
        'groupByCountValue': !exists(json, 'groupByCountValue') ? undefined : json['groupByCountValue'],
    };
}

export function GroupHasItemsFilterToJSON(value?: GroupHasItemsFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entityGroup': value.entityGroup,
        'itemsSubfilter': FilterToJSON(value.itemsSubfilter),
        'groupByCountAttributes': value.groupByCountAttributes,
        'groupByCountOperator': BinaryOperatorToJSON(value.groupByCountOperator),
        'groupByCountValue': value.groupByCountValue,
    };
}

