/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SurveyVersion
 */
export interface SurveyVersion {
    /**
     * unique concept id of the cope survey
     * @type {number}
     * @memberof SurveyVersion
     */
    surveyVersionConceptId: number;
    /**
     * name of the version
     * @type {string}
     * @memberof SurveyVersion
     */
    displayName: string;
    /**
     * item count
     * @type {number}
     * @memberof SurveyVersion
     */
    itemCount: number;
}

/**
 * Check if a given object implements the SurveyVersion interface.
 */
export function instanceOfSurveyVersion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "surveyVersionConceptId" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "itemCount" in value;

    return isInstance;
}

export function SurveyVersionFromJSON(json: any): SurveyVersion {
    return SurveyVersionFromJSONTyped(json, false);
}

export function SurveyVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyVersion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'surveyVersionConceptId': json['surveyVersionConceptId'],
        'displayName': json['displayName'],
        'itemCount': json['itemCount'],
    };
}

export function SurveyVersionToJSON(value?: SurveyVersion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'surveyVersionConceptId': value.surveyVersionConceptId,
        'displayName': value.displayName,
        'itemCount': value.itemCount,
    };
}

