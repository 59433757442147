/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EmptyResponse,
  ErrorResponse,
  Runtime,
  RuntimeLocalizeRequest,
  RuntimeLocalizeResponse,
  UpdateRuntimeRequest,
} from '../models';
import {
    EmptyResponseFromJSON,
    EmptyResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    RuntimeFromJSON,
    RuntimeToJSON,
    RuntimeLocalizeRequestFromJSON,
    RuntimeLocalizeRequestToJSON,
    RuntimeLocalizeResponseFromJSON,
    RuntimeLocalizeResponseToJSON,
    UpdateRuntimeRequestFromJSON,
    UpdateRuntimeRequestToJSON,
} from '../models';

export interface RuntimeApiCreateRuntimeRequest {
    workspaceNamespace: string;
    runtime: Runtime;
}

export interface RuntimeApiDeleteRuntimeRequest {
    workspaceNamespace: string;
    deleteDisk?: boolean;
}

export interface RuntimeApiGetRuntimeRequest {
    workspaceNamespace: string;
}

export interface RuntimeApiLocalizeRequest {
    workspaceNamespace: string;
    localizeAllFiles?: boolean;
    body?: RuntimeLocalizeRequest;
}

export interface RuntimeApiUpdateRuntimeOperationRequest {
    workspaceNamespace: string;
    runtime?: UpdateRuntimeRequest;
}

/**
 * 
 */
export class RuntimeApi extends runtime.BaseAPI {

    /**
     * Creates a new runtime for the current user in the given billing project. If a runtime already exists for the user in this billing project, a 409 conflict error is returned (even if the runtime is still initializing or is not in a ready state). TODO(RW-3697): Custom runtime creation params should be added as a body param here. 
     * Create a workspace runtime.
     */
    async createRuntimeRaw(requestParameters: RuntimeApiCreateRuntimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling createRuntime.');
        }

        if (requestParameters.runtime === null || requestParameters.runtime === undefined) {
            throw new runtime.RequiredError('runtime','Required parameter requestParameters.runtime was null or undefined when calling createRuntime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/runtimes/{workspaceNamespace}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RuntimeToJSON(requestParameters.runtime),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Creates a new runtime for the current user in the given billing project. If a runtime already exists for the user in this billing project, a 409 conflict error is returned (even if the runtime is still initializing or is not in a ready state). TODO(RW-3697): Custom runtime creation params should be added as a body param here. 
     * Create a workspace runtime.
     */
    async createRuntime(workspaceNamespace: string, runtime: Runtime, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.createRuntimeRaw({ workspaceNamespace: workspaceNamespace, runtime: runtime }, initOverrides);
        return await response.value();
    }

    /**
     * TODO(RW-3695): updateRuntime should be added as a PATCH endpoint here.
     * Delete a workspace runtime.
     */
    async deleteRuntimeRaw(requestParameters: RuntimeApiDeleteRuntimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling deleteRuntime.');
        }

        const queryParameters: any = {};

        if (requestParameters.deleteDisk !== undefined) {
            queryParameters['deleteDisk'] = requestParameters.deleteDisk;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/runtimes/{workspaceNamespace}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * TODO(RW-3695): updateRuntime should be added as a PATCH endpoint here.
     * Delete a workspace runtime.
     */
    async deleteRuntime(workspaceNamespace: string, deleteDisk?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.deleteRuntimeRaw({ workspaceNamespace: workspaceNamespace, deleteDisk: deleteDisk }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the current user\'s runtime, if any, which has been created for the given workspace.
     * Get the user\'s workspace runtime.
     */
    async getRuntimeRaw(requestParameters: RuntimeApiGetRuntimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Runtime>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getRuntime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/runtimes/{workspaceNamespace}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuntimeFromJSON(jsonValue));
    }

    /**
     * Returns the current user\'s runtime, if any, which has been created for the given workspace.
     * Get the user\'s workspace runtime.
     */
    async getRuntime(workspaceNamespace: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Runtime> {
        const response = await this.getRuntimeRaw({ workspaceNamespace: workspaceNamespace }, initOverrides);
        return await response.value();
    }

    /**
     * Localizes files to the runtime for the current user and given workspace. As a side-effect, JSON workspace environment files will also be localized to the runtime.
     * Localize files to the user\'s runtime.
     */
    async localizeRaw(requestParameters: RuntimeApiLocalizeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuntimeLocalizeResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling localize.');
        }

        const queryParameters: any = {};

        if (requestParameters.localizeAllFiles !== undefined) {
            queryParameters['localizeAllFiles'] = requestParameters.localizeAllFiles;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/runtimes/{workspaceNamespace}/localize`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RuntimeLocalizeRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuntimeLocalizeResponseFromJSON(jsonValue));
    }

    /**
     * Localizes files to the runtime for the current user and given workspace. As a side-effect, JSON workspace environment files will also be localized to the runtime.
     * Localize files to the user\'s runtime.
     */
    async localize(workspaceNamespace: string, localizeAllFiles?: boolean, body?: RuntimeLocalizeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuntimeLocalizeResponse> {
        const response = await this.localizeRaw({ workspaceNamespace: workspaceNamespace, localizeAllFiles: localizeAllFiles, body: body }, initOverrides);
        return await response.value();
    }

    /**
     * In order to update the configuration of a runtime, it must first be running or paused
     * Updates the configuration of a runtime
     */
    async updateRuntimeRaw(requestParameters: RuntimeApiUpdateRuntimeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling updateRuntime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/runtimes/{workspaceNamespace}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRuntimeRequestToJSON(requestParameters.runtime),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * In order to update the configuration of a runtime, it must first be running or paused
     * Updates the configuration of a runtime
     */
    async updateRuntime(workspaceNamespace: string, runtime?: UpdateRuntimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.updateRuntimeRaw({ workspaceNamespace: workspaceNamespace, runtime: runtime }, initOverrides);
        return await response.value();
    }

}
