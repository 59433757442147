/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorReport,
  FeatureSet,
  FeatureSetCloneInfo,
  FeatureSetCreateInfo,
  FeatureSetUpdateInfo,
} from '../models';
import {
    ErrorReportFromJSON,
    ErrorReportToJSON,
    FeatureSetFromJSON,
    FeatureSetToJSON,
    FeatureSetCloneInfoFromJSON,
    FeatureSetCloneInfoToJSON,
    FeatureSetCreateInfoFromJSON,
    FeatureSetCreateInfoToJSON,
    FeatureSetUpdateInfoFromJSON,
    FeatureSetUpdateInfoToJSON,
} from '../models';

export interface CloneFeatureSetRequest {
    studyId: string;
    featureSetId: string;
    featureSetCloneInfo: FeatureSetCloneInfo;
}

export interface CreateFeatureSetRequest {
    studyId: string;
    featureSetCreateInfo: FeatureSetCreateInfo;
}

export interface DeleteFeatureSetRequest {
    studyId: string;
    featureSetId: string;
}

export interface GetFeatureSetRequest {
    studyId: string;
    featureSetId: string;
}

export interface ListFeatureSetsRequest {
    studyId: string;
    offset?: number;
    limit?: number;
}

export interface UpdateFeatureSetRequest {
    studyId: string;
    featureSetId: string;
    featureSetUpdateInfo: FeatureSetUpdateInfo;
}

/**
 * 
 */
export class FeatureSetsApi extends runtime.BaseAPI {

    /**
     * Clone an existing feature set
     */
    async cloneFeatureSetRaw(requestParameters: CloneFeatureSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FeatureSet>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling cloneFeatureSet.');
        }

        if (requestParameters.featureSetId === null || requestParameters.featureSetId === undefined) {
            throw new runtime.RequiredError('featureSetId','Required parameter requestParameters.featureSetId was null or undefined when calling cloneFeatureSet.');
        }

        if (requestParameters.featureSetCloneInfo === null || requestParameters.featureSetCloneInfo === undefined) {
            throw new runtime.RequiredError('featureSetCloneInfo','Required parameter requestParameters.featureSetCloneInfo was null or undefined when calling cloneFeatureSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/featureSets/{featureSetId}/clone`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"featureSetId"}}`, encodeURIComponent(String(requestParameters.featureSetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FeatureSetCloneInfoToJSON(requestParameters.featureSetCloneInfo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeatureSetFromJSON(jsonValue));
    }

    /**
     * Clone an existing feature set
     */
    async cloneFeatureSet(requestParameters: CloneFeatureSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FeatureSet> {
        const response = await this.cloneFeatureSetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new feature set
     */
    async createFeatureSetRaw(requestParameters: CreateFeatureSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FeatureSet>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling createFeatureSet.');
        }

        if (requestParameters.featureSetCreateInfo === null || requestParameters.featureSetCreateInfo === undefined) {
            throw new runtime.RequiredError('featureSetCreateInfo','Required parameter requestParameters.featureSetCreateInfo was null or undefined when calling createFeatureSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/featureSets`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FeatureSetCreateInfoToJSON(requestParameters.featureSetCreateInfo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeatureSetFromJSON(jsonValue));
    }

    /**
     * Create a new feature set
     */
    async createFeatureSet(requestParameters: CreateFeatureSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FeatureSet> {
        const response = await this.createFeatureSetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a feature set
     */
    async deleteFeatureSetRaw(requestParameters: DeleteFeatureSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling deleteFeatureSet.');
        }

        if (requestParameters.featureSetId === null || requestParameters.featureSetId === undefined) {
            throw new runtime.RequiredError('featureSetId','Required parameter requestParameters.featureSetId was null or undefined when calling deleteFeatureSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/featureSets/{featureSetId}`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"featureSetId"}}`, encodeURIComponent(String(requestParameters.featureSetId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a feature set
     */
    async deleteFeatureSet(requestParameters: DeleteFeatureSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteFeatureSetRaw(requestParameters, initOverrides);
    }

    /**
     * Get an existing feature set
     */
    async getFeatureSetRaw(requestParameters: GetFeatureSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FeatureSet>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling getFeatureSet.');
        }

        if (requestParameters.featureSetId === null || requestParameters.featureSetId === undefined) {
            throw new runtime.RequiredError('featureSetId','Required parameter requestParameters.featureSetId was null or undefined when calling getFeatureSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/featureSets/{featureSetId}`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"featureSetId"}}`, encodeURIComponent(String(requestParameters.featureSetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeatureSetFromJSON(jsonValue));
    }

    /**
     * Get an existing feature set
     */
    async getFeatureSet(requestParameters: GetFeatureSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FeatureSet> {
        const response = await this.getFeatureSetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all feature sets in a study
     */
    async listFeatureSetsRaw(requestParameters: ListFeatureSetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FeatureSet>>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling listFeatureSets.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/featureSets`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FeatureSetFromJSON));
    }

    /**
     * List all feature sets in a study
     */
    async listFeatureSets(requestParameters: ListFeatureSetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FeatureSet>> {
        const response = await this.listFeatureSetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing feature set
     */
    async updateFeatureSetRaw(requestParameters: UpdateFeatureSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FeatureSet>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling updateFeatureSet.');
        }

        if (requestParameters.featureSetId === null || requestParameters.featureSetId === undefined) {
            throw new runtime.RequiredError('featureSetId','Required parameter requestParameters.featureSetId was null or undefined when calling updateFeatureSet.');
        }

        if (requestParameters.featureSetUpdateInfo === null || requestParameters.featureSetUpdateInfo === undefined) {
            throw new runtime.RequiredError('featureSetUpdateInfo','Required parameter requestParameters.featureSetUpdateInfo was null or undefined when calling updateFeatureSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("authorization", ["openid", "email", "profile"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/studies/{studyId}/featureSets/{featureSetId}`.replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"featureSetId"}}`, encodeURIComponent(String(requestParameters.featureSetId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: FeatureSetUpdateInfoToJSON(requestParameters.featureSetUpdateInfo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeatureSetFromJSON(jsonValue));
    }

    /**
     * Update an existing feature set
     */
    async updateFeatureSet(requestParameters: UpdateFeatureSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FeatureSet> {
        const response = await this.updateFeatureSetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
