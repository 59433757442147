/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CheckEmailRequest,
  CheckEmailResponse,
  ErrorResponse,
  GetInstitutionsResponse,
  GetPublicInstitutionDetailsResponse,
  Institution,
  InstitutionUserInstructions,
} from '../models';
import {
    CheckEmailRequestFromJSON,
    CheckEmailRequestToJSON,
    CheckEmailResponseFromJSON,
    CheckEmailResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    GetInstitutionsResponseFromJSON,
    GetInstitutionsResponseToJSON,
    GetPublicInstitutionDetailsResponseFromJSON,
    GetPublicInstitutionDetailsResponseToJSON,
    InstitutionFromJSON,
    InstitutionToJSON,
    InstitutionUserInstructionsFromJSON,
    InstitutionUserInstructionsToJSON,
} from '../models';

export interface InstitutionApiCheckEmailOperationRequest {
    shortName: string;
    contactEmail: CheckEmailRequest;
}

export interface InstitutionApiCreateInstitutionRequest {
    institutionToCreate: Institution;
}

export interface InstitutionApiDeleteInstitutionRequest {
    shortName: string;
}

export interface InstitutionApiDeleteInstitutionUserInstructionsRequest {
    shortName: string;
}

export interface InstitutionApiGetInstitutionRequest {
    shortName: string;
}

export interface InstitutionApiSetInstitutionUserInstructionsRequest {
    userInstructions?: InstitutionUserInstructions;
}

export interface InstitutionApiUpdateInstitutionRequest {
    shortName: string;
    institutionToUpdate: Institution;
}

/**
 * 
 */
export class InstitutionApi extends runtime.BaseAPI {

    /**
     * Checks whether the given email address is a verified member of an institution.
     */
    async checkEmailRaw(requestParameters: InstitutionApiCheckEmailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckEmailResponse>> {
        if (requestParameters.shortName === null || requestParameters.shortName === undefined) {
            throw new runtime.RequiredError('shortName','Required parameter requestParameters.shortName was null or undefined when calling checkEmail.');
        }

        if (requestParameters.contactEmail === null || requestParameters.contactEmail === undefined) {
            throw new runtime.RequiredError('contactEmail','Required parameter requestParameters.contactEmail was null or undefined when calling checkEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/institutions/{shortName}/checkEmail`.replace(`{${"shortName"}}`, encodeURIComponent(String(requestParameters.shortName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckEmailRequestToJSON(requestParameters.contactEmail),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckEmailResponseFromJSON(jsonValue));
    }

    /**
     * Checks whether the given email address is a verified member of an institution.
     */
    async checkEmail(shortName: string, contactEmail: CheckEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckEmailResponse> {
        const response = await this.checkEmailRaw({ shortName: shortName, contactEmail: contactEmail }, initOverrides);
        return await response.value();
    }

    /**
     * Create an Institution. Requires INSTITUTION_ADMIN authority.
     */
    async createInstitutionRaw(requestParameters: InstitutionApiCreateInstitutionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Institution>> {
        if (requestParameters.institutionToCreate === null || requestParameters.institutionToCreate === undefined) {
            throw new runtime.RequiredError('institutionToCreate','Required parameter requestParameters.institutionToCreate was null or undefined when calling createInstitution.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/institutions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstitutionToJSON(requestParameters.institutionToCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstitutionFromJSON(jsonValue));
    }

    /**
     * Create an Institution. Requires INSTITUTION_ADMIN authority.
     */
    async createInstitution(institutionToCreate: Institution, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Institution> {
        const response = await this.createInstitutionRaw({ institutionToCreate: institutionToCreate }, initOverrides);
        return await response.value();
    }

    /**
     * Deletes the Institution corresponding to the shortName ID. Requires INSTITUTION_ADMIN authority.
     */
    async deleteInstitutionRaw(requestParameters: InstitutionApiDeleteInstitutionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shortName === null || requestParameters.shortName === undefined) {
            throw new runtime.RequiredError('shortName','Required parameter requestParameters.shortName was null or undefined when calling deleteInstitution.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/institutions/{shortName}`.replace(`{${"shortName"}}`, encodeURIComponent(String(requestParameters.shortName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes the Institution corresponding to the shortName ID. Requires INSTITUTION_ADMIN authority.
     */
    async deleteInstitution(shortName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteInstitutionRaw({ shortName: shortName }, initOverrides);
    }

    /**
     * Remove the Institution-specific user instructions to be sent by email after registration. Requires INSTITUTION_ADMIN authority.
     */
    async deleteInstitutionUserInstructionsRaw(requestParameters: InstitutionApiDeleteInstitutionUserInstructionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shortName === null || requestParameters.shortName === undefined) {
            throw new runtime.RequiredError('shortName','Required parameter requestParameters.shortName was null or undefined when calling deleteInstitutionUserInstructions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/institutions/instructionsEmail/{shortName}`.replace(`{${"shortName"}}`, encodeURIComponent(String(requestParameters.shortName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove the Institution-specific user instructions to be sent by email after registration. Requires INSTITUTION_ADMIN authority.
     */
    async deleteInstitutionUserInstructions(shortName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteInstitutionUserInstructionsRaw({ shortName: shortName }, initOverrides);
    }

    /**
     * Gets the Institution corresponding to the shortName ID. Requires INSTITUTION_ADMIN authority.
     */
    async getInstitutionRaw(requestParameters: InstitutionApiGetInstitutionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Institution>> {
        if (requestParameters.shortName === null || requestParameters.shortName === undefined) {
            throw new runtime.RequiredError('shortName','Required parameter requestParameters.shortName was null or undefined when calling getInstitution.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/institutions/{shortName}`.replace(`{${"shortName"}}`, encodeURIComponent(String(requestParameters.shortName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstitutionFromJSON(jsonValue));
    }

    /**
     * Gets the Institution corresponding to the shortName ID. Requires INSTITUTION_ADMIN authority.
     */
    async getInstitution(shortName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Institution> {
        const response = await this.getInstitutionRaw({ shortName: shortName }, initOverrides);
        return await response.value();
    }

    /**
     * Gets the list of all Institutions. Requires INSTITUTION_ADMIN authority.
     */
    async getInstitutionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInstitutionsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/institutions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInstitutionsResponseFromJSON(jsonValue));
    }

    /**
     * Gets the list of all Institutions. Requires INSTITUTION_ADMIN authority.
     */
    async getInstitutions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInstitutionsResponse> {
        const response = await this.getInstitutionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets the public details for the list of all Institutions.
     */
    async getPublicInstitutionDetailsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPublicInstitutionDetailsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/institutions/publicDetails`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPublicInstitutionDetailsResponseFromJSON(jsonValue));
    }

    /**
     * Gets the public details for the list of all Institutions.
     */
    async getPublicInstitutionDetails(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPublicInstitutionDetailsResponse> {
        const response = await this.getPublicInstitutionDetailsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Sets or updates the Institution-specific user instructions to be sent by email after registration. Requires INSTITUTION_ADMIN authority.
     */
    async setInstitutionUserInstructionsRaw(requestParameters: InstitutionApiSetInstitutionUserInstructionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstitutionUserInstructions>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/institutions/instructionsEmail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstitutionUserInstructionsToJSON(requestParameters.userInstructions),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstitutionUserInstructionsFromJSON(jsonValue));
    }

    /**
     * Sets or updates the Institution-specific user instructions to be sent by email after registration. Requires INSTITUTION_ADMIN authority.
     */
    async setInstitutionUserInstructions(userInstructions?: InstitutionUserInstructions, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstitutionUserInstructions> {
        const response = await this.setInstitutionUserInstructionsRaw({ userInstructions: userInstructions }, initOverrides);
        return await response.value();
    }

    /**
     * Updates an Institution. Requires INSTITUTION_ADMIN authority.
     */
    async updateInstitutionRaw(requestParameters: InstitutionApiUpdateInstitutionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Institution>> {
        if (requestParameters.shortName === null || requestParameters.shortName === undefined) {
            throw new runtime.RequiredError('shortName','Required parameter requestParameters.shortName was null or undefined when calling updateInstitution.');
        }

        if (requestParameters.institutionToUpdate === null || requestParameters.institutionToUpdate === undefined) {
            throw new runtime.RequiredError('institutionToUpdate','Required parameter requestParameters.institutionToUpdate was null or undefined when calling updateInstitution.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/institutions/{shortName}`.replace(`{${"shortName"}}`, encodeURIComponent(String(requestParameters.shortName))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: InstitutionToJSON(requestParameters.institutionToUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstitutionFromJSON(jsonValue));
    }

    /**
     * Updates an Institution. Requires INSTITUTION_ADMIN authority.
     */
    async updateInstitution(shortName: string, institutionToUpdate: Institution, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Institution> {
        const response = await this.updateInstitutionRaw({ shortName: shortName, institutionToUpdate: institutionToUpdate }, initOverrides);
        return await response.value();
    }

}
