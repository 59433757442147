/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BinaryOperator } from './BinaryOperator';
import {
    BinaryOperatorFromJSON,
    BinaryOperatorFromJSONTyped,
    BinaryOperatorToJSON,
} from './BinaryOperator';
import type { Filter } from './Filter';
import {
    FilterFromJSON,
    FilterFromJSONTyped,
    FilterToJSON,
} from './Filter';
import type { PrimaryWithCriteriaFilterOccurrenceSubfiltersAndGroupByAttributesValue } from './PrimaryWithCriteriaFilterOccurrenceSubfiltersAndGroupByAttributesValue';
import {
    PrimaryWithCriteriaFilterOccurrenceSubfiltersAndGroupByAttributesValueFromJSON,
    PrimaryWithCriteriaFilterOccurrenceSubfiltersAndGroupByAttributesValueFromJSONTyped,
    PrimaryWithCriteriaFilterOccurrenceSubfiltersAndGroupByAttributesValueToJSON,
} from './PrimaryWithCriteriaFilterOccurrenceSubfiltersAndGroupByAttributesValue';

/**
 * Filter the primary entity with a criteria-occurrence entity group (e.g. people where 
 * condition=diabetes and visit_type=inpatient).
 * If the groupByCountOperator is set, then the groupByCountValue must also be set.
 * The groupByCountAttributes are optional. If undefined, we group by the id only.
 * When group by fields are set, we filter on the number of related occurrence instances.
 * e.g. filter person instances with >2 related condition_occurrence instances
 * @export
 * @interface PrimaryWithCriteriaFilter
 */
export interface PrimaryWithCriteriaFilter {
    /**
     * 
     * @type {string}
     * @memberof PrimaryWithCriteriaFilter
     */
    entityGroup: string;
    /**
     * 
     * @type {Filter}
     * @memberof PrimaryWithCriteriaFilter
     */
    criteriaSubfilter?: Filter | null;
    /**
     * Set of sub-filter and group by attributes per occurrence entity.
     * Map key is the occurrence entity name.
     * @type {{ [key: string]: PrimaryWithCriteriaFilterOccurrenceSubfiltersAndGroupByAttributesValue; }}
     * @memberof PrimaryWithCriteriaFilter
     */
    occurrenceSubfiltersAndGroupByAttributes?: { [key: string]: PrimaryWithCriteriaFilterOccurrenceSubfiltersAndGroupByAttributesValue; };
    /**
     * 
     * @type {BinaryOperator}
     * @memberof PrimaryWithCriteriaFilter
     */
    groupByCountOperator?: BinaryOperator;
    /**
     * 
     * @type {number}
     * @memberof PrimaryWithCriteriaFilter
     */
    groupByCountValue?: number;
}

/**
 * Check if a given object implements the PrimaryWithCriteriaFilter interface.
 */
export function instanceOfPrimaryWithCriteriaFilter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entityGroup" in value;

    return isInstance;
}

export function PrimaryWithCriteriaFilterFromJSON(json: any): PrimaryWithCriteriaFilter {
    return PrimaryWithCriteriaFilterFromJSONTyped(json, false);
}

export function PrimaryWithCriteriaFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrimaryWithCriteriaFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityGroup': json['entityGroup'],
        'criteriaSubfilter': !exists(json, 'criteriaSubfilter') ? undefined : FilterFromJSON(json['criteriaSubfilter']),
        'occurrenceSubfiltersAndGroupByAttributes': !exists(json, 'occurrenceSubfiltersAndGroupByAttributes') ? undefined : (mapValues(json['occurrenceSubfiltersAndGroupByAttributes'], PrimaryWithCriteriaFilterOccurrenceSubfiltersAndGroupByAttributesValueFromJSON)),
        'groupByCountOperator': !exists(json, 'groupByCountOperator') ? undefined : BinaryOperatorFromJSON(json['groupByCountOperator']),
        'groupByCountValue': !exists(json, 'groupByCountValue') ? undefined : json['groupByCountValue'],
    };
}

export function PrimaryWithCriteriaFilterToJSON(value?: PrimaryWithCriteriaFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entityGroup': value.entityGroup,
        'criteriaSubfilter': FilterToJSON(value.criteriaSubfilter),
        'occurrenceSubfiltersAndGroupByAttributes': value.occurrenceSubfiltersAndGroupByAttributes === undefined ? undefined : (mapValues(value.occurrenceSubfiltersAndGroupByAttributes, PrimaryWithCriteriaFilterOccurrenceSubfiltersAndGroupByAttributesValueToJSON)),
        'groupByCountOperator': BinaryOperatorToJSON(value.groupByCountOperator),
        'groupByCountValue': value.groupByCountValue,
    };
}

