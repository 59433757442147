/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConceptIdName } from './ConceptIdName';
import {
    ConceptIdNameFromJSON,
    ConceptIdNameFromJSONTyped,
    ConceptIdNameToJSON,
} from './ConceptIdName';

/**
 * 
 * @export
 * @interface ParticipantDemographics
 */
export interface ParticipantDemographics {
    /**
     * 
     * @type {Array<ConceptIdName>}
     * @memberof ParticipantDemographics
     */
    genderList: Array<ConceptIdName>;
    /**
     * 
     * @type {Array<ConceptIdName>}
     * @memberof ParticipantDemographics
     */
    raceList: Array<ConceptIdName>;
    /**
     * 
     * @type {Array<ConceptIdName>}
     * @memberof ParticipantDemographics
     */
    ethnicityList: Array<ConceptIdName>;
    /**
     * 
     * @type {Array<ConceptIdName>}
     * @memberof ParticipantDemographics
     */
    sexAtBirthList: Array<ConceptIdName>;
}

/**
 * Check if a given object implements the ParticipantDemographics interface.
 */
export function instanceOfParticipantDemographics(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "genderList" in value;
    isInstance = isInstance && "raceList" in value;
    isInstance = isInstance && "ethnicityList" in value;
    isInstance = isInstance && "sexAtBirthList" in value;

    return isInstance;
}

export function ParticipantDemographicsFromJSON(json: any): ParticipantDemographics {
    return ParticipantDemographicsFromJSONTyped(json, false);
}

export function ParticipantDemographicsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantDemographics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'genderList': ((json['genderList'] as Array<any>).map(ConceptIdNameFromJSON)),
        'raceList': ((json['raceList'] as Array<any>).map(ConceptIdNameFromJSON)),
        'ethnicityList': ((json['ethnicityList'] as Array<any>).map(ConceptIdNameFromJSON)),
        'sexAtBirthList': ((json['sexAtBirthList'] as Array<any>).map(ConceptIdNameFromJSON)),
    };
}

export function ParticipantDemographicsToJSON(value?: ParticipantDemographics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'genderList': ((value.genderList as Array<any>).map(ConceptIdNameToJSON)),
        'raceList': ((value.raceList as Array<any>).map(ConceptIdNameToJSON)),
        'ethnicityList': ((value.ethnicityList as Array<any>).map(ConceptIdNameToJSON)),
        'sexAtBirthList': ((value.sexAtBirthList as Array<any>).map(ConceptIdNameToJSON)),
    };
}

