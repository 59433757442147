/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Domain } from './Domain';
import {
    DomainFromJSON,
    DomainFromJSONTyped,
    DomainToJSON,
} from './Domain';
import type { FilterColumns } from './FilterColumns';
import {
    FilterColumnsFromJSON,
    FilterColumnsFromJSONTyped,
    FilterColumnsToJSON,
} from './FilterColumns';
import type { FilterList } from './FilterList';
import {
    FilterListFromJSON,
    FilterListFromJSONTyped,
    FilterListToJSON,
} from './FilterList';
import type { SortOrder } from './SortOrder';
import {
    SortOrderFromJSON,
    SortOrderFromJSONTyped,
    SortOrderToJSON,
} from './SortOrder';

/**
 * 
 * @export
 * @interface PageFilterRequest
 */
export interface PageFilterRequest {
    /**
     * specific page (default is 0)
     * @type {number}
     * @memberof PageFilterRequest
     */
    page: number;
    /**
     * page size of results (default is 25)
     * @type {number}
     * @memberof PageFilterRequest
     */
    pageSize: number;
    /**
     * 
     * @type {SortOrder}
     * @memberof PageFilterRequest
     */
    sortOrder: SortOrder;
    /**
     * 
     * @type {FilterColumns}
     * @memberof PageFilterRequest
     */
    sortColumn?: FilterColumns;
    /**
     * 
     * @type {FilterList}
     * @memberof PageFilterRequest
     */
    filters?: FilterList;
    /**
     * 
     * @type {Domain}
     * @memberof PageFilterRequest
     */
    domain?: Domain;
}

/**
 * Check if a given object implements the PageFilterRequest interface.
 */
export function instanceOfPageFilterRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "pageSize" in value;
    isInstance = isInstance && "sortOrder" in value;

    return isInstance;
}

export function PageFilterRequestFromJSON(json: any): PageFilterRequest {
    return PageFilterRequestFromJSONTyped(json, false);
}

export function PageFilterRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageFilterRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': json['page'],
        'pageSize': json['pageSize'],
        'sortOrder': SortOrderFromJSON(json['sortOrder']),
        'sortColumn': !exists(json, 'sortColumn') ? undefined : FilterColumnsFromJSON(json['sortColumn']),
        'filters': !exists(json, 'filters') ? undefined : FilterListFromJSON(json['filters']),
        'domain': !exists(json, 'domain') ? undefined : DomainFromJSON(json['domain']),
    };
}

export function PageFilterRequestToJSON(value?: PageFilterRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'pageSize': value.pageSize,
        'sortOrder': SortOrderToJSON(value.sortOrder),
        'sortColumn': FilterColumnsToJSON(value.sortColumn),
        'filters': FilterListToJSON(value.filters),
        'domain': DomainToJSON(value.domain),
    };
}

