/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserAppEnvironment } from './UserAppEnvironment';
import {
    UserAppEnvironmentFromJSON,
    UserAppEnvironmentFromJSONTyped,
    UserAppEnvironmentToJSON,
} from './UserAppEnvironment';

/**
 * 
 * @export
 * @interface ListAppsResponse
 */
export interface ListAppsResponse extends Array<UserAppEnvironment> {
}

/**
 * Check if a given object implements the ListAppsResponse interface.
 */
export function instanceOfListAppsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListAppsResponseFromJSON(json: any): ListAppsResponse {
    return ListAppsResponseFromJSONTyped(json, false);
}

export function ListAppsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAppsResponse {
    return json;
}

export function ListAppsResponseToJSON(value?: ListAppsResponse | null): any {
    return value;
}

