/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Cohort,
  CohortListResponse,
  DuplicateCohortRequest,
  EmptyResponse,
} from '../models';
import {
    CohortFromJSON,
    CohortToJSON,
    CohortListResponseFromJSON,
    CohortListResponseToJSON,
    DuplicateCohortRequestFromJSON,
    DuplicateCohortRequestToJSON,
    EmptyResponseFromJSON,
    EmptyResponseToJSON,
} from '../models';

export interface CohortsApiCreateCohortRequest {
    workspaceNamespace: string;
    terraName: string;
    cohort?: Cohort;
}

export interface CohortsApiDeleteCohortRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortId: number;
}

export interface CohortsApiDuplicateCohortOperationRequest {
    workspaceNamespace: string;
    terraName: string;
    duplicateCohortRequest?: DuplicateCohortRequest;
}

export interface CohortsApiGetCohortRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortId: number;
}

export interface CohortsApiGetCohortsInWorkspaceRequest {
    workspaceNamespace: string;
    terraName: string;
}

export interface CohortsApiUpdateCohortRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortId: number;
    cohort?: Cohort;
}

/**
 * 
 */
export class CohortsApi extends runtime.BaseAPI {

    /**
     * Creates a cohort definition in a workspace.
     */
    async createCohortRaw(requestParameters: CohortsApiCreateCohortRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Cohort>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling createCohort.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling createCohort.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/cohorts`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CohortToJSON(requestParameters.cohort),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CohortFromJSON(jsonValue));
    }

    /**
     * Creates a cohort definition in a workspace.
     */
    async createCohort(workspaceNamespace: string, terraName: string, cohort?: Cohort, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Cohort> {
        const response = await this.createCohortRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohort: cohort }, initOverrides);
        return await response.value();
    }

    /**
     * Deletes the cohort definition with the specified ID
     */
    async deleteCohortRaw(requestParameters: CohortsApiDeleteCohortRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling deleteCohort.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling deleteCohort.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling deleteCohort.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/cohorts/{cohortId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Deletes the cohort definition with the specified ID
     */
    async deleteCohort(workspaceNamespace: string, terraName: string, cohortId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.deleteCohortRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortId: cohortId }, initOverrides);
        return await response.value();
    }

    /**
     * Creates a duplicate of the cohort specified by cohortId
     */
    async duplicateCohortRaw(requestParameters: CohortsApiDuplicateCohortOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Cohort>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling duplicateCohort.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling duplicateCohort.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/cohorts/duplicate`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DuplicateCohortRequestToJSON(requestParameters.duplicateCohortRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CohortFromJSON(jsonValue));
    }

    /**
     * Creates a duplicate of the cohort specified by cohortId
     */
    async duplicateCohort(workspaceNamespace: string, terraName: string, duplicateCohortRequest?: DuplicateCohortRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Cohort> {
        const response = await this.duplicateCohortRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, duplicateCohortRequest: duplicateCohortRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the cohort definition with the specified ID
     */
    async getCohortRaw(requestParameters: CohortsApiGetCohortRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Cohort>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getCohort.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getCohort.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling getCohort.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/cohorts/{cohortId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CohortFromJSON(jsonValue));
    }

    /**
     * Returns the cohort definition with the specified ID
     */
    async getCohort(workspaceNamespace: string, terraName: string, cohortId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Cohort> {
        const response = await this.getCohortRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortId: cohortId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns all cohort definitions in a workspace
     */
    async getCohortsInWorkspaceRaw(requestParameters: CohortsApiGetCohortsInWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CohortListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getCohortsInWorkspace.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getCohortsInWorkspace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/cohorts`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CohortListResponseFromJSON(jsonValue));
    }

    /**
     * Returns all cohort definitions in a workspace
     */
    async getCohortsInWorkspace(workspaceNamespace: string, terraName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CohortListResponse> {
        const response = await this.getCohortsInWorkspaceRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName }, initOverrides);
        return await response.value();
    }

    /**
     * Modifies the cohort definition with the specified ID; fields that are omitted will not be modified
     */
    async updateCohortRaw(requestParameters: CohortsApiUpdateCohortRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Cohort>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling updateCohort.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling updateCohort.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling updateCohort.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/cohorts/{cohortId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CohortToJSON(requestParameters.cohort),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CohortFromJSON(jsonValue));
    }

    /**
     * Modifies the cohort definition with the specified ID; fields that are omitted will not be modified
     */
    async updateCohort(workspaceNamespace: string, terraName: string, cohortId: number, cohort?: Cohort, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Cohort> {
        const response = await this.updateCohortRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortId: cohortId, cohort: cohort }, initOverrides);
        return await response.value();
    }

}
