/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EgressEventStatus } from './EgressEventStatus';
import {
    EgressEventStatusFromJSON,
    EgressEventStatusFromJSONTyped,
    EgressEventStatusToJSON,
} from './EgressEventStatus';

/**
 * 
 * @export
 * @interface EgressEvent
 */
export interface EgressEvent {
    /**
     * Unique identifier for this egress event.
     * @type {string}
     * @memberof EgressEvent
     */
    egressEventId?: string;
    /**
     * Researcher email for the user associated with this event, if known.
     * @type {string}
     * @memberof EgressEvent
     */
    sourceUserEmail?: string;
    /**
     * Workspace in which the event fired, if known.
     * @type {string}
     * @memberof EgressEvent
     */
    sourceWorkspaceNamespace?: string;
    /**
     * Google project in which the event fired, if known.
     * @type {string}
     * @memberof EgressEvent
     */
    sourceGoogleProject?: string;
    /**
     * Volume of egress which triggered the alert, in Megabytes.
     * @type {number}
     * @memberof EgressEvent
     */
    egressMegabytes?: number;
    /**
     * Window duration over which the egress was detected, in seconds.
     * @type {number}
     * @memberof EgressEvent
     */
    egressWindowSeconds?: number;
    /**
     * Time at which the egress event was recorded in the RW DB, in ISO 8601 format.  This is NOT the event time itself.
     * @type {string}
     * @memberof EgressEvent
     */
    creationTime?: string;
    /**
     * The beginning of the time window in which egress was detected, in epoch milliseconds.
     * @type {number}
     * @memberof EgressEvent
     */
    timeWindowStartEpochMillis?: number;
    /**
     * The end of the time window in which egress was detected, in epoch milliseconds.
     * @type {number}
     * @memberof EgressEvent
     */
    timeWindowEndEpochMillis?: number;
    /**
     * 
     * @type {EgressEventStatus}
     * @memberof EgressEvent
     */
    status?: EgressEventStatus;
}

/**
 * Check if a given object implements the EgressEvent interface.
 */
export function instanceOfEgressEvent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EgressEventFromJSON(json: any): EgressEvent {
    return EgressEventFromJSONTyped(json, false);
}

export function EgressEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): EgressEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'egressEventId': !exists(json, 'egressEventId') ? undefined : json['egressEventId'],
        'sourceUserEmail': !exists(json, 'sourceUserEmail') ? undefined : json['sourceUserEmail'],
        'sourceWorkspaceNamespace': !exists(json, 'sourceWorkspaceNamespace') ? undefined : json['sourceWorkspaceNamespace'],
        'sourceGoogleProject': !exists(json, 'sourceGoogleProject') ? undefined : json['sourceGoogleProject'],
        'egressMegabytes': !exists(json, 'egressMegabytes') ? undefined : json['egressMegabytes'],
        'egressWindowSeconds': !exists(json, 'egressWindowSeconds') ? undefined : json['egressWindowSeconds'],
        'creationTime': !exists(json, 'creationTime') ? undefined : json['creationTime'],
        'timeWindowStartEpochMillis': !exists(json, 'timeWindowStartEpochMillis') ? undefined : json['timeWindowStartEpochMillis'],
        'timeWindowEndEpochMillis': !exists(json, 'timeWindowEndEpochMillis') ? undefined : json['timeWindowEndEpochMillis'],
        'status': !exists(json, 'status') ? undefined : EgressEventStatusFromJSON(json['status']),
    };
}

export function EgressEventToJSON(value?: EgressEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'egressEventId': value.egressEventId,
        'sourceUserEmail': value.sourceUserEmail,
        'sourceWorkspaceNamespace': value.sourceWorkspaceNamespace,
        'sourceGoogleProject': value.sourceGoogleProject,
        'egressMegabytes': value.egressMegabytes,
        'egressWindowSeconds': value.egressWindowSeconds,
        'creationTime': value.creationTime,
        'timeWindowStartEpochMillis': value.timeWindowStartEpochMillis,
        'timeWindowEndEpochMillis': value.timeWindowEndEpochMillis,
        'status': EgressEventStatusToJSON(value.status),
    };
}

