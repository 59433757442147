/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CdrVersion } from './CdrVersion';
import {
    CdrVersionFromJSON,
    CdrVersionFromJSONTyped,
    CdrVersionToJSON,
} from './CdrVersion';

/**
 * 
 * @export
 * @interface CdrVersionTier
 */
export interface CdrVersionTier {
    /**
     * 
     * @type {string}
     * @memberof CdrVersionTier
     */
    accessTierShortName: string;
    /**
     * 
     * @type {string}
     * @memberof CdrVersionTier
     */
    accessTierDisplayName: string;
    /**
     * 
     * @type {string}
     * @memberof CdrVersionTier
     */
    defaultCdrVersionId: string;
    /**
     * Milliseconds since the UNIX epoch. This timestamp is derived from the deid dataset name. Ex - deid_output_20191004 -> 2019-10-04 00:00:00Z
     * @type {number}
     * @memberof CdrVersionTier
     */
    defaultCdrVersionCreationTime: number;
    /**
     * 
     * @type {Array<CdrVersion>}
     * @memberof CdrVersionTier
     */
    versions: Array<CdrVersion>;
}

/**
 * Check if a given object implements the CdrVersionTier interface.
 */
export function instanceOfCdrVersionTier(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accessTierShortName" in value;
    isInstance = isInstance && "accessTierDisplayName" in value;
    isInstance = isInstance && "defaultCdrVersionId" in value;
    isInstance = isInstance && "defaultCdrVersionCreationTime" in value;
    isInstance = isInstance && "versions" in value;

    return isInstance;
}

export function CdrVersionTierFromJSON(json: any): CdrVersionTier {
    return CdrVersionTierFromJSONTyped(json, false);
}

export function CdrVersionTierFromJSONTyped(json: any, ignoreDiscriminator: boolean): CdrVersionTier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessTierShortName': json['accessTierShortName'],
        'accessTierDisplayName': json['accessTierDisplayName'],
        'defaultCdrVersionId': json['defaultCdrVersionId'],
        'defaultCdrVersionCreationTime': json['defaultCdrVersionCreationTime'],
        'versions': ((json['versions'] as Array<any>).map(CdrVersionFromJSON)),
    };
}

export function CdrVersionTierToJSON(value?: CdrVersionTier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessTierShortName': value.accessTierShortName,
        'accessTierDisplayName': value.accessTierDisplayName,
        'defaultCdrVersionId': value.defaultCdrVersionId,
        'defaultCdrVersionCreationTime': value.defaultCdrVersionCreationTime,
        'versions': ((value.versions as Array<any>).map(CdrVersionToJSON)),
    };
}

