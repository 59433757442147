/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WorkspaceAccessLevel } from './WorkspaceAccessLevel';
import {
    WorkspaceAccessLevelFromJSON,
    WorkspaceAccessLevelFromJSONTyped,
    WorkspaceAccessLevelToJSON,
} from './WorkspaceAccessLevel';

/**
 * 
 * @export
 * @interface UserRole
 */
export interface UserRole {
    /**
     * Username of the user
     * @type {string}
     * @memberof UserRole
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    givenName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    familyName?: string;
    /**
     * 
     * @type {WorkspaceAccessLevel}
     * @memberof UserRole
     */
    role: WorkspaceAccessLevel;
}

/**
 * Check if a given object implements the UserRole interface.
 */
export function instanceOfUserRole(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function UserRoleFromJSON(json: any): UserRole {
    return UserRoleFromJSONTyped(json, false);
}

export function UserRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'givenName': !exists(json, 'givenName') ? undefined : json['givenName'],
        'familyName': !exists(json, 'familyName') ? undefined : json['familyName'],
        'role': WorkspaceAccessLevelFromJSON(json['role']),
    };
}

export function UserRoleToJSON(value?: UserRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'givenName': value.givenName,
        'familyName': value.familyName,
        'role': WorkspaceAccessLevelToJSON(value.role),
    };
}

