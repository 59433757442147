/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * represents gender, sex at birth, race or ethnicity
 * @export
 */
export const GenderSexRaceOrEthType = {
    GENDER: 'GENDER',
    SEX_AT_BIRTH: 'SEX_AT_BIRTH',
    RACE: 'RACE',
    ETHNICITY: 'ETHNICITY',
    SELF_REPORTED_CATEGORY: 'SELF_REPORTED_CATEGORY'
} as const;
export type GenderSexRaceOrEthType = typeof GenderSexRaceOrEthType[keyof typeof GenderSexRaceOrEthType];


export function GenderSexRaceOrEthTypeFromJSON(json: any): GenderSexRaceOrEthType {
    return GenderSexRaceOrEthTypeFromJSONTyped(json, false);
}

export function GenderSexRaceOrEthTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenderSexRaceOrEthType {
    return json as GenderSexRaceOrEthType;
}

export function GenderSexRaceOrEthTypeToJSON(value?: GenderSexRaceOrEthType | null): any {
    return value as any;
}

