/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateNewUserSatisfactionSurvey,
  CreateNewUserSatisfactionSurveyWithOneTimeCode,
} from '../models';
import {
    CreateNewUserSatisfactionSurveyFromJSON,
    CreateNewUserSatisfactionSurveyToJSON,
    CreateNewUserSatisfactionSurveyWithOneTimeCodeFromJSON,
    CreateNewUserSatisfactionSurveyWithOneTimeCodeToJSON,
} from '../models';

export interface SurveysApiCreateNewUserSatisfactionSurveyRequest {
    newUserSatisfactionSurvey: CreateNewUserSatisfactionSurvey;
}

export interface SurveysApiCreateNewUserSatisfactionSurveyWithOneTimeCodeRequest {
    newUserSatisfactionSurveyWithOneTimeCode: CreateNewUserSatisfactionSurveyWithOneTimeCode;
}

export interface SurveysApiValidateOneTimeCodeForNewUserSatisfactionSurveyRequest {
    oneTimeCode: string;
}

/**
 * 
 */
export class SurveysApi extends runtime.BaseAPI {

    /**
     * Stores user responses for the new user satisfaction survey.
     */
    async createNewUserSatisfactionSurveyRaw(requestParameters: SurveysApiCreateNewUserSatisfactionSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.newUserSatisfactionSurvey === null || requestParameters.newUserSatisfactionSurvey === undefined) {
            throw new runtime.RequiredError('newUserSatisfactionSurvey','Required parameter requestParameters.newUserSatisfactionSurvey was null or undefined when calling createNewUserSatisfactionSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/surveys/newUserSatisfactionSurvey`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateNewUserSatisfactionSurveyToJSON(requestParameters.newUserSatisfactionSurvey),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Stores user responses for the new user satisfaction survey.
     */
    async createNewUserSatisfactionSurvey(newUserSatisfactionSurvey: CreateNewUserSatisfactionSurvey, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createNewUserSatisfactionSurveyRaw({ newUserSatisfactionSurvey: newUserSatisfactionSurvey }, initOverrides);
    }

    /**
     * Stores user responses for the new user satisfaction survey if they have a valid one-time code.
     */
    async createNewUserSatisfactionSurveyWithOneTimeCodeRaw(requestParameters: SurveysApiCreateNewUserSatisfactionSurveyWithOneTimeCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.newUserSatisfactionSurveyWithOneTimeCode === null || requestParameters.newUserSatisfactionSurveyWithOneTimeCode === undefined) {
            throw new runtime.RequiredError('newUserSatisfactionSurveyWithOneTimeCode','Required parameter requestParameters.newUserSatisfactionSurveyWithOneTimeCode was null or undefined when calling createNewUserSatisfactionSurveyWithOneTimeCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/surveys/newUserSatisfactionSurvey/withOneTimeCode`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateNewUserSatisfactionSurveyWithOneTimeCodeToJSON(requestParameters.newUserSatisfactionSurveyWithOneTimeCode),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Stores user responses for the new user satisfaction survey if they have a valid one-time code.
     */
    async createNewUserSatisfactionSurveyWithOneTimeCode(newUserSatisfactionSurveyWithOneTimeCode: CreateNewUserSatisfactionSurveyWithOneTimeCode, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createNewUserSatisfactionSurveyWithOneTimeCodeRaw({ newUserSatisfactionSurveyWithOneTimeCode: newUserSatisfactionSurveyWithOneTimeCode }, initOverrides);
    }

    /**
     * Checks if a given one-time can be used to create a new user satisfaction survey response.
     */
    async validateOneTimeCodeForNewUserSatisfactionSurveyRaw(requestParameters: SurveysApiValidateOneTimeCodeForNewUserSatisfactionSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.oneTimeCode === null || requestParameters.oneTimeCode === undefined) {
            throw new runtime.RequiredError('oneTimeCode','Required parameter requestParameters.oneTimeCode was null or undefined when calling validateOneTimeCodeForNewUserSatisfactionSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/surveys/newUserSatisfactionSurvey/validateOneTimeCode/{oneTimeCode}`.replace(`{${"oneTimeCode"}}`, encodeURIComponent(String(requestParameters.oneTimeCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Checks if a given one-time can be used to create a new user satisfaction survey response.
     */
    async validateOneTimeCodeForNewUserSatisfactionSurvey(oneTimeCode: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.validateOneTimeCodeForNewUserSatisfactionSurveyRaw({ oneTimeCode: oneTimeCode }, initOverrides);
        return await response.value();
    }

}
