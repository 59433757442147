/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Criteria } from './Criteria';
import {
    CriteriaFromJSON,
    CriteriaFromJSONTyped,
    CriteriaToJSON,
} from './Criteria';
import type { Domain } from './Domain';
import {
    DomainFromJSON,
    DomainFromJSONTyped,
    DomainToJSON,
} from './Domain';
import type { Surveys } from './Surveys';
import {
    SurveysFromJSON,
    SurveysFromJSONTyped,
    SurveysToJSON,
} from './Surveys';

/**
 * 
 * @export
 * @interface ConceptSet
 */
export interface ConceptSet {
    /**
     * 
     * @type {number}
     * @memberof ConceptSet
     */
    id?: number;
    /**
     * Entity tag for optimistic concurrency control. To be set during a read-modify-write to ensure that the client has not attempted to modify a changed resource.
     * @type {string}
     * @memberof ConceptSet
     */
    etag?: string;
    /**
     * 
     * @type {string}
     * @memberof ConceptSet
     */
    name: string;
    /**
     * 
     * @type {Domain}
     * @memberof ConceptSet
     */
    domain?: Domain;
    /**
     * 
     * @type {Surveys}
     * @memberof ConceptSet
     */
    survey?: Surveys;
    /**
     * 
     * @type {string}
     * @memberof ConceptSet
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ConceptSet
     */
    creator?: string;
    /**
     * Milliseconds since the UNIX epoch.
     * @type {number}
     * @memberof ConceptSet
     */
    creationTime?: number;
    /**
     * The user who last modified this resource.
     * @type {string}
     * @memberof ConceptSet
     */
    lastModifiedBy?: string;
    /**
     * Milliseconds since the UNIX epoch.
     * @type {number}
     * @memberof ConceptSet
     */
    lastModifiedTime?: number;
    /**
     * Count of participants in the CDR version for the workspace containing this concept set that match the specified concept set
     * @type {number}
     * @memberof ConceptSet
     */
    participantCount?: number;
    /**
     * Criteriums in the concept set, in conceptName order.
     * @type {Array<Criteria>}
     * @memberof ConceptSet
     */
    criteriums: Array<Criteria>;
}

/**
 * Check if a given object implements the ConceptSet interface.
 */
export function instanceOfConceptSet(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "criteriums" in value;

    return isInstance;
}

export function ConceptSetFromJSON(json: any): ConceptSet {
    return ConceptSetFromJSONTyped(json, false);
}

export function ConceptSetFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConceptSet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'etag': !exists(json, 'etag') ? undefined : json['etag'],
        'name': json['name'],
        'domain': !exists(json, 'domain') ? undefined : DomainFromJSON(json['domain']),
        'survey': !exists(json, 'survey') ? undefined : SurveysFromJSON(json['survey']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'creator': !exists(json, 'creator') ? undefined : json['creator'],
        'creationTime': !exists(json, 'creationTime') ? undefined : json['creationTime'],
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedTime': !exists(json, 'lastModifiedTime') ? undefined : json['lastModifiedTime'],
        'participantCount': !exists(json, 'participantCount') ? undefined : json['participantCount'],
        'criteriums': ((json['criteriums'] as Array<any>).map(CriteriaFromJSON)),
    };
}

export function ConceptSetToJSON(value?: ConceptSet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'etag': value.etag,
        'name': value.name,
        'domain': DomainToJSON(value.domain),
        'survey': SurveysToJSON(value.survey),
        'description': value.description,
        'creator': value.creator,
        'creationTime': value.creationTime,
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedTime': value.lastModifiedTime,
        'participantCount': value.participantCount,
        'criteriums': ((value.criteriums as Array<any>).map(CriteriaToJSON)),
    };
}

