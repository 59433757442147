/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataSetPreviewValueList
 */
export interface DataSetPreviewValueList {
    /**
     * Value selected by user which will act as column header in preview table
     * @type {string}
     * @memberof DataSetPreviewValueList
     */
    value?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataSetPreviewValueList
     */
    queryValue?: Array<string>;
}

/**
 * Check if a given object implements the DataSetPreviewValueList interface.
 */
export function instanceOfDataSetPreviewValueList(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataSetPreviewValueListFromJSON(json: any): DataSetPreviewValueList {
    return DataSetPreviewValueListFromJSONTyped(json, false);
}

export function DataSetPreviewValueListFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSetPreviewValueList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'queryValue': !exists(json, 'queryValue') ? undefined : json['queryValue'],
    };
}

export function DataSetPreviewValueListToJSON(value?: DataSetPreviewValueList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'queryValue': value.queryValue,
    };
}

