/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Name that descibes the type of attribute
 * @export
 */
export const AttrName = {
    ANY: 'ANY',
    NUM: 'NUM',
    CAT: 'CAT',
    AGE: 'AGE',
    AGE_AT_CONSENT: 'AGE_AT_CONSENT',
    AGE_AT_CDR: 'AGE_AT_CDR',
    SURVEY_VERSION_CONCEPT_ID: 'SURVEY_VERSION_CONCEPT_ID',
    PERSONAL_FAMILY_HEALTH_HISTORY: 'PERSONAL_FAMILY_HEALTH_HISTORY'
} as const;
export type AttrName = typeof AttrName[keyof typeof AttrName];


export function AttrNameFromJSON(json: any): AttrName {
    return AttrNameFromJSONTyped(json, false);
}

export function AttrNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttrName {
    return json as AttrName;
}

export function AttrNameToJSON(value?: AttrName | null): any {
    return value as any;
}

