/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccessModule } from './AccessModule';
import {
    AccessModuleFromJSON,
    AccessModuleFromJSONTyped,
    AccessModuleToJSON,
} from './AccessModule';

/**
 * 
 * @export
 * @interface AccessBypassRequest
 */
export interface AccessBypassRequest {
    /**
     * 
     * @type {AccessModule}
     * @memberof AccessBypassRequest
     */
    moduleName: AccessModule;
    /**
     * 
     * @type {boolean}
     * @memberof AccessBypassRequest
     */
    bypassed: boolean;
}

/**
 * Check if a given object implements the AccessBypassRequest interface.
 */
export function instanceOfAccessBypassRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "moduleName" in value;
    isInstance = isInstance && "bypassed" in value;

    return isInstance;
}

export function AccessBypassRequestFromJSON(json: any): AccessBypassRequest {
    return AccessBypassRequestFromJSONTyped(json, false);
}

export function AccessBypassRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessBypassRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'moduleName': AccessModuleFromJSON(json['moduleName']),
        'bypassed': json['bypassed'],
    };
}

export function AccessBypassRequestToJSON(value?: AccessBypassRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'moduleName': AccessModuleToJSON(value.moduleName),
        'bypassed': value.bypassed,
    };
}

