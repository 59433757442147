/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Domain } from './Domain';
import {
    DomainFromJSON,
    DomainFromJSONTyped,
    DomainToJSON,
} from './Domain';
import type { PrePackagedConceptSetEnum } from './PrePackagedConceptSetEnum';
import {
    PrePackagedConceptSetEnumFromJSON,
    PrePackagedConceptSetEnumFromJSONTyped,
    PrePackagedConceptSetEnumToJSON,
} from './PrePackagedConceptSetEnum';

/**
 * 
 * @export
 * @interface DataSetPreviewRequest
 */
export interface DataSetPreviewRequest {
    /**
     * 
     * @type {Domain}
     * @memberof DataSetPreviewRequest
     */
    domain: Domain;
    /**
     * Whether to include all participants or filter by cohorts
     * @type {boolean}
     * @memberof DataSetPreviewRequest
     */
    includesAllParticipants?: boolean;
    /**
     * 
     * @type {Array<PrePackagedConceptSetEnum>}
     * @memberof DataSetPreviewRequest
     */
    prePackagedConceptSet?: Array<PrePackagedConceptSetEnum>;
    /**
     * The ids of all concept sets in the data set
     * @type {Array<number>}
     * @memberof DataSetPreviewRequest
     */
    conceptSetIds?: Array<number>;
    /**
     * The ids of all cohorts in the data set
     * @type {Array<number>}
     * @memberof DataSetPreviewRequest
     */
    cohortIds?: Array<number>;
    /**
     * All the selected values in the data set
     * @type {Array<string>}
     * @memberof DataSetPreviewRequest
     */
    values?: Array<string>;
}

/**
 * Check if a given object implements the DataSetPreviewRequest interface.
 */
export function instanceOfDataSetPreviewRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "domain" in value;

    return isInstance;
}

export function DataSetPreviewRequestFromJSON(json: any): DataSetPreviewRequest {
    return DataSetPreviewRequestFromJSONTyped(json, false);
}

export function DataSetPreviewRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSetPreviewRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'domain': DomainFromJSON(json['domain']),
        'includesAllParticipants': !exists(json, 'includesAllParticipants') ? undefined : json['includesAllParticipants'],
        'prePackagedConceptSet': !exists(json, 'prePackagedConceptSet') ? undefined : ((json['prePackagedConceptSet'] as Array<any>).map(PrePackagedConceptSetEnumFromJSON)),
        'conceptSetIds': !exists(json, 'conceptSetIds') ? undefined : json['conceptSetIds'],
        'cohortIds': !exists(json, 'cohortIds') ? undefined : json['cohortIds'],
        'values': !exists(json, 'values') ? undefined : json['values'],
    };
}

export function DataSetPreviewRequestToJSON(value?: DataSetPreviewRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'domain': DomainToJSON(value.domain),
        'includesAllParticipants': value.includesAllParticipants,
        'prePackagedConceptSet': value.prePackagedConceptSet === undefined ? undefined : ((value.prePackagedConceptSet as Array<any>).map(PrePackagedConceptSetEnumToJSON)),
        'conceptSetIds': value.conceptSetIds,
        'cohortIds': value.cohortIds,
        'values': value.values,
    };
}

