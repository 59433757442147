/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateAccountRequest,
  ErrorResponse,
  PageVisit,
  Profile,
  RasLinkRequestBody,
  ResendWelcomeEmailRequest,
  SendBillingSetupEmailRequest,
  UpdateContactEmailRequest,
  UsernameTakenResponse,
} from '../models';
import {
    CreateAccountRequestFromJSON,
    CreateAccountRequestToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PageVisitFromJSON,
    PageVisitToJSON,
    ProfileFromJSON,
    ProfileToJSON,
    RasLinkRequestBodyFromJSON,
    RasLinkRequestBodyToJSON,
    ResendWelcomeEmailRequestFromJSON,
    ResendWelcomeEmailRequestToJSON,
    SendBillingSetupEmailRequestFromJSON,
    SendBillingSetupEmailRequestToJSON,
    UpdateContactEmailRequestFromJSON,
    UpdateContactEmailRequestToJSON,
    UsernameTakenResponseFromJSON,
    UsernameTakenResponseToJSON,
} from '../models';

export interface ProfileApiAcceptTermsOfServiceRequest {
    termsOfServiceVersion?: number;
}

export interface ProfileApiCreateAccountOperationRequest {
    createAccountRequest?: CreateAccountRequest;
}

export interface ProfileApiIsUsernameTakenRequest {
    username: string;
}

export interface ProfileApiLinkRasAccountRequest {
    rasLinkRequestBody?: RasLinkRequestBody;
}

export interface ProfileApiResendWelcomeEmailOperationRequest {
    resendWelcomeEmail?: ResendWelcomeEmailRequest;
}

export interface ProfileApiSendBillingSetupEmailOperationRequest {
    sendBillingSetupEmail?: SendBillingSetupEmailRequest;
}

export interface ProfileApiSubmitDUCCRequest {
    duccSignedVersion: number;
    initials: string;
}

export interface ProfileApiUpdateContactEmailOperationRequest {
    updateContactEmailRequest?: UpdateContactEmailRequest;
}

export interface ProfileApiUpdatePageVisitsRequest {
    pageVisit?: PageVisit;
}

export interface ProfileApiUpdateProfileRequest {
    updatedProfile?: Profile;
}

/**
 * 
 */
export class ProfileApi extends runtime.BaseAPI {

    /**
     * User accepts current version of Terra and specific version Of All of Us Terms of Service
     */
    async acceptTermsOfServiceRaw(requestParameters: ProfileApiAcceptTermsOfServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.termsOfServiceVersion !== undefined) {
            queryParameters['termsOfServiceVersion'] = requestParameters.termsOfServiceVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/acceptTermsOfService`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * User accepts current version of Terra and specific version Of All of Us Terms of Service
     */
    async acceptTermsOfService(termsOfServiceVersion?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acceptTermsOfServiceRaw({ termsOfServiceVersion: termsOfServiceVersion }, initOverrides);
    }

    /**
     * Used by users to confirm that their profile is up to date, as part of the annual renewal compliance process.
     */
    async confirmProfileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/confirmProfile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by users to confirm that their profile is up to date, as part of the annual renewal compliance process.
     */
    async confirmProfile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.confirmProfileRaw(initOverrides);
    }

    /**
     * Used by users to confirm that they have either reported any AoU-related publications, or they have none.
     */
    async confirmPublicationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/confirmPublications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by users to confirm that they have either reported any AoU-related publications, or they have none.
     */
    async confirmPublications(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.confirmPublicationsRaw(initOverrides);
    }

    /**
     * Creates an account in the researchallofus.org domain.
     */
    async createAccountRaw(requestParameters: ProfileApiCreateAccountOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Profile>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/google-account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAccountRequestToJSON(requestParameters.createAccountRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
    }

    /**
     * Creates an account in the researchallofus.org domain.
     */
    async createAccount(createAccountRequest?: CreateAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Profile> {
        const response = await this.createAccountRaw({ createAccountRequest: createAccountRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Deletes the user\'s profile and gsuite account, does not clean up in firecloud.
     */
    async deleteProfileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/profile`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes the user\'s profile and gsuite account, does not clean up in firecloud.
     */
    async deleteProfile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProfileRaw(initOverrides);
    }

    /**
     * User requests extension of initial credit expiration period
     */
    async extendInitialCreditExpirationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/user/extendInitialCreditExpiration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * User requests extension of initial credit expiration period
     */
    async extendInitialCreditExpiration(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.extendInitialCreditExpirationRaw(initOverrides);
    }

    /**
     * Returns the user\'s profile information
     */
    async getMeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Profile>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
    }

    /**
     * Returns the user\'s profile information
     */
    async getMe(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Profile> {
        const response = await this.getMeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns if the logged in user has accepted the latest versions of the All of Us and Terra Terms of Service agreements
     */
    async getUserTermsOfServiceStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/getUserTermsOfServiceStatus`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns if the logged in user has accepted the latest versions of the All of Us and Terra Terms of Service agreements
     */
    async getUserTermsOfServiceStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.getUserTermsOfServiceStatusRaw(initOverrides);
        return await response.value();
    }

    /**
     * Checks to see if the given username is not available.
     */
    async isUsernameTakenRaw(requestParameters: ProfileApiIsUsernameTakenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsernameTakenResponse>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling isUsernameTaken.');
        }

        const queryParameters: any = {};

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/is-username-taken`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsernameTakenResponseFromJSON(jsonValue));
    }

    /**
     * Checks to see if the given username is not available.
     */
    async isUsernameTaken(username: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsernameTakenResponse> {
        const response = await this.isUsernameTakenRaw({ username: username }, initOverrides);
        return await response.value();
    }

    /**
     * Link user with a RAS account
     */
    async linkRasAccountRaw(requestParameters: ProfileApiLinkRasAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Profile>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/user/link-ras-account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RasLinkRequestBodyToJSON(requestParameters.rasLinkRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
    }

    /**
     * Link user with a RAS account
     */
    async linkRasAccount(rasLinkRequestBody?: RasLinkRequestBody, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Profile> {
        const response = await this.linkRasAccountRaw({ rasLinkRequestBody: rasLinkRequestBody }, initOverrides);
        return await response.value();
    }

    /**
     * Resend welcome email
     */
    async resendWelcomeEmailRaw(requestParameters: ProfileApiResendWelcomeEmailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/resend-welcome-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendWelcomeEmailRequestToJSON(requestParameters.resendWelcomeEmail),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resend welcome email
     */
    async resendWelcomeEmail(resendWelcomeEmail?: ResendWelcomeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resendWelcomeEmailRaw({ resendWelcomeEmail: resendWelcomeEmail }, initOverrides);
    }

    /**
     * Send billing setup email to GCP partner
     */
    async sendBillingSetupEmailRaw(requestParameters: ProfileApiSendBillingSetupEmailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/send-billing-setup-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendBillingSetupEmailRequestToJSON(requestParameters.sendBillingSetupEmail),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send billing setup email to GCP partner
     */
    async sendBillingSetupEmail(sendBillingSetupEmail?: SendBillingSetupEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendBillingSetupEmailRaw({ sendBillingSetupEmail: sendBillingSetupEmail }, initOverrides);
    }

    /**
     * Submits consent to the Data User Code of Conduct for researchers.
     */
    async submitDUCCRaw(requestParameters: ProfileApiSubmitDUCCRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Profile>> {
        if (requestParameters.duccSignedVersion === null || requestParameters.duccSignedVersion === undefined) {
            throw new runtime.RequiredError('duccSignedVersion','Required parameter requestParameters.duccSignedVersion was null or undefined when calling submitDUCC.');
        }

        if (requestParameters.initials === null || requestParameters.initials === undefined) {
            throw new runtime.RequiredError('initials','Required parameter requestParameters.initials was null or undefined when calling submitDUCC.');
        }

        const queryParameters: any = {};

        if (requestParameters.duccSignedVersion !== undefined) {
            queryParameters['duccSignedVersion'] = requestParameters.duccSignedVersion;
        }

        if (requestParameters.initials !== undefined) {
            queryParameters['initials'] = requestParameters.initials;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/account/submit-data-user-code-of-conduct`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
    }

    /**
     * Submits consent to the Data User Code of Conduct for researchers.
     */
    async submitDUCC(duccSignedVersion: number, initials: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Profile> {
        const response = await this.submitDUCCRaw({ duccSignedVersion: duccSignedVersion, initials: initials }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves moodleId(either from DB or call from Moodle API) and gets Training status on the basis of that
     * Sync compliance training status
     */
    async syncComplianceTrainingStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Profile>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/account/sync-training-status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
    }

    /**
     * Retrieves moodleId(either from DB or call from Moodle API) and gets Training status on the basis of that
     * Sync compliance training status
     */
    async syncComplianceTrainingStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Profile> {
        const response = await this.syncComplianceTrainingStatusRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves and stores the current user\'s NIH / eRA Commons linkage status, fetched via the FireCloud API.
     * Sync eRA Commons status
     */
    async syncEraCommonsStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Profile>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/account/sync-era-commons-status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
    }

    /**
     * Retrieves and stores the current user\'s NIH / eRA Commons linkage status, fetched via the FireCloud API.
     * Sync eRA Commons status
     */
    async syncEraCommonsStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Profile> {
        const response = await this.syncEraCommonsStatusRaw(initOverrides);
        return await response.value();
    }

    /**
     * Syncs a user\'s 2FA status from google
     * Sync two factor auth status
     */
    async syncTwoFactorAuthStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Profile>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/account/sync-two-factor-auth-status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
    }

    /**
     * Syncs a user\'s 2FA status from google
     * Sync two factor auth status
     */
    async syncTwoFactorAuthStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Profile> {
        const response = await this.syncTwoFactorAuthStatusRaw(initOverrides);
        return await response.value();
    }

    /**
     * Only for accounts that have not logged in yet, update contact email.
     */
    async updateContactEmailRaw(requestParameters: ProfileApiUpdateContactEmailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/update-contact-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateContactEmailRequestToJSON(requestParameters.updateContactEmailRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Only for accounts that have not logged in yet, update contact email.
     */
    async updateContactEmail(updateContactEmailRequest?: UpdateContactEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateContactEmailRaw({ updateContactEmailRequest: updateContactEmailRequest }, initOverrides);
    }

    /**
     * Updates a users page visits
     */
    async updatePageVisitsRaw(requestParameters: ProfileApiUpdatePageVisitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Profile>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/page-visits`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PageVisitToJSON(requestParameters.pageVisit),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
    }

    /**
     * Updates a users page visits
     */
    async updatePageVisits(pageVisit?: PageVisit, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Profile> {
        const response = await this.updatePageVisitsRaw({ pageVisit: pageVisit }, initOverrides);
        return await response.value();
    }

    /**
     * Updates a users profile
     */
    async updateProfileRaw(requestParameters: ProfileApiUpdateProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/profile`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProfileToJSON(requestParameters.updatedProfile),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a users profile
     */
    async updateProfile(updatedProfile?: Profile, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateProfileRaw({ updatedProfile: updatedProfile }, initOverrides);
    }

}
