/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AuditAction } from './AuditAction';
import {
    AuditActionFromJSON,
    AuditActionFromJSONTyped,
    AuditActionToJSON,
} from './AuditAction';
import type { AuditLogEntry } from './AuditLogEntry';
import {
    AuditLogEntryFromJSON,
    AuditLogEntryFromJSONTyped,
    AuditLogEntryToJSON,
} from './AuditLogEntry';

/**
 * Represents the results of a query into the audit dataset in BigQery without filtering or transformation.
 * @export
 * @interface WorkspaceAuditLogQueryResponse
 */
export interface WorkspaceAuditLogQueryResponse {
    /**
     * Re-constructed objects for each composite action in the query results.
     * @type {Array<AuditAction>}
     * @memberof WorkspaceAuditLogQueryResponse
     */
    actions?: Array<AuditAction>;
    /**
     * 
     * @type {Array<AuditLogEntry>}
     * @memberof WorkspaceAuditLogQueryResponse
     */
    logEntries: Array<AuditLogEntry>;
    /**
     * Copy of the query used in BigQuery to assist in further exploration or debugging.
     * @type {string}
     * @memberof WorkspaceAuditLogQueryResponse
     */
    query: string;
    /**
     * ID in the MySQL database and BigQuery Audit Database for the workspace queried.
     * @type {number}
     * @memberof WorkspaceAuditLogQueryResponse
     */
    workspaceDatabaseId: number;
}

/**
 * Check if a given object implements the WorkspaceAuditLogQueryResponse interface.
 */
export function instanceOfWorkspaceAuditLogQueryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "logEntries" in value;
    isInstance = isInstance && "query" in value;
    isInstance = isInstance && "workspaceDatabaseId" in value;

    return isInstance;
}

export function WorkspaceAuditLogQueryResponseFromJSON(json: any): WorkspaceAuditLogQueryResponse {
    return WorkspaceAuditLogQueryResponseFromJSONTyped(json, false);
}

export function WorkspaceAuditLogQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceAuditLogQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actions': !exists(json, 'actions') ? undefined : ((json['actions'] as Array<any>).map(AuditActionFromJSON)),
        'logEntries': ((json['logEntries'] as Array<any>).map(AuditLogEntryFromJSON)),
        'query': json['query'],
        'workspaceDatabaseId': json['workspaceDatabaseId'],
    };
}

export function WorkspaceAuditLogQueryResponseToJSON(value?: WorkspaceAuditLogQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actions': value.actions === undefined ? undefined : ((value.actions as Array<any>).map(AuditActionToJSON)),
        'logEntries': ((value.logEntries as Array<any>).map(AuditLogEntryToJSON)),
        'query': value.query,
        'workspaceDatabaseId': value.workspaceDatabaseId,
    };
}

