/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Short parsable error descriptions
 * @export
 */
export const ErrorCode = {
    PARSE_ERROR: 'PARSE_ERROR',
    USER_DISABLED: 'USER_DISABLED',
    COMPUTE_SECURITY_SUSPENDED: 'COMPUTE_SECURITY_SUSPENDED',
    TERRA_TOS_NON_COMPLIANT: 'TERRA_TOS_NON_COMPLIANT',
    TERRA_TOS_COMPLIANCE_UNKNOWN: 'TERRA_TOS_COMPLIANCE_UNKNOWN'
} as const;
export type ErrorCode = typeof ErrorCode[keyof typeof ErrorCode];


export function ErrorCodeFromJSON(json: any): ErrorCode {
    return ErrorCodeFromJSONTyped(json, false);
}

export function ErrorCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorCode {
    return json as ErrorCode;
}

export function ErrorCodeToJSON(value?: ErrorCode | null): any {
    return value as any;
}

