/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TerraJobStatus } from './TerraJobStatus';
import {
    TerraJobStatusFromJSON,
    TerraJobStatusFromJSONTyped,
    TerraJobStatusToJSON,
} from './TerraJobStatus';

/**
 * 
 * @export
 * @interface GenomicExtractionJob
 */
export interface GenomicExtractionJob {
    /**
     * Workbench database Id for the job
     * @type {number}
     * @memberof GenomicExtractionJob
     */
    genomicExtractionJobId?: number;
    /**
     * 
     * @type {string}
     * @memberof GenomicExtractionJob
     */
    datasetName?: string;
    /**
     * 
     * @type {number}
     * @memberof GenomicExtractionJob
     */
    cost?: number;
    /**
     * Size of all the VCF files generated by this extraction job
     * @type {number}
     * @memberof GenomicExtractionJob
     */
    vcfSizeMb?: number;
    /**
     * 
     * @type {string}
     * @memberof GenomicExtractionJob
     */
    outputDir?: string;
    /**
     * 
     * @type {TerraJobStatus}
     * @memberof GenomicExtractionJob
     */
    status?: TerraJobStatus;
    /**
     * Submission Timestamp (epoch) according to Terra
     * @type {number}
     * @memberof GenomicExtractionJob
     */
    submissionDate?: number;
    /**
     * Submission Completion Timestamp (epoch) according to Terra
     * @type {number}
     * @memberof GenomicExtractionJob
     */
    completionTime?: number;
}

/**
 * Check if a given object implements the GenomicExtractionJob interface.
 */
export function instanceOfGenomicExtractionJob(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GenomicExtractionJobFromJSON(json: any): GenomicExtractionJob {
    return GenomicExtractionJobFromJSONTyped(json, false);
}

export function GenomicExtractionJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenomicExtractionJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'genomicExtractionJobId': !exists(json, 'genomicExtractionJobId') ? undefined : json['genomicExtractionJobId'],
        'datasetName': !exists(json, 'datasetName') ? undefined : json['datasetName'],
        'cost': !exists(json, 'cost') ? undefined : json['cost'],
        'vcfSizeMb': !exists(json, 'vcfSizeMb') ? undefined : json['vcfSizeMb'],
        'outputDir': !exists(json, 'outputDir') ? undefined : json['outputDir'],
        'status': !exists(json, 'status') ? undefined : TerraJobStatusFromJSON(json['status']),
        'submissionDate': !exists(json, 'submissionDate') ? undefined : json['submissionDate'],
        'completionTime': !exists(json, 'completionTime') ? undefined : json['completionTime'],
    };
}

export function GenomicExtractionJobToJSON(value?: GenomicExtractionJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'genomicExtractionJobId': value.genomicExtractionJobId,
        'datasetName': value.datasetName,
        'cost': value.cost,
        'vcfSizeMb': value.vcfSizeMb,
        'outputDir': value.outputDir,
        'status': TerraJobStatusToJSON(value.status),
        'submissionDate': value.submissionDate,
        'completionTime': value.completionTime,
    };
}

