/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EducationV2 } from './EducationV2';
import {
    EducationV2FromJSON,
    EducationV2FromJSONTyped,
    EducationV2ToJSON,
} from './EducationV2';
import type { EthnicCategory } from './EthnicCategory';
import {
    EthnicCategoryFromJSON,
    EthnicCategoryFromJSONTyped,
    EthnicCategoryToJSON,
} from './EthnicCategory';
import type { GenderIdentityV2 } from './GenderIdentityV2';
import {
    GenderIdentityV2FromJSON,
    GenderIdentityV2FromJSONTyped,
    GenderIdentityV2ToJSON,
} from './GenderIdentityV2';
import type { SexAtBirthV2 } from './SexAtBirthV2';
import {
    SexAtBirthV2FromJSON,
    SexAtBirthV2FromJSONTyped,
    SexAtBirthV2ToJSON,
} from './SexAtBirthV2';
import type { SexualOrientationV2 } from './SexualOrientationV2';
import {
    SexualOrientationV2FromJSON,
    SexualOrientationV2FromJSONTyped,
    SexualOrientationV2ToJSON,
} from './SexualOrientationV2';
import type { YesNoPreferNot } from './YesNoPreferNot';
import {
    YesNoPreferNotFromJSON,
    YesNoPreferNotFromJSONTyped,
    YesNoPreferNotToJSON,
} from './YesNoPreferNot';

/**
 * 
 * @export
 * @interface DemographicSurveyV2
 */
export interface DemographicSurveyV2 {
    /**
     * The time the user most recently completed the survey
     * @type {Date}
     * @memberof DemographicSurveyV2
     */
    completionTime?: Date;
    /**
     * 
     * @type {Array<EthnicCategory>}
     * @memberof DemographicSurveyV2
     */
    ethnicCategories?: Array<EthnicCategory>;
    /**
     * American Indian or Alaska Native /  None of these fully describe me, and I want to specify.  Max length 200.
     * @type {string}
     * @memberof DemographicSurveyV2
     */
    ethnicityAiAnOtherText?: string;
    /**
     * Asian / None of these fully describe me, and I want to specify.  Max length 200.
     * @type {string}
     * @memberof DemographicSurveyV2
     */
    ethnicityAsianOtherText?: string;
    /**
     * Black / None of these fully describe me, and I want to specify.  Max length 200.
     * @type {string}
     * @memberof DemographicSurveyV2
     */
    ethnicityBlackOtherText?: string;
    /**
     * Hispanic / None of these fully describe me, and I want to specify.  Max length 200.
     * @type {string}
     * @memberof DemographicSurveyV2
     */
    ethnicityHispanicOtherText?: string;
    /**
     * Middle Eastern or North African / None of these fully describe me, and I want to specify.  Max length 200.
     * @type {string}
     * @memberof DemographicSurveyV2
     */
    ethnicityMeNaOtherText?: string;
    /**
     * Native Hawaiian or other Pacific Islander / None of these fully describe me, and I want to specify.  Max length 200.
     * @type {string}
     * @memberof DemographicSurveyV2
     */
    ethnicityNhPiOtherText?: string;
    /**
     * White / None of these fully describe me, and I want to specify.  Max length 200.
     * @type {string}
     * @memberof DemographicSurveyV2
     */
    ethnicityWhiteOtherText?: string;
    /**
     * None of these fully describe me, and I want to specify.  Max length 200.
     * @type {string}
     * @memberof DemographicSurveyV2
     */
    ethnicityOtherText?: string;
    /**
     * 
     * @type {Array<GenderIdentityV2>}
     * @memberof DemographicSurveyV2
     */
    genderIdentities?: Array<GenderIdentityV2>;
    /**
     * None of these fully describe me, and I want to specify.  Max length 200.
     * @type {string}
     * @memberof DemographicSurveyV2
     */
    genderOtherText?: string;
    /**
     * 
     * @type {Array<SexualOrientationV2>}
     * @memberof DemographicSurveyV2
     */
    sexualOrientations?: Array<SexualOrientationV2>;
    /**
     * None of these fully describe me, and I want to specify.  Max length 200.
     * @type {string}
     * @memberof DemographicSurveyV2
     */
    orientationOtherText?: string;
    /**
     * 
     * @type {SexAtBirthV2}
     * @memberof DemographicSurveyV2
     */
    sexAtBirth?: SexAtBirthV2;
    /**
     * None of these fully describe me, and I want to specify.  Max length 200.
     * @type {string}
     * @memberof DemographicSurveyV2
     */
    sexAtBirthOtherText?: string;
    /**
     * 
     * @type {number}
     * @memberof DemographicSurveyV2
     */
    yearOfBirth?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DemographicSurveyV2
     */
    yearOfBirthPreferNot?: boolean;
    /**
     * 
     * @type {YesNoPreferNot}
     * @memberof DemographicSurveyV2
     */
    disabilityHearing?: YesNoPreferNot;
    /**
     * 
     * @type {YesNoPreferNot}
     * @memberof DemographicSurveyV2
     */
    disabilitySeeing?: YesNoPreferNot;
    /**
     * 
     * @type {YesNoPreferNot}
     * @memberof DemographicSurveyV2
     */
    disabilityConcentrating?: YesNoPreferNot;
    /**
     * 
     * @type {YesNoPreferNot}
     * @memberof DemographicSurveyV2
     */
    disabilityWalking?: YesNoPreferNot;
    /**
     * 
     * @type {YesNoPreferNot}
     * @memberof DemographicSurveyV2
     */
    disabilityDressing?: YesNoPreferNot;
    /**
     * 
     * @type {YesNoPreferNot}
     * @memberof DemographicSurveyV2
     */
    disabilityErrands?: YesNoPreferNot;
    /**
     * Do you have a physical, cognitive, and/or emotional condition that substantially inhibits one or more life activities not specified through the above questions, and want to share more? Please describe.
     * @type {string}
     * @memberof DemographicSurveyV2
     */
    disabilityOtherText?: string;
    /**
     * 
     * @type {EducationV2}
     * @memberof DemographicSurveyV2
     */
    education?: EducationV2;
    /**
     * 
     * @type {YesNoPreferNot}
     * @memberof DemographicSurveyV2
     */
    disadvantaged?: YesNoPreferNot;
    /**
     * Is there any aspect of your identity that we have not covered in the preceding questions that we may want to consider including in future surveys?
     * @type {string}
     * @memberof DemographicSurveyV2
     */
    surveyComments?: string;
}

/**
 * Check if a given object implements the DemographicSurveyV2 interface.
 */
export function instanceOfDemographicSurveyV2(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DemographicSurveyV2FromJSON(json: any): DemographicSurveyV2 {
    return DemographicSurveyV2FromJSONTyped(json, false);
}

export function DemographicSurveyV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): DemographicSurveyV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'completionTime': !exists(json, 'completionTime') ? undefined : (new Date(json['completionTime'])),
        'ethnicCategories': !exists(json, 'ethnicCategories') ? undefined : ((json['ethnicCategories'] as Array<any>).map(EthnicCategoryFromJSON)),
        'ethnicityAiAnOtherText': !exists(json, 'ethnicityAiAnOtherText') ? undefined : json['ethnicityAiAnOtherText'],
        'ethnicityAsianOtherText': !exists(json, 'ethnicityAsianOtherText') ? undefined : json['ethnicityAsianOtherText'],
        'ethnicityBlackOtherText': !exists(json, 'ethnicityBlackOtherText') ? undefined : json['ethnicityBlackOtherText'],
        'ethnicityHispanicOtherText': !exists(json, 'ethnicityHispanicOtherText') ? undefined : json['ethnicityHispanicOtherText'],
        'ethnicityMeNaOtherText': !exists(json, 'ethnicityMeNaOtherText') ? undefined : json['ethnicityMeNaOtherText'],
        'ethnicityNhPiOtherText': !exists(json, 'ethnicityNhPiOtherText') ? undefined : json['ethnicityNhPiOtherText'],
        'ethnicityWhiteOtherText': !exists(json, 'ethnicityWhiteOtherText') ? undefined : json['ethnicityWhiteOtherText'],
        'ethnicityOtherText': !exists(json, 'ethnicityOtherText') ? undefined : json['ethnicityOtherText'],
        'genderIdentities': !exists(json, 'genderIdentities') ? undefined : ((json['genderIdentities'] as Array<any>).map(GenderIdentityV2FromJSON)),
        'genderOtherText': !exists(json, 'genderOtherText') ? undefined : json['genderOtherText'],
        'sexualOrientations': !exists(json, 'sexualOrientations') ? undefined : ((json['sexualOrientations'] as Array<any>).map(SexualOrientationV2FromJSON)),
        'orientationOtherText': !exists(json, 'orientationOtherText') ? undefined : json['orientationOtherText'],
        'sexAtBirth': !exists(json, 'sexAtBirth') ? undefined : SexAtBirthV2FromJSON(json['sexAtBirth']),
        'sexAtBirthOtherText': !exists(json, 'sexAtBirthOtherText') ? undefined : json['sexAtBirthOtherText'],
        'yearOfBirth': !exists(json, 'yearOfBirth') ? undefined : json['yearOfBirth'],
        'yearOfBirthPreferNot': !exists(json, 'yearOfBirthPreferNot') ? undefined : json['yearOfBirthPreferNot'],
        'disabilityHearing': !exists(json, 'disabilityHearing') ? undefined : YesNoPreferNotFromJSON(json['disabilityHearing']),
        'disabilitySeeing': !exists(json, 'disabilitySeeing') ? undefined : YesNoPreferNotFromJSON(json['disabilitySeeing']),
        'disabilityConcentrating': !exists(json, 'disabilityConcentrating') ? undefined : YesNoPreferNotFromJSON(json['disabilityConcentrating']),
        'disabilityWalking': !exists(json, 'disabilityWalking') ? undefined : YesNoPreferNotFromJSON(json['disabilityWalking']),
        'disabilityDressing': !exists(json, 'disabilityDressing') ? undefined : YesNoPreferNotFromJSON(json['disabilityDressing']),
        'disabilityErrands': !exists(json, 'disabilityErrands') ? undefined : YesNoPreferNotFromJSON(json['disabilityErrands']),
        'disabilityOtherText': !exists(json, 'disabilityOtherText') ? undefined : json['disabilityOtherText'],
        'education': !exists(json, 'education') ? undefined : EducationV2FromJSON(json['education']),
        'disadvantaged': !exists(json, 'disadvantaged') ? undefined : YesNoPreferNotFromJSON(json['disadvantaged']),
        'surveyComments': !exists(json, 'surveyComments') ? undefined : json['surveyComments'],
    };
}

export function DemographicSurveyV2ToJSON(value?: DemographicSurveyV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'completionTime': value.completionTime === undefined ? undefined : (value.completionTime.toISOString()),
        'ethnicCategories': value.ethnicCategories === undefined ? undefined : ((value.ethnicCategories as Array<any>).map(EthnicCategoryToJSON)),
        'ethnicityAiAnOtherText': value.ethnicityAiAnOtherText,
        'ethnicityAsianOtherText': value.ethnicityAsianOtherText,
        'ethnicityBlackOtherText': value.ethnicityBlackOtherText,
        'ethnicityHispanicOtherText': value.ethnicityHispanicOtherText,
        'ethnicityMeNaOtherText': value.ethnicityMeNaOtherText,
        'ethnicityNhPiOtherText': value.ethnicityNhPiOtherText,
        'ethnicityWhiteOtherText': value.ethnicityWhiteOtherText,
        'ethnicityOtherText': value.ethnicityOtherText,
        'genderIdentities': value.genderIdentities === undefined ? undefined : ((value.genderIdentities as Array<any>).map(GenderIdentityV2ToJSON)),
        'genderOtherText': value.genderOtherText,
        'sexualOrientations': value.sexualOrientations === undefined ? undefined : ((value.sexualOrientations as Array<any>).map(SexualOrientationV2ToJSON)),
        'orientationOtherText': value.orientationOtherText,
        'sexAtBirth': SexAtBirthV2ToJSON(value.sexAtBirth),
        'sexAtBirthOtherText': value.sexAtBirthOtherText,
        'yearOfBirth': value.yearOfBirth,
        'yearOfBirthPreferNot': value.yearOfBirthPreferNot,
        'disabilityHearing': YesNoPreferNotToJSON(value.disabilityHearing),
        'disabilitySeeing': YesNoPreferNotToJSON(value.disabilitySeeing),
        'disabilityConcentrating': YesNoPreferNotToJSON(value.disabilityConcentrating),
        'disabilityWalking': YesNoPreferNotToJSON(value.disabilityWalking),
        'disabilityDressing': YesNoPreferNotToJSON(value.disabilityDressing),
        'disabilityErrands': YesNoPreferNotToJSON(value.disabilityErrands),
        'disabilityOtherText': value.disabilityOtherText,
        'education': EducationV2ToJSON(value.education),
        'disadvantaged': YesNoPreferNotToJSON(value.disadvantaged),
        'surveyComments': value.surveyComments,
    };
}

