/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuditEgressEventResponse,
  EgressEvent,
  ListEgressEventsRequest,
  ListEgressEventsResponse,
  UpdateEgressEventRequest,
} from '../models';
import {
    AuditEgressEventResponseFromJSON,
    AuditEgressEventResponseToJSON,
    EgressEventFromJSON,
    EgressEventToJSON,
    ListEgressEventsRequestFromJSON,
    ListEgressEventsRequestToJSON,
    ListEgressEventsResponseFromJSON,
    ListEgressEventsResponseToJSON,
    UpdateEgressEventRequestFromJSON,
    UpdateEgressEventRequestToJSON,
} from '../models';

export interface EgressEventsAdminApiAuditEgressEventRequest {
    id: string;
    request?: object;
}

export interface EgressEventsAdminApiListEgressEventsOperationRequest {
    request?: ListEgressEventsRequest;
}

export interface EgressEventsAdminApiUpdateEgressEventOperationRequest {
    id: string;
    request?: UpdateEgressEventRequest;
}

/**
 * 
 */
export class EgressEventsAdminApi extends runtime.BaseAPI {

    /**
     * Administrative API to get audit details for an egress event. 
     */
    async auditEgressEventRaw(requestParameters: EgressEventsAdminApiAuditEgressEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuditEgressEventResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling auditEgressEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/egressEvents/{id}/audit`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuditEgressEventResponseFromJSON(jsonValue));
    }

    /**
     * Administrative API to get audit details for an egress event. 
     */
    async auditEgressEvent(id: string, request?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuditEgressEventResponse> {
        const response = await this.auditEgressEventRaw({ id: id, request: request }, initOverrides);
        return await response.value();
    }

    /**
     * Administrative API to receive a paginated list of egress events. Optionally filtered by different criteria. 
     */
    async listEgressEventsRaw(requestParameters: EgressEventsAdminApiListEgressEventsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListEgressEventsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/egressEvents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListEgressEventsRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListEgressEventsResponseFromJSON(jsonValue));
    }

    /**
     * Administrative API to receive a paginated list of egress events. Optionally filtered by different criteria. 
     */
    async listEgressEvents(request?: ListEgressEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListEgressEventsResponse> {
        const response = await this.listEgressEventsRaw({ request: request }, initOverrides);
        return await response.value();
    }

    /**
     * Administrative API to update an egress event. Currently, only the following fields can be updated - all other fields are ignored:   - status (REMEDIATED or VERIFIED_FALSE_POSITIVE) 
     */
    async updateEgressEventRaw(requestParameters: EgressEventsAdminApiUpdateEgressEventOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EgressEvent>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateEgressEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/egressEvents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEgressEventRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EgressEventFromJSON(jsonValue));
    }

    /**
     * Administrative API to update an egress event. Currently, only the following fields can be updated - all other fields are ignored:   - status (REMEDIATED or VERIFIED_FALSE_POSITIVE) 
     */
    async updateEgressEvent(id: string, request?: UpdateEgressEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EgressEvent> {
        const response = await this.updateEgressEventRaw({ id: id, request: request }, initOverrides);
        return await response.value();
    }

}
