/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CdrVersionTiersResponse,
} from '../models';
import {
    CdrVersionTiersResponseFromJSON,
    CdrVersionTiersResponseToJSON,
} from '../models';

/**
 * 
 */
export class CdrVersionsApi extends runtime.BaseAPI {

    /**
     * Returns all curated data repository (CDR) versions in all tiers that the user has access to 
     */
    async getCdrVersionsByTierRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CdrVersionTiersResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cdrVersionsByTier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CdrVersionTiersResponseFromJSON(jsonValue));
    }

    /**
     * Returns all curated data repository (CDR) versions in all tiers that the user has access to 
     */
    async getCdrVersionsByTier(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CdrVersionTiersResponse> {
        const response = await this.getCdrVersionsByTierRaw(initOverrides);
        return await response.value();
    }

}
