/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppStatus } from './AppStatus';
import {
    AppStatusFromJSON,
    AppStatusFromJSONTyped,
    AppStatusToJSON,
} from './AppStatus';
import type { AppType } from './AppType';
import {
    AppTypeFromJSON,
    AppTypeFromJSONTyped,
    AppTypeToJSON,
} from './AppType';
import type { KubernetesError } from './KubernetesError';
import {
    KubernetesErrorFromJSON,
    KubernetesErrorFromJSONTyped,
    KubernetesErrorToJSON,
} from './KubernetesError';
import type { KubernetesRuntimeConfig } from './KubernetesRuntimeConfig';
import {
    KubernetesRuntimeConfigFromJSON,
    KubernetesRuntimeConfigFromJSONTyped,
    KubernetesRuntimeConfigToJSON,
} from './KubernetesRuntimeConfig';

/**
 * 
 * @export
 * @interface UserAppEnvironment
 */
export interface UserAppEnvironment {
    /**
     * The Google Project used to create the user app. Read only.
     * @type {string}
     * @memberof UserAppEnvironment
     */
    googleProject?: string;
    /**
     * The user-supplied name for the user app. Read only.
     * @type {string}
     * @memberof UserAppEnvironment
     */
    appName?: string;
    /**
     * 
     * @type {AppType}
     * @memberof UserAppEnvironment
     */
    appType?: AppType;
    /**
     * The creator of the app
     * @type {string}
     * @memberof UserAppEnvironment
     */
    creator?: string;
    /**
     * The date and time the user app was created, in ISO-8601 format. Read only.
     * @type {string}
     * @memberof UserAppEnvironment
     */
    createdDate?: string;
    /**
     * 
     * @type {AppStatus}
     * @memberof UserAppEnvironment
     */
    status?: AppStatus;
    /**
     * The number of minutes of idle time to elapse before the cluster is autopaused. If autopause is set to false, this value is disregarded. A value of 0 is equivalent to autopause being turned off.
     * @type {number}
     * @memberof UserAppEnvironment
     */
    autopauseThreshold?: number;
    /**
     * Whether to turn on autodelete
     * @type {boolean}
     * @memberof UserAppEnvironment
     */
    autodeleteEnabled?: boolean;
    /**
     * The number of minutes of idle time to elapse before the app is deleted. When autodeleteEnabled is true, a positive integer is required
     * @type {number}
     * @memberof UserAppEnvironment
     */
    autodeleteThreshold?: number;
    /**
     * 
     * @type {KubernetesRuntimeConfig}
     * @memberof UserAppEnvironment
     */
    kubernetesRuntimeConfig?: KubernetesRuntimeConfig;
    /**
     * Read only. Map of service name to proxyUrl
     * @type {object}
     * @memberof UserAppEnvironment
     */
    proxyUrls?: object;
    /**
     * Read only. The name of the disk associated with this user app
     * @type {string}
     * @memberof UserAppEnvironment
     */
    diskName?: string;
    /**
     * The date and time the environment was last accessed, in ISO-8601 format.
     * Date accessed is defined as the last time the runtime was created, modified, or accessed via the proxy.
     * @type {string}
     * @memberof UserAppEnvironment
     */
    dateAccessed?: string;
    /**
     * The list of errors that were encountered on user app creation, if any.
     * @type {Array<KubernetesError>}
     * @memberof UserAppEnvironment
     */
    errors?: Array<KubernetesError>;
    /**
     * The labels of each user app in the response whose key is in includeLabels in the request. Of type Map[String,String]
     * @type {object}
     * @memberof UserAppEnvironment
     */
    labels?: object;
}

/**
 * Check if a given object implements the UserAppEnvironment interface.
 */
export function instanceOfUserAppEnvironment(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserAppEnvironmentFromJSON(json: any): UserAppEnvironment {
    return UserAppEnvironmentFromJSONTyped(json, false);
}

export function UserAppEnvironmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAppEnvironment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'googleProject': !exists(json, 'googleProject') ? undefined : json['googleProject'],
        'appName': !exists(json, 'appName') ? undefined : json['appName'],
        'appType': !exists(json, 'appType') ? undefined : AppTypeFromJSON(json['appType']),
        'creator': !exists(json, 'creator') ? undefined : json['creator'],
        'createdDate': !exists(json, 'createdDate') ? undefined : json['createdDate'],
        'status': !exists(json, 'status') ? undefined : AppStatusFromJSON(json['status']),
        'autopauseThreshold': !exists(json, 'autopauseThreshold') ? undefined : json['autopauseThreshold'],
        'autodeleteEnabled': !exists(json, 'autodeleteEnabled') ? undefined : json['autodeleteEnabled'],
        'autodeleteThreshold': !exists(json, 'autodeleteThreshold') ? undefined : json['autodeleteThreshold'],
        'kubernetesRuntimeConfig': !exists(json, 'kubernetesRuntimeConfig') ? undefined : KubernetesRuntimeConfigFromJSON(json['kubernetesRuntimeConfig']),
        'proxyUrls': !exists(json, 'proxyUrls') ? undefined : json['proxyUrls'],
        'diskName': !exists(json, 'diskName') ? undefined : json['diskName'],
        'dateAccessed': !exists(json, 'dateAccessed') ? undefined : json['dateAccessed'],
        'errors': !exists(json, 'errors') ? undefined : ((json['errors'] as Array<any>).map(KubernetesErrorFromJSON)),
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
    };
}

export function UserAppEnvironmentToJSON(value?: UserAppEnvironment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'googleProject': value.googleProject,
        'appName': value.appName,
        'appType': AppTypeToJSON(value.appType),
        'creator': value.creator,
        'createdDate': value.createdDate,
        'status': AppStatusToJSON(value.status),
        'autopauseThreshold': value.autopauseThreshold,
        'autodeleteEnabled': value.autodeleteEnabled,
        'autodeleteThreshold': value.autodeleteThreshold,
        'kubernetesRuntimeConfig': KubernetesRuntimeConfigToJSON(value.kubernetesRuntimeConfig),
        'proxyUrls': value.proxyUrls,
        'diskName': value.diskName,
        'dateAccessed': value.dateAccessed,
        'errors': value.errors === undefined ? undefined : ((value.errors as Array<any>).map(KubernetesErrorToJSON)),
        'labels': value.labels,
    };
}

