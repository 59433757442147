/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConceptSet } from './ConceptSet';
import {
    ConceptSetFromJSON,
    ConceptSetFromJSONTyped,
    ConceptSetToJSON,
} from './ConceptSet';
import type { ConceptSetConceptId } from './ConceptSetConceptId';
import {
    ConceptSetConceptIdFromJSON,
    ConceptSetConceptIdFromJSONTyped,
    ConceptSetConceptIdToJSON,
} from './ConceptSetConceptId';

/**
 * 
 * @export
 * @interface CreateConceptSetRequest
 */
export interface CreateConceptSetRequest {
    /**
     * 
     * @type {ConceptSet}
     * @memberof CreateConceptSetRequest
     */
    conceptSet: ConceptSet;
    /**
     * The IDs of concepts to be added to the concept set.
     * @type {Array<ConceptSetConceptId>}
     * @memberof CreateConceptSetRequest
     */
    addedConceptSetConceptIds?: Array<ConceptSetConceptId>;
}

/**
 * Check if a given object implements the CreateConceptSetRequest interface.
 */
export function instanceOfCreateConceptSetRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "conceptSet" in value;

    return isInstance;
}

export function CreateConceptSetRequestFromJSON(json: any): CreateConceptSetRequest {
    return CreateConceptSetRequestFromJSONTyped(json, false);
}

export function CreateConceptSetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateConceptSetRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conceptSet': ConceptSetFromJSON(json['conceptSet']),
        'addedConceptSetConceptIds': !exists(json, 'addedConceptSetConceptIds') ? undefined : ((json['addedConceptSetConceptIds'] as Array<any>).map(ConceptSetConceptIdFromJSON)),
    };
}

export function CreateConceptSetRequestToJSON(value?: CreateConceptSetRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conceptSet': ConceptSetToJSON(value.conceptSet),
        'addedConceptSetConceptIds': value.addedConceptSetConceptIds === undefined ? undefined : ((value.addedConceptSetConceptIds as Array<any>).map(ConceptSetConceptIdToJSON)),
    };
}

