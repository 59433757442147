import * as React from 'react';

export const AouTitle = () => {
  return (
    <span>
      <i>All of Us</i> Research Program
    </span>
  );
};

export const AoU = () => {
  return (
    <span>
      <i>All of Us</i>
    </span>
  );
};
