/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateNewUserSatisfactionSurvey } from './CreateNewUserSatisfactionSurvey';
import {
    CreateNewUserSatisfactionSurveyFromJSON,
    CreateNewUserSatisfactionSurveyFromJSONTyped,
    CreateNewUserSatisfactionSurveyToJSON,
} from './CreateNewUserSatisfactionSurvey';

/**
 * 
 * @export
 * @interface CreateNewUserSatisfactionSurveyWithOneTimeCode
 */
export interface CreateNewUserSatisfactionSurveyWithOneTimeCode {
    /**
     * 
     * @type {CreateNewUserSatisfactionSurvey}
     * @memberof CreateNewUserSatisfactionSurveyWithOneTimeCode
     */
    createNewUserSatisfactionSurvey?: CreateNewUserSatisfactionSurvey;
    /**
     * One-time code for creating this survey response.
     * @type {string}
     * @memberof CreateNewUserSatisfactionSurveyWithOneTimeCode
     */
    oneTimeCode?: string;
}

/**
 * Check if a given object implements the CreateNewUserSatisfactionSurveyWithOneTimeCode interface.
 */
export function instanceOfCreateNewUserSatisfactionSurveyWithOneTimeCode(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateNewUserSatisfactionSurveyWithOneTimeCodeFromJSON(json: any): CreateNewUserSatisfactionSurveyWithOneTimeCode {
    return CreateNewUserSatisfactionSurveyWithOneTimeCodeFromJSONTyped(json, false);
}

export function CreateNewUserSatisfactionSurveyWithOneTimeCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateNewUserSatisfactionSurveyWithOneTimeCode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createNewUserSatisfactionSurvey': !exists(json, 'createNewUserSatisfactionSurvey') ? undefined : CreateNewUserSatisfactionSurveyFromJSON(json['createNewUserSatisfactionSurvey']),
        'oneTimeCode': !exists(json, 'oneTimeCode') ? undefined : json['oneTimeCode'],
    };
}

export function CreateNewUserSatisfactionSurveyWithOneTimeCodeToJSON(value?: CreateNewUserSatisfactionSurveyWithOneTimeCode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createNewUserSatisfactionSurvey': CreateNewUserSatisfactionSurveyToJSON(value.createNewUserSatisfactionSurvey),
        'oneTimeCode': value.oneTimeCode,
    };
}

