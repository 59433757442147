/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PublicInstitutionDetails } from './PublicInstitutionDetails';
import {
    PublicInstitutionDetailsFromJSON,
    PublicInstitutionDetailsFromJSONTyped,
    PublicInstitutionDetailsToJSON,
} from './PublicInstitutionDetails';

/**
 * 
 * @export
 * @interface GetPublicInstitutionDetailsResponse
 */
export interface GetPublicInstitutionDetailsResponse {
    /**
     * 
     * @type {Array<PublicInstitutionDetails>}
     * @memberof GetPublicInstitutionDetailsResponse
     */
    institutions: Array<PublicInstitutionDetails>;
}

/**
 * Check if a given object implements the GetPublicInstitutionDetailsResponse interface.
 */
export function instanceOfGetPublicInstitutionDetailsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "institutions" in value;

    return isInstance;
}

export function GetPublicInstitutionDetailsResponseFromJSON(json: any): GetPublicInstitutionDetailsResponse {
    return GetPublicInstitutionDetailsResponseFromJSONTyped(json, false);
}

export function GetPublicInstitutionDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPublicInstitutionDetailsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'institutions': ((json['institutions'] as Array<any>).map(PublicInstitutionDetailsFromJSON)),
    };
}

export function GetPublicInstitutionDetailsResponseToJSON(value?: GetPublicInstitutionDetailsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'institutions': ((value.institutions as Array<any>).map(PublicInstitutionDetailsToJSON)),
    };
}

