/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AnnotationValue } from './AnnotationValue';
import {
    AnnotationValueFromJSON,
    AnnotationValueFromJSONTyped,
    AnnotationValueToJSON,
} from './AnnotationValue';
import type { ValueDisplay } from './ValueDisplay';
import {
    ValueDisplayFromJSON,
    ValueDisplayFromJSONTyped,
    ValueDisplayToJSON,
} from './ValueDisplay';

/**
 * 
 * @export
 * @interface ReviewInstance
 */
export interface ReviewInstance {
    /**
     * Index of the instance, unchanged when sorting
     * @type {number}
     * @memberof ReviewInstance
     */
    stableIndex?: number;
    /**
     * A map of entity attribute names to their value for this instance. The id attribute will always be included.
     * @type {{ [key: string]: ValueDisplay; }}
     * @memberof ReviewInstance
     */
    attributes?: { [key: string]: ValueDisplay; };
    /**
     * A map of annotation ids to their values for this instance in this review
     * @type {{ [key: string]: Array<AnnotationValue>; }}
     * @memberof ReviewInstance
     */
    annotations?: { [key: string]: Array<AnnotationValue>; };
}

/**
 * Check if a given object implements the ReviewInstance interface.
 */
export function instanceOfReviewInstance(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReviewInstanceFromJSON(json: any): ReviewInstance {
    return ReviewInstanceFromJSONTyped(json, false);
}

export function ReviewInstanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewInstance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stableIndex': !exists(json, 'stableIndex') ? undefined : json['stableIndex'],
        'attributes': !exists(json, 'attributes') ? undefined : (mapValues(json['attributes'], ValueDisplayFromJSON)),
        'annotations': !exists(json, 'annotations') ? undefined : json['annotations'],
    };
}

export function ReviewInstanceToJSON(value?: ReviewInstance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stableIndex': value.stableIndex,
        'attributes': value.attributes === undefined ? undefined : (mapValues(value.attributes, ValueDisplayToJSON)),
        'annotations': value.annotations,
    };
}

