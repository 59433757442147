/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AnalysisLanguage } from './AnalysisLanguage';
import {
    AnalysisLanguageFromJSON,
    AnalysisLanguageFromJSONTyped,
    AnalysisLanguageToJSON,
} from './AnalysisLanguage';
import type { DataSetRequest } from './DataSetRequest';
import {
    DataSetRequestFromJSON,
    DataSetRequestFromJSONTyped,
    DataSetRequestToJSON,
} from './DataSetRequest';

/**
 * 
 * @export
 * @interface DataSetExportRequest
 */
export interface DataSetExportRequest {
    /**
     * 
     * @type {DataSetRequest}
     * @memberof DataSetExportRequest
     */
    dataSetRequest: DataSetRequest;
    /**
     * 
     * @type {string}
     * @memberof DataSetExportRequest
     */
    notebookName: string;
    /**
     * 
     * @type {boolean}
     * @memberof DataSetExportRequest
     */
    newNotebook: boolean;
    /**
     * 
     * @type {AnalysisLanguage}
     * @memberof DataSetExportRequest
     */
    analysisLanguage?: AnalysisLanguage;
    /**
     * 
     * @type {boolean}
     * @memberof DataSetExportRequest
     */
    generateGenomicsAnalysisCode?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DataSetExportRequest
     */
    genomicsAnalysisTool?: DataSetExportRequestGenomicsAnalysisToolEnum;
}


/**
 * @export
 */
export const DataSetExportRequestGenomicsAnalysisToolEnum = {
    NONE: 'NONE',
    PLINK: 'PLINK',
    HAIL: 'HAIL'
} as const;
export type DataSetExportRequestGenomicsAnalysisToolEnum = typeof DataSetExportRequestGenomicsAnalysisToolEnum[keyof typeof DataSetExportRequestGenomicsAnalysisToolEnum];


/**
 * Check if a given object implements the DataSetExportRequest interface.
 */
export function instanceOfDataSetExportRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dataSetRequest" in value;
    isInstance = isInstance && "notebookName" in value;
    isInstance = isInstance && "newNotebook" in value;

    return isInstance;
}

export function DataSetExportRequestFromJSON(json: any): DataSetExportRequest {
    return DataSetExportRequestFromJSONTyped(json, false);
}

export function DataSetExportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSetExportRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataSetRequest': DataSetRequestFromJSON(json['dataSetRequest']),
        'notebookName': json['notebookName'],
        'newNotebook': json['newNotebook'],
        'analysisLanguage': !exists(json, 'analysisLanguage') ? undefined : AnalysisLanguageFromJSON(json['analysisLanguage']),
        'generateGenomicsAnalysisCode': !exists(json, 'generateGenomicsAnalysisCode') ? undefined : json['generateGenomicsAnalysisCode'],
        'genomicsAnalysisTool': !exists(json, 'genomicsAnalysisTool') ? undefined : json['genomicsAnalysisTool'],
    };
}

export function DataSetExportRequestToJSON(value?: DataSetExportRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataSetRequest': DataSetRequestToJSON(value.dataSetRequest),
        'notebookName': value.notebookName,
        'newNotebook': value.newNotebook,
        'analysisLanguage': AnalysisLanguageToJSON(value.analysisLanguage),
        'generateGenomicsAnalysisCode': value.generateGenomicsAnalysisCode,
        'genomicsAnalysisTool': value.genomicsAnalysisTool,
    };
}

