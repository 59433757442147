/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParticipantChartData
 */
export interface ParticipantChartData {
    /**
     * the standard name
     * @type {string}
     * @memberof ParticipantChartData
     */
    standardName: string;
    /**
     * the standard vocabulary
     * @type {string}
     * @memberof ParticipantChartData
     */
    standardVocabulary: string;
    /**
     * the date of the event
     * @type {string}
     * @memberof ParticipantChartData
     */
    startDate: string;
    /**
     * The age at event for the participant
     * @type {number}
     * @memberof ParticipantChartData
     */
    ageAtEvent: number;
    /**
     * the rank of the data
     * @type {number}
     * @memberof ParticipantChartData
     */
    rank: number;
}

/**
 * Check if a given object implements the ParticipantChartData interface.
 */
export function instanceOfParticipantChartData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "standardName" in value;
    isInstance = isInstance && "standardVocabulary" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "ageAtEvent" in value;
    isInstance = isInstance && "rank" in value;

    return isInstance;
}

export function ParticipantChartDataFromJSON(json: any): ParticipantChartData {
    return ParticipantChartDataFromJSONTyped(json, false);
}

export function ParticipantChartDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantChartData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'standardName': json['standardName'],
        'standardVocabulary': json['standardVocabulary'],
        'startDate': json['startDate'],
        'ageAtEvent': json['ageAtEvent'],
        'rank': json['rank'],
    };
}

export function ParticipantChartDataToJSON(value?: ParticipantChartData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'standardName': value.standardName,
        'standardVocabulary': value.standardVocabulary,
        'startDate': value.startDate,
        'ageAtEvent': value.ageAtEvent,
        'rank': value.rank,
    };
}

