/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Composite object containing audited target property changes. See AuditLogEntry for individual fields.
 * @export
 * @interface AuditTargetPropertyChange
 */
export interface AuditTargetPropertyChange {
    /**
     * 
     * @type {string}
     * @memberof AuditTargetPropertyChange
     */
    targetProperty?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditTargetPropertyChange
     */
    previousValue?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditTargetPropertyChange
     */
    newValue?: string;
}

/**
 * Check if a given object implements the AuditTargetPropertyChange interface.
 */
export function instanceOfAuditTargetPropertyChange(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuditTargetPropertyChangeFromJSON(json: any): AuditTargetPropertyChange {
    return AuditTargetPropertyChangeFromJSONTyped(json, false);
}

export function AuditTargetPropertyChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditTargetPropertyChange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'targetProperty': !exists(json, 'targetProperty') ? undefined : json['targetProperty'],
        'previousValue': !exists(json, 'previousValue') ? undefined : json['previousValue'],
        'newValue': !exists(json, 'newValue') ? undefined : json['newValue'],
    };
}

export function AuditTargetPropertyChangeToJSON(value?: AuditTargetPropertyChange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'targetProperty': value.targetProperty,
        'previousValue': value.previousValue,
        'newValue': value.newValue,
    };
}

