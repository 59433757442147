/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppType } from './AppType';
import {
    AppTypeFromJSON,
    AppTypeFromJSONTyped,
    AppTypeToJSON,
} from './AppType';

/**
 * 
 * @export
 * @interface AppLocalizeRequest
 */
export interface AppLocalizeRequest {
    /**
     * Names of notebooks to localize. This is just the basename (no gs:// needed). This is interpreted as relative to the /notebooks directory within the provided workspace's Google Cloud Storage bucket.
     * @type {Array<string>}
     * @memberof AppLocalizeRequest
     */
    fileNames: Array<string>;
    /**
     * Set to true if playgroundMode needed
     * @type {boolean}
     * @memberof AppLocalizeRequest
     */
    playgroundMode: boolean;
    /**
     * 
     * @type {AppType}
     * @memberof AppLocalizeRequest
     */
    appType?: AppType;
}

/**
 * Check if a given object implements the AppLocalizeRequest interface.
 */
export function instanceOfAppLocalizeRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fileNames" in value;
    isInstance = isInstance && "playgroundMode" in value;

    return isInstance;
}

export function AppLocalizeRequestFromJSON(json: any): AppLocalizeRequest {
    return AppLocalizeRequestFromJSONTyped(json, false);
}

export function AppLocalizeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppLocalizeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileNames': json['fileNames'],
        'playgroundMode': json['playgroundMode'],
        'appType': !exists(json, 'appType') ? undefined : AppTypeFromJSON(json['appType']),
    };
}

export function AppLocalizeRequestToJSON(value?: AppLocalizeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileNames': value.fileNames,
        'playgroundMode': value.playgroundMode,
        'appType': AppTypeToJSON(value.appType),
    };
}

