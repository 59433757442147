/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Filter } from './Filter';
import {
    FilterFromJSON,
    FilterFromJSONTyped,
    FilterToJSON,
} from './Filter';

/**
 * Filters an occurrence entity with a criteria-occurrence entity group (e.g. condition
 * occurrences for people with gender=female, condition=diabetes).
 * @export
 * @interface OccurrenceForPrimaryFilter
 */
export interface OccurrenceForPrimaryFilter {
    /**
     * 
     * @type {string}
     * @memberof OccurrenceForPrimaryFilter
     */
    entityGroup: string;
    /**
     * 
     * @type {string}
     * @memberof OccurrenceForPrimaryFilter
     */
    occurrenceEntity: string;
    /**
     * 
     * @type {Filter}
     * @memberof OccurrenceForPrimaryFilter
     */
    primarySubfilter?: Filter | null;
    /**
     * 
     * @type {Filter}
     * @memberof OccurrenceForPrimaryFilter
     */
    criteriaSubfilter?: Filter | null;
}

/**
 * Check if a given object implements the OccurrenceForPrimaryFilter interface.
 */
export function instanceOfOccurrenceForPrimaryFilter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entityGroup" in value;
    isInstance = isInstance && "occurrenceEntity" in value;

    return isInstance;
}

export function OccurrenceForPrimaryFilterFromJSON(json: any): OccurrenceForPrimaryFilter {
    return OccurrenceForPrimaryFilterFromJSONTyped(json, false);
}

export function OccurrenceForPrimaryFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): OccurrenceForPrimaryFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityGroup': json['entityGroup'],
        'occurrenceEntity': json['occurrenceEntity'],
        'primarySubfilter': !exists(json, 'primarySubfilter') ? undefined : FilterFromJSON(json['primarySubfilter']),
        'criteriaSubfilter': !exists(json, 'criteriaSubfilter') ? undefined : FilterFromJSON(json['criteriaSubfilter']),
    };
}

export function OccurrenceForPrimaryFilterToJSON(value?: OccurrenceForPrimaryFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entityGroup': value.entityGroup,
        'occurrenceEntity': value.occurrenceEntity,
        'primarySubfilter': FilterToJSON(value.primarySubfilter),
        'criteriaSubfilter': FilterToJSON(value.criteriaSubfilter),
    };
}

