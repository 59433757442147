/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Domain values map to various column names in OMOP.
 * @export
 * @interface DomainValue
 */
export interface DomainValue {
    /**
     * The user facing display name for the value.
     * @type {string}
     * @memberof DomainValue
     */
    value: string;
}

/**
 * Check if a given object implements the DomainValue interface.
 */
export function instanceOfDomainValue(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function DomainValueFromJSON(json: any): DomainValue {
    return DomainValueFromJSONTyped(json, false);
}

export function DomainValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): DomainValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
    };
}

export function DomainValueToJSON(value?: DomainValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
    };
}

