/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkspaceCreatorFreeCreditsRemainingResponse
 */
export interface WorkspaceCreatorFreeCreditsRemainingResponse {
    /**
     * amount of free credits remaining for the workspace creator, in USD
     * @type {number}
     * @memberof WorkspaceCreatorFreeCreditsRemainingResponse
     */
    freeCreditsRemaining: number;
}

/**
 * Check if a given object implements the WorkspaceCreatorFreeCreditsRemainingResponse interface.
 */
export function instanceOfWorkspaceCreatorFreeCreditsRemainingResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "freeCreditsRemaining" in value;

    return isInstance;
}

export function WorkspaceCreatorFreeCreditsRemainingResponseFromJSON(json: any): WorkspaceCreatorFreeCreditsRemainingResponse {
    return WorkspaceCreatorFreeCreditsRemainingResponseFromJSONTyped(json, false);
}

export function WorkspaceCreatorFreeCreditsRemainingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceCreatorFreeCreditsRemainingResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'freeCreditsRemaining': json['freeCreditsRemaining'],
    };
}

export function WorkspaceCreatorFreeCreditsRemainingResponseToJSON(value?: WorkspaceCreatorFreeCreditsRemainingResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'freeCreditsRemaining': value.freeCreditsRemaining,
    };
}

