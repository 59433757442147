/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * possible criteria types
 * @export
 */
export const CriteriaSubType = {
    ANSWER: 'ANSWER',
    BMI: 'BMI',
    BP: 'BP',
    CLIN: 'CLIN',
    HC: 'HC',
    HEIGHT: 'HEIGHT',
    HR: 'HR',
    HR_DETAIL: 'HR-DETAIL',
    HR_IRR: 'HR-IRR',
    HR_NOIRR: 'HR-NOIRR',
    LAB: 'LAB',
    PREG: 'PREG',
    QUESTION: 'QUESTION',
    SURVEY: 'SURVEY',
    WC: 'WC',
    WEIGHT: 'WEIGHT',
    WHEEL: 'WHEEL'
} as const;
export type CriteriaSubType = typeof CriteriaSubType[keyof typeof CriteriaSubType];


export function CriteriaSubTypeFromJSON(json: any): CriteriaSubType {
    return CriteriaSubTypeFromJSONTyped(json, false);
}

export function CriteriaSubTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CriteriaSubType {
    return json as CriteriaSubType;
}

export function CriteriaSubTypeToJSON(value?: CriteriaSubType | null): any {
    return value as any;
}

