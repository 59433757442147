/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Cohort } from './Cohort';
import {
    CohortFromJSON,
    CohortFromJSONTyped,
    CohortToJSON,
} from './Cohort';
import type { ConceptSet } from './ConceptSet';
import {
    ConceptSetFromJSON,
    ConceptSetFromJSONTyped,
    ConceptSetToJSON,
} from './ConceptSet';
import type { DomainValuePair } from './DomainValuePair';
import {
    DomainValuePairFromJSON,
    DomainValuePairFromJSONTyped,
    DomainValuePairToJSON,
} from './DomainValuePair';
import type { PrePackagedConceptSetEnum } from './PrePackagedConceptSetEnum';
import {
    PrePackagedConceptSetEnumFromJSON,
    PrePackagedConceptSetEnumFromJSONTyped,
    PrePackagedConceptSetEnumToJSON,
} from './PrePackagedConceptSetEnum';

/**
 * 
 * @export
 * @interface DataSet
 */
export interface DataSet {
    /**
     * 
     * @type {number}
     * @memberof DataSet
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DataSet
     */
    name?: string;
    /**
     * Entity tag for optimistic concurrency control. To be set during a read-modify-write to ensure that the client has not attempted to modify a changed resource.
     * @type {string}
     * @memberof DataSet
     */
    etag?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DataSet
     */
    includesAllParticipants?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DataSet
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof DataSet
     */
    workspaceId?: number;
    /**
     * The user who last modified this resource.
     * @type {string}
     * @memberof DataSet
     */
    lastModifiedBy?: string;
    /**
     * Milliseconds since the UNIX epoch.
     * @type {number}
     * @memberof DataSet
     */
    lastModifiedTime?: number;
    /**
     * All concept sets in the data set
     * @type {Array<ConceptSet>}
     * @memberof DataSet
     */
    conceptSets?: Array<ConceptSet>;
    /**
     * All cohorts in the data set
     * @type {Array<Cohort>}
     * @memberof DataSet
     */
    cohorts?: Array<Cohort>;
    /**
     * All the selected domain/value pairs in the data set
     * @type {Array<DomainValuePair>}
     * @memberof DataSet
     */
    domainValuePairs?: Array<DomainValuePair>;
    /**
     * List of pre Packaged concept set
     * @type {Array<PrePackagedConceptSetEnum>}
     * @memberof DataSet
     */
    prePackagedConceptSet?: Array<PrePackagedConceptSetEnum>;
}

/**
 * Check if a given object implements the DataSet interface.
 */
export function instanceOfDataSet(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataSetFromJSON(json: any): DataSet {
    return DataSetFromJSONTyped(json, false);
}

export function DataSetFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'etag': !exists(json, 'etag') ? undefined : json['etag'],
        'includesAllParticipants': !exists(json, 'includesAllParticipants') ? undefined : json['includesAllParticipants'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'workspaceId': !exists(json, 'workspaceId') ? undefined : json['workspaceId'],
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedTime': !exists(json, 'lastModifiedTime') ? undefined : json['lastModifiedTime'],
        'conceptSets': !exists(json, 'conceptSets') ? undefined : ((json['conceptSets'] as Array<any>).map(ConceptSetFromJSON)),
        'cohorts': !exists(json, 'cohorts') ? undefined : ((json['cohorts'] as Array<any>).map(CohortFromJSON)),
        'domainValuePairs': !exists(json, 'domainValuePairs') ? undefined : ((json['domainValuePairs'] as Array<any>).map(DomainValuePairFromJSON)),
        'prePackagedConceptSet': !exists(json, 'prePackagedConceptSet') ? undefined : ((json['prePackagedConceptSet'] as Array<any>).map(PrePackagedConceptSetEnumFromJSON)),
    };
}

export function DataSetToJSON(value?: DataSet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'etag': value.etag,
        'includesAllParticipants': value.includesAllParticipants,
        'description': value.description,
        'workspaceId': value.workspaceId,
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedTime': value.lastModifiedTime,
        'conceptSets': value.conceptSets === undefined ? undefined : ((value.conceptSets as Array<any>).map(ConceptSetToJSON)),
        'cohorts': value.cohorts === undefined ? undefined : ((value.cohorts as Array<any>).map(CohortToJSON)),
        'domainValuePairs': value.domainValuePairs === undefined ? undefined : ((value.domainValuePairs as Array<any>).map(DomainValuePairToJSON)),
        'prePackagedConceptSet': value.prePackagedConceptSet === undefined ? undefined : ((value.prePackagedConceptSet as Array<any>).map(PrePackagedConceptSetEnumToJSON)),
    };
}

