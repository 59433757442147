/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Terra job status. Indicates if the Terra Submission and Workflows are still running, succeeded, or failed. Effectively a collapsed model of Terras Submission status and Workflow status
 * @export
 */
export const TerraJobStatus = {
    RUNNING: 'RUNNING',
    FAILED: 'FAILED',
    SUCCEEDED: 'SUCCEEDED',
    ABORTED: 'ABORTED',
    ABORTING: 'ABORTING'
} as const;
export type TerraJobStatus = typeof TerraJobStatus[keyof typeof TerraJobStatus];


export function TerraJobStatusFromJSON(json: any): TerraJobStatus {
    return TerraJobStatusFromJSONTyped(json, false);
}

export function TerraJobStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): TerraJobStatus {
    return json as TerraJobStatus;
}

export function TerraJobStatusToJSON(value?: TerraJobStatus | null): any {
    return value as any;
}

