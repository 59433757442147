/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * a kubernetes app error
 * @export
 * @interface KubernetesError
 */
export interface KubernetesError {
    /**
     * a helpful error message
     * @type {string}
     * @memberof KubernetesError
     */
    errorMessage?: string;
    /**
     * timestamp for error in ISO 8601 format
     * @type {string}
     * @memberof KubernetesError
     */
    timestamp?: string;
    /**
     * the api-level action associated with the error, e.g. createApp
     * @type {string}
     * @memberof KubernetesError
     */
    action?: string;
    /**
     * the internal source associated with the error
     * @type {string}
     * @memberof KubernetesError
     */
    source?: string;
    /**
     * if the error is associated with an external API call, the error code will be propagated here
     * @type {number}
     * @memberof KubernetesError
     */
    googleErrorCode?: number;
}

/**
 * Check if a given object implements the KubernetesError interface.
 */
export function instanceOfKubernetesError(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function KubernetesErrorFromJSON(json: any): KubernetesError {
    return KubernetesErrorFromJSONTyped(json, false);
}

export function KubernetesErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): KubernetesError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
        'action': !exists(json, 'action') ? undefined : json['action'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'googleErrorCode': !exists(json, 'googleErrorCode') ? undefined : json['googleErrorCode'],
    };
}

export function KubernetesErrorToJSON(value?: KubernetesError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errorMessage': value.errorMessage,
        'timestamp': value.timestamp,
        'action': value.action,
        'source': value.source,
        'googleErrorCode': value.googleErrorCode,
    };
}

