/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Operator } from './Operator';
import {
    OperatorFromJSON,
    OperatorFromJSONTyped,
    OperatorToJSON,
} from './Operator';

/**
 * 
 * @export
 * @interface ParticipantCountFilter
 */
export interface ParticipantCountFilter {
    /**
     * 
     * @type {Operator}
     * @memberof ParticipantCountFilter
     */
    operator: Operator;
    /**
     * 
     * @type {Array<number>}
     * @memberof ParticipantCountFilter
     */
    operands: Array<number>;
}

/**
 * Check if a given object implements the ParticipantCountFilter interface.
 */
export function instanceOfParticipantCountFilter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "operator" in value;
    isInstance = isInstance && "operands" in value;

    return isInstance;
}

export function ParticipantCountFilterFromJSON(json: any): ParticipantCountFilter {
    return ParticipantCountFilterFromJSONTyped(json, false);
}

export function ParticipantCountFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantCountFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'operator': OperatorFromJSON(json['operator']),
        'operands': json['operands'],
    };
}

export function ParticipantCountFilterToJSON(value?: ParticipantCountFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'operator': OperatorToJSON(value.operator),
        'operands': value.operands,
    };
}

