/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Workspace } from './Workspace';
import {
    WorkspaceFromJSON,
    WorkspaceFromJSONTyped,
    WorkspaceToJSON,
} from './Workspace';
import type { WorkspaceAccessLevel } from './WorkspaceAccessLevel';
import {
    WorkspaceAccessLevelFromJSON,
    WorkspaceAccessLevelFromJSONTyped,
    WorkspaceAccessLevelToJSON,
} from './WorkspaceAccessLevel';

/**
 * 
 * @export
 * @interface WorkspaceResponse
 */
export interface WorkspaceResponse {
    /**
     * 
     * @type {Workspace}
     * @memberof WorkspaceResponse
     */
    workspace: Workspace;
    /**
     * 
     * @type {WorkspaceAccessLevel}
     * @memberof WorkspaceResponse
     */
    accessLevel: WorkspaceAccessLevel;
}

/**
 * Check if a given object implements the WorkspaceResponse interface.
 */
export function instanceOfWorkspaceResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "workspace" in value;
    isInstance = isInstance && "accessLevel" in value;

    return isInstance;
}

export function WorkspaceResponseFromJSON(json: any): WorkspaceResponse {
    return WorkspaceResponseFromJSONTyped(json, false);
}

export function WorkspaceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workspace': WorkspaceFromJSON(json['workspace']),
        'accessLevel': WorkspaceAccessLevelFromJSON(json['accessLevel']),
    };
}

export function WorkspaceResponseToJSON(value?: WorkspaceResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workspace': WorkspaceToJSON(value.workspace),
        'accessLevel': WorkspaceAccessLevelToJSON(value.accessLevel),
    };
}

