/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotebookRename
 */
export interface NotebookRename {
    /**
     * 
     * @type {string}
     * @memberof NotebookRename
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof NotebookRename
     */
    newName?: string;
}

/**
 * Check if a given object implements the NotebookRename interface.
 */
export function instanceOfNotebookRename(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotebookRenameFromJSON(json: any): NotebookRename {
    return NotebookRenameFromJSONTyped(json, false);
}

export function NotebookRenameFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotebookRename {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'newName': !exists(json, 'newName') ? undefined : json['newName'],
    };
}

export function NotebookRenameToJSON(value?: NotebookRename | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'newName': value.newName,
    };
}

