/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataDictionaryEntry
 */
export interface DataDictionaryEntry {
    /**
     * 
     * @type {string}
     * @memberof DataDictionaryEntry
     */
    relevantOmopTable?: string;
    /**
     * 
     * @type {string}
     * @memberof DataDictionaryEntry
     */
    fieldName?: string;
    /**
     * 
     * @type {string}
     * @memberof DataDictionaryEntry
     */
    omopCdmStandardOrCustomField?: string;
    /**
     * 
     * @type {string}
     * @memberof DataDictionaryEntry
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof DataDictionaryEntry
     */
    fieldType?: string;
    /**
     * 
     * @type {string}
     * @memberof DataDictionaryEntry
     */
    dataProvenance?: string;
    /**
     * 
     * @type {string}
     * @memberof DataDictionaryEntry
     */
    sourcePpiModule?: string;
}

/**
 * Check if a given object implements the DataDictionaryEntry interface.
 */
export function instanceOfDataDictionaryEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataDictionaryEntryFromJSON(json: any): DataDictionaryEntry {
    return DataDictionaryEntryFromJSONTyped(json, false);
}

export function DataDictionaryEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataDictionaryEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'relevantOmopTable': !exists(json, 'relevantOmopTable') ? undefined : json['relevantOmopTable'],
        'fieldName': !exists(json, 'fieldName') ? undefined : json['fieldName'],
        'omopCdmStandardOrCustomField': !exists(json, 'omopCdmStandardOrCustomField') ? undefined : json['omopCdmStandardOrCustomField'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'fieldType': !exists(json, 'fieldType') ? undefined : json['fieldType'],
        'dataProvenance': !exists(json, 'dataProvenance') ? undefined : json['dataProvenance'],
        'sourcePpiModule': !exists(json, 'sourcePpiModule') ? undefined : json['sourcePpiModule'],
    };
}

export function DataDictionaryEntryToJSON(value?: DataDictionaryEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'relevantOmopTable': value.relevantOmopTable,
        'fieldName': value.fieldName,
        'omopCdmStandardOrCustomField': value.omopCdmStandardOrCustomField,
        'description': value.description,
        'fieldType': value.fieldType,
        'dataProvenance': value.dataProvenance,
        'sourcePpiModule': value.sourcePpiModule,
    };
}

