/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents antipicated outcomes from the research
 * @export
 */
export const ResearchOutcomeEnum = {
    PROMOTE_HEALTHY_LIVING: 'PROMOTE_HEALTHY_LIVING',
    IMPROVE_HEALTH_EQUALITY_UBR_POPULATIONS: 'IMPROVE_HEALTH_EQUALITY_UBR_POPULATIONS',
    IMPROVED_RISK_ASSESMENT: 'IMPROVED_RISK_ASSESMENT',
    DECREASE_ILLNESS_BURDEN: 'DECREASE_ILLNESS_BURDEN',
    PRECISION_INTERVENTION: 'PRECISION_INTERVENTION',
    NONE_APPLY: 'NONE_APPLY'
} as const;
export type ResearchOutcomeEnum = typeof ResearchOutcomeEnum[keyof typeof ResearchOutcomeEnum];


export function ResearchOutcomeEnumFromJSON(json: any): ResearchOutcomeEnum {
    return ResearchOutcomeEnumFromJSONTyped(json, false);
}

export function ResearchOutcomeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResearchOutcomeEnum {
    return json as ResearchOutcomeEnum;
}

export function ResearchOutcomeEnumToJSON(value?: ResearchOutcomeEnum | null): any {
    return value as any;
}

