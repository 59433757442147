/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Which terms best express how you describe your current sexual orientation? (Select all that apply)
 * @export
 */
export const SexualOrientationV2 = {
    ASEXUAL: 'ASEXUAL',
    BISEXUAL: 'BISEXUAL',
    GAY: 'GAY',
    LESBIAN: 'LESBIAN',
    POLYSEXUAL: 'POLYSEXUAL',
    QUEER: 'QUEER',
    QUESTIONING: 'QUESTIONING',
    SAME_GENDER: 'SAME_GENDER',
    STRAIGHT: 'STRAIGHT',
    TWO_SPIRIT: 'TWO_SPIRIT',
    OTHER: 'OTHER',
    PREFER_NOT_TO_ANSWER: 'PREFER_NOT_TO_ANSWER'
} as const;
export type SexualOrientationV2 = typeof SexualOrientationV2[keyof typeof SexualOrientationV2];


export function SexualOrientationV2FromJSON(json: any): SexualOrientationV2 {
    return SexualOrientationV2FromJSONTyped(json, false);
}

export function SexualOrientationV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): SexualOrientationV2 {
    return json as SexualOrientationV2;
}

export function SexualOrientationV2ToJSON(value?: SexualOrientationV2 | null): any {
    return value as any;
}

