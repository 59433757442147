/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * a survey for concepts
 * @export
 */
export const Surveys = {
    THE_BASICS: 'THE BASICS',
    LIFESTYLE: 'LIFESTYLE',
    OVERALL_HEALTH: 'OVERALL HEALTH'
} as const;
export type Surveys = typeof Surveys[keyof typeof Surveys];


export function SurveysFromJSON(json: any): Surveys {
    return SurveysFromJSONTyped(json, false);
}

export function SurveysFromJSONTyped(json: any, ignoreDiscriminator: boolean): Surveys {
    return json as Surveys;
}

export function SurveysToJSON(value?: Surveys | null): any {
    return value as any;
}

