/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CohortChartData
 */
export interface CohortChartData {
    /**
     * the name of this data
     * @type {string}
     * @memberof CohortChartData
     */
    name: string;
    /**
     * the source concept id for this data
     * @type {number}
     * @memberof CohortChartData
     */
    conceptId: number;
    /**
     * the count for this data
     * @type {number}
     * @memberof CohortChartData
     */
    count: number;
}

/**
 * Check if a given object implements the CohortChartData interface.
 */
export function instanceOfCohortChartData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "conceptId" in value;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function CohortChartDataFromJSON(json: any): CohortChartData {
    return CohortChartDataFromJSONTyped(json, false);
}

export function CohortChartDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CohortChartData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'conceptId': json['conceptId'],
        'count': json['count'],
    };
}

export function CohortChartDataToJSON(value?: CohortChartData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'conceptId': value.conceptId,
        'count': value.count,
    };
}

