/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataprocConfig } from './DataprocConfig';
import {
    DataprocConfigFromJSON,
    DataprocConfigFromJSONTyped,
    DataprocConfigToJSON,
} from './DataprocConfig';
import type { GceConfig } from './GceConfig';
import {
    GceConfigFromJSON,
    GceConfigFromJSONTyped,
    GceConfigToJSON,
} from './GceConfig';
import type { GceWithPdConfig } from './GceWithPdConfig';
import {
    GceWithPdConfigFromJSON,
    GceWithPdConfigFromJSONTyped,
    GceWithPdConfigToJSON,
} from './GceWithPdConfig';
import type { RuntimeConfigurationType } from './RuntimeConfigurationType';
import {
    RuntimeConfigurationTypeFromJSON,
    RuntimeConfigurationTypeFromJSONTyped,
    RuntimeConfigurationTypeToJSON,
} from './RuntimeConfigurationType';
import type { RuntimeError } from './RuntimeError';
import {
    RuntimeErrorFromJSON,
    RuntimeErrorFromJSONTyped,
    RuntimeErrorToJSON,
} from './RuntimeError';
import type { RuntimeStatus } from './RuntimeStatus';
import {
    RuntimeStatusFromJSON,
    RuntimeStatusFromJSONTyped,
    RuntimeStatusToJSON,
} from './RuntimeStatus';

/**
 * 
 * @export
 * @interface Runtime
 */
export interface Runtime {
    /**
     * The Google Project used to create the runtime. Read only.
     * @type {string}
     * @memberof Runtime
     */
    googleProject?: string;
    /**
     * The user-supplied name for the runtime. Read only.
     * @type {string}
     * @memberof Runtime
     */
    runtimeName?: string;
    /**
     * The date and time the runtime was created, in ISO-8601 format. Read only.
     * @type {string}
     * @memberof Runtime
     */
    createdDate?: string;
    /**
     * 
     * @type {RuntimeStatus}
     * @memberof Runtime
     */
    status?: RuntimeStatus;
    /**
     * The number of minutes of idle time to elapse before the cluster is autopaused. If autopause is set to false, this value is disregarded. A value of 0 is equivalent to autopause being turned off.
     * @type {number}
     * @memberof Runtime
     */
    autopauseThreshold?: number;
    /**
     * 
     * @type {RuntimeConfigurationType}
     * @memberof Runtime
     */
    configurationType?: RuntimeConfigurationType;
    /**
     * The tool docker image to install. May be Dockerhub or GCR. If not set, a default Jupyter image will be installed.
     * @type {string}
     * @memberof Runtime
     */
    toolDockerImage?: string;
    /**
     * 
     * @type {GceConfig}
     * @memberof Runtime
     */
    gceConfig?: GceConfig;
    /**
     * 
     * @type {GceWithPdConfig}
     * @memberof Runtime
     */
    gceWithPdConfig?: GceWithPdConfig;
    /**
     * 
     * @type {DataprocConfig}
     * @memberof Runtime
     */
    dataprocConfig?: DataprocConfig;
    /**
     * The list of errors that were encountered on runtime create, if any.
     * @type {Array<RuntimeError>}
     * @memberof Runtime
     */
    errors?: Array<RuntimeError>;
}

/**
 * Check if a given object implements the Runtime interface.
 */
export function instanceOfRuntime(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RuntimeFromJSON(json: any): Runtime {
    return RuntimeFromJSONTyped(json, false);
}

export function RuntimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Runtime {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'googleProject': !exists(json, 'googleProject') ? undefined : json['googleProject'],
        'runtimeName': !exists(json, 'runtimeName') ? undefined : json['runtimeName'],
        'createdDate': !exists(json, 'createdDate') ? undefined : json['createdDate'],
        'status': !exists(json, 'status') ? undefined : RuntimeStatusFromJSON(json['status']),
        'autopauseThreshold': !exists(json, 'autopauseThreshold') ? undefined : json['autopauseThreshold'],
        'configurationType': !exists(json, 'configurationType') ? undefined : RuntimeConfigurationTypeFromJSON(json['configurationType']),
        'toolDockerImage': !exists(json, 'toolDockerImage') ? undefined : json['toolDockerImage'],
        'gceConfig': !exists(json, 'gceConfig') ? undefined : GceConfigFromJSON(json['gceConfig']),
        'gceWithPdConfig': !exists(json, 'gceWithPdConfig') ? undefined : GceWithPdConfigFromJSON(json['gceWithPdConfig']),
        'dataprocConfig': !exists(json, 'dataprocConfig') ? undefined : DataprocConfigFromJSON(json['dataprocConfig']),
        'errors': !exists(json, 'errors') ? undefined : ((json['errors'] as Array<any>).map(RuntimeErrorFromJSON)),
    };
}

export function RuntimeToJSON(value?: Runtime | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'googleProject': value.googleProject,
        'runtimeName': value.runtimeName,
        'createdDate': value.createdDate,
        'status': RuntimeStatusToJSON(value.status),
        'autopauseThreshold': value.autopauseThreshold,
        'configurationType': RuntimeConfigurationTypeToJSON(value.configurationType),
        'toolDockerImage': value.toolDockerImage,
        'gceConfig': GceConfigToJSON(value.gceConfig),
        'gceWithPdConfig': GceWithPdConfigToJSON(value.gceWithPdConfig),
        'dataprocConfig': DataprocConfigToJSON(value.dataprocConfig),
        'errors': value.errors === undefined ? undefined : ((value.errors as Array<any>).map(RuntimeErrorToJSON)),
    };
}

