/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Literal } from './Literal';
import {
    LiteralFromJSON,
    LiteralFromJSONTyped,
    LiteralToJSON,
} from './Literal';

/**
 * Filter on an annotation value (e.g. reviewStatus=complete)
 * @export
 * @interface AnnotationFilter
 */
export interface AnnotationFilter {
    /**
     * 
     * @type {string}
     * @memberof AnnotationFilter
     */
    annotation?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationFilter
     */
    operator?: AnnotationFilterOperatorEnum;
    /**
     * 
     * @type {Literal}
     * @memberof AnnotationFilter
     */
    value?: Literal | null;
}


/**
 * @export
 */
export const AnnotationFilterOperatorEnum = {
    Equals: 'EQUALS',
    NotEquals: 'NOT_EQUALS',
    LessThan: 'LESS_THAN',
    GreaterThan: 'GREATER_THAN'
} as const;
export type AnnotationFilterOperatorEnum = typeof AnnotationFilterOperatorEnum[keyof typeof AnnotationFilterOperatorEnum];


/**
 * Check if a given object implements the AnnotationFilter interface.
 */
export function instanceOfAnnotationFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AnnotationFilterFromJSON(json: any): AnnotationFilter {
    return AnnotationFilterFromJSONTyped(json, false);
}

export function AnnotationFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnotationFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annotation': !exists(json, 'annotation') ? undefined : json['annotation'],
        'operator': !exists(json, 'operator') ? undefined : json['operator'],
        'value': !exists(json, 'value') ? undefined : LiteralFromJSON(json['value']),
    };
}

export function AnnotationFilterToJSON(value?: AnnotationFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annotation': value.annotation,
        'operator': value.operator,
        'value': LiteralToJSON(value.value),
    };
}

