export enum BreadcrumbType {
  Workspaces = 'Workspaces',
  Workspace = 'Workspace',
  WorkspaceEdit = 'WorkspaceEdit',
  WorkspaceDuplicate = 'WorkspaceDuplicate',
  Analysis = 'Analysis',
  AnalysisPreview = 'AnalysisPreview',
  UserApp = 'UserApp',
  ConceptSet = 'ConceptSet',
  Cohort = 'Cohort',
  CohortReview = 'CohortReview',
  Participant = 'Participant',
  CohortAdd = 'CohortAdd',
  SearchConcepts = 'SearchConcepts',
  Dataset = 'Dataset',
  Data = 'Data',
}
