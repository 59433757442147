/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The configuration type for this runtime. Namely, whether there was a user override of a specific cloud service type, or whether one of the preset system defaults was used. This distinction is meaningful for display purposes, and allows the user to pickup natural upgrades to the default configuration.
 * @export
 */
export const RuntimeConfigurationType = {
    USER_OVERRIDE: 'UserOverride',
    GENERAL_ANALYSIS: 'GeneralAnalysis',
    HAIL_GENOMIC_ANALYSIS: 'HailGenomicAnalysis'
} as const;
export type RuntimeConfigurationType = typeof RuntimeConfigurationType[keyof typeof RuntimeConfigurationType];


export function RuntimeConfigurationTypeFromJSON(json: any): RuntimeConfigurationType {
    return RuntimeConfigurationTypeFromJSONTyped(json, false);
}

export function RuntimeConfigurationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuntimeConfigurationType {
    return json as RuntimeConfigurationType;
}

export function RuntimeConfigurationTypeToJSON(value?: RuntimeConfigurationType | null): any {
    return value as any;
}

