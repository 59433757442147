/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CriteriaAttribute
 */
export interface CriteriaAttribute {
    /**
     * id of the criteria
     * @type {number}
     * @memberof CriteriaAttribute
     */
    id: number;
    /**
     * categorical concept id
     * @type {number}
     * @memberof CriteriaAttribute
     */
    valueAsConceptId: number;
    /**
     * name of concept
     * @type {string}
     * @memberof CriteriaAttribute
     */
    conceptName: string;
    /**
     * numerical or categorical
     * @type {string}
     * @memberof CriteriaAttribute
     */
    type: string;
    /**
     * possible count
     * @type {string}
     * @memberof CriteriaAttribute
     */
    estCount: string;
}

/**
 * Check if a given object implements the CriteriaAttribute interface.
 */
export function instanceOfCriteriaAttribute(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "valueAsConceptId" in value;
    isInstance = isInstance && "conceptName" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "estCount" in value;

    return isInstance;
}

export function CriteriaAttributeFromJSON(json: any): CriteriaAttribute {
    return CriteriaAttributeFromJSONTyped(json, false);
}

export function CriteriaAttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CriteriaAttribute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'valueAsConceptId': json['valueAsConceptId'],
        'conceptName': json['conceptName'],
        'type': json['type'],
        'estCount': json['estCount'],
    };
}

export function CriteriaAttributeToJSON(value?: CriteriaAttribute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'valueAsConceptId': value.valueAsConceptId,
        'conceptName': value.conceptName,
        'type': value.type,
        'estCount': value.estCount,
    };
}

