/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AccountPropertyUpdate,
  AdminUserListResponse,
  BatchSyncAccessRequest,
  BatchSyncAccessResponse,
  CreateEgressBypassWindowRequest,
  EmptyResponse,
  ErrorResponse,
  ListEgressBypassWindowResponse,
  Profile,
  UserAuditLogQueryResponse,
} from '../models';
import {
    AccountPropertyUpdateFromJSON,
    AccountPropertyUpdateToJSON,
    AdminUserListResponseFromJSON,
    AdminUserListResponseToJSON,
    BatchSyncAccessRequestFromJSON,
    BatchSyncAccessRequestToJSON,
    BatchSyncAccessResponseFromJSON,
    BatchSyncAccessResponseToJSON,
    CreateEgressBypassWindowRequestFromJSON,
    CreateEgressBypassWindowRequestToJSON,
    EmptyResponseFromJSON,
    EmptyResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ListEgressBypassWindowResponseFromJSON,
    ListEgressBypassWindowResponseToJSON,
    ProfileFromJSON,
    ProfileToJSON,
    UserAuditLogQueryResponseFromJSON,
    UserAuditLogQueryResponseToJSON,
} from '../models';

export interface UserAdminApiBatchSyncAccessOperationRequest {
    request?: BatchSyncAccessRequest;
}

export interface UserAdminApiCreateEgressBypassWindowOperationRequest {
    userId: number;
    request?: CreateEgressBypassWindowRequest;
}

export interface UserAdminApiGetUserAuditLogEntriesRequest {
    usernameWithoutGsuiteDomain: string;
    limit?: number;
    after?: number;
    before?: number;
}

export interface UserAdminApiGetUserByUsernameRequest {
    username: string;
}

export interface UserAdminApiListEgressBypassWindowsRequest {
    userId: number;
}

export interface UserAdminApiUpdateAccountPropertiesRequest {
    request?: AccountPropertyUpdate;
}

/**
 * 
 */
export class UserAdminApi extends runtime.BaseAPI {

    /**
     * Batch sync user access modules, asynchronously. This is a work around until SAM supports batch update. See https://broadworkbench.atlassian.net/browse/CA-1504
     */
    async batchSyncAccessRaw(requestParameters: UserAdminApiBatchSyncAccessOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BatchSyncAccessResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/users/batchSyncAccess`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BatchSyncAccessRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchSyncAccessResponseFromJSON(jsonValue));
    }

    /**
     * Batch sync user access modules, asynchronously. This is a work around until SAM supports batch update. See https://broadworkbench.atlassian.net/browse/CA-1504
     */
    async batchSyncAccess(request?: BatchSyncAccessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BatchSyncAccessResponse> {
        const response = await this.batchSyncAccessRaw({ request: request }, initOverrides);
        return await response.value();
    }

    /**
     * Administrative API to add egress bypass window to allow user download files without  triggering egress alerts. Require SECURITY_ADMIN authority 
     */
    async createEgressBypassWindowRaw(requestParameters: UserAdminApiCreateEgressBypassWindowOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createEgressBypassWindow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/users/{userId}/egressBypassWindow`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEgressBypassWindowRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Administrative API to add egress bypass window to allow user download files without  triggering egress alerts. Require SECURITY_ADMIN authority 
     */
    async createEgressBypassWindow(userId: number, request?: CreateEgressBypassWindowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createEgressBypassWindowRaw({ userId: userId, request: request }, initOverrides);
    }

    /**
     * Returns a list of profiles for users to be reviewed. Requires ACCESS_CONTROL_ADMIN authority.
     */
    async getAllUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminUserListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/users/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminUserListResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of profiles for users to be reviewed. Requires ACCESS_CONTROL_ADMIN authority.
     */
    async getAllUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminUserListResponse> {
        const response = await this.getAllUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Fetch audit logs involving this user as either a subject or object. Requires the RESEARCHER_DATA_VIEW authority. 
     */
    async getUserAuditLogEntriesRaw(requestParameters: UserAdminApiGetUserAuditLogEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserAuditLogQueryResponse>> {
        if (requestParameters.usernameWithoutGsuiteDomain === null || requestParameters.usernameWithoutGsuiteDomain === undefined) {
            throw new runtime.RequiredError('usernameWithoutGsuiteDomain','Required parameter requestParameters.usernameWithoutGsuiteDomain was null or undefined when calling getUserAuditLogEntries.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        if (requestParameters.before !== undefined) {
            queryParameters['before'] = requestParameters.before;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/users/{usernameWithoutGsuiteDomain}/audit`.replace(`{${"usernameWithoutGsuiteDomain"}}`, encodeURIComponent(String(requestParameters.usernameWithoutGsuiteDomain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAuditLogQueryResponseFromJSON(jsonValue));
    }

    /**
     * Fetch audit logs involving this user as either a subject or object. Requires the RESEARCHER_DATA_VIEW authority. 
     */
    async getUserAuditLogEntries(usernameWithoutGsuiteDomain: string, limit?: number, after?: number, before?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserAuditLogQueryResponse> {
        const response = await this.getUserAuditLogEntriesRaw({ usernameWithoutGsuiteDomain: usernameWithoutGsuiteDomain, limit: limit, after: after, before: before }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a user\'s profile for review. Requires ACCESS_CONTROL_ADMIN authority.
     */
    async getUserByUsernameRaw(requestParameters: UserAdminApiGetUserByUsernameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Profile>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling getUserByUsername.');
        }

        const queryParameters: any = {};

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/users/getUserByUsername`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
    }

    /**
     * Returns a user\'s profile for review. Requires ACCESS_CONTROL_ADMIN authority.
     */
    async getUserByUsername(username: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Profile> {
        const response = await this.getUserByUsernameRaw({ username: username }, initOverrides);
        return await response.value();
    }

    /**
     * List all of the user\'s egress bypass windows.  Requires SECURITY_ADMIN authority 
     */
    async listEgressBypassWindowsRaw(requestParameters: UserAdminApiListEgressBypassWindowsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListEgressBypassWindowResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listEgressBypassWindows.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/users/{userId}/egressBypassWindow`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListEgressBypassWindowResponseFromJSON(jsonValue));
    }

    /**
     * List all of the user\'s egress bypass windows.  Requires SECURITY_ADMIN authority 
     */
    async listEgressBypassWindows(userId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListEgressBypassWindowResponse> {
        const response = await this.listEgressBypassWindowsRaw({ userId: userId }, initOverrides);
        return await response.value();
    }

    /**
     * Updates the given user to bypass all access requirements
     */
    async unsafeSelfBypassAccessRequirementsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/unsafe-self-bypass-access-requirements`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Updates the given user to bypass all access requirements
     */
    async unsafeSelfBypassAccessRequirements(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.unsafeSelfBypassAccessRequirementsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates a subset of a user\'s account metadata, as defined by the AccountPropertyUpdate object.  Requires ACCESS_CONTROL_ADMIN authority.
     */
    async updateAccountPropertiesRaw(requestParameters: UserAdminApiUpdateAccountPropertiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Profile>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/admin/users/updateAccount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountPropertyUpdateToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
    }

    /**
     * Updates a subset of a user\'s account metadata, as defined by the AccountPropertyUpdate object.  Requires ACCESS_CONTROL_ADMIN authority.
     */
    async updateAccountProperties(request?: AccountPropertyUpdate, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Profile> {
        const response = await this.updateAccountPropertiesRaw({ request: request }, initOverrides);
        return await response.value();
    }

}
