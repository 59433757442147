/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConceptSet,
  ConceptSetListResponse,
  CopyRequest,
  CreateConceptSetRequest,
  EmptyResponse,
  UpdateConceptSetRequest,
} from '../models';
import {
    ConceptSetFromJSON,
    ConceptSetToJSON,
    ConceptSetListResponseFromJSON,
    ConceptSetListResponseToJSON,
    CopyRequestFromJSON,
    CopyRequestToJSON,
    CreateConceptSetRequestFromJSON,
    CreateConceptSetRequestToJSON,
    EmptyResponseFromJSON,
    EmptyResponseToJSON,
    UpdateConceptSetRequestFromJSON,
    UpdateConceptSetRequestToJSON,
} from '../models';

export interface ConceptSetsApiCopyConceptSetRequest {
    workspaceNamespace: string;
    terraName: string;
    conceptSetId: string;
    copyConceptSetRequest: CopyRequest;
}

export interface ConceptSetsApiCountConceptsInConceptSetRequest {
    workspaceNamespace: string;
    terraName: string;
    conceptSetId: number;
}

export interface ConceptSetsApiCreateConceptSetOperationRequest {
    workspaceNamespace: string;
    terraName: string;
    request?: CreateConceptSetRequest;
}

export interface ConceptSetsApiDeleteConceptSetRequest {
    workspaceNamespace: string;
    terraName: string;
    conceptSetId: number;
}

export interface ConceptSetsApiGetConceptSetRequest {
    workspaceNamespace: string;
    terraName: string;
    conceptSetId: number;
}

export interface ConceptSetsApiGetConceptSetsInWorkspaceRequest {
    workspaceNamespace: string;
    terraName: string;
}

export interface ConceptSetsApiUpdateConceptSetRequest {
    workspaceNamespace: string;
    terraName: string;
    conceptSetId: number;
    conceptSet?: ConceptSet;
}

export interface ConceptSetsApiUpdateConceptSetConceptsRequest {
    workspaceNamespace: string;
    terraName: string;
    conceptSetId: number;
    request?: UpdateConceptSetRequest;
}

/**
 * 
 */
export class ConceptSetsApi extends runtime.BaseAPI {

    /**
     * Copy specified concept set in path to specified workspace in body
     */
    async copyConceptSetRaw(requestParameters: ConceptSetsApiCopyConceptSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConceptSet>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling copyConceptSet.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling copyConceptSet.');
        }

        if (requestParameters.conceptSetId === null || requestParameters.conceptSetId === undefined) {
            throw new runtime.RequiredError('conceptSetId','Required parameter requestParameters.conceptSetId was null or undefined when calling copyConceptSet.');
        }

        if (requestParameters.copyConceptSetRequest === null || requestParameters.copyConceptSetRequest === undefined) {
            throw new runtime.RequiredError('copyConceptSetRequest','Required parameter requestParameters.copyConceptSetRequest was null or undefined when calling copyConceptSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/concept-sets/{conceptSetId}/copy`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"conceptSetId"}}`, encodeURIComponent(String(requestParameters.conceptSetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CopyRequestToJSON(requestParameters.copyConceptSetRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConceptSetFromJSON(jsonValue));
    }

    /**
     * Copy specified concept set in path to specified workspace in body
     */
    async copyConceptSet(workspaceNamespace: string, terraName: string, conceptSetId: string, copyConceptSetRequest: CopyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConceptSet> {
        const response = await this.copyConceptSetRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, conceptSetId: conceptSetId, copyConceptSetRequest: copyConceptSetRequest }, initOverrides);
        return await response.value();
    }

    /**
     * The number of concepts in this concept-set.
     */
    async countConceptsInConceptSetRaw(requestParameters: ConceptSetsApiCountConceptsInConceptSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling countConceptsInConceptSet.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling countConceptsInConceptSet.');
        }

        if (requestParameters.conceptSetId === null || requestParameters.conceptSetId === undefined) {
            throw new runtime.RequiredError('conceptSetId','Required parameter requestParameters.conceptSetId was null or undefined when calling countConceptsInConceptSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/concept-sets/{conceptSetId}/concept-count`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"conceptSetId"}}`, encodeURIComponent(String(requestParameters.conceptSetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * The number of concepts in this concept-set.
     */
    async countConceptsInConceptSet(workspaceNamespace: string, terraName: string, conceptSetId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countConceptsInConceptSetRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, conceptSetId: conceptSetId }, initOverrides);
        return await response.value();
    }

    /**
     * Creates a concept set in a workspace.
     */
    async createConceptSetRaw(requestParameters: ConceptSetsApiCreateConceptSetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConceptSet>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling createConceptSet.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling createConceptSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/concept-sets`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateConceptSetRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConceptSetFromJSON(jsonValue));
    }

    /**
     * Creates a concept set in a workspace.
     */
    async createConceptSet(workspaceNamespace: string, terraName: string, request?: CreateConceptSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConceptSet> {
        const response = await this.createConceptSetRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, request: request }, initOverrides);
        return await response.value();
    }

    /**
     * Deletes the concept set with the specified ID
     */
    async deleteConceptSetRaw(requestParameters: ConceptSetsApiDeleteConceptSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling deleteConceptSet.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling deleteConceptSet.');
        }

        if (requestParameters.conceptSetId === null || requestParameters.conceptSetId === undefined) {
            throw new runtime.RequiredError('conceptSetId','Required parameter requestParameters.conceptSetId was null or undefined when calling deleteConceptSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/concept-sets/{conceptSetId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"conceptSetId"}}`, encodeURIComponent(String(requestParameters.conceptSetId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Deletes the concept set with the specified ID
     */
    async deleteConceptSet(workspaceNamespace: string, terraName: string, conceptSetId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.deleteConceptSetRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, conceptSetId: conceptSetId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the concept set definition with the specified ID
     */
    async getConceptSetRaw(requestParameters: ConceptSetsApiGetConceptSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConceptSet>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getConceptSet.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getConceptSet.');
        }

        if (requestParameters.conceptSetId === null || requestParameters.conceptSetId === undefined) {
            throw new runtime.RequiredError('conceptSetId','Required parameter requestParameters.conceptSetId was null or undefined when calling getConceptSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/concept-sets/{conceptSetId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"conceptSetId"}}`, encodeURIComponent(String(requestParameters.conceptSetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConceptSetFromJSON(jsonValue));
    }

    /**
     * Returns the concept set definition with the specified ID
     */
    async getConceptSet(workspaceNamespace: string, terraName: string, conceptSetId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConceptSet> {
        const response = await this.getConceptSetRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, conceptSetId: conceptSetId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns all concept sets in a workspace
     */
    async getConceptSetsInWorkspaceRaw(requestParameters: ConceptSetsApiGetConceptSetsInWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConceptSetListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getConceptSetsInWorkspace.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getConceptSetsInWorkspace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/concept-sets`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConceptSetListResponseFromJSON(jsonValue));
    }

    /**
     * Returns all concept sets in a workspace
     */
    async getConceptSetsInWorkspace(workspaceNamespace: string, terraName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConceptSetListResponse> {
        const response = await this.getConceptSetsInWorkspaceRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName }, initOverrides);
        return await response.value();
    }

    /**
     * Modifies the name or description of the concept set with the specified ID; fields that are omitted will not be modified
     */
    async updateConceptSetRaw(requestParameters: ConceptSetsApiUpdateConceptSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConceptSet>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling updateConceptSet.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling updateConceptSet.');
        }

        if (requestParameters.conceptSetId === null || requestParameters.conceptSetId === undefined) {
            throw new runtime.RequiredError('conceptSetId','Required parameter requestParameters.conceptSetId was null or undefined when calling updateConceptSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/concept-sets/{conceptSetId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"conceptSetId"}}`, encodeURIComponent(String(requestParameters.conceptSetId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ConceptSetToJSON(requestParameters.conceptSet),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConceptSetFromJSON(jsonValue));
    }

    /**
     * Modifies the name or description of the concept set with the specified ID; fields that are omitted will not be modified
     */
    async updateConceptSet(workspaceNamespace: string, terraName: string, conceptSetId: number, conceptSet?: ConceptSet, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConceptSet> {
        const response = await this.updateConceptSetRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, conceptSetId: conceptSetId, conceptSet: conceptSet }, initOverrides);
        return await response.value();
    }

    /**
     * Adds or removes concepts from the concept set.
     */
    async updateConceptSetConceptsRaw(requestParameters: ConceptSetsApiUpdateConceptSetConceptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConceptSet>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling updateConceptSetConcepts.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling updateConceptSetConcepts.');
        }

        if (requestParameters.conceptSetId === null || requestParameters.conceptSetId === undefined) {
            throw new runtime.RequiredError('conceptSetId','Required parameter requestParameters.conceptSetId was null or undefined when calling updateConceptSetConcepts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/concept-sets/{conceptSetId}/concepts`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"conceptSetId"}}`, encodeURIComponent(String(requestParameters.conceptSetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateConceptSetRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConceptSetFromJSON(jsonValue));
    }

    /**
     * Adds or removes concepts from the concept set.
     */
    async updateConceptSetConcepts(workspaceNamespace: string, terraName: string, conceptSetId: number, request?: UpdateConceptSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConceptSet> {
        const response = await this.updateConceptSetConceptsRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, conceptSetId: conceptSetId, request: request }, initOverrides);
        return await response.value();
    }

}
