/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The language used for code generation in the Dataset Builder.
 * In the context of Jupyter notebook files, this value is equal to the kernel in KernelTypeEnum.
 * @export
 */
export const AnalysisLanguage = {
    PYTHON: 'Python',
    R: 'R',
    SAS: 'SAS'
} as const;
export type AnalysisLanguage = typeof AnalysisLanguage[keyof typeof AnalysisLanguage];


export function AnalysisLanguageFromJSON(json: any): AnalysisLanguage {
    return AnalysisLanguageFromJSONTyped(json, false);
}

export function AnalysisLanguageFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalysisLanguage {
    return json as AnalysisLanguage;
}

export function AnalysisLanguageToJSON(value?: AnalysisLanguage | null): any {
    return value as any;
}

