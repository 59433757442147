/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * possible criteria types
 * @export
 */
export const CriteriaType = {
    AGE: 'AGE',
    ATC: 'ATC',
    BRAND: 'BRAND',
    CPT4: 'CPT4',
    DECEASED: 'DECEASED',
    ETHNICITY: 'ETHNICITY',
    GENDER: 'GENDER',
    HCPCS: 'HCPCS',
    ICD10_CM: 'ICD10CM',
    ICD10_PCS: 'ICD10PCS',
    ICD9_CM: 'ICD9CM',
    ICD9_PROC: 'ICD9Proc',
    LOINC: 'LOINC',
    PPI: 'PPI',
    RACE: 'RACE',
    RXNORM: 'RXNORM',
    SEX: 'SEX',
    SNOMED: 'SNOMED',
    VISIT: 'VISIT',
    NONE: 'NONE',
    HAS_EHR_DATA: 'HAS_EHR_DATA',
    SELF_REPORTED_CATEGORY: 'SELF_REPORTED_CATEGORY'
} as const;
export type CriteriaType = typeof CriteriaType[keyof typeof CriteriaType];


export function CriteriaTypeFromJSON(json: any): CriteriaType {
    return CriteriaTypeFromJSONTyped(json, false);
}

export function CriteriaTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CriteriaType {
    return json as CriteriaType;
}

export function CriteriaTypeToJSON(value?: CriteriaType | null): any {
    return value as any;
}

