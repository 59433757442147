/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendBillingSetupEmailRequest
 */
export interface SendBillingSetupEmailRequest {
    /**
     * User contact phone
     * @type {string}
     * @memberof SendBillingSetupEmailRequest
     */
    phone?: string;
    /**
     * The insitution of the user.
     * @type {string}
     * @memberof SendBillingSetupEmailRequest
     */
    institution?: string;
    /**
     * Whether its NIH Funded research
     * @type {boolean}
     * @memberof SendBillingSetupEmailRequest
     */
    nihFunded?: boolean;
}

/**
 * Check if a given object implements the SendBillingSetupEmailRequest interface.
 */
export function instanceOfSendBillingSetupEmailRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SendBillingSetupEmailRequestFromJSON(json: any): SendBillingSetupEmailRequest {
    return SendBillingSetupEmailRequestFromJSONTyped(json, false);
}

export function SendBillingSetupEmailRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendBillingSetupEmailRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'institution': !exists(json, 'institution') ? undefined : json['institution'],
        'nihFunded': !exists(json, 'nihFunded') ? undefined : json['nihFunded'],
    };
}

export function SendBillingSetupEmailRequestToJSON(value?: SendBillingSetupEmailRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phone': value.phone,
        'institution': value.institution,
        'nihFunded': value.nihFunded,
    };
}

