/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Workbench processing status for this egress event.
 * @export
 */
export const EgressEventStatus = {
    BYPASSED: 'BYPASSED',
    PENDING: 'PENDING',
    REMEDIATED: 'REMEDIATED',
    VERIFIED_FALSE_POSITIVE: 'VERIFIED_FALSE_POSITIVE'
} as const;
export type EgressEventStatus = typeof EgressEventStatus[keyof typeof EgressEventStatus];


export function EgressEventStatusFromJSON(json: any): EgressEventStatus {
    return EgressEventStatusFromJSONTyped(json, false);
}

export function EgressEventStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EgressEventStatus {
    return json as EgressEventStatus;
}

export function EgressEventStatusToJSON(value?: EgressEventStatus | null): any {
    return value as any;
}

