/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Domain } from './Domain';
import {
    DomainFromJSON,
    DomainFromJSONTyped,
    DomainToJSON,
} from './Domain';

/**
 * 
 * @export
 * @interface DomainValuePair
 */
export interface DomainValuePair {
    /**
     * 
     * @type {Domain}
     * @memberof DomainValuePair
     */
    domain?: Domain;
    /**
     * 
     * @type {string}
     * @memberof DomainValuePair
     */
    value?: string;
}

/**
 * Check if a given object implements the DomainValuePair interface.
 */
export function instanceOfDomainValuePair(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DomainValuePairFromJSON(json: any): DomainValuePair {
    return DomainValuePairFromJSONTyped(json, false);
}

export function DomainValuePairFromJSONTyped(json: any, ignoreDiscriminator: boolean): DomainValuePair {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'domain': !exists(json, 'domain') ? undefined : DomainFromJSON(json['domain']),
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function DomainValuePairToJSON(value?: DomainValuePair | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'domain': DomainToJSON(value.domain),
        'value': value.value,
    };
}

