/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminTableUser } from './AdminTableUser';
import {
    AdminTableUserFromJSON,
    AdminTableUserFromJSONTyped,
    AdminTableUserToJSON,
} from './AdminTableUser';

/**
 * 
 * @export
 * @interface AdminUserListResponse
 */
export interface AdminUserListResponse {
    /**
     * 
     * @type {Array<AdminTableUser>}
     * @memberof AdminUserListResponse
     */
    users: Array<AdminTableUser>;
}

/**
 * Check if a given object implements the AdminUserListResponse interface.
 */
export function instanceOfAdminUserListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "users" in value;

    return isInstance;
}

export function AdminUserListResponseFromJSON(json: any): AdminUserListResponse {
    return AdminUserListResponseFromJSONTyped(json, false);
}

export function AdminUserListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminUserListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'users': ((json['users'] as Array<any>).map(AdminTableUserFromJSON)),
    };
}

export function AdminUserListResponseToJSON(value?: AdminUserListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'users': ((value.users as Array<any>).map(AdminTableUserToJSON)),
    };
}

