/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CloneWorkspaceRequest,
  CloneWorkspaceResponse,
  CreateWorkspaceTaskRequest,
  DuplicateWorkspaceTaskRequest,
  EmptyResponse,
  ErrorResponse,
  RecentWorkspaceResponse,
  ShareWorkspaceRequest,
  UpdateWorkspaceRequest,
  Workspace,
  WorkspaceBillingUsageResponse,
  WorkspaceCreatorFreeCreditsRemainingResponse,
  WorkspaceOperation,
  WorkspaceResourceResponse,
  WorkspaceResponse,
  WorkspaceResponseListResponse,
  WorkspaceUserRolesResponse,
} from '../models';
import {
    CloneWorkspaceRequestFromJSON,
    CloneWorkspaceRequestToJSON,
    CloneWorkspaceResponseFromJSON,
    CloneWorkspaceResponseToJSON,
    CreateWorkspaceTaskRequestFromJSON,
    CreateWorkspaceTaskRequestToJSON,
    DuplicateWorkspaceTaskRequestFromJSON,
    DuplicateWorkspaceTaskRequestToJSON,
    EmptyResponseFromJSON,
    EmptyResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    RecentWorkspaceResponseFromJSON,
    RecentWorkspaceResponseToJSON,
    ShareWorkspaceRequestFromJSON,
    ShareWorkspaceRequestToJSON,
    UpdateWorkspaceRequestFromJSON,
    UpdateWorkspaceRequestToJSON,
    WorkspaceFromJSON,
    WorkspaceToJSON,
    WorkspaceBillingUsageResponseFromJSON,
    WorkspaceBillingUsageResponseToJSON,
    WorkspaceCreatorFreeCreditsRemainingResponseFromJSON,
    WorkspaceCreatorFreeCreditsRemainingResponseToJSON,
    WorkspaceOperationFromJSON,
    WorkspaceOperationToJSON,
    WorkspaceResourceResponseFromJSON,
    WorkspaceResourceResponseToJSON,
    WorkspaceResponseFromJSON,
    WorkspaceResponseToJSON,
    WorkspaceResponseListResponseFromJSON,
    WorkspaceResponseListResponseToJSON,
    WorkspaceUserRolesResponseFromJSON,
    WorkspaceUserRolesResponseToJSON,
} from '../models';

export interface WorkspacesApiCloneWorkspaceOperationRequest {
    workspaceNamespace: string;
    terraName: string;
    body?: CloneWorkspaceRequest;
}

export interface WorkspacesApiCreateWorkspaceRequest {
    workspace?: Workspace;
}

export interface WorkspacesApiCreateWorkspaceAsyncRequest {
    workspace?: Workspace;
}

export interface WorkspacesApiDeleteWorkspaceRequest {
    workspaceNamespace: string;
    terraName: string;
}

export interface WorkspacesApiDuplicateWorkspaceAsyncRequest {
    workspaceNamespace: string;
    terraName: string;
    body?: CloneWorkspaceRequest;
}

export interface WorkspacesApiGetBillingUsageRequest {
    workspaceNamespace: string;
    terraName: string;
}

export interface WorkspacesApiGetFirecloudWorkspaceUserRolesRequest {
    workspaceNamespace: string;
    terraName: string;
}

export interface WorkspacesApiGetWorkspaceRequest {
    workspaceNamespace: string;
    terraName: string;
}

export interface WorkspacesApiGetWorkspaceAccessRequest {
    workspaceNamespace: string;
}

export interface WorkspacesApiGetWorkspaceCreatorFreeCreditsRemainingRequest {
    workspaceNamespace: string;
    terraName: string;
}

export interface WorkspacesApiGetWorkspaceOperationRequest {
    id: number;
}

export interface WorkspacesApiGetWorkspaceResourcesV2Request {
    workspaceNamespace: string;
    terraName: string;
    resourceTypesToFetch?: Array<string>;
}

export interface WorkspacesApiNotebookTransferCompleteRequest {
    workspaceNamespace: string;
    terraName: string;
}

export interface WorkspacesApiProcessCreateWorkspaceTaskRequest {
    request: CreateWorkspaceTaskRequest;
}

export interface WorkspacesApiProcessDuplicateWorkspaceTaskRequest {
    request: DuplicateWorkspaceTaskRequest;
}

export interface WorkspacesApiPublishCommunityWorkspaceRequest {
    workspaceNamespace: string;
}

export interface WorkspacesApiShareWorkspacePatchRequest {
    workspaceNamespace: string;
    terraName: string;
    body?: ShareWorkspaceRequest;
}

export interface WorkspacesApiUpdateRecentWorkspacesRequest {
    workspaceNamespace: string;
    terraName: string;
}

export interface WorkspacesApiUpdateWorkspaceOperationRequest {
    workspaceNamespace: string;
    terraName: string;
    workspace?: UpdateWorkspaceRequest;
}

/**
 * 
 */
export class WorkspacesApi extends runtime.BaseAPI {

    /**
     * Duplicate an existing workspace, with given modifications to workspace metadata. Caller will own the newly duplicated workspace, and must have read access to the source workspace. In addition to workspace metadata, the following will also be duplicated:   - the associated Firecloud workspace   - cohorts, along with reviews and annotations   - notebooks located in the default notebook directory for this workspace 
     */
    async cloneWorkspaceRaw(requestParameters: WorkspacesApiCloneWorkspaceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CloneWorkspaceResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling cloneWorkspace.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling cloneWorkspace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/duplicate`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CloneWorkspaceRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CloneWorkspaceResponseFromJSON(jsonValue));
    }

    /**
     * Duplicate an existing workspace, with given modifications to workspace metadata. Caller will own the newly duplicated workspace, and must have read access to the source workspace. In addition to workspace metadata, the following will also be duplicated:   - the associated Firecloud workspace   - cohorts, along with reviews and annotations   - notebooks located in the default notebook directory for this workspace 
     */
    async cloneWorkspace(workspaceNamespace: string, terraName: string, body?: CloneWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CloneWorkspaceResponse> {
        const response = await this.cloneWorkspaceRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, body: body }, initOverrides);
        return await response.value();
    }

    /**
     * Creates a workspace
     */
    async createWorkspaceRaw(requestParameters: WorkspacesApiCreateWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Workspace>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkspaceToJSON(requestParameters.workspace),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceFromJSON(jsonValue));
    }

    /**
     * Creates a workspace
     */
    async createWorkspace(workspace?: Workspace, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Workspace> {
        const response = await this.createWorkspaceRaw({ workspace: workspace }, initOverrides);
        return await response.value();
    }

    /**
     * Creates a workspace asynchronously
     */
    async createWorkspaceAsyncRaw(requestParameters: WorkspacesApiCreateWorkspaceAsyncRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceOperation>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/createAsync`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkspaceToJSON(requestParameters.workspace),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceOperationFromJSON(jsonValue));
    }

    /**
     * Creates a workspace asynchronously
     */
    async createWorkspaceAsync(workspace?: Workspace, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceOperation> {
        const response = await this.createWorkspaceAsyncRaw({ workspace: workspace }, initOverrides);
        return await response.value();
    }

    /**
     * Deletes the workspace definition with the specified ID and namespace
     */
    async deleteWorkspaceRaw(requestParameters: WorkspacesApiDeleteWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling deleteWorkspace.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling deleteWorkspace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Deletes the workspace definition with the specified ID and namespace
     */
    async deleteWorkspace(workspaceNamespace: string, terraName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.deleteWorkspaceRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName }, initOverrides);
        return await response.value();
    }

    /**
     * Asynchronously duplicate an existing workspace. Semantics otherwise match cloneWorkspace. 
     */
    async duplicateWorkspaceAsyncRaw(requestParameters: WorkspacesApiDuplicateWorkspaceAsyncRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceOperation>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling duplicateWorkspaceAsync.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling duplicateWorkspaceAsync.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/duplicateAsync`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CloneWorkspaceRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceOperationFromJSON(jsonValue));
    }

    /**
     * Asynchronously duplicate an existing workspace. Semantics otherwise match cloneWorkspace. 
     */
    async duplicateWorkspaceAsync(workspaceNamespace: string, terraName: string, body?: CloneWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceOperation> {
        const response = await this.duplicateWorkspaceAsyncRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, body: body }, initOverrides);
        return await response.value();
    }

    /**
     * Gets the free tier billing spend for a specified workspace
     */
    async getBillingUsageRaw(requestParameters: WorkspacesApiGetBillingUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceBillingUsageResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getBillingUsage.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getBillingUsage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/billing-usage`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceBillingUsageResponseFromJSON(jsonValue));
    }

    /**
     * Gets the free tier billing spend for a specified workspace
     */
    async getBillingUsage(workspaceNamespace: string, terraName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceBillingUsageResponse> {
        const response = await this.getBillingUsageRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the user roles for all collaborators on a workspace
     */
    async getFirecloudWorkspaceUserRolesRaw(requestParameters: WorkspacesApiGetFirecloudWorkspaceUserRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceUserRolesResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getFirecloudWorkspaceUserRoles.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getFirecloudWorkspaceUserRoles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/userRoles`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceUserRolesResponseFromJSON(jsonValue));
    }

    /**
     * Returns the user roles for all collaborators on a workspace
     */
    async getFirecloudWorkspaceUserRoles(workspaceNamespace: string, terraName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceUserRolesResponse> {
        const response = await this.getFirecloudWorkspaceUserRolesRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the workspaces edited by the user in descending order of update time
     */
    async getUserRecentWorkspacesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecentWorkspaceResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/user-recent-workspaces`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecentWorkspaceResponseFromJSON(jsonValue));
    }

    /**
     * Returns the workspaces edited by the user in descending order of update time
     */
    async getUserRecentWorkspaces(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecentWorkspaceResponse> {
        const response = await this.getUserRecentWorkspacesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns the workspace definition with the specified ID and namespace
     */
    async getWorkspaceRaw(requestParameters: WorkspacesApiGetWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getWorkspace.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getWorkspace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceResponseFromJSON(jsonValue));
    }

    /**
     * Returns the workspace definition with the specified ID and namespace
     */
    async getWorkspace(workspaceNamespace: string, terraName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceResponse> {
        const response = await this.getWorkspaceRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the WorkspaceAccessLevel Enum as String for  workspace namespace
     */
    async getWorkspaceAccessRaw(requestParameters: WorkspacesApiGetWorkspaceAccessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getWorkspaceAccess.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/access/{workspaceNamespace}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns the WorkspaceAccessLevel Enum as String for  workspace namespace
     */
    async getWorkspaceAccess(workspaceNamespace: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getWorkspaceAccessRaw({ workspaceNamespace: workspaceNamespace }, initOverrides);
        return await response.value();
    }

    /**
     * Gets the free credits remaining for the creator of a specified workspace
     */
    async getWorkspaceCreatorFreeCreditsRemainingRaw(requestParameters: WorkspacesApiGetWorkspaceCreatorFreeCreditsRemainingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceCreatorFreeCreditsRemainingResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getWorkspaceCreatorFreeCreditsRemaining.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getWorkspaceCreatorFreeCreditsRemaining.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/creator-free-credits-remaining`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceCreatorFreeCreditsRemainingResponseFromJSON(jsonValue));
    }

    /**
     * Gets the free credits remaining for the creator of a specified workspace
     */
    async getWorkspaceCreatorFreeCreditsRemaining(workspaceNamespace: string, terraName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceCreatorFreeCreditsRemainingResponse> {
        const response = await this.getWorkspaceCreatorFreeCreditsRemainingRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a workspace operation, only accessible to the creator of the operation.
     */
    async getWorkspaceOperationRaw(requestParameters: WorkspacesApiGetWorkspaceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceOperation>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getWorkspaceOperation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/operations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceOperationFromJSON(jsonValue));
    }

    /**
     * Returns a workspace operation, only accessible to the creator of the operation.
     */
    async getWorkspaceOperation(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceOperation> {
        const response = await this.getWorkspaceOperationRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Gets a user defined selection of objects contained within a workspace
     */
    async getWorkspaceResourcesV2Raw(requestParameters: WorkspacesApiGetWorkspaceResourcesV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceResourceResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getWorkspaceResourcesV2.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getWorkspaceResourcesV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.resourceTypesToFetch) {
            queryParameters['resourceTypesToFetch'] = requestParameters.resourceTypesToFetch.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v2/workspaces/{workspaceNamespace}/{terraName}/resources`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceResourceResponseFromJSON(jsonValue));
    }

    /**
     * Gets a user defined selection of objects contained within a workspace
     */
    async getWorkspaceResourcesV2(workspaceNamespace: string, terraName: string, resourceTypesToFetch?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceResourceResponse> {
        const response = await this.getWorkspaceResourcesV2Raw({ workspaceNamespace: workspaceNamespace, terraName: terraName, resourceTypesToFetch: resourceTypesToFetch }, initOverrides);
        return await response.value();
    }

    /**
     * Returns all workspaces that a user has access to
     */
    async getWorkspacesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceResponseListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceResponseListResponseFromJSON(jsonValue));
    }

    /**
     * Returns all workspaces that a user has access to
     */
    async getWorkspaces(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceResponseListResponse> {
        const response = await this.getWorkspacesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Checks if the notebooks have been copied to the workspace
     */
    async notebookTransferCompleteRaw(requestParameters: WorkspacesApiNotebookTransferCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling notebookTransferComplete.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling notebookTransferComplete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/isNotebookTransferComplete`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Checks if the notebooks have been copied to the workspace
     */
    async notebookTransferComplete(workspaceNamespace: string, terraName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.notebookTransferCompleteRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName }, initOverrides);
        return await response.value();
    }

    /**
     * Asynchronously creates a workspace. Note: unlike other cloud tasks, this task requires passing along the user\'s auth credential header. 
     */
    async processCreateWorkspaceTaskRaw(requestParameters: WorkspacesApiProcessCreateWorkspaceTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling processCreateWorkspaceTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cloudTask/createWorkspace`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWorkspaceTaskRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Asynchronously creates a workspace. Note: unlike other cloud tasks, this task requires passing along the user\'s auth credential header. 
     */
    async processCreateWorkspaceTask(request: CreateWorkspaceTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.processCreateWorkspaceTaskRaw({ request: request }, initOverrides);
    }

    /**
     * Asynchronously duplicates a workspace. Note: unlike other cloud tasks, this task requires passing along the user\'s auth credential header. 
     */
    async processDuplicateWorkspaceTaskRaw(requestParameters: WorkspacesApiProcessDuplicateWorkspaceTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling processDuplicateWorkspaceTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/cloudTask/duplicateWorkspace`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DuplicateWorkspaceTaskRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Asynchronously duplicates a workspace. Note: unlike other cloud tasks, this task requires passing along the user\'s auth credential header. 
     */
    async processDuplicateWorkspaceTask(request: DuplicateWorkspaceTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.processDuplicateWorkspaceTaskRaw({ request: request }, initOverrides);
    }

    /**
     * Publishes a workspace as Community Workspace by Owner
     */
    async publishCommunityWorkspaceRaw(requestParameters: WorkspacesApiPublishCommunityWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling publishCommunityWorkspace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/publishCommunityWorkspace`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Publishes a workspace as Community Workspace by Owner
     */
    async publishCommunityWorkspace(workspaceNamespace: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.publishCommunityWorkspaceRaw({ workspaceNamespace: workspaceNamespace }, initOverrides);
        return await response.value();
    }

    /**
     * Shares/unshares a workspace with the given users and permissions. Use the WorkspaceAccessLevel enum value \"NO ACCESS\" to unshare.  All existing users and roles who are not specified in this request are not affected.
     */
    async shareWorkspacePatchRaw(requestParameters: WorkspacesApiShareWorkspacePatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceUserRolesResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling shareWorkspacePatch.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling shareWorkspacePatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/share`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ShareWorkspaceRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceUserRolesResponseFromJSON(jsonValue));
    }

    /**
     * Shares/unshares a workspace with the given users and permissions. Use the WorkspaceAccessLevel enum value \"NO ACCESS\" to unshare.  All existing users and roles who are not specified in this request are not affected.
     */
    async shareWorkspacePatch(workspaceNamespace: string, terraName: string, body?: ShareWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceUserRolesResponse> {
        const response = await this.shareWorkspacePatchRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, body: body }, initOverrides);
        return await response.value();
    }

    /**
     * Add/update user recent workspaces
     */
    async updateRecentWorkspacesRaw(requestParameters: WorkspacesApiUpdateRecentWorkspacesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecentWorkspaceResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling updateRecentWorkspaces.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling updateRecentWorkspaces.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/user-recent-workspaces/update`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecentWorkspaceResponseFromJSON(jsonValue));
    }

    /**
     * Add/update user recent workspaces
     */
    async updateRecentWorkspaces(workspaceNamespace: string, terraName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecentWorkspaceResponse> {
        const response = await this.updateRecentWorkspacesRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName }, initOverrides);
        return await response.value();
    }

    /**
     * Modifies the workspace definition with the specified ID and namespace; fields that are omitted will not be modified
     */
    async updateWorkspaceRaw(requestParameters: WorkspacesApiUpdateWorkspaceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Workspace>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling updateWorkspace.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling updateWorkspace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWorkspaceRequestToJSON(requestParameters.workspace),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceFromJSON(jsonValue));
    }

    /**
     * Modifies the workspace definition with the specified ID and namespace; fields that are omitted will not be modified
     */
    async updateWorkspace(workspaceNamespace: string, terraName: string, workspace?: UpdateWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Workspace> {
        const response = await this.updateWorkspaceRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, workspace: workspace }, initOverrides);
        return await response.value();
    }

}
