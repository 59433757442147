/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParticipantData
 */
export interface ParticipantData {
    /**
     * The date of the event
     * @type {string}
     * @memberof ParticipantData
     */
    itemDate: string;
    /**
     * the domain of this data.
     * @type {string}
     * @memberof ParticipantData
     */
    domain?: string;
    /**
     * The standard name of this data
     * @type {string}
     * @memberof ParticipantData
     */
    standardName?: string;
    /**
     * The age at event for the participant
     * @type {number}
     * @memberof ParticipantData
     */
    ageAtEvent?: number;
    /**
     * The standard concept id
     * @type {number}
     * @memberof ParticipantData
     */
    standardConceptId?: number;
    /**
     * The source concept id
     * @type {number}
     * @memberof ParticipantData
     */
    sourceConceptId?: number;
    /**
     * The source vocabulary type of this data
     * @type {string}
     * @memberof ParticipantData
     */
    sourceVocabulary?: string;
    /**
     * The standard vocabulary of this data
     * @type {string}
     * @memberof ParticipantData
     */
    standardVocabulary?: string;
    /**
     * The source name of this data
     * @type {string}
     * @memberof ParticipantData
     */
    sourceName?: string;
    /**
     * The source code of this data
     * @type {string}
     * @memberof ParticipantData
     */
    sourceCode?: string;
    /**
     * The standard code of this data
     * @type {string}
     * @memberof ParticipantData
     */
    standardCode?: string;
    /**
     * The source value of this data
     * @type {string}
     * @memberof ParticipantData
     */
    value?: string;
    /**
     * The visit type of this data
     * @type {string}
     * @memberof ParticipantData
     */
    visitType?: string;
    /**
     * The number of mentions of this data
     * @type {string}
     * @memberof ParticipantData
     */
    numMentions?: string;
    /**
     * The date of first mention of this data
     * @type {string}
     * @memberof ParticipantData
     */
    firstMention?: string;
    /**
     * The date of last mention of this data
     * @type {string}
     * @memberof ParticipantData
     */
    lastMention?: string;
    /**
     * The unit of this data
     * @type {string}
     * @memberof ParticipantData
     */
    unit?: string;
    /**
     * The quantity of this data
     * @type {string}
     * @memberof ParticipantData
     */
    dose?: string;
    /**
     * The strength of this data
     * @type {string}
     * @memberof ParticipantData
     */
    strength?: string;
    /**
     * The route of this data
     * @type {string}
     * @memberof ParticipantData
     */
    route?: string;
    /**
     * The reference range of this data
     * @type {string}
     * @memberof ParticipantData
     */
    refRange?: string;
    /**
     * The name of this survey
     * @type {string}
     * @memberof ParticipantData
     */
    survey?: string;
    /**
     * a question in this survey
     * @type {string}
     * @memberof ParticipantData
     */
    question?: string;
    /**
     * an answer in this survey
     * @type {string}
     * @memberof ParticipantData
     */
    answer?: string;
}

/**
 * Check if a given object implements the ParticipantData interface.
 */
export function instanceOfParticipantData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "itemDate" in value;

    return isInstance;
}

export function ParticipantDataFromJSON(json: any): ParticipantData {
    return ParticipantDataFromJSONTyped(json, false);
}

export function ParticipantDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemDate': json['itemDate'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'standardName': !exists(json, 'standardName') ? undefined : json['standardName'],
        'ageAtEvent': !exists(json, 'ageAtEvent') ? undefined : json['ageAtEvent'],
        'standardConceptId': !exists(json, 'standardConceptId') ? undefined : json['standardConceptId'],
        'sourceConceptId': !exists(json, 'sourceConceptId') ? undefined : json['sourceConceptId'],
        'sourceVocabulary': !exists(json, 'sourceVocabulary') ? undefined : json['sourceVocabulary'],
        'standardVocabulary': !exists(json, 'standardVocabulary') ? undefined : json['standardVocabulary'],
        'sourceName': !exists(json, 'sourceName') ? undefined : json['sourceName'],
        'sourceCode': !exists(json, 'sourceCode') ? undefined : json['sourceCode'],
        'standardCode': !exists(json, 'standardCode') ? undefined : json['standardCode'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'visitType': !exists(json, 'visitType') ? undefined : json['visitType'],
        'numMentions': !exists(json, 'numMentions') ? undefined : json['numMentions'],
        'firstMention': !exists(json, 'firstMention') ? undefined : json['firstMention'],
        'lastMention': !exists(json, 'lastMention') ? undefined : json['lastMention'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'dose': !exists(json, 'dose') ? undefined : json['dose'],
        'strength': !exists(json, 'strength') ? undefined : json['strength'],
        'route': !exists(json, 'route') ? undefined : json['route'],
        'refRange': !exists(json, 'refRange') ? undefined : json['refRange'],
        'survey': !exists(json, 'survey') ? undefined : json['survey'],
        'question': !exists(json, 'question') ? undefined : json['question'],
        'answer': !exists(json, 'answer') ? undefined : json['answer'],
    };
}

export function ParticipantDataToJSON(value?: ParticipantData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'itemDate': value.itemDate,
        'domain': value.domain,
        'standardName': value.standardName,
        'ageAtEvent': value.ageAtEvent,
        'standardConceptId': value.standardConceptId,
        'sourceConceptId': value.sourceConceptId,
        'sourceVocabulary': value.sourceVocabulary,
        'standardVocabulary': value.standardVocabulary,
        'sourceName': value.sourceName,
        'sourceCode': value.sourceCode,
        'standardCode': value.standardCode,
        'value': value.value,
        'visitType': value.visitType,
        'numMentions': value.numMentions,
        'firstMention': value.firstMention,
        'lastMention': value.lastMention,
        'unit': value.unit,
        'dose': value.dose,
        'strength': value.strength,
        'route': value.route,
        'refRange': value.refRange,
        'survey': value.survey,
        'question': value.question,
        'answer': value.answer,
    };
}

