/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillingStatus } from './BillingStatus';
import {
    BillingStatusFromJSON,
    BillingStatusFromJSONTyped,
    BillingStatusToJSON,
} from './BillingStatus';
import type { Cohort } from './Cohort';
import {
    CohortFromJSON,
    CohortFromJSONTyped,
    CohortToJSON,
} from './Cohort';
import type { CohortReview } from './CohortReview';
import {
    CohortReviewFromJSON,
    CohortReviewFromJSONTyped,
    CohortReviewToJSON,
} from './CohortReview';
import type { ConceptSet } from './ConceptSet';
import {
    ConceptSetFromJSON,
    ConceptSetFromJSONTyped,
    ConceptSetToJSON,
} from './ConceptSet';
import type { DataSet } from './DataSet';
import {
    DataSetFromJSON,
    DataSetFromJSONTyped,
    DataSetToJSON,
} from './DataSet';
import type { FileDetail } from './FileDetail';
import {
    FileDetailFromJSON,
    FileDetailFromJSONTyped,
    FileDetailToJSON,
} from './FileDetail';

/**
 * 
 * @export
 * @interface WorkspaceResource
 */
export interface WorkspaceResource {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceResource
     */
    workspaceId?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceResource
     */
    workspaceNamespace: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceResource
     */
    workspaceFirecloudName: string;
    /**
     * 
     * @type {BillingStatus}
     * @memberof WorkspaceResource
     */
    workspaceBillingStatus: BillingStatus;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceResource
     */
    cdrVersionId: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceResource
     */
    accessTierShortName: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceResource
     */
    permission: string;
    /**
     * 
     * @type {Cohort}
     * @memberof WorkspaceResource
     */
    cohort?: Cohort;
    /**
     * 
     * @type {CohortReview}
     * @memberof WorkspaceResource
     */
    cohortReview?: CohortReview;
    /**
     * 
     * @type {FileDetail}
     * @memberof WorkspaceResource
     */
    notebook?: FileDetail;
    /**
     * 
     * @type {ConceptSet}
     * @memberof WorkspaceResource
     */
    conceptSet?: ConceptSet;
    /**
     * 
     * @type {DataSet}
     * @memberof WorkspaceResource
     */
    dataSet?: DataSet;
    /**
     * The user who last modified this resource.
     * @type {string}
     * @memberof WorkspaceResource
     */
    lastModifiedBy?: string;
    /**
     * the resource's last modified time, in milliseconds since the epoch
     * @type {number}
     * @memberof WorkspaceResource
     */
    lastModifiedEpochMillis: number;
    /**
     * Whether an administrator has prevented most actions on the workspace for all users
     * @type {boolean}
     * @memberof WorkspaceResource
     */
    adminLocked: boolean;
}

/**
 * Check if a given object implements the WorkspaceResource interface.
 */
export function instanceOfWorkspaceResource(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "workspaceNamespace" in value;
    isInstance = isInstance && "workspaceFirecloudName" in value;
    isInstance = isInstance && "workspaceBillingStatus" in value;
    isInstance = isInstance && "cdrVersionId" in value;
    isInstance = isInstance && "accessTierShortName" in value;
    isInstance = isInstance && "permission" in value;
    isInstance = isInstance && "lastModifiedEpochMillis" in value;
    isInstance = isInstance && "adminLocked" in value;

    return isInstance;
}

export function WorkspaceResourceFromJSON(json: any): WorkspaceResource {
    return WorkspaceResourceFromJSONTyped(json, false);
}

export function WorkspaceResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workspaceId': !exists(json, 'workspaceId') ? undefined : json['workspaceId'],
        'workspaceNamespace': json['workspaceNamespace'],
        'workspaceFirecloudName': json['workspaceFirecloudName'],
        'workspaceBillingStatus': BillingStatusFromJSON(json['workspaceBillingStatus']),
        'cdrVersionId': json['cdrVersionId'],
        'accessTierShortName': json['accessTierShortName'],
        'permission': json['permission'],
        'cohort': !exists(json, 'cohort') ? undefined : CohortFromJSON(json['cohort']),
        'cohortReview': !exists(json, 'cohortReview') ? undefined : CohortReviewFromJSON(json['cohortReview']),
        'notebook': !exists(json, 'notebook') ? undefined : FileDetailFromJSON(json['notebook']),
        'conceptSet': !exists(json, 'conceptSet') ? undefined : ConceptSetFromJSON(json['conceptSet']),
        'dataSet': !exists(json, 'dataSet') ? undefined : DataSetFromJSON(json['dataSet']),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedEpochMillis': json['lastModifiedEpochMillis'],
        'adminLocked': json['adminLocked'],
    };
}

export function WorkspaceResourceToJSON(value?: WorkspaceResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workspaceId': value.workspaceId,
        'workspaceNamespace': value.workspaceNamespace,
        'workspaceFirecloudName': value.workspaceFirecloudName,
        'workspaceBillingStatus': BillingStatusToJSON(value.workspaceBillingStatus),
        'cdrVersionId': value.cdrVersionId,
        'accessTierShortName': value.accessTierShortName,
        'permission': value.permission,
        'cohort': CohortToJSON(value.cohort),
        'cohortReview': CohortReviewToJSON(value.cohortReview),
        'notebook': FileDetailToJSON(value.notebook),
        'conceptSet': ConceptSetToJSON(value.conceptSet),
        'dataSet': DataSetToJSON(value.dataSet),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedEpochMillis': value.lastModifiedEpochMillis,
        'adminLocked': value.adminLocked,
    };
}

