/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { Authority } from './Authority';
import {
    AuthorityFromJSON,
    AuthorityFromJSONTyped,
    AuthorityToJSON,
} from './Authority';
import type { Degree } from './Degree';
import {
    DegreeFromJSON,
    DegreeFromJSONTyped,
    DegreeToJSON,
} from './Degree';
import type { DemographicSurvey } from './DemographicSurvey';
import {
    DemographicSurveyFromJSON,
    DemographicSurveyFromJSONTyped,
    DemographicSurveyToJSON,
} from './DemographicSurvey';
import type { DemographicSurveyV2 } from './DemographicSurveyV2';
import {
    DemographicSurveyV2FromJSON,
    DemographicSurveyV2FromJSONTyped,
    DemographicSurveyV2ToJSON,
} from './DemographicSurveyV2';
import type { GeneralDiscoverySource } from './GeneralDiscoverySource';
import {
    GeneralDiscoverySourceFromJSON,
    GeneralDiscoverySourceFromJSONTyped,
    GeneralDiscoverySourceToJSON,
} from './GeneralDiscoverySource';
import type { PageVisit } from './PageVisit';
import {
    PageVisitFromJSON,
    PageVisitFromJSONTyped,
    PageVisitToJSON,
} from './PageVisit';
import type { PartnerDiscoverySource } from './PartnerDiscoverySource';
import {
    PartnerDiscoverySourceFromJSON,
    PartnerDiscoverySourceFromJSONTyped,
    PartnerDiscoverySourceToJSON,
} from './PartnerDiscoverySource';
import type { ProfileAccessModules } from './ProfileAccessModules';
import {
    ProfileAccessModulesFromJSON,
    ProfileAccessModulesFromJSONTyped,
    ProfileAccessModulesToJSON,
} from './ProfileAccessModules';
import type { UserTierEligibility } from './UserTierEligibility';
import {
    UserTierEligibilityFromJSON,
    UserTierEligibilityFromJSONTyped,
    UserTierEligibilityToJSON,
} from './UserTierEligibility';
import type { VerifiedInstitutionalAffiliation } from './VerifiedInstitutionalAffiliation';
import {
    VerifiedInstitutionalAffiliationFromJSON,
    VerifiedInstitutionalAffiliationFromJSONTyped,
    VerifiedInstitutionalAffiliationToJSON,
} from './VerifiedInstitutionalAffiliation';

/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * researchallofus userId
     * @type {number}
     * @memberof Profile
     */
    userId?: number;
    /**
     * The username prefix, e.g. "gjordan". Note that this is different from the use of "username" elsewhere in the RW codebase, where username represents the full GSuite email address.
     * @type {string}
     * @memberof Profile
     */
    username: string;
    /**
     * A value which can be used to secure API calls during the account creation flow, prior to account login.
     * @type {string}
     * @memberof Profile
     */
    creationNonce?: string;
    /**
     * email address that can be used to contact the user
     * @type {string}
     * @memberof Profile
     */
    contactEmail?: string;
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    firstSignInTime?: number;
    /**
     * Short names of access tiers available to the user.
     * @type {Array<string>}
     * @memberof Profile
     */
    accessTierShortNames?: Array<string>;
    /**
     * User eligibility of each acces tier based on their institution's tier requirements.
     * @type {Array<UserTierEligibility>}
     * @memberof Profile
     */
    tierEligibilities?: Array<UserTierEligibility>;
    /**
     * 
     * @type {Array<Degree>}
     * @memberof Profile
     */
    degrees?: Array<Degree>;
    /**
     * 
     * @type {Array<GeneralDiscoverySource>}
     * @memberof Profile
     */
    generalDiscoverySources: Array<GeneralDiscoverySource>;
    /**
     * Additional information about the 'OTHER' general discovery source. Present iff generalDiscoverySources contains 'OTHER'.
     * @type {string}
     * @memberof Profile
     */
    generalDiscoverySourceOtherText?: string;
    /**
     * 
     * @type {Array<PartnerDiscoverySource>}
     * @memberof Profile
     */
    partnerDiscoverySources: Array<PartnerDiscoverySource>;
    /**
     * Additional information about the 'OTHER' partner discovery source. Present iff partnerDiscoverySources contains 'OTHER'.
     * @type {string}
     * @memberof Profile
     */
    partnerDiscoverySourceOtherText?: string;
    /**
     * the user's given name (e.g. Alice)
     * @type {string}
     * @memberof Profile
     */
    givenName?: string;
    /**
     * the user's family  name (e.g. Jones)
     * @type {string}
     * @memberof Profile
     */
    familyName?: string;
    /**
     * the link to the user's professional url
     * @type {string}
     * @memberof Profile
     */
    professionalUrl?: string;
    /**
     * authorities granted to this user
     * @type {Array<Authority>}
     * @memberof Profile
     */
    authorities?: Array<Authority>;
    /**
     * pages user has visited
     * @type {Array<PageVisit>}
     * @memberof Profile
     */
    pageVisits?: Array<PageVisit>;
    /**
     * Timestamp when the user completed a demographic survey in milliseconds since the UNIX epoch.
     * @type {number}
     * @memberof Profile
     */
    demographicSurveyCompletionTime?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    disabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    areaOfResearch?: string;
    /**
     * 
     * @type {VerifiedInstitutionalAffiliation}
     * @memberof Profile
     */
    verifiedInstitutionalAffiliation?: VerifiedInstitutionalAffiliation;
    /**
     * 
     * @type {DemographicSurvey}
     * @memberof Profile
     */
    demographicSurvey?: DemographicSurvey;
    /**
     * The user's NIH username
     * @type {string}
     * @memberof Profile
     */
    eraCommonsLinkedNihUsername?: string;
    /**
     * The FireCloud-calculated expiration time
     * @type {number}
     * @memberof Profile
     */
    eraCommonsLinkExpireTime?: number;
    /**
     * Version of the data user code of conduct agreement that the user last signed.
     * @type {number}
     * @memberof Profile
     */
    duccSignedVersion?: number;
    /**
     * The initials used for the user's most recent signing of the DUCC
     * @type {string}
     * @memberof Profile
     */
    duccSignedInitials?: string;
    /**
     * The date of the user's most recent signature of the DUCC
     * @type {number}
     * @memberof Profile
     */
    duccCompletionTimeEpochMillis?: number;
    /**
     * 
     * @type {Address}
     * @memberof Profile
     */
    address?: Address;
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    freeTierUsage?: number;
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    freeTierDollarQuota?: number;
    /**
     * Version of the terms of service that the user last acknowledged. May be null if the user has never acknowledged the Terms of Service.
     * @type {number}
     * @memberof Profile
     */
    latestTermsOfServiceVersion?: number;
    /**
     * Timestamp when the user last acknowledged the terms of service. May be null if the user has never acknowledged the Terms of Service.
     * @type {number}
     * @memberof Profile
     */
    latestTermsOfServiceTime?: number;
    /**
     * The user's identity name in RAS.
     * @type {string}
     * @memberof Profile
     */
    rasLinkUsername?: string;
    /**
     * 
     * @type {ProfileAccessModules}
     * @memberof Profile
     */
    accessModules?: ProfileAccessModules;
    /**
     * 
     * @type {DemographicSurveyV2}
     * @memberof Profile
     */
    demographicSurveyV2?: DemographicSurveyV2;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    newUserSatisfactionSurveyEligibility?: boolean;
    /**
     * Timestamp indicating when the user is ineligible to take the new user survey in ISO 8601 format
     * @type {string}
     * @memberof Profile
     */
    newUserSatisfactionSurveyEligibilityEndTime?: string;
    /**
     * Timestamp indicating when the user's initial credits will expire, if applicable
     * @type {number}
     * @memberof Profile
     */
    initialCreditsExpirationEpochMillis?: number;
    /**
     * When set to true, the user will not be required to spend their initial credits within the expiration period.
     * @type {boolean}
     * @memberof Profile
     */
    initialCreditsExpirationBypassed?: boolean;
}

/**
 * Check if a given object implements the Profile interface.
 */
export function instanceOfProfile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "generalDiscoverySources" in value;
    isInstance = isInstance && "partnerDiscoverySources" in value;

    return isInstance;
}

export function ProfileFromJSON(json: any): Profile {
    return ProfileFromJSONTyped(json, false);
}

export function ProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): Profile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'username': json['username'],
        'creationNonce': !exists(json, 'creationNonce') ? undefined : json['creationNonce'],
        'contactEmail': !exists(json, 'contactEmail') ? undefined : json['contactEmail'],
        'firstSignInTime': !exists(json, 'firstSignInTime') ? undefined : json['firstSignInTime'],
        'accessTierShortNames': !exists(json, 'accessTierShortNames') ? undefined : json['accessTierShortNames'],
        'tierEligibilities': !exists(json, 'tierEligibilities') ? undefined : ((json['tierEligibilities'] as Array<any>).map(UserTierEligibilityFromJSON)),
        'degrees': !exists(json, 'degrees') ? undefined : ((json['degrees'] as Array<any>).map(DegreeFromJSON)),
        'generalDiscoverySources': ((json['generalDiscoverySources'] as Array<any>).map(GeneralDiscoverySourceFromJSON)),
        'generalDiscoverySourceOtherText': !exists(json, 'generalDiscoverySourceOtherText') ? undefined : json['generalDiscoverySourceOtherText'],
        'partnerDiscoverySources': ((json['partnerDiscoverySources'] as Array<any>).map(PartnerDiscoverySourceFromJSON)),
        'partnerDiscoverySourceOtherText': !exists(json, 'partnerDiscoverySourceOtherText') ? undefined : json['partnerDiscoverySourceOtherText'],
        'givenName': !exists(json, 'givenName') ? undefined : json['givenName'],
        'familyName': !exists(json, 'familyName') ? undefined : json['familyName'],
        'professionalUrl': !exists(json, 'professionalUrl') ? undefined : json['professionalUrl'],
        'authorities': !exists(json, 'authorities') ? undefined : ((json['authorities'] as Array<any>).map(AuthorityFromJSON)),
        'pageVisits': !exists(json, 'pageVisits') ? undefined : ((json['pageVisits'] as Array<any>).map(PageVisitFromJSON)),
        'demographicSurveyCompletionTime': !exists(json, 'demographicSurveyCompletionTime') ? undefined : json['demographicSurveyCompletionTime'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
        'areaOfResearch': !exists(json, 'areaOfResearch') ? undefined : json['areaOfResearch'],
        'verifiedInstitutionalAffiliation': !exists(json, 'verifiedInstitutionalAffiliation') ? undefined : VerifiedInstitutionalAffiliationFromJSON(json['verifiedInstitutionalAffiliation']),
        'demographicSurvey': !exists(json, 'demographicSurvey') ? undefined : DemographicSurveyFromJSON(json['demographicSurvey']),
        'eraCommonsLinkedNihUsername': !exists(json, 'eraCommonsLinkedNihUsername') ? undefined : json['eraCommonsLinkedNihUsername'],
        'eraCommonsLinkExpireTime': !exists(json, 'eraCommonsLinkExpireTime') ? undefined : json['eraCommonsLinkExpireTime'],
        'duccSignedVersion': !exists(json, 'duccSignedVersion') ? undefined : json['duccSignedVersion'],
        'duccSignedInitials': !exists(json, 'duccSignedInitials') ? undefined : json['duccSignedInitials'],
        'duccCompletionTimeEpochMillis': !exists(json, 'duccCompletionTimeEpochMillis') ? undefined : json['duccCompletionTimeEpochMillis'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'freeTierUsage': !exists(json, 'freeTierUsage') ? undefined : json['freeTierUsage'],
        'freeTierDollarQuota': !exists(json, 'freeTierDollarQuota') ? undefined : json['freeTierDollarQuota'],
        'latestTermsOfServiceVersion': !exists(json, 'latestTermsOfServiceVersion') ? undefined : json['latestTermsOfServiceVersion'],
        'latestTermsOfServiceTime': !exists(json, 'latestTermsOfServiceTime') ? undefined : json['latestTermsOfServiceTime'],
        'rasLinkUsername': !exists(json, 'rasLinkUsername') ? undefined : json['rasLinkUsername'],
        'accessModules': !exists(json, 'accessModules') ? undefined : ProfileAccessModulesFromJSON(json['accessModules']),
        'demographicSurveyV2': !exists(json, 'demographicSurveyV2') ? undefined : DemographicSurveyV2FromJSON(json['demographicSurveyV2']),
        'newUserSatisfactionSurveyEligibility': !exists(json, 'newUserSatisfactionSurveyEligibility') ? undefined : json['newUserSatisfactionSurveyEligibility'],
        'newUserSatisfactionSurveyEligibilityEndTime': !exists(json, 'newUserSatisfactionSurveyEligibilityEndTime') ? undefined : json['newUserSatisfactionSurveyEligibilityEndTime'],
        'initialCreditsExpirationEpochMillis': !exists(json, 'initialCreditsExpirationEpochMillis') ? undefined : json['initialCreditsExpirationEpochMillis'],
        'initialCreditsExpirationBypassed': !exists(json, 'initialCreditsExpirationBypassed') ? undefined : json['initialCreditsExpirationBypassed'],
    };
}

export function ProfileToJSON(value?: Profile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'username': value.username,
        'creationNonce': value.creationNonce,
        'contactEmail': value.contactEmail,
        'firstSignInTime': value.firstSignInTime,
        'accessTierShortNames': value.accessTierShortNames,
        'tierEligibilities': value.tierEligibilities === undefined ? undefined : ((value.tierEligibilities as Array<any>).map(UserTierEligibilityToJSON)),
        'degrees': value.degrees === undefined ? undefined : ((value.degrees as Array<any>).map(DegreeToJSON)),
        'generalDiscoverySources': ((value.generalDiscoverySources as Array<any>).map(GeneralDiscoverySourceToJSON)),
        'generalDiscoverySourceOtherText': value.generalDiscoverySourceOtherText,
        'partnerDiscoverySources': ((value.partnerDiscoverySources as Array<any>).map(PartnerDiscoverySourceToJSON)),
        'partnerDiscoverySourceOtherText': value.partnerDiscoverySourceOtherText,
        'givenName': value.givenName,
        'familyName': value.familyName,
        'professionalUrl': value.professionalUrl,
        'authorities': value.authorities === undefined ? undefined : ((value.authorities as Array<any>).map(AuthorityToJSON)),
        'pageVisits': value.pageVisits === undefined ? undefined : ((value.pageVisits as Array<any>).map(PageVisitToJSON)),
        'demographicSurveyCompletionTime': value.demographicSurveyCompletionTime,
        'disabled': value.disabled,
        'areaOfResearch': value.areaOfResearch,
        'verifiedInstitutionalAffiliation': VerifiedInstitutionalAffiliationToJSON(value.verifiedInstitutionalAffiliation),
        'demographicSurvey': DemographicSurveyToJSON(value.demographicSurvey),
        'eraCommonsLinkedNihUsername': value.eraCommonsLinkedNihUsername,
        'eraCommonsLinkExpireTime': value.eraCommonsLinkExpireTime,
        'duccSignedVersion': value.duccSignedVersion,
        'duccSignedInitials': value.duccSignedInitials,
        'duccCompletionTimeEpochMillis': value.duccCompletionTimeEpochMillis,
        'address': AddressToJSON(value.address),
        'freeTierUsage': value.freeTierUsage,
        'freeTierDollarQuota': value.freeTierDollarQuota,
        'latestTermsOfServiceVersion': value.latestTermsOfServiceVersion,
        'latestTermsOfServiceTime': value.latestTermsOfServiceTime,
        'rasLinkUsername': value.rasLinkUsername,
        'accessModules': ProfileAccessModulesToJSON(value.accessModules),
        'demographicSurveyV2': DemographicSurveyV2ToJSON(value.demographicSurveyV2),
        'newUserSatisfactionSurveyEligibility': value.newUserSatisfactionSurveyEligibility,
        'newUserSatisfactionSurveyEligibilityEndTime': value.newUserSatisfactionSurveyEligibilityEndTime,
        'initialCreditsExpirationEpochMillis': value.initialCreditsExpirationEpochMillis,
        'initialCreditsExpirationBypassed': value.initialCreditsExpirationBypassed,
    };
}

