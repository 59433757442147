/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Configuration for a single Dataproc cluster.
 * @export
 * @interface DataprocConfig
 */
export interface DataprocConfig {
    /**
     * Optional, number of workers in the cluster. Can be 0 (default), 2 or more. Google Dataproc does not allow 1 worker.
     * @type {number}
     * @memberof DataprocConfig
     */
    numberOfWorkers?: number;
    /**
     * Optional, the machine type determines the number of CPUs and memory for the master node. For example "n1-standard-16" or "n1-highmem-64". If unspecified, defaults to creating a "n1-standard-4" machine. To decide which is right for you, see https://cloud.google.com/compute/docs/machine-types
     * @type {string}
     * @memberof DataprocConfig
     */
    masterMachineType?: string;
    /**
     * Optional, the size in gigabytes of the disk on the master node. Minimum size is 50GB. If unspecified, default size is 100GB.
     * @type {number}
     * @memberof DataprocConfig
     */
    masterDiskSize?: number;
    /**
     * Optional, the machine type determines the number of CPUs and memory for the worker nodes. For example "n1-standard-16" or "n1-highmem-64". If unspecified, defaults to creating a "n1-standard-4" machine. To decide which is right for you, see https://cloud.google.com/compute/docs/machine-types. Ignored if numberOfWorkers is 0.
     * @type {string}
     * @memberof DataprocConfig
     */
    workerMachineType?: string;
    /**
     * Optional, the size in gigabytes of the disk on the master node. Minimum size is 10GB. If unspecified, default size is 100GB. Ignored if numberOfWorkers is 0.
     * @type {number}
     * @memberof DataprocConfig
     */
    workerDiskSize?: number;
    /**
     * Optional, the number of local solid state disks for workers. If unspecified, the default number is 0. Ignored if numberOfWorkers is 0.
     * @type {number}
     * @memberof DataprocConfig
     */
    numberOfWorkerLocalSSDs?: number;
    /**
     * Optional, the number of preemptible workers. If unspecified, the default number is 0. Ignored if numberOfWorkers is 0. For more information, see https://cloud.google.com/compute/docs/instances/preemptible
     * @type {number}
     * @memberof DataprocConfig
     */
    numberOfPreemptibleWorkers?: number;
}

/**
 * Check if a given object implements the DataprocConfig interface.
 */
export function instanceOfDataprocConfig(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataprocConfigFromJSON(json: any): DataprocConfig {
    return DataprocConfigFromJSONTyped(json, false);
}

export function DataprocConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataprocConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numberOfWorkers': !exists(json, 'numberOfWorkers') ? undefined : json['numberOfWorkers'],
        'masterMachineType': !exists(json, 'masterMachineType') ? undefined : json['masterMachineType'],
        'masterDiskSize': !exists(json, 'masterDiskSize') ? undefined : json['masterDiskSize'],
        'workerMachineType': !exists(json, 'workerMachineType') ? undefined : json['workerMachineType'],
        'workerDiskSize': !exists(json, 'workerDiskSize') ? undefined : json['workerDiskSize'],
        'numberOfWorkerLocalSSDs': !exists(json, 'numberOfWorkerLocalSSDs') ? undefined : json['numberOfWorkerLocalSSDs'],
        'numberOfPreemptibleWorkers': !exists(json, 'numberOfPreemptibleWorkers') ? undefined : json['numberOfPreemptibleWorkers'],
    };
}

export function DataprocConfigToJSON(value?: DataprocConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numberOfWorkers': value.numberOfWorkers,
        'masterMachineType': value.masterMachineType,
        'masterDiskSize': value.masterDiskSize,
        'workerMachineType': value.workerMachineType,
        'workerDiskSize': value.workerDiskSize,
        'numberOfWorkerLocalSSDs': value.numberOfWorkerLocalSSDs,
        'numberOfPreemptibleWorkers': value.numberOfPreemptibleWorkers,
    };
}

