/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadOnlyNotebookResponse
 */
export interface ReadOnlyNotebookResponse {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyNotebookResponse
     */
    html?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyNotebookResponse
     */
    text?: string;
}

/**
 * Check if a given object implements the ReadOnlyNotebookResponse interface.
 */
export function instanceOfReadOnlyNotebookResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReadOnlyNotebookResponseFromJSON(json: any): ReadOnlyNotebookResponse {
    return ReadOnlyNotebookResponseFromJSONTyped(json, false);
}

export function ReadOnlyNotebookResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyNotebookResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'html': !exists(json, 'html') ? undefined : json['html'],
        'text': !exists(json, 'text') ? undefined : json['text'],
    };
}

export function ReadOnlyNotebookResponseToJSON(value?: ReadOnlyNotebookResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'html': value.html,
        'text': value.text,
    };
}

