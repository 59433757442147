/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CohortAnnotationDefinition,
  CohortAnnotationDefinitionListResponse,
  EmptyResponse,
} from '../models';
import {
    CohortAnnotationDefinitionFromJSON,
    CohortAnnotationDefinitionToJSON,
    CohortAnnotationDefinitionListResponseFromJSON,
    CohortAnnotationDefinitionListResponseToJSON,
    EmptyResponseFromJSON,
    EmptyResponseToJSON,
} from '../models';

export interface CohortAnnotationDefinitionApiCreateCohortAnnotationDefinitionRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortId: number;
    request: CohortAnnotationDefinition;
}

export interface CohortAnnotationDefinitionApiDeleteCohortAnnotationDefinitionRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortId: number;
    annotationDefinitionId: number;
}

export interface CohortAnnotationDefinitionApiGetCohortAnnotationDefinitionRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortId: number;
    annotationDefinitionId: number;
}

export interface CohortAnnotationDefinitionApiGetCohortAnnotationDefinitionsRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortId: number;
}

export interface CohortAnnotationDefinitionApiUpdateCohortAnnotationDefinitionRequest {
    workspaceNamespace: string;
    terraName: string;
    cohortId: number;
    annotationDefinitionId: number;
    cohortAnnotationDefinition?: CohortAnnotationDefinition;
}

/**
 * 
 */
export class CohortAnnotationDefinitionApi extends runtime.BaseAPI {

    /**
     * This endpoint will create a CohortAnnotationDefinition.
     */
    async createCohortAnnotationDefinitionRaw(requestParameters: CohortAnnotationDefinitionApiCreateCohortAnnotationDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CohortAnnotationDefinition>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling createCohortAnnotationDefinition.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling createCohortAnnotationDefinition.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling createCohortAnnotationDefinition.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling createCohortAnnotationDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/cohorts/{cohortId}/annotationdefinitions`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CohortAnnotationDefinitionToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CohortAnnotationDefinitionFromJSON(jsonValue));
    }

    /**
     * This endpoint will create a CohortAnnotationDefinition.
     */
    async createCohortAnnotationDefinition(workspaceNamespace: string, terraName: string, cohortId: number, request: CohortAnnotationDefinition, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CohortAnnotationDefinition> {
        const response = await this.createCohortAnnotationDefinitionRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortId: cohortId, request: request }, initOverrides);
        return await response.value();
    }

    /**
     * Deletes the CohortAnnotationDefinition with the specified ID
     */
    async deleteCohortAnnotationDefinitionRaw(requestParameters: CohortAnnotationDefinitionApiDeleteCohortAnnotationDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling deleteCohortAnnotationDefinition.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling deleteCohortAnnotationDefinition.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling deleteCohortAnnotationDefinition.');
        }

        if (requestParameters.annotationDefinitionId === null || requestParameters.annotationDefinitionId === undefined) {
            throw new runtime.RequiredError('annotationDefinitionId','Required parameter requestParameters.annotationDefinitionId was null or undefined when calling deleteCohortAnnotationDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/cohorts/{cohortId}/annotationdefinitions/{annotationDefinitionId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))).replace(`{${"annotationDefinitionId"}}`, encodeURIComponent(String(requestParameters.annotationDefinitionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Deletes the CohortAnnotationDefinition with the specified ID
     */
    async deleteCohortAnnotationDefinition(workspaceNamespace: string, terraName: string, cohortId: number, annotationDefinitionId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.deleteCohortAnnotationDefinitionRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortId: cohortId, annotationDefinitionId: annotationDefinitionId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a CohortAnnotationDefinition.
     */
    async getCohortAnnotationDefinitionRaw(requestParameters: CohortAnnotationDefinitionApiGetCohortAnnotationDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CohortAnnotationDefinition>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getCohortAnnotationDefinition.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getCohortAnnotationDefinition.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling getCohortAnnotationDefinition.');
        }

        if (requestParameters.annotationDefinitionId === null || requestParameters.annotationDefinitionId === undefined) {
            throw new runtime.RequiredError('annotationDefinitionId','Required parameter requestParameters.annotationDefinitionId was null or undefined when calling getCohortAnnotationDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/cohorts/{cohortId}/annotationdefinitions/{annotationDefinitionId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))).replace(`{${"annotationDefinitionId"}}`, encodeURIComponent(String(requestParameters.annotationDefinitionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CohortAnnotationDefinitionFromJSON(jsonValue));
    }

    /**
     * Returns a CohortAnnotationDefinition.
     */
    async getCohortAnnotationDefinition(workspaceNamespace: string, terraName: string, cohortId: number, annotationDefinitionId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CohortAnnotationDefinition> {
        const response = await this.getCohortAnnotationDefinitionRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortId: cohortId, annotationDefinitionId: annotationDefinitionId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a collection of CohortAnnotationDefinition.
     */
    async getCohortAnnotationDefinitionsRaw(requestParameters: CohortAnnotationDefinitionApiGetCohortAnnotationDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CohortAnnotationDefinitionListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getCohortAnnotationDefinitions.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getCohortAnnotationDefinitions.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling getCohortAnnotationDefinitions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/cohorts/{cohortId}/annotationdefinitions`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CohortAnnotationDefinitionListResponseFromJSON(jsonValue));
    }

    /**
     * Returns a collection of CohortAnnotationDefinition.
     */
    async getCohortAnnotationDefinitions(workspaceNamespace: string, terraName: string, cohortId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CohortAnnotationDefinitionListResponse> {
        const response = await this.getCohortAnnotationDefinitionsRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortId: cohortId }, initOverrides);
        return await response.value();
    }

    /**
     * modify the CohortAnnotationDefinition.
     */
    async updateCohortAnnotationDefinitionRaw(requestParameters: CohortAnnotationDefinitionApiUpdateCohortAnnotationDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CohortAnnotationDefinition>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling updateCohortAnnotationDefinition.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling updateCohortAnnotationDefinition.');
        }

        if (requestParameters.cohortId === null || requestParameters.cohortId === undefined) {
            throw new runtime.RequiredError('cohortId','Required parameter requestParameters.cohortId was null or undefined when calling updateCohortAnnotationDefinition.');
        }

        if (requestParameters.annotationDefinitionId === null || requestParameters.annotationDefinitionId === undefined) {
            throw new runtime.RequiredError('annotationDefinitionId','Required parameter requestParameters.annotationDefinitionId was null or undefined when calling updateCohortAnnotationDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/cohorts/{cohortId}/annotationdefinitions/{annotationDefinitionId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"cohortId"}}`, encodeURIComponent(String(requestParameters.cohortId))).replace(`{${"annotationDefinitionId"}}`, encodeURIComponent(String(requestParameters.annotationDefinitionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CohortAnnotationDefinitionToJSON(requestParameters.cohortAnnotationDefinition),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CohortAnnotationDefinitionFromJSON(jsonValue));
    }

    /**
     * modify the CohortAnnotationDefinition.
     */
    async updateCohortAnnotationDefinition(workspaceNamespace: string, terraName: string, cohortId: number, annotationDefinitionId: number, cohortAnnotationDefinition?: CohortAnnotationDefinition, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CohortAnnotationDefinition> {
        const response = await this.updateCohortAnnotationDefinitionRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, cohortId: cohortId, annotationDefinitionId: annotationDefinitionId, cohortAnnotationDefinition: cohortAnnotationDefinition }, initOverrides);
        return await response.value();
    }

}
