/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserRole } from './UserRole';
import {
    UserRoleFromJSON,
    UserRoleFromJSONTyped,
    UserRoleToJSON,
} from './UserRole';

/**
 * 
 * @export
 * @interface ShareWorkspaceRequest
 */
export interface ShareWorkspaceRequest {
    /**
     * Associated workspace etag retrieved via reading the workspaces API. If provided, validates that the workspace (and its user roles) has not been modified since this etag was retrieved.
     * @type {string}
     * @memberof ShareWorkspaceRequest
     */
    workspaceEtag?: string;
    /**
     * 
     * @type {Array<UserRole>}
     * @memberof ShareWorkspaceRequest
     */
    items: Array<UserRole>;
}

/**
 * Check if a given object implements the ShareWorkspaceRequest interface.
 */
export function instanceOfShareWorkspaceRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function ShareWorkspaceRequestFromJSON(json: any): ShareWorkspaceRequest {
    return ShareWorkspaceRequestFromJSONTyped(json, false);
}

export function ShareWorkspaceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShareWorkspaceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workspaceEtag': !exists(json, 'workspaceEtag') ? undefined : json['workspaceEtag'],
        'items': ((json['items'] as Array<any>).map(UserRoleFromJSON)),
    };
}

export function ShareWorkspaceRequestToJSON(value?: ShareWorkspaceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workspaceEtag': value.workspaceEtag,
        'items': ((value.items as Array<any>).map(UserRoleToJSON)),
    };
}

