/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConceptSetConceptId } from './ConceptSetConceptId';
import {
    ConceptSetConceptIdFromJSON,
    ConceptSetConceptIdFromJSONTyped,
    ConceptSetConceptIdToJSON,
} from './ConceptSetConceptId';

/**
 * 
 * @export
 * @interface UpdateConceptSetRequest
 */
export interface UpdateConceptSetRequest {
    /**
     * Etag of the concept set being modified. Validates that the concept set has not been modified since this etag was retrieved.
     * @type {string}
     * @memberof UpdateConceptSetRequest
     */
    etag: string;
    /**
     * The IDs of concepts to be added to the concept set.
     * @type {Array<ConceptSetConceptId>}
     * @memberof UpdateConceptSetRequest
     */
    addedConceptSetConceptIds?: Array<ConceptSetConceptId>;
    /**
     * The IDs of concepts to be added to the concept set.
     * @type {Array<ConceptSetConceptId>}
     * @memberof UpdateConceptSetRequest
     */
    removedConceptSetConceptIds?: Array<ConceptSetConceptId>;
}

/**
 * Check if a given object implements the UpdateConceptSetRequest interface.
 */
export function instanceOfUpdateConceptSetRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "etag" in value;

    return isInstance;
}

export function UpdateConceptSetRequestFromJSON(json: any): UpdateConceptSetRequest {
    return UpdateConceptSetRequestFromJSONTyped(json, false);
}

export function UpdateConceptSetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateConceptSetRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'etag': json['etag'],
        'addedConceptSetConceptIds': !exists(json, 'addedConceptSetConceptIds') ? undefined : ((json['addedConceptSetConceptIds'] as Array<any>).map(ConceptSetConceptIdFromJSON)),
        'removedConceptSetConceptIds': !exists(json, 'removedConceptSetConceptIds') ? undefined : ((json['removedConceptSetConceptIds'] as Array<any>).map(ConceptSetConceptIdFromJSON)),
    };
}

export function UpdateConceptSetRequestToJSON(value?: UpdateConceptSetRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'etag': value.etag,
        'addedConceptSetConceptIds': value.addedConceptSetConceptIds === undefined ? undefined : ((value.addedConceptSetConceptIds as Array<any>).map(ConceptSetConceptIdToJSON)),
        'removedConceptSetConceptIds': value.removedConceptSetConceptIds === undefined ? undefined : ((value.removedConceptSetConceptIds as Array<any>).map(ConceptSetConceptIdToJSON)),
    };
}

