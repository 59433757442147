/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ParticipantCountFilter } from './ParticipantCountFilter';
import {
    ParticipantCountFilterFromJSON,
    ParticipantCountFilterFromJSONTyped,
    ParticipantCountFilterToJSON,
} from './ParticipantCountFilter';

/**
 * 
 * @export
 * @interface VariantFilterResponse
 */
export interface VariantFilterResponse {
    /**
     * List of string gene names
     * @type {Array<string>}
     * @memberof VariantFilterResponse
     */
    geneList?: Array<string>;
    /**
     * List of string consequence names
     * @type {Array<string>}
     * @memberof VariantFilterResponse
     */
    consequenceList?: Array<string>;
    /**
     * List of string clinical significance names
     * @type {Array<string>}
     * @memberof VariantFilterResponse
     */
    clinicalSignificanceList?: Array<string>;
    /**
     * Allele count min
     * @type {number}
     * @memberof VariantFilterResponse
     */
    countMin?: number;
    /**
     * Allele count max
     * @type {number}
     * @memberof VariantFilterResponse
     */
    countMax?: number;
    /**
     * Allele number min
     * @type {number}
     * @memberof VariantFilterResponse
     */
    numberMin?: number;
    /**
     * Allele number max
     * @type {number}
     * @memberof VariantFilterResponse
     */
    numberMax?: number;
    /**
     * Allele frequency min(floating point)
     * @type {number}
     * @memberof VariantFilterResponse
     */
    frequencyMin?: number;
    /**
     * Allele number max(floating point)
     * @type {number}
     * @memberof VariantFilterResponse
     */
    frequencyMax?: number;
    /**
     * 
     * @type {ParticipantCountFilter}
     * @memberof VariantFilterResponse
     */
    participantCountRange?: ParticipantCountFilter;
    /**
     * List of vids to exclude from filter
     * @type {Array<string>}
     * @memberof VariantFilterResponse
     */
    exclusionList?: Array<string>;
    /**
     * List of string column names
     * @type {Array<string>}
     * @memberof VariantFilterResponse
     */
    sortByList?: Array<string>;
}

/**
 * Check if a given object implements the VariantFilterResponse interface.
 */
export function instanceOfVariantFilterResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VariantFilterResponseFromJSON(json: any): VariantFilterResponse {
    return VariantFilterResponseFromJSONTyped(json, false);
}

export function VariantFilterResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariantFilterResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'geneList': !exists(json, 'geneList') ? undefined : json['geneList'],
        'consequenceList': !exists(json, 'consequenceList') ? undefined : json['consequenceList'],
        'clinicalSignificanceList': !exists(json, 'clinicalSignificanceList') ? undefined : json['clinicalSignificanceList'],
        'countMin': !exists(json, 'countMin') ? undefined : json['countMin'],
        'countMax': !exists(json, 'countMax') ? undefined : json['countMax'],
        'numberMin': !exists(json, 'numberMin') ? undefined : json['numberMin'],
        'numberMax': !exists(json, 'numberMax') ? undefined : json['numberMax'],
        'frequencyMin': !exists(json, 'frequencyMin') ? undefined : json['frequencyMin'],
        'frequencyMax': !exists(json, 'frequencyMax') ? undefined : json['frequencyMax'],
        'participantCountRange': !exists(json, 'participantCountRange') ? undefined : ParticipantCountFilterFromJSON(json['participantCountRange']),
        'exclusionList': !exists(json, 'exclusionList') ? undefined : json['exclusionList'],
        'sortByList': !exists(json, 'sortByList') ? undefined : json['sortByList'],
    };
}

export function VariantFilterResponseToJSON(value?: VariantFilterResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'geneList': value.geneList,
        'consequenceList': value.consequenceList,
        'clinicalSignificanceList': value.clinicalSignificanceList,
        'countMin': value.countMin,
        'countMax': value.countMax,
        'numberMin': value.numberMin,
        'numberMax': value.numberMax,
        'frequencyMin': value.frequencyMin,
        'frequencyMax': value.frequencyMax,
        'participantCountRange': ParticipantCountFilterToJSON(value.participantCountRange),
        'exclusionList': value.exclusionList,
        'sortByList': value.sortByList,
    };
}

