/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttrName } from './AttrName';
import {
    AttrNameFromJSON,
    AttrNameFromJSONTyped,
    AttrNameToJSON,
} from './AttrName';
import type { Operator } from './Operator';
import {
    OperatorFromJSON,
    OperatorFromJSONTyped,
    OperatorToJSON,
} from './Operator';

/**
 * 
 * @export
 * @interface Attribute
 */
export interface Attribute {
    /**
     * 
     * @type {AttrName}
     * @memberof Attribute
     */
    name: AttrName;
    /**
     * 
     * @type {Operator}
     * @memberof Attribute
     */
    operator?: Operator;
    /**
     * 
     * @type {Array<string>}
     * @memberof Attribute
     */
    operands: Array<string>;
    /**
     * The concept id that maps to concept table.
     * @type {number}
     * @memberof Attribute
     */
    conceptId?: number;
}

/**
 * Check if a given object implements the Attribute interface.
 */
export function instanceOfAttribute(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "operands" in value;

    return isInstance;
}

export function AttributeFromJSON(json: any): Attribute {
    return AttributeFromJSONTyped(json, false);
}

export function AttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Attribute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': AttrNameFromJSON(json['name']),
        'operator': !exists(json, 'operator') ? undefined : OperatorFromJSON(json['operator']),
        'operands': json['operands'],
        'conceptId': !exists(json, 'conceptId') ? undefined : json['conceptId'],
    };
}

export function AttributeToJSON(value?: Attribute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': AttrNameToJSON(value.name),
        'operator': OperatorToJSON(value.operator),
        'operands': value.operands,
        'conceptId': value.conceptId,
    };
}

